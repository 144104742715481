import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import Util from '../../../Util'
import { GetSaleDiamondDetail } from '../detail/Actions/GetSaleDiamonds'

const SaleDiamondInformationDetail = props => {
    const { diamonds, sale, searchDetailDiamond } = props

    return (
        diamonds.map(diamond => {
            if (!diamond.deletedFromSales) {
                return (
                    <div className='overflow-hidden card m-2' key={diamond.id}>
                        <Row>
                            <Col lg="6" className='table_title ps-3'>Diamond</Col>
                            <Col lg="6">
                                {
                                    GetSaleDiamondDetail(
                                        diamond?.diamondProductModel?.certNo,
                                        sale,
                                        searchDetailDiamond
                                    )
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" className='ps-3'>Cost</Col>
                            <Col lg="6">{Util.formatCurrency(diamond?.cost)}</Col>
                        </Row>
                        <Row>
                            <Col lg="6" className='ps-3'>Internal Invoice</Col>
                            <Col lg="6">{diamond?.internalInvoice}</Col>
                        </Row>
                        <Row>
                            <Col lg="6" className='ps-3'>Accounting Status</Col>
                            <Col lg="6">{diamond?.accountingStatus}</Col>
                        </Row>
                    </div>
                )
            }

            return null
        })
    )
}

SaleDiamondInformationDetail.propTypes = {
    diamonds: PropTypes.array.isRequired,
    sale: PropTypes.any.isRequired,
    searchDetailDiamond: PropTypes.bool
}

SaleDiamondInformationDetail.defaultProps = {
    searchDetailDiamond: true
}

export default SaleDiamondInformationDetail