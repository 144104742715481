import React, { Component } from 'react';
import axios from 'axios';
import Auth from '../../../Auth';
import Util from '../../../Util';
import * as globalConst from '../../../global/const';
import { sprintf } from "sprintf-js";

// SCSS
import '../../../css/sales/sale_information.scss'
import { findTypeOfClawStandard, renderIsStandarMetalClaw, renderIsStandarTypeOfClaw } from '../edit/SaleFindTypeClaw';
import { Col, Row } from 'react-bootstrap';
import LoadingSpinnerLayer from '../../LoadingSpinnerLayer';
import { getEngagementRingPicture } from '../Information/EngagementRingPicture';
import { getDiamonProductPicture } from '../Information/LooseDiamondPicture';

class SaleInformationProduct extends Component {

    constructor(props) {
        super();
        this.state = {
            sale: props.sale,
            product: null,
            download_product: true,
            product_not_found: false,
            product_image_url: null,
            product_name: null,
            product_website_url: null,
            loadImage: false,
            showTypeClaw: false,
            typeAndClaw: {
                metalClaw: '',
                metalClawStandard: '',
                typeOfClaw: ''
            },
            loadDataStandard: true,
            classHideImage: ''
        }
        this.fetchJewelleryByReference = this.fetchJewelleryByReference.bind(this)
        this.getProductPictureUrl = this.getProductPictureUrl.bind(this)
        this.getProductImageUrl = this.getProductImageUrl.bind(this)
        this.getProductNameAndWebsiteUrl = this.getProductNameAndWebsiteUrl.bind(this)
    }

    setDataStandard = async () => {
        this.setState({
            loadDataStandard: true
        })

        const { saleType, ringPreference } = this.state.sale

        const response = await findTypeOfClawStandard(
            ringPreference, saleType
        )

        this.setState({
            typeAndClaw: {
                metalClaw: response.metalClaw,
                metalClawStandard: response.metalClawStandard,
                typeOfClaw: response.typeOfClaw
            },
            loadDataStandard: response.loadDataStandard
        })
    }

    setProductDataNameAndWebSite = (
        name = null,
        webSiteURl = null
    ) => {
        this.setState({
            product_name: name ?? null,
            product_website_url: webSiteURl ?? null,
        })
    }

    setProductDataImage = (
        image = null
    ) => {
        this.setState({
            product_image_url: image ?? null
        })
    }

    getProductImageUrl() {
        this.setProductDataImage()

        const { product } = this.state

        if (!product) {
            return null
        }

        const { productPictureModels } = product

        if (!Array.isArray(productPictureModels)) {
            return null
        }

        productPictureModels.map(productPicture => {
            if (
                productPicture.mainPicture === true &&
                productPicture.realLivePicture !== true
            ) {
                this.setProductDataImage(
                    this.getProductPictureUrl(
                        productPicture,
                        product.productCategoryId
                    )
                )

                return;
            }
        })
    }

    getProductNameAndWebsiteUrl() {
        this.setProductDataNameAndWebSite()

        const { product, sale } = this.state

        if (!product) {
            this.setProductDataNameAndWebSite()

            return
        }

        if (!product.name) {
            this.setProductDataNameAndWebSite()

            return
        }

        const { saleType } = sale

        const { name } = product
        let productWebsiteUrl = globalConst.REACT_APP_NOVITA_WEBSITE_DOMAIN

        if (saleType === globalConst.REACT_APP_SALE_TYPE_WEDDING_BANDS) {
            productWebsiteUrl += `${globalConst.REACT_APP_NOVITA_WEBSITE_WEDDING_BANDS}${product.id}`
        }

        if (saleType === globalConst.REACT_APP_SALE_TYPE_JEWELLERY) {
            productWebsiteUrl += `${globalConst.REACT_APP_NOVITA_WEBSITE_JEWELLERY}${product.id}`
        }

        if (saleType === globalConst.REACT_APP_SALE_TYPE_ENGAGEMENT_RINGS) {
            productWebsiteUrl += `${globalConst.REACT_APP_NOVITA_WEBSITE_ENGAGEMENT_RINGS}${product.id}`
        }

        if (saleType === globalConst.REACT_APP_SALE_TYPE_LOOSE_DIAMOND) {
            productWebsiteUrl += `${globalConst.REACT_APP_NOVITA_WEBSITE_LOOSE_DIAMOND}${product.id}`
        }

        this.setProductDataNameAndWebSite(
            name,
            productWebsiteUrl
        )
    }

    getDiamonProduct = async () => {
        this.setProductDataNameAndWebSite()
        this.setProductDataImage()

        const { sale } = this.state;

        const product = await getDiamonProductPicture(sale)

        if (!product) {
            this.setState({
                product: null,
                download_product: false,
                product_not_found: false,
                loadImage: false
            })
        }

        this.setState({
            product: product,
            download_product: false,
            product_not_found: false,
            loadImage: false
        })

        this.getDiamonProductUrlAndName(product)
    }

    getDiamonProductUrlAndName = (diamondProduct) => {
        if (!diamondProduct) {
            this.setProductDataImage()
            this.setProductDataNameAndWebSite()

            return
        }

        const urlLooseDiamond = sprintf(
            '%s%s%s',
            process.env.REACT_APP_NOVITA_WEBSITE_DOMAIN,
            process.env.REACT_APP_NOVITA_WEBSITE_LOOSE_DIAMOND,
            diamondProduct.id
        )

        this.setProductDataImage(diamondProduct.imageExternalUrl)
        this.setProductDataNameAndWebSite(
            this.state.sale.diamondDetails,
            urlLooseDiamond
        )
    }

    getEngagementRing = async () => {
        const { sale } = this.state
        const product = await getEngagementRingPicture(sale)

        if (!product) {
            this.setState({
                product: null,
                download_product: false,
                product_not_found: false,
                loadImage: false
            })

            return
        }

        this.setState({
            product: product,
            download_product: false,
            product_not_found: false,
            loadImage: false
        })

        this.getProductImageUrl()
        this.getProductNameAndWebsiteUrl()
    }

    ajaxRequest = null;
    async fetchJewelleryByReference() {
        this.setProductDataNameAndWebSite()
        this.setProductDataImage()

        this.setState({
            loadImage: true,
            classHideImage: ''
        })

        const reference = this.state.sale.ringPreference

        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if (this.ajaxRequest !== null) {
            this.ajaxRequest.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.ajaxRequest = axios.CancelToken.source();

        const { saleType } = this.state.sale

        if (Util.wordInArray(saleType, ['loose diamond']) === true) {
            return this.getDiamonProduct()
        }

        if (
            Util.wordInArray(saleType, ['engagement ring']) === true
        ) {
            return this.getEngagementRing()
        }

        if (
            Util.wordInArray(this.state.sale.saleType, ['wedding band', 'jewellery']) === true &&
            reference !== '' &&
            reference !== null &&
            reference !== undefined
        ) {
            //FINISH AJAX CONTROLS
            const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_PRODUCT_LIST

            const parameters = {
                reference: reference
            }

            axios.get(
                url,
                {
                    params: parameters,
                    headers: { Authorization: `Bearer ${Auth.getToken()}` }
                },
                {
                    cancelToken: this.ajaxRequest.token
                }
            )
                .then(api_response => {
                    const result = api_response.data.result;
                    const items = result.rows;
                    if (Array.isArray(items) === true && items.length > 0) {
                        let datas = items.filter(item => {
                            return item.reference === reference
                        })

                        const product = datas[0]

                        this.setState({
                            product: product,
                            download_product: false,
                            product_not_found: false,
                            loadImage: false
                        })
                        this.getProductImageUrl()
                        this.getProductNameAndWebsiteUrl()
                    }
                    else {
                        this.setState(
                            {
                                product: null,
                                download_product: false,
                                product_not_found: true,
                                product_image_url: null,
                                product_name: null,
                                product_website_url: null,
                                loadImage: false
                            }
                        )
                    }
                })
                .catch(err => {
                    this.setState({
                        loadImage: false
                    })

                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            console.log('User not authorised. Please reload the page');
                        }
                        else {
                            console.log('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        console.log('There was a problem with your request. Please reload this page and try again.');
                    }
                    console.log(err);
                })
        }
        else {
            this.setState(
                {
                    product: null,
                    download_product: false,
                    product_not_found: true,
                    product_image_url: null,
                    product_name: null,
                    product_website_url: null,
                    loadImage: false
                }
            )
        }
    }

    renderUrlProduct = () => {
        const {
            product_website_url, product_image_url, product_name, product, sale
        } = this.state

        const { saleType } = sale

        if (!product) {
            return null
        }

        const { productCategoryId } = product

        if (
            (
                product_image_url &&
                productCategoryId !== globalConst.REACT_APP_PRODUCT_CATEGORY_ID_CABLE_CHAIN
            ) ||
            Util.wordInArray(saleType, ['loose diamond']) === true
        ) {
            return `<a href="${product_website_url}" target="_blank">${product_name}</a>`
        }

        if (productCategoryId === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_CABLE_CHAIN) {
            return `<span>${product_name}</span>`
        }

        return `<span>${product_name}</span>`
    }

    hiddenImage = (event) => {
        this.setState({
            classHideImage: 'hide'
        })
    }

    render() {
        const sale = this.state.sale

        let ownReferenceModified = ""
        let ownReferenceModifiedClass = ""
        if (sale.ownReferenceModified) {
            ownReferenceModified = "Yes"
            ownReferenceModifiedClass = "modifiedDesign"
        } else if (sale.ownReferenceModified === false) {
            ownReferenceModified = "No"
        } else {
            ownReferenceModified = "Not specified"
        }

        let settingHeightClass = 'col-sm'
        if (!Util.wordInArray(sale.settingHeight, ['standard'])) {
            settingHeightClass = 'col-sm red_colour'
        }

        return (
            <div className='sale_information_subcontainer'>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-4 border-right'>
                            <div className='row'>
                                {
                                    this.state.loadImage ?
                                        <div className="center-element mt-2">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    (
                                        this.state.product_image_url !== null
                                    ) ?

                                        <div className="col-sm align-center">
                                            <img
                                                className={this.state.classHideImage}
                                                src={this.state.product_image_url}
                                                alt="Picture not available"
                                                onErrorCapture={(e) => this.hiddenImage(e)}
                                            />
                                        </div>

                                        : (null)
                                }
                                {
                                    (
                                        this.state.product_name !== null
                                    ) ?
                                        <div className="col-12 align-center">
                                            <div
                                                dangerouslySetInnerHTML=
                                                {
                                                    {
                                                        __html: this.renderUrlProduct()
                                                    }
                                                }
                                            />
                                        </div>
                                        : (null)
                                }
                                {
                                    (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band']) === true
                                        && sale.expressJobRequired) ?
                                        <div className="col-12 align-center red_colour">
                                            This order includes EXPRESS JOB
                                        </div>
                                        : (null)
                                }
                            </div>
                        </div>
                        <div className='col-md-8'>
                            {
                                (Util.wordInArray(sale.saleType, ['engagement ring', 'jewellery', 'diamond']) === true) ?
                                    <div className="row">
                                        <div className="col-sm text-center">
                                            <span className='table_title'>Diamond cert #, Shape ct Colour Clarity</span>
                                            <br />
                                            <b>
                                                {
                                                    (sale.diamondDetails === '' || sale.diamondDetails === null) ? (
                                                        'Diamond not included'
                                                    ) :
                                                        (sale.diamondDetails)
                                                }</b>
                                        </div>
                                    </div>
                                    : (null)
                            }


                            {
                                (Util.wordInArray(sale.saleType, ['engagement ring']) === true
                                    && sale.includesReadyToShip) ?
                                    <div className="row">
                                        <div className="col-sm align-center red_colour">This order includes a product READY TO SHIP</div>
                                    </div>
                                    : (null)
                            }
                            <div className="row">
                                <div className="col-sm  border-right bold">Type of Product</div>
                                <div className="col-sm">{sale.saleType}</div>
                            </div>
                            {
                                (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band', 'jewellery']) === true) ?
                                    <div className="row">
                                        <div className="col-sm  border-right"><span className='table_title'>Product's Reference</span></div>
                                        <div className="col-sm">{sale.ringPreference}</div>
                                    </div>
                                    : (null)
                            }
                            <div className="row">
                                <div className="col-sm  border-right"><span className='table_title'>Customisation</span></div>
                                <div className={"col-sm " + ownReferenceModifiedClass}>{ownReferenceModified}</div>
                            </div>
                            {
                                (sale.ownReferenceModified) ? (
                                    <div className="row">
                                        <div className="col-sm  border-right"><span className='table_title'>Description customised</span></div>
                                        <div className={"col-sm " + ownReferenceModifiedClass}>{sale.descriptionModified}</div>
                                    </div>
                                ) : (null)
                            }
                            {
                                (Util.wordInArray(sale.saleType, ['engagement ring']) === true) ?
                                    <div className="row">
                                        <div className="col-sm border-right">
                                            <Row className='table_title'>
                                                <Col lg="8">
                                                    Type of Claw
                                                </Col>
                                                <Col lg="4">
                                                    {
                                                        this.state.loadDataStandard === true ?
                                                            <LoadingSpinnerLayer />
                                                            :
                                                            (null)
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="col-sm"
                                            dangerouslySetInnerHTML={
                                                {
                                                    __html: renderIsStandarTypeOfClaw(
                                                        sale.clawType ?? '', this.state.typeAndClaw
                                                    )
                                                }
                                            }
                                        />
                                    </div>
                                    : (null)
                            }
                            {
                                (Util.wordInArray(sale.saleType, ['engagement ring']) === true) ?
                                    (
                                        <div className="row">
                                            <div className="col-sm  border-right">
                                                <span className='table_title'>Setting Height</span>
                                            </div>
                                            <div className={settingHeightClass}>
                                                {sale.settingHeight}
                                            </div>
                                        </div>
                                    ) : (null)
                            }
                            {
                                (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band']) === true) ?
                                    <div className="row">
                                        <div className="col-sm  border-right"><span className='table_title'>Engraving</span></div>
                                        <div className="col-sm">{sale.engraving}</div>
                                    </div>
                                    : (null)
                            }
                            {
                                (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band', 'jewellery']) === true) ?
                                    <div className="row">
                                        <div className="col-sm  border-right"><span className='table_title'>Band Metal</span></div>
                                        <div className="col-sm">{sale.metal}</div>
                                    </div>
                                    : (null)
                            }
                            {
                                (Util.wordInArray(sale.saleType, ['engagement ring']) === true) ?
                                    <div className="row">
                                        <div className="col-sm  border-right">
                                            <Row className='table_title'>
                                                <Col lg="8">
                                                    Claw metal
                                                </Col>
                                                <Col lg="4">
                                                    {
                                                        this.state.loadDataStandard === true ?
                                                            <LoadingSpinnerLayer />
                                                            :
                                                            (null)
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="col-sm"
                                            dangerouslySetInnerHTML={
                                                {
                                                    __html: renderIsStandarMetalClaw(
                                                        sale.clawMetal ?? '', this.state.typeAndClaw
                                                    )
                                                }
                                            }
                                        />
                                    </div>
                                    : (null)
                            }
                            {
                                (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band']) === true) ?
                                    (
                                        <div className="row">
                                            <div className="col-sm  border-right"><span className='table_title'>Ring size</span></div>
                                            <div className="col-sm">{sale.ringSize}</div>
                                        </div>
                                    ) : (null)
                            }

                            {
                                (
                                    Util.wordInArray(sale.saleType, ['wedding']) === true &&
                                    sale.ringPreference === globalConst.REACT_APP_REFERENCE_AG_WBJ
                                ) ?

                                    <div className="row">
                                        <div className="col-sm  border-right">
                                            <span className='table_title'>Product details</span>
                                        </div>
                                        <div className="col-sm">{sale.productDetails}</div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>


                </div>
            </div>
        )
    }

    renderWebsiteUrl = () => {
        if (!this.state.product) {
            return ''
        }

        if (this.state.product.productCategoryId === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_CABLE_CHAIN) {
            return `<span>${this.state.product_name}</span>`
        }

        return '<a href="' + this.state.product_website_url + '" target="_blank">' + this.state.product_name + '</a>'
    }


    getProductPictureUrl(productPicture, procuct_category_id) {
        let product_picture_url = globalConst.REACT_APP_NOVITA_DOCS_DOMAIN

        if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_RING) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_RINGS}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_EARRING) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_EARRINGS}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_BRACELET) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_BRACELETS}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_NECKLACE) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_NECKLACES}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_ER) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_ENG_RINGS}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_CABLE_CHAIN) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_CABLE_CHAINS}`
        }

        return product_picture_url += `${productPicture.filename}`
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    componentDidMount() {
        this.setState({
            product_image_url: null,
            product_name: null,
            product_website_url: null
        })

        this.fetchJewelleryByReference();

        this.setDataStandard()
    }

    async componentDidUpdate(previousState) {
        if (previousState.sale !== this.state.sale) {
            this.fetchJewelleryByReference(this.state.sale.ringPreference)

            this.state.typeAndClaw.metalClaw = ''
            this.state.typeAndClaw.metalClawStandard = ''
            this.state.typeAndClaw.typeOfClaw = ''

            this.setDataStandard()
        }
    }

    static getDerivedStateFromProps(props, state) {
        //
        let download_product = false
        if (
            (
                state.product === null && state.product_not_found !== true
            ) &&
            Util.wordInArray(props.sale.saleType, ['engagement ring', 'wedding band', 'jewellery']) === true
        ) {
            download_product = true
        }

        //
        if (
            (state.sale.ringPreference !== props.sale.ringPreference) &&
            Util.wordInArray(props.sale.saleType, ['engagement ring', 'wedding band', 'jewellery']) === true
        ) {
            download_product = true
        }

        //
        let product = null
        if (
            state.product !== null &&
            Util.wordInArray(props.sale.saleType, ['engagement ring', 'wedding band', 'jewellery', 'loose diamond']) === true
        ) {
            product = state.product
        }

        return {
            sale: props.sale,
            download_product: download_product,
            product: product
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

}

export default SaleInformationProduct;