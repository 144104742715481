  import React, { useEffect, useState } from 'react';
  import { Modal, Button, Form, ProgressBar } from 'react-bootstrap';
  import { useDispatch, useSelector } from 'react-redux';
  import CategorySelect from '../categories/category-select';
  import { EmployeeSelect } from './employee-select';
  import { RoleSelect } from './role-select';
  import { createDocument, editDocument, fetchCategories, selectCategory } from '../../../store/slices/categories';
  import { API_STATUS_PENDING } from '../../../store/constants';

  const allowedRoles = [
    'ROLE_SALES',
    'ROLE_ACCOUNTING',
    'ROLE_COSTS',
    'ROLE_OPERATIONS',
    'ROLE_MARKETING',
    'ROLE_CONTENT'
  ];

  const allowedFileTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'video/mp4',
    'audio/mp3'
  ];


  export default function CreateFile({ handleClose, document }) {
    const { categories, status, progress, selectedCategory } = useSelector((state) => state.categories);

    const [ selectedCategoryId, setSelectedCategoryId ] = useState(null);
    const [ roles, setRoles ] = useState([]);
    const [ addedEmployees, setAddedEmployees ] = useState([]);

    const dispatch = useDispatch();

    useEffect(()=>{
      if (selectedCategory) {
        setSelectedCategoryId(selectedCategory.id);
      }
    }, [selectedCategory]);

    useEffect(()=>{
      if (document) {
        setRoles(document?.roles || []);
        setSelectedCategoryId(document?.documentCategoryId);
        setAddedEmployees([...document?.employeeDocumentAccesses.map((e) => e.employee) || []])
      } else {
        setRoles([]);
        setSelectedCategoryId(null);
        setAddedEmployees([]);
      }
    }, [document]);

    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!selectedCategoryId) {
        alert("Please select a category");
        return;
      }
      const title = event.target.elements.formTitle.value;
      if (!title) {
        alert("Please enter a file title");
        return;
      }

      const description = event.target.elements.formDescription.value;
      if (!description) {
        alert("Please enter a file description");
        return;
      }

      const maxFileSize = 50 * 1024 * 1024;
      const maxThumbnailSize = 2 * 1024 * 1024;

      const form = event.target;
      const formData = new FormData(form);

      const thumbnailFile = formData.get("thumbnailName");
      const file = formData.get("filename");

      if (file.size > maxFileSize) {
        alert("File size is too large");
        return;
      }
      if (thumbnailFile.size > maxThumbnailSize) {
        alert("Thumbnail size is too large");
        return;
      }

      if (document) {
        //Update document
        const newFormData = new FormData();

        let isThumbnailAttached = false;
        if (file.size > 0) {
          newFormData.append("files[]", file);
        }

        if (thumbnailFile.size > 0) {
          isThumbnailAttached = true;
          newFormData.append("files[]", thumbnailFile);
        }

        newFormData.append("id", document.id);
        newFormData.append("title", title);
        newFormData.append("description", description);
        newFormData.append("documentCategoryId", selectedCategoryId);
        newFormData.append("roles", JSON.stringify(roles));
        newFormData.append("employees", JSON.stringify(addedEmployees.map((employee) => employee.id)));
        newFormData.append("isThumbnailAttached", isThumbnailAttached ? "true" : "false");
        dispatch(editDocument({data: newFormData, id: document.id})).then((response) => {
          handleClose();
          setTimeout(() => {
            window.location.reload();
          }, 100);
        });
      } else {
        // Create document
        const newFormData = new FormData();
        newFormData.append("files[]", file);
        newFormData.append("files[]", thumbnailFile);

        newFormData.append("title", title);
        newFormData.append("description", description);
        newFormData.append("documentCategoryId", selectedCategoryId);
        newFormData.append("roles", JSON.stringify(roles));
        newFormData.append("employees", JSON.stringify(addedEmployees.map((employee) => employee.id)));

        dispatch(createDocument(newFormData)).then((response) => {  handleClose(); });
      }
    };

    return (
      <>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create New File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group controlId="formParent">
                <CategorySelect selectedCategoryId={selectedCategoryId} prompt={"Select a category"} categories={categories} onChange={(event)=>{setSelectedCategoryId(event.target.value)}} />
              </Form.Group>
              <Form.Group className='mb-3' controlId="formTitle">
                <Form.Control defaultValue={document?.title} type="text" placeholder="Enter file title" />
              </Form.Group>
              <Form.Group className='mb-3' controlId="formDescription">
                  <Form.Control defaultValue={document?.description} as="textarea" placeholder="Enter file description" />
              </Form.Group>
              <RoleSelect allRoles={allowedRoles} roles={roles} setRoles={setRoles}/>
              <EmployeeSelect addedEmployees={addedEmployees} setAddedEmployees={setAddedEmployees} />
              <Form.Group className="form-group">
                  <Form.Label>Upload File Thumbnail</Form.Label>
                  <Form.Control
                      required={document ? false : true}
                      type="file"
                      name="thumbnailName"
                      id="uploadThumbnail"
                      accept={['image/png', 'image/jpeg']}
                  />
              </Form.Group>
              <Form.Group className="form-group">
                  <Form.Label>Upload File</Form.Label>
                  <Form.Control
                      required={document ? false : true}
                      type="file"
                      name="filename"
                      id="uploadFile"
                      accept={allowedFileTypes}
                  />
              </Form.Group>
            {status === API_STATUS_PENDING && <ProgressBar animated now={progress} />}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button disabled={status === API_STATUS_PENDING} variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </>
    );
  }

