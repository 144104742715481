import React, { Component } from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';

// SCSS
import '../../../css/sales/sale_create.scss'
import '../../../css/sales/sales.scss'

class CostsEditCloseConfirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.closeModal = this.closeModal.bind(this)
        this.closeEditModal = this.closeEditModal.bind(this)
    }

    closeModal() {
        this.props.closeEditCloseConfirmation()
    }

    closeEditModal() {
        this.props.closeEditionComponentWithoutChanges()
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        return (
            <div id="SaleEditionRequirementModal_component">
                <Modal className="sale-edit-close-confirmation"
                    show={true}
                    onHide={this.closeModal}
                    dialogClassName="modal-90w"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        Confirm
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className='form-group'>
                                <FormLabel>You still have pending changes. Are you sure you want to close?</FormLabel>
                            </Form.Group>
                            <Button
                                type="button" className='btn btn-default confirm-btn'
                                onClick={this.closeEditModal}
                            >
                                Discard Changes
                            </Button>
                            <Button className='btn btn-default disagree-btn'
                                type="button"
                                onClick={this.closeModal}
                            >
                                Continue Editing
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    handleChange(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
}

export default CostsEditCloseConfirmation;