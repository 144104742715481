import React, { Component } from 'react';
import Util from '../../../Util'

const MANUFACTURING = "Manufacturing"

class SaleDetailProduction extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sale: props.sale,
            workflowSales: props.workflowSales ?? []
        }
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        const sale = this.state.sale

        let customerNeedsOnDate = null
        if (sale.customerNeedsOn !== null && sale.customerNeedsOn !== '') {
            customerNeedsOnDate = new Date(sale.customerNeedsOn).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            customerNeedsOnDate = customerNeedsOnDate.slice(0, -5) + ", " + customerNeedsOnDate.slice(-4)
        }

        let urgentDateRequestedByCustomer = "No"
        if (sale.urgentDateRequestedByCustomer === true) {
            urgentDateRequestedByCustomer = "Yes"
        }

        let urgentDateRequestedByCustomerClass = ""
        if (urgentDateRequestedByCustomer === "Yes") {
            urgentDateRequestedByCustomerClass = "highlighted"
        }

        let weNeedByDate = null
        if (sale.dateWeNeedBy !== null && sale.dateWeNeedBy !== '') {
            weNeedByDate = new Date(sale.dateWeNeedBy).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            weNeedByDate = weNeedByDate.slice(0, -5) + ", " + weNeedByDate.slice(-4)
        }

        let collectedShippedDate = null
        if (sale.collectedShippedDate !== null && sale.collectedShippedDate !== '') {
            collectedShippedDate = new Date(sale.collectedShippedDate).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            collectedShippedDate = collectedShippedDate.slice(0, -5) + ", " + collectedShippedDate.slice(-4)
        }

        return (
            <div id="SaleDetailProduction_component">
                <div className='sale_data'>
                    <h2 className='heading'>PRODUCTION</h2>
                    <div className="container mb-4">
                        <div className="row">
                            <div className="col-sm">Order Status</div>
                            <div className="col-sm">
                                <div className={Util.statusAsSlug(sale.status)}></div>
                                {sale.status}
                            </div>
                        </div>
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'jewellery', 'wedding']) === true) ?
                                <div className="row">
                                    <div className="col-sm">Jeweller</div>
                                    <div className="col-sm">{this.showSaleJeweller()}</div>
                                </div>
                                : (null)
                        }
                        <div className="row">
                            <div className="col-sm">Setting Stock Number</div>
                            <div className="col-sm">{this.showSaleJewellerStockNumber()}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Setting Status</div>
                            <div className="col-sm">{sale.settingStatus}</div>
                        </div>
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'jewellery', 'diamond']) === true) ?
                                <div className="row">
                                    <div className='grid_title align-center'>Diamond Status</div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm">Diamond Location</div>
                                            <div className="col-sm">{sale.diamondLocation}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm">In Transit to</div>
                                            <div className="col-sm">{(sale.inTransitTo === '' || sale.inTransitTo === null) ? 'Not registered' : sale.inTransitTo}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm">Requested to overseas provider</div>
                                            <div className="col-sm">{sale.requestedToOverseasProvider === true ? 'Yes' : 'No'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm">Confirmed from overseas provider</div>
                                            <div className="col-sm">{sale.confirmedFromOverseasProvider === true ? 'Yes' : 'No'}</div>
                                        </div>
                                    </div>
                                </div>
                                : (null)
                        }
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'jewellery', 'wedding']) === true) ?
                                <div className="row">
                                    <div className="col-sm">Manufacturing Location</div>
                                    <div className="col-sm">{sale.manufacturingLocation}</div>
                                </div>
                                : (null)
                        }
                        <div className="row">
                            <div className="col-sm">Date customer needs by</div>
                            <div className={"col-sm dateCustomerNeedsBy " + urgentDateRequestedByCustomerClass}>
                                {customerNeedsOnDate !== null ?
                                    <span>
                                        {customerNeedsOnDate}
                                    </span>
                                    : ('Not registered')
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Urgent Date - requested by Customer</div>
                            <div className={"col-sm urgentDateRequestedByCustomer " + urgentDateRequestedByCustomerClass}>
                                <span>
                                    {urgentDateRequestedByCustomer}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Date we need by</div>
                            <div className="col-sm">
                                {
                                    weNeedByDate !== null ?
                                        <span>
                                            {weNeedByDate}
                                        </span>
                                        : ('Not registered')
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Customer's Notes</div>
                            <div className="col-sm">{sale.notes}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Customer's Notes Proposal Date</div>
                            <div className="col-sm">{sale.customerNotesProposalDate}</div>
                        </div>
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'jewellery', 'wedding']) === true) ?
                                <div className="row">
                                    <div className="col-sm">Appraisal Status</div>
                                    <div className="col-sm">{sale.valuationStatus}</div>
                                </div>
                                : (null)
                        }
                        <div className="row">
                            <div className="col-sm">Ring Sent With Resize</div>
                            <div className="col-sm">{sale.ringSentWithResize}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Date collected or shipped</div>
                            <div className="col-sm">{collectedShippedDate}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    showSaleJeweller() {
        if (this.state.sale.workflowLatestProvider) {
            let sale = this.state.sale
            return `${sale?.workflowLatestProvider} - (${sale?.workflowLatestProviderContactName})`
        }

        return ''
    }

    showSaleJewellerStockNumber() {
        let stockNumber = ''

        this.state.workflowSales.map(function (workflowSale) {
            if (workflowSale.hasOwnProperty('workflowActivityModels')) {
                workflowSale.workflowActivityModels.map(function (workflowActivity) {
                    if (workflowActivity?.type === MANUFACTURING) {
                        stockNumber = workflowActivity?.stockNumber
                    }
                })

            }
        })

        return stockNumber
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************


}

export default SaleDetailProduction;