import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function DateFilter(props) {
    const [selectedMinDate, setSelectedMinDate] = useState(null)
    const [selectedMaxDate, setSelectedMaxDate] = useState(null)

    const handleMinDateChange = (date) => {
        setSelectedMinDate(date)
        props.handleSaleMinDateFilter(date)
    }

    const handleMaxDateChange = (date) => {
        setSelectedMaxDate(date)
        props.handleSaleMaxDateFilter(date)
    }

    return (
        <div className='dateFilter'>
            <DatePicker
                className="min_date_picker"
                selected={selectedMinDate}
                onChange={date => handleMinDateChange(date)}
                dateFormat='dd/MM/yyyy'
                maxDate={new Date()}
                isClearable
                closeOnScroll={true}
                placeholderText="From"
            />
            <DatePicker
                className="max_date_picker"
                selected={selectedMaxDate}
                onChange={date => handleMaxDateChange(date)}
                dateFormat='dd/MM/yyyy'
                maxDate={new Date()}
                isClearable
                closeOnScroll={true}
                placeholderText="To"
            />
        </div>
    )

}

export default DateFilter;