import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TreeViewIcon = ({ isOpen, handleExpand }) => {
    if (isOpen) {
        return <FontAwesomeIcon
            onClick={handleExpand}
            className='ps-1'
            icon={faFolderOpen}
        />;
    }

    return <FontAwesomeIcon
        onClick={handleExpand}
        className='ps-1'
        icon={faFolder}
    />;
}
