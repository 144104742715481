import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointLeft } from '@fortawesome/free-regular-svg-icons'
import PostSaleInformationRequest from './PostSaleInformationRequest';
import PostSaleInformationBackOffice from './PostSaleInformationBackOffice';
import PostSaleInformationAccounting from './PostSaleInformationAccounting';
import PostSaleInformationProduct from './PostSaleInformationProduct';
import PostSaleInformationHistory from './PostSaleInformationHistory';
import PostSaleComments from '../postSaleComment/PostSaleComments';
import EditIcon from '../../../image/Edit.png';
import { Link } from 'react-router-dom';
import detailsIcon from '../../../image/see_details.png';

// SCSS
import '../../../css/sales/sale_information.scss'

class PostSaleInformation extends Component {

    last_rendered_sale = null

    constructor(props) {
        super();

        this.child = React.createRef();

        this.state = {
            sale: props.sale,
            openEditionComponent: props.openEditionComponent,
            sales_logs: null,
            loading_log: false
        }
        this.editSale = this.editSale.bind(this)
        this.updateLog = this.updateLog.bind(this)
    }

    editSale() {
        this.state.openEditionComponent(this.state.sale)
    }

    render() {
        const sale = this.state.sale;
        if (sale === null) {
            return (
                <div id="SaleInformation_component">
                    <div className="choose_message">
                        Choose an item from the list to find more
                        <br />
                        <span>
                            <FontAwesomeIcon icon={faHandPointLeft} />
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div id="SaleInformation_component">
                <div className='information'>
                    <div className='details-top-heading'>
                        <div className='order-no-section'>
                            <h1>ORDER #{sale.salesModel !== null ? sale.salesModel.onlineOrderNumber : 'N/A'}</h1>
                        </div>
                        <div className='order-action-section'>
                            <div className="link_to_detail">
                                <Link to={`/post_sale_services/${sale.id}`} target="_blank">
                                    Multimedia <img src={detailsIcon} alt="" />
                                </Link>
                            </div>
                            <div className="link_to_edit">
                                <button className='btn' onClick={this.editSale}>
                                    Edit <img src={EditIcon} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <PostSaleComments
                        sale={this.state.sale}
                    />
                    <div className="accounts_information sales-details-card">
                        <h2>REQUEST</h2>
                        <PostSaleInformationRequest
                            sale={this.state.sale}
                        />
                    </div>
                    <div className="back_office_information sales-details-card">
                        <h2>Back Office Information</h2>
                        <PostSaleInformationBackOffice
                            sale={this.state.sale}
                        />
                    </div>
                    <div className="accounting_information sales-details-card">
                        <h2>Accounting</h2>
                        <PostSaleInformationAccounting
                            sale={this.state.sale}
                        />
                    </div>
                    <div className="product_information sales-details-card">
                        <h2>Product</h2>
                        <PostSaleInformationProduct
                            sale={this.state.sale}
                        />
                    </div>
                    <div className="history_information sales-details-card">
                        <h2>History</h2>
                        <PostSaleInformationHistory
                            ref={this.child}
                            key={this.state.sales_logs}
                            sale={this.state.sale}
                            loading_log={this.state.loading_log}
                            sales_logs={this.state.sales_logs}
                        />
                    </div>
                </div>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount() {
        //execute once the first time
    }

    componentDidUpdate() {
        this.setState(
            {
                loading_log: false,
                sales_logs: this.props.sales_logs
            }
        )
        if (this.state.sale !== null) {
            this.last_rendered_sale = this.state.sale
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        //only updates the render if the sale to show in the information is different than the one that is already shown
        const next_sale = nextState.sale
        if (nextState.sale === null) {
            return false;
        }
        else if (this.last_rendered_sale === next_sale) {
            return false;
        }
        else {
            return true;
        }

    }

    static getDerivedStateFromProps(props, state) {
        //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
        //return values for the state
        return {
            sale: props.sale,
            sales_logs: props.sales_logs,
            loading_log: props.loading_log
        }
    }
    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

    updateLog(log) {
        this.child.current.updateLog(log)
        this.setState(
            {
                loading_log: false,
                sales_logs: log
            }
        )
    }

}

export default PostSaleInformation;