import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import DividerDashed from "../../../../Divider/DividerDashed";
import { dateFormatCustom } from "../../../../../utils/dateUtils";
import FormDatePicker from "../../../../Form/FormDatePicker";
import FormInputCheckbox from "../../../../Form/FormInputCheckbox";
import FormSelectCustomer from "../../../../Form/FormSelectCustomer";
import { useDispatch, useSelector } from "react-redux";
import { saleHaveTwoDiamonds } from "../../../../../store/slices/workflowReturnSlice";
import { validFormSendGD } from "./Actions/Validations";
import ConfirmSendProdcutToGD from "./ConfirmSendProdcutToGD";
import AlertMessage from "../../../../Utils/AlertMessage";
import { API_STATUS_FAILED } from "../../../../../store/constants";

const FIELDS_DIAMONDS = "diamonds";
const FIELDS_JEWELLERY = "jewellery";

const FIELDS_VALID = [FIELDS_DIAMONDS, FIELDS_JEWELLERY];

const SaleWorkflowEditReturnStepThree = ({
  stepThree,
  onResponse,
  sale,
  onResponseGD,
}) => {
  const { statusGD, error } = useSelector((state) => state.workflowReturn);
  const formRef = React.createRef();
  const { saleDiamonds } = useSelector((state) => state.workflowReturn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saleHaveTwoDiamonds(sale));
  }, [dispatch, sale]);

  const [btnDisabledSendGD, setBtnDisabledSendGD] = useState(true);
  const [showConfirmSednGD, setShowConfirmSednGD] = useState(false);

  const response = validFormSendGD(saleDiamonds, sale);

  if (!stepThree.diamonds && !stepThree.jewellery) {
    stepThree.diamonds = response.diamonds;
    stepThree.jewellery = response.jewellery;
  }

  const showDiamonds = response.showDiamonds;
  const showJewellery = response.showJewellery;
  const disabledDiamonds = response.disabledDiamonds;
  const disabledJewellery = response.disabledJewellery;

  const [step, setStep] = useState(stepThree);

  const cityOptions = process.env.REACT_APP_SEND_GD_CITY_OPTIONS.split(",");

  const listCitiesSend = cityOptions.map((item) => {
    return {
      fromCityName: item,
      value: item,
    };
  });

  const handleChangeSelect = (data, name) => {
    setBtnDisabledSendGD(true);

    let value = "";

    const item = data ? JSON.parse(data) : {};

    value = item.value;

    setStep({
      ...step,
      [name]: value,
    });

    stepThree[name] = value;

    onResponse(stepThree, "stepThreeQuestions");
  };

  const handleChange = (value, name) => {
    if (FIELDS_VALID.includes(name)) {
      setBtnDisabledSendGD(true);
    }

    setStep({
      ...step,
      [name]: value,
    });

    stepThree[name] = value;

    onResponse(stepThree, "stepThreeQuestions");
  };

  useEffect(() => {
    if (btnDisabledSendGD) {
      disabledSendGD(formRef.current);
    }
  }, [btnDisabledSendGD, formRef]);

  const disabledSendGD = (form) => {
    const check = form.checkValidity();

    setBtnDisabledSendGD(!check);
  };

  const confirmSendToGD = (value) => {
    setShowConfirmSednGD(false);

    if (value) {
      onResponseGD();
    }
  };

  const disabledInputSendGD = (name) => {
    if (step.sendProductsToGd) {
      return step.sendProductsToGd;
    }

    if (name === FIELDS_DIAMONDS) {
      return disabledDiamonds;
    }

    if (name === FIELDS_JEWELLERY) {
      return disabledJewellery;
    }

    return false;
  };

  return (
    <>
      <DividerDashed />

      <Row>
        <Col xs="12" lg="12">
          <h2>
            Sent for re-stocking on the{" "}
            {dateFormatCustom(new Date(), "Do MMM of YYYY")}
          </h2>
        </Col>
      </Row>

      <Row className="mt-1">
        <Col>
          <Card>
            <Card.Body>
              {statusGD === API_STATUS_FAILED && (
                <AlertMessage
                  type="danger"
                  message={`There was an error reported by GD system: "${
                    error ?? ""
                  }"`}
                  infoHeading=""
                  autoClose={false}
                />
              )}

              <Card.Title>Products sent to main office:</Card.Title>
              <Form ref={formRef}>
                <Row>
                  {showDiamonds && (
                    <Col xs={12} lg={4}>
                      <FormInputCheckbox
                        typeView="switch"
                        type="checkbox"
                        label="Diamond(s)"
                        nameInput="diamonds"
                        disabled={disabledInputSendGD("diamonds")}
                        checked={step.diamonds ?? false}
                        required={true}
                        onResponse={handleChange}
                      />
                    </Col>
                  )}

                  {showJewellery && (
                    <Col xs={12} lg={4}>
                      <FormInputCheckbox
                        typeView="switch"
                        type="checkbox"
                        label="Jewellery/Wedding Band/Ring Setting"
                        nameInput="jewellery"
                        disabled={disabledInputSendGD("jewellery")}
                        checked={step.jewellery ?? false}
                        required={true}
                        onResponse={handleChange}
                      />
                    </Col>
                  )}
                  <Col xs={12} lg={4}>
                    <FormSelectCustomer
                      label="Sent from"
                      nameInput="fromCityName"
                      items={listCitiesSend}
                      keyValue={"value"}
                      value={step.fromCityName ?? ""}
                      required={true}
                      disabled={disabledInputSendGD("fromCityName")}
                      onResponse={handleChangeSelect}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} lg={12} className="center-element">
                    {!step.sendProductsToGd && (
                      <Button
                        disabled={btnDisabledSendGD}
                        onClick={() => setShowConfirmSednGD(true)}
                      >
                        Notify product transfer to GD
                      </Button>
                    )}

                    {step.sendProductsToGd &&
                      "The products were notified as sent"}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormDatePicker
              label={"Date stock received in main office"}
              nameInput="dateStockReceivedMainOffice"
              value={step.dateStockReceivedMainOffice ?? ""}
              onResponse={handleChange}
              columnsSize={"12"}
              format={"dd/MM/yyyy"}
              maxDate={new Date()}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormInputCheckbox
              typeView="switch"
              type="checkbox"
              label="Stock and / or diamond were re-activated"
              nameInput="stockOrDiamondReactivated"
              checked={step.stockOrDiamondReactivated ?? false}
              onResponse={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormInputCheckbox
              typeView="switch"
              type="checkbox"
              label="Location was updated in GD system"
              nameInput="locationUpdatedInGd"
              checked={step.locationUpdatedInGd ?? false}
              onResponse={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormInputCheckbox
              typeView="switch"
              type="checkbox"
              label="Chip reactivated or new tag created"
              nameInput="chipReactivationRequested"
              checked={step.chipReactivationRequested ?? false}
              onResponse={handleChange}
            />
          </Col>
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- For ring
            and/or diamond
          </span>
        </Row>

        {showConfirmSednGD && (
          <ConfirmSendProdcutToGD
            message="The selected products will be reported as in transit to the main office to the GD system. Are you sure?"
            onResponse={confirmSendToGD}
          />
        )}
      </Row>
    </>
  );
};

SaleWorkflowEditReturnStepThree.propTypes = {
  stepThree: PropTypes.object.isRequired,
  sale: PropTypes.object.isRequired,
};

export default SaleWorkflowEditReturnStepThree;
