import React, { Component } from 'react';
import { Form } from "react-bootstrap";

class AccountingRow extends Component {

    constructor(props) {
        super();
        this.state = {
            accounting: props.accounting,
            selected_accounting: props.selected_accounting,
            addToMyList: props.addToMyList,
            currentTab: props.currentTab,
            selectedTabToAdd: props.selectedTabToAdd,
        }
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        //this.addSaleToCustomisedList = this.addSaleToCustomisedList.bind(this)
    }

    handleChangeCheckbox(event) {
        if (this.state.selectedTabToAdd === null || this.state.selectedTabToAdd === "") {
            alert("You need to select a personalised list from the dropdown to add elements to it.")
        }
        else {
            const field_name = event.target.name
            let state = {}
            state[field_name] = event.target.checked
            this.setState(state)
            this.props.handleChangeCheckbox(this.props.index, event.target.checked)
            //this.addSaleToCustomisedList()
        }
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        const accounting = this.state.accounting;

        let created = null
        if (accounting.created !== null && accounting.created !== '') {
            created = new Date(accounting.created).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            created = created.slice(0, -5) + ", " + created.slice(-4)
        }

        let row_class = "sale_row";
        if (this.state.highlight_row === true) {
            row_class = "sale_row highlighted_row";
        }

        if (this.state.selected_accounting !== null && this.state.selected_accounting !== undefined && accounting !== null && accounting.id === this.state.selected_accounting.id) {
            row_class = row_class + " highlighted_row"
        }

        let typeOfJob = ""
        if (accounting.typeOfService === "Post Sale Service"
            && accounting.postSaleServiceModel !== null) {
            typeOfJob = accounting.postSaleServiceModel.typeOfJob
        }

        let name = ""
        if (accounting.salesModel !== null) {
            name = accounting.salesModel.name
        }
        if (accounting.postSaleServiceModel !== null) {
            name = accounting.postSaleServiceModel.name
        }

        let saleType = ""
        if (accounting.salesModel !== null) {
            saleType = accounting.salesModel.saleType
        }

        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                saleType = accounting.postSaleServiceModel.salesModel.saleType
            }
        }

        let diamondDetails = ""
        if (accounting.salesModel !== null) {
            diamondDetails = accounting.salesModel.diamondDetails
        }
        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                diamondDetails = accounting.postSaleServiceModel.salesModel.diamondDetails
            }
        }

        let ringPreference = ""
        if (accounting.salesModel !== null) {
            ringPreference = accounting.salesModel.ringPreference
        }
        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                ringPreference = accounting.postSaleServiceModel.salesModel.ringPreference
            }
        }

        let orderCurrency = ""
        if (accounting.salesModel !== null) {
            orderCurrency = accounting.salesModel.orderCurrency
        }
        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                orderCurrency = accounting.postSaleServiceModel.salesModel.orderCurrency
            }
        }

        let invoice = ""
        if (accounting.salesModel !== null) {
            invoice = accounting.salesModel.invoice
        }
        if (accounting.postSaleServiceModel !== null) {
            invoice = "Now"
        }

        let totalIncGst = ""
        if (accounting.salesModel !== null) {
            totalIncGst = this.formatCurrency(accounting.salesModel.totalIncGst)
        }
        if (accounting.postSaleServiceModel !== null) {
            totalIncGst = this.formatCurrency(accounting.postSaleServiceModel.priceToCharge)
        }

        let balance = ""
        if (accounting.salesModel !== null) {
            balance = this.formatCurrency(accounting.salesModel.balance)
        }
        if (accounting.postSaleServiceModel !== null) {
            balance = this.formatCurrency(accounting.postSaleServiceModel.balance)
        }

        let xeroCode = ""
        if (accounting.salesModel !== null) {
            xeroCode = accounting.salesModel.xeroCode
        }
        if (accounting.postSaleServiceModel !== null) {
            xeroCode = accounting.postSaleServiceModel.xeroCode
        }

        let onlineOrderNumber = ""
        if (accounting.salesModel !== null) {
            onlineOrderNumber = accounting.salesModel.onlineOrderNumber
        }
        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                onlineOrderNumber = accounting.postSaleServiceModel.salesModel.onlineOrderNumber
            }
        }

        let paymentMethod = ""
        if (accounting.salesModel !== null) {
            paymentMethod = accounting.salesModel.paymentMethod
        }
        if (accounting.postSaleServiceModel !== null) {
            paymentMethod = accounting.postSaleServiceModel.paymentMethod
        }

        let accountingStatus = ""
        if (accounting.salesModel !== null) {
            accountingStatus = accounting.salesModel.accountingStatus
        }

        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                accountingStatus = accounting.postSaleServiceModel.salesModel.accountingStatus
            }
        }

        return (
            <tr className={row_class} onClick={() => this.props.handleRowSelection(this.props.index)}>
                <td className="created">
                    {created !== null ?
                        <div className="created">
                            {created}
                        </div>
                        : ('')
                    }
                </td>
                <td className="addToMyList" onClick={e => e.stopPropagation()}>
                    <Form.Check
                        type="checkbox"
                        name="addToMyList"
                        id={"addToMyList" + this.props.index}
                        checked={this.state.addToMyList === true ? true : false}
                        onChange={this.handleChangeCheckbox}
                    //disabled={(this.state.selectedTabToAdd === "results" || this.state.selectedTabToAdd === "" || this.state.selectedTabToAdd === null) ? true : false}
                    />
                </td>
                <td className="name">
                    {name}
                </td>
                <td className="typeOfService">
                    {(accounting.typeOfService === "Post Sale Service") ? "Post Sale Service - " + typeOfJob : "Sale"}
                </td>
                <td className="saleType">
                    {saleType}
                </td>
                <td className="diamondDetails">
                    {diamondDetails}
                </td>
                <td className="ringPreference">
                    {ringPreference}
                </td>
                <td className="orderCurrency">
                    {orderCurrency}
                </td>
                <td className="invoice">
                    {invoice}
                </td>
                <td className="totalIncGst">
                    {totalIncGst}
                </td>
                <td className="balance">
                    {balance}
                </td>
                <td className="xeroCode">
                    {xeroCode}
                </td>
                <td className="onlineOrderNumber">
                    {onlineOrderNumber}
                </td>
                <td className="paymentMethod">
                    {paymentMethod}
                </td>
                <td className="accountingStatus">
                    {accountingStatus}
                </td>
            </tr>
        )
    }

    formatCurrency(value) {
        if (typeof value === "undefined") {
            return "";
        }
        else if (value === null || value === "") {
            return "";
        }
        else if (isNaN(Number(value))) {
            return value;
        }

        value = value.toString();
        value = value.replace(",", "");
        value = (Math.round(value * 100) / 100).toFixed(2);
        const currencyFormatter = Intl.NumberFormat(process.env.REACT_APP_NUMBER_FROMAT, { style: 'currency', currency: process.env.REACT_APP_CURRENCY });
        let formattedValue = currencyFormatter.format(value).replace("$", "");
        if (typeof formattedValue === "undefined") {
            return Number(0).toFixed(2);
        }
        if (formattedValue === "NaN") {
            return Number(0).toFixed(2);
        }
        return formattedValue;
    }


    highlightRow() {
        this.setState({
            highlight_row: !this.state.highlight_row
        });
    }

    removeSaleFromCustomisedList() {
        let saleId = this.accounting.id;
        this.props.removeSaleFromCustomisedList(saleId);
    }

    /*addSaleToCustomisedList() {
        let saleId = this.state.accounting.id;
        this.props.addSaleToCustomisedList(saleId);
    }*/

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return {
            accounting: props.accounting,
            selected_accounting: props.selected_accounting,
            addToMyList: props.addToMyList,
            currentTab: props.currentTab,
            selectedTabToAdd: props.selectedTabToAdd,
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

}

export default AccountingRow;