import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {
  addFollowup,
  updateFollowup,
} from "../../../../store/slices/customerSlice";

export const AddFollowUps = ({
  show,
  onHide,
  customer,
  appointment,
  editingFollowup,
}) => {
  const disptach = useDispatch();
  const [followup, setFollowup] = useState({
    notes: "",
    date: new Date(),
  });
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (editingFollowup) {
      setFollowup(editingFollowup);
    }
  }, [editingFollowup]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidated(false);
    const form = e.currentTarget;
    const check = form.checkValidity();

    if (!check) {
      setValidated(true);
      return;
    }

    if (editingFollowup) {
      disptach(
        updateFollowup({
          ...followup,
          appointmentId: appointment.id,
          customerId: customer.id,
          id: editingFollowup.id,
          date: followup.date,
        })
      );
    } else {
      disptach(
        addFollowup({
          customerId: customer.id,
          appointmentId: appointment.id,
          ...followup,
        })
      );
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {editingFollowup ? "Edit Followup" : "Add Follow Up"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
          <Form.Group className="mt-2" controlId="customerName">
            <Form.Label>Notes *</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={followup.notes}
              onChange={(e) => {
                setFollowup({ ...followup, notes: e.target.value });
              }}
              required
            />
          </Form.Group>
          <Form.Group controlId="customerFirstAppointmentDate">
            <Form.Label>Followup Sent On:</Form.Label>
            <DatePicker
              selected={new Date(followup.date)}
              onChange={(date) => setFollowup({ ...followup, date: date })}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              name="followupDate"
            />
          </Form.Group>
          <Button className="mt-2" variant="primary" type="submit">
            {editingFollowup ? "Update Followup" : "Add Follow Up"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
