import { Form, FormLabel, Button } from "react-bootstrap"
import React, { Component } from 'react';
import LoadingLayer from '../../LoadingLayer'
import FlashMessages from "../../FlashMessages";
import Auth from "../../../Auth";
import axios from 'axios'
import PostSaleCostsRowEdit from './PostSaleCostsRowEdit'
import * as globalConst from '../../../global/const';

// SCSS
import '../../../css/sales/sale_edit.scss'

class PostSaleAccountingEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sale: props.sale,
            sheetType: props.sale.sheetType,
            error_messages: [],
            warning_messages: [],
            success_messages: [],
            updating_sale: false,
            fields_no_updated: true,
            priceToCharge: props.sale.priceToCharge,
            balance: props.sale.balance,
            customerNeedsToPay: props.sale.customerNeedsToPay,
            xeroCode: props.sale.xeroCode,
            paymentMethod: props.sale.paymentMethod,
            jewellers: '',
            postSaleJewellerContainerId: 0,
            isAccounting: (props.isAccounting ? true : false),
            isCosts: (props.isCosts ? true : false),
        }
        this.editSale = this.editSale.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.updateJeweller = this.updateJeweller.bind(this)

        this.loadListInformation()
    }

    loadListInformation = () => {
        this.listPaymentMethod = process.env.REACT_APP_ACCOUNTS_PAYMENT_METHOD.split(',')
    }

    unsavedJewellers = null

    handleChange(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        if (event.target.value !== this.state[field_name]) {
            state["fields_no_updated"] = false
            this.props.handleTabChange("accounting", true)
        }
        if (field_name === "priceToCharge") {
            this.setState({
                balance: event.target.value
            })
        }
        this.setState(state)
    }

    handleChangeSelect(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        if (event.target.value !== this.state[field_name]) {
            state["fields_no_updated"] = false
            this.props.handleTabChange("accounting", true)
        }
        this.setState(state)
    }

    handleChangeCheckbox(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.checked
        if (event.target.checked !== this.state[field_name]) {
            state["fields_no_updated"] = false
            this.props.handleTabChange("accounting", true)
        }
        this.setState(state)
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        let jewellers
        if (typeof this.state.jewellers !== "undefined" && this.state.jewellers !== null) {
            if (this.state.jewellers !== "") {
                try {
                    jewellers = JSON.parse(this.state.jewellers)
                }
                catch (e) {
                    console.log("Error: " + e)
                }
            }
        }

        if (typeof jewellers === "undefined" || jewellers === null) {
            jewellers = []
        }

        return (
            <div id="SaleGeneralEdit_component">
                {
                    this.state.updating_sale === true ?
                        (
                            <LoadingLayer />
                        )
                        :
                        ('')
                }
                {
                    <FlashMessages
                        error_messages={this.state.error_messages}
                        warning_messages={this.state.warning_messages}
                        success_messages={this.state.success_messages}
                    />
                }
                <Form>
                    {(!this.state.isAccounting && !this.state.isCosts) ? (
                        <div className="row">
                            <div className="col-6">
                                <Form.Group>
                                    <FormLabel>Price to charge to customer</FormLabel>
                                    <Form.Control
                                        type="number"
                                        name="priceToCharge"
                                        value={this.state.priceToCharge === null ? '' : this.state.priceToCharge}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel>Balance</FormLabel>
                                    <Form.Control
                                        type="number"
                                        name="balance"
                                        value={this.state.balance === null ? '' : this.state.balance}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check
                                        type="checkbox"
                                        name="customerNeedsToPay"
                                        label="Customer needs to pay"
                                        checked={this.state.customerNeedsToPay === true ? true : false}
                                        onChange={this.handleChangeCheckbox}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel>Xero Code</FormLabel>
                                    <Form.Control
                                        type="text"
                                        name="xeroCode"
                                        value={this.state.xeroCode === null ? '' : this.state.xeroCode}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel className="d-block">Payment Method</FormLabel>
                                    <Form.Select
                                        onChange={(e) => this.handleChangeSelect(e)}
                                        value={this.state.paymentMethod ?? ''}
                                        name="paymentMethod"
                                        required={true}
                                    >
                                        <option value=""></option>
                                        {
                                            this.listPaymentMethod.map(listPaymentMethod => {
                                                return (
                                                    <option
                                                        key={listPaymentMethod}
                                                        value={listPaymentMethod}>
                                                        {listPaymentMethod}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </div>

                            <div className="col-6">
                                <Form.Group className="form_group_create card">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="sale_title alight-center bold_font">Costs</div>
                                            <div key={this.state.postSaleJewellerContainerId}>
                                                {
                                                    (typeof jewellers !== "undefined" && jewellers !== null && jewellers !== "") ?
                                                        jewellers.map((jeweller, index) => {
                                                            return (
                                                                <PostSaleCostsRowEdit
                                                                    key={index}
                                                                    index={index}
                                                                    ref={"postSaleJewellerRow" + index}
                                                                    jeweller={jeweller}
                                                                    updateJeweller={this.updateJeweller}
                                                                />
                                                            )
                                                        }) : (null)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    ) : (
                        (this.state.isAccounting) ? (
                            <div className="row">
                                <div className="col-6">
                                    <Form.Group>
                                        <FormLabel>Price to charge to customer</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="priceToCharge"
                                            value={this.state.priceToCharge === null ? '' : this.state.priceToCharge}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <FormLabel>Balance</FormLabel>
                                        <Form.Control
                                            type="number"
                                            name="balance"
                                            value={this.state.balance === null ? '' : this.state.balance}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <FormLabel>Xero Code</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="xeroCode"
                                            value={this.state.xeroCode === null ? '' : this.state.xeroCode}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <FormLabel>Payment Method</FormLabel>
                                        <Form.Select
                                            onChange={(e) => this.handleChangeSelect(e)}
                                            value={this.state.paymentMethod ?? ''}
                                            name="paymentMethod"
                                            required={true}
                                        >
                                            <option value=""></option>
                                            {
                                                this.listPaymentMethod.map(listPaymentMethod => {
                                                    return (
                                                        <option
                                                            key={listPaymentMethod}
                                                            value={listPaymentMethod}>
                                                            {listPaymentMethod}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-6">
                                    <Form.Group className="form_group_create card">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="sale_title alight-center bold_font">Costs</div>
                                                <div key={this.state.postSaleJewellerContainerId}>
                                                    {
                                                        (typeof jewellers !== "undefined" && jewellers !== null && jewellers !== "") ?
                                                            jewellers.map((jeweller, index) => {
                                                                return (
                                                                    <PostSaleCostsRowEdit
                                                                        key={index}
                                                                        index={index}
                                                                        postSaleUpdated ref={"postSaleJewellerRow" + index}
                                                                        jeweller={jeweller}
                                                                        updateJeweller={this.updateJeweller}
                                                                    />
                                                                )
                                                            }) : (null)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        )
                    )
                    }

                    <Button
                        type="submit"
                        onClick={this.editSale}
                        disabled={this.state.creating_sale}
                    >
                        Update
                    </Button>
                </Form>
            </div>
        )
    }

    updateJeweller(updateIndex, state) {
        let jewellers
        if (typeof this.state.jewellers !== "undefined" && this.state.jewellers !== null) {
            if (this.state.jewellers !== "") {
                try {
                    jewellers = JSON.parse(this.state.jewellers)
                }
                catch (e) {
                    console.log("Error: " + e)
                    jewellers = []
                }
            }
            else {
                jewellers = []
            }
        }
        else {
            jewellers = []
        }

        let changeStatusToReady = false
        if (jewellers.length > 0) {
            if (typeof jewellers[updateIndex] !== "undefined") {
                if ("cost" in state) jewellers[updateIndex].cost = state.cost
                if ("jewellersInvoice" in state) jewellers[updateIndex].jewellersInvoice = state.jewellersInvoice

                this.unsavedJewellers = JSON.stringify(jewellers)

                if (changeStatusToReady) {
                    this.setState({
                        status: "Ready"
                    })
                }

                this.props.handleTabChange("accounting", true)
                this.props.updateJewellers(JSON.stringify(jewellers));
            }
        }
    }

    axioRequest = null;
    editSale(event) {
        if (!this.state.isAccounting) {
            this.setState({
                jewellers: JSON.stringify(this.unsavedJewellers)
            })
        }

        event.preventDefault();
        this.setState({
            updating_sale: true
        })
        const user = Auth.getAuthenticatedUser()
        const sale = this.props.sale
        if (user === null || user === undefined || user.employeeModel === null || user.employeeModel === undefined) {
            this.setState(
                {
                    error_messages: ['Authentication error. Reload the page and try again.']
                }
            )
        }
        else if (sale === null || sale === undefined) {
            this.setState(
                {
                    error_messages: ['Sale identification error. Reload the page and try again.']
                }
            )
        }
        else {
            let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_EDIT
            url = url.replace("{id}", sale.id)
            const params = new URLSearchParams()

            let saleId = this.state.sale.id;

            let priceToCharge = this.state.priceToCharge;
            if (priceToCharge === null || typeof priceToCharge === 'undefined') {
                priceToCharge = this.state.sale.priceToCharge
            }
            let balance = this.state.balance;
            if (balance === null || typeof balance === 'undefined') {
                balance = this.state.sale.balance
            }
            let customerNeedsToPay = this.state.customerNeedsToPay;
            if (customerNeedsToPay === null || typeof customerNeedsToPay === 'undefined') {
                customerNeedsToPay = this.state.sale.customerNeedsToPay
            }
            let xeroCode = this.state.xeroCode;
            if (xeroCode === null || typeof xeroCode === 'undefined') {
                xeroCode = this.state.sale.xeroCode
            }
            let paymentMethod = this.state.paymentMethod;
            if (paymentMethod === null || typeof paymentMethod === 'undefined') {
                paymentMethod = this.state.sale.paymentMethod
            }

            if (!this.state.isAccounting && !this.state.isCosts) {
                params.append('id', saleId)
                params.append('priceToCharge', priceToCharge)
                params.append('balance', balance)
                params.append('customerNeedsToPay', customerNeedsToPay)
                params.append('xeroCode', xeroCode)
                params.append('paymentMethod', paymentMethod)
                params.append('jewellers', this.unsavedJewellers)
            }
            else {
                if (this.state.isAccounting) {
                    params.append('id', saleId)
                    params.append('priceToCharge', priceToCharge)
                    params.append('balance', balance)
                    params.append('xeroCode', xeroCode)
                    params.append('paymentMethod', paymentMethod)
                }
                else {
                    params.append('id', saleId)
                    params.append('jewellers', this.unsavedJewellers)
                }
            }

            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }

            axios.put(
                url,
                params,
                config
            )
                .then(
                    (response) => {
                        const data = response.data
                        if (data !== undefined && data.code === 200) {
                            this.props.handleTabChange("accounting", false)

                            if (!this.state.isAccounting) {
                                this.props.updateJewellers(this.unsavedJewellers);
                            }

                            this.setState(
                                {
                                    updating_sale: false,
                                    success_messages: ['Post sale updated']
                                }
                            )
                        }
                        else {
                            this.setState(
                                {
                                    updating_sale: false,
                                    error_messages: ['There was a problem processing the information. Please reload this page and try again.']
                                }
                            )
                        }
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            let error_messages = []
                            if ('status' in err.response && err.response.status === 401) {
                                error_messages = ['Not authorised.']
                            }
                            else {
                                error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                            }
                            this.setState(
                                {
                                    updating_sale: false,
                                    error_messages: error_messages
                                }
                            )
                        } else if (err.request) {
                            const error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                            this.setState(
                                {
                                    updating_sale: false,
                                    error_messages: error_messages
                                }
                            )
                        } else {
                            //error not detected. Request could have been cancelled and it's waiting for second
                            //request to finish
                            this.setState(
                                {
                                    updating_sale: true
                                }
                            )
                        }
                        //console.log((err);
                        return null;
                    }
                )
        }
    }

    updateGeneralTabSheetType(sheetType) {
        this.setState({
            sheetType: sheetType
        })
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return {
            jewellers: props.sale.jewellers,
            postSaleJewellerContainerId: Math.random(), //this ensure that the whole component would rerender on delete
        }
    }

    componentDidMount() {
        this.setState({
            jewellers: this.props.sale.jewellers,
        })
        this.unsavedJewellers = this.props.sale.jewellers
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************


}

export default PostSaleAccountingEdit;