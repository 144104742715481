import React from "react";
import { Card, Table, Button } from "react-bootstrap";
import Util from "../../../Util";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { DOWNLOAD_CHOICE_REPORTS } from "../../../store/constants";
import { setDownloadExcelReport } from "../../../store/slices";

const TotalSalesReportDetails = ({ jsonData }) => {
  let allTotal = 0,
    allSales = 0,
    allFollowUp = 0,
    allReviews = 0,
    allAverageSaleValue = 0,
    allTotalSaleVolume = 0,
    allNumberOfDiscounts = 0,
    allTotalDiscounts = 0;

  const dispatch = useDispatch();

  const handleDownloadExcel = () => {
    dispatch(setDownloadExcelReport(DOWNLOAD_CHOICE_REPORTS[0]));
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title className="d-flex justify-content-between align-items-center">
          <h2>Appointments Efficiency</h2>
          <Button
            disabled={jsonData && jsonData.length > 0 ? false : true}
            className="btn-icon-pink"
            onClick={handleDownloadExcel}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </Card.Title>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Month</th>
              <th>Appointments</th>
              <th># Conversion</th>
              <th>% Conversion</th>
              <th># Follow ups</th>
              <th># Reviews requests</th>
              <th>Average Sale Value</th>
              <th>Total Sale Volume</th>
              <th>Number of discounts issued</th>
              <th>Total $ of discounts issued</th>
            </tr>
          </thead>
          <tbody>
            {jsonData.map((item, index) => {
              const {
                ca_year,
                ca_month,
                total,
                ca_sale,
                ca_follow_up,
                ca_review,
                total_sale_volume,
                number_of_discounts,
                total_discounts,
              } = item;

              const monthDate = moment(
                new Date(`${ca_year}-${ca_month}-28`),
                "YYYY-MM-DD"
              ).format("MMM");

              const salePercentage = ca_sale > 0 ? (ca_sale * 100) / total : 0;
              const average_sale_value =
                total_sale_volume > 0 ? total_sale_volume / ca_sale : 0;
              allTotal += parseInt(total);
              allSales += parseInt(ca_sale);
              allFollowUp += parseInt(ca_follow_up);
              allReviews += parseInt(ca_review);
              allAverageSaleValue += average_sale_value;
              allTotalSaleVolume += total_sale_volume;
              allNumberOfDiscounts += parseInt(number_of_discounts);
              allTotalDiscounts += total_discounts;

              return (
                <tr key={index}>
                  <td>
                    {ca_year}-{monthDate}
                  </td>
                  <td>{total}</td>
                  <td>{ca_sale}</td>
                  <td>{Util.numberDecimal(salePercentage)}%</td>
                  <td>{ca_follow_up}</td>
                  <td>{ca_review}</td>
                  <td>{Util.formatCurrency(average_sale_value, false)}</td>
                  <td>{Util.formatCurrency(total_sale_volume ?? 0, false)}</td>
                  <td>{number_of_discounts}</td>
                  <td>{Util.formatCurrency(total_discounts ?? 0, false)}</td>
                </tr>
              );
            })}
            {jsonData.length > 0 && (
              <tr>
                <th>Total</th>
                <th>{allTotal}</th>
                <th>{allSales}</th>
                <th>
                  {allSales > 0
                    ? Util.numberDecimal((allSales * 100) / allTotal)
                    : 0}
                  %
                </th>
                <th>{allFollowUp}</th>
                <th>{allReviews}</th>
                <th>
                  {allAverageSaleValue > 0
                    ? Util.formatCurrency(allAverageSaleValue, false)
                    : 0}
                </th>
                <th>
                  {allTotalSaleVolume > 0
                    ? Util.formatCurrency(allTotalSaleVolume, false)
                    : 0}
                </th>
                <th>{allNumberOfDiscounts}</th>
                <th>
                  {allTotalDiscounts > 0
                    ? Util.formatCurrency(allTotalDiscounts, false)
                    : 0}
                </th>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default TotalSalesReportDetails;
