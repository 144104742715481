import axios from "axios"
import { sprintf } from "sprintf-js";
import * as globalConst from '../../../../../global/const';
import Auth from "../../../../../Auth";
import { ExistSaleDiamonds, GetSaleDiamonds } from "../../../detail/Actions/GetSaleDiamonds";

const CancelToken = axios.CancelToken;
let cancel;

export const SearchSales = async (
    sale,
    search
) => {
    try {
        if (!search) {
            return []
        }

        const url = sprintf(
            '%s%s',
            process.env.REACT_APP_NOVITAERP_API_DOMAIN,
            globalConst.REACT_APP_NOVITAERP_API_SALES_SEARCH_MERGE
        );

        const { id } = sale

        const parameters = {
            excludeSale: {
                id: id
            },
            search: {
                onlineOrderNumber: search
            }
        }

        if (cancel !== undefined) {
            cancel();
        }

        return await axios.post(
            url,
            parameters,
            {
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
                headers: {
                    Authorization: `Bearer ${Auth.getToken()}`,
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(response => {
                const { data } = response;
                const { value } = data;

                if (Array.isArray(value) === true && value.length > 0) {
                    return value
                }

                return []
            }).catch(error => {
                return []
            })
    } catch (error) {
        return []
    }
}

export const SearchValuesSales = (
    sale
) => {
    if (ExistSaleDiamonds(sale)) {
        const response = GetSaleDiamonds(sale)
        const saleDiamonds = response.saleDiamondModels

        const {
            soldPrice = null,
            discountPercentage = null,
            discountPrice = null
        } = saleDiamonds[0]

        return {
            soldPrice,
            discountPercentage,
            discountPrice
        }
    }

    const { totalDiamondWogst } = sale

    return {
        soldPrice: totalDiamondWogst,
        discountPercentage: null,
        discountPrice: null
    }
}