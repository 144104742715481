import React, {Component} from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap'
import Auth from '../../Auth';
import axios from 'axios'
import FlashMessages from "../FlashMessages";
import LoadingLayer from '../LoadingLayer'
import SaleRow from '../sales/SaleRow';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as globalConst from '../../global/const';

// SCSS
import '../../css/sales/sale_create.scss'

class SaleSearch extends Component {

    constructor(props){
        super(props);
        this.state = {
            sales: [],
            total_results : 0,
            api_has_more : false,
            saleDate : new Date(),
            name : '',
            phone : '',
            email : '',
            saleType : 'Wedding Band',
            ringPreference : 'Custom',
            settingStatus : '',
            manufacturingLocation : '',
            onlineOrderNumber : 'N/A',
            loading_list : false,
            show_sale_search_modal: false,
            saleId: null,
            selected_sale_index: null,
            disable_button: true
        }
        this.closeModal = this.closeModal.bind(this)
        this.handleChangeDateField = this.handleChangeDateField.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.selectSale = this.selectSale.bind(this)
    }

    filter_params = {}

    closeModal(){
        this.props.closeComponent()
    }

    async selectSale(event){
        try {
            const sale = this.state.sales[this.state.selected_sale_index]
            await this.fetchSaleDetailWithId(sale.id, this.state.selected_sale_index)
            this.closeModal();
        }
        catch (e)
        {
            console.log(e);
        }
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        return(
            <div>
                <Modal 
                    show={true}
                    onHide={this.closeModal}
                    dialogClassName="modal-90w"
                >
                    <Modal.Header closeButton>
                        SELECT SALE
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.loading_list === true ? 
                            (
                                <LoadingLayer/>
                            )
                            :
                            ('')
                        }
                        {
                            <FlashMessages
                                error_messages = {this.state.error_messages}
                                warning_messages = {this.state.warning_messages}
                                success_messages = {this.state.success_messages}
                            />
                        }
                        <Form id="PostSaleSearch_component">
                            <Form.Group>
                                <FormLabel>Search sale:</FormLabel>
                                <input className='box-bg' type="text" onChange={(e) => this.handleFilterChange(e, 'generalSearch')} onKeyPress={e => { if (e.key === 'Enter') e.preventDefault(); }} />
                            </Form.Group>
                            <InfiniteScroll
                                dataLength={this.state.sales.length}
                                next={this.fetchMoreData}
                                hasMore={this.state.api_has_more}
                                height={'calc(70vh - 200px)'}
                            >
                                <table className="table table-striped table-sm table-hover" id="table_sales">
                                <thead className="thead-dark">
                                    <tr className='titles'>
                                        <th className="saleDate">
                                            Date of Sale
                                        </th>
                                        <th className="addToMyList">
                                            My List
                                        </th>
                                        <th className="name">
                                            Name
                                        </th>
                                        <th className="status">
                                            Order Status
                                        </th>
                                        <th className="orderTypeCol">
                                            Type of Order
                                        </th>
                                        <th className="dateCustomerNeedsBy">
                                            Date Customer Needs by
                                        </th>
                                        <th className="settingStatus">
                                            Setting Status
                                        </th>
                                        <th className="saleType">
                                            Type of Product
                                        </th>
                                        <th className="ringPreference">
                                            Product's Ref.
                                        </th>
                                        <th className="jeweller">
                                            Jeweller
                                        </th>
                                        <th className="diamondLocation">
                                            Diamond Location
                                        </th>
                                        <th className="manufacturingLocation">
                                            Manufacturing Location
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.sales.map((sale, index) => {
                                            return (
                                                <SaleRow
                                                    ref={ "saleRow" + sale.id }
                                                    key={ sale.id }
                                                    sale={ sale }
                                                    index={ index }
                                                    handleRowSelection={ this.handleRowSelection }
                                                    selected_sale={this.state.selected_sale}
                                                />
                                            )
                                        })
                                    }
                                </tbody>
                                </table>
                            </InfiniteScroll>
                            <Button 
                                className="btn-default save-information-btn"
                                type="button"
                                onClick={this.selectSale}
                                disabled={this.state.loading_list || this.state.disable_button}
                            >
                                Select
                            </Button>
                        </Form>



                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    handleRowSelection = (index) => {
        //const sale = this.state.sales[index]
        //const sale_id = sale.id;
        //this.fetchSaleDetailWithId(sale_id, index)
        /*if (window.innerWidth <= 600) {
            this.openInformationComponent()
        }*/

        this.setState({
            disable_button: false,
            selected_sale : this.state.sales[index],
            selected_sale_index : index
        })
    }

    ajaxRequestForDetail = null;
    async fetchSaleDetailWithId(sale_id, selected_sale_index){
        this.setState(
            {
                loading_list: true
            }
        )
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if(this.ajaxRequestForDetail !== null){
            this.ajaxRequestForDetail.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.ajaxRequestForDetail = axios.CancelToken.source();
        //FINISH AJAX CONTROLS
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALES_DETAIL
        url = url.replace("{id}", sale_id)

        return await axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            },
            {
                cancelToken: this.ajaxRequestForDetail.token
            }
        )
        .then(
            api_response => {
                const selected_sale = api_response.data.result
                //selected_sale["pickupAddress"] = selected_sale.shippingTo
                //selected_sale["dropoffAddress"] = selected_sale.shippingTo
                this.setState({
                    selected_sale : selected_sale,
                    selected_sale_index : selected_sale_index,
                    loading_list: false
                })
                this.props.setSelectedSale(selected_sale);
            }
        )
        .catch(
            err => {
                if (err.response) {
                    if('status' in err.response && err.response.status === 401){
                        alert('User not authorised. Please reload the page');
                    }
                    else{
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                    this.setState(
                        {
                            loading_list: false
                        }
                    )
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                    this.setState(
                        {
                            loading_list: false
                        }
                    )
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                    this.setState(
                        {
                            loading_list: true
                        }
                    )
                }
                console.log(err);
                return null;
            }
        )
    }

    handleChange(event){
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    handleChangeDateField = (date, field_name) => {
        const state = {}
        if(date !== null && date !== ''){
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth()+1)).slice(-2)
            const year = date.getFullYear()
            state[field_name] = year+'-'+month+'-'+day
        }
        else{
            state[field_name] = null
        }
        this.setState(state)
    }

    openSaleSearchPopup = () => {
        this.setState(
            {
                show_sale_search_modal: true
            }
        )
    }

    closeSaleSearchPopup = () => {
        this.setState(
            {
                show_sale_search_modal: false
            }
        )
    }

    handleFilterChange = (e, field_name) => {
        let filter_value = e.target.value;
        let execute_filter = false;

        if(
            field_name === 'generalSearch'
        ){
            this.filter_params[field_name] = filter_value;
            execute_filter = true;
        }
        this.filter_params['page'] = 1;
        this.filter_params['max_results'] = 100;
        this.filter_params['order_by'] = 'saleDate';
        this.filter_params['order_type'] = 'DESC';
        if(execute_filter === true) {
            this.fetchSales();
        }
    }

    ajaxRequest = null;
    fetchSales(){
        this.setState(
            {
                loading_list: true
            }
        )
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if(this.ajaxRequest !== null){
            this.ajaxRequest.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.ajaxRequest = axios.CancelToken.source();
        //FINISH AJAX CONTROLS
        const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALES_LIST
        axios.get(
            url,
            {
                params: this.filter_params,//this params are sent as query. In post requests they should go as body params in the following block
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            },
            {
                cancelToken: this.ajaxRequest.token
            })
            .then(api_response => {
                const result = api_response.data.result;
                const items = result.rows;

                // add list to previous items
                let list = []
                if(this.filter_params.page === 1){
                    list = items;
                    // window.scrollTo(0,0);
                    window.scrollTo({ top: 0 });
                }
                else{
                    let current_list = [
                        ...this.state.sales
                    ]
                    list = current_list.concat(items);
                }

                this.setState({
                    sales: list,
                    loading_list: false,
                    total_results: result.count,
                    api_has_more: result.amount_results > 0 ? true : false,
                    sheetType : this.filter_params.sheetType
                })
            })
            .catch(err => {
                if (err.response) {
                    if('status' in err.response && err.response.status === 401){
                        alert('User not authorised. Please reload the page');
                    }
                    else{
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                    this.setState(
                        {
                            loading_list: false
                        }
                    )
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                    this.setState(
                        {
                            loading_list: false
                        }
                    )
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                    this.setState(
                        {
                            loading_list: true
                        }
                    )
                }
                console.log(err);
            })
    }


    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    

}

export default SaleSearch;