import { Link } from "react-router-dom";
import Notifications from "../../Notifications";
import BackIcon from "../../image/backArrow.png";

export default function CustomersHeader() {
  return (
    <div className="header">
      <div className="row header_tools">
        <div className="">
          <div className="col-12">
            <div className="link_to_dashboard">
              <Link to="/dashboard">
                <img height="50px" src={BackIcon} alt="" />
              </Link>
            </div>
            <div className="db-top-heading">
              <h2 className="crm-title">CRM</h2>
              <Notifications />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
