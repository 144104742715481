import React, {Component} from 'react';
import { Form } from "react-bootstrap";

class SaleRow extends Component {

    constructor(props){
        super();
        this.state = {
            accounting : props.accounting,
            selected_accounting : props.selected_accounting,
            addToMyList : props.addToMyList,
            currentTab : props.currentTab
        }
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
    }

    handleChangeCheckbox(event){
        this.removeSaleFromCustomisedList();
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        const accounting = this.state.accounting;
        
        let saleDate = null
        if(accounting.created !== null && accounting.created !== ''){
            saleDate = new Date(accounting.created).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            saleDate = saleDate.slice(0, -5) + ", " + saleDate.slice(-4)
        }

        /*let dateCustomersNeedBy = null
        if(accounting.customerNeedsOn !== null && accounting.customerNeedsOn !== ''){
            dateCustomersNeedBy = new Date(accounting.customerNeedsOn).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            dateCustomersNeedBy = dateCustomersNeedBy.slice(0, -5) + ", " + dateCustomersNeedBy.slice(-4)
        }*/

        let row_class = "sale_row";
        if (this.state.highlight_row === true) {
            row_class = "sale_row highlighted_row";
        }

        if(this.state.selected_accounting !== null && this.state.selected_accounting !== undefined && accounting !== null && accounting.id === this.state.selected_accounting.id){
            row_class = row_class+" highlighted_row"
        }

        /*let orderTypeClass = ""
        if (accounting.orderType === "Express") {
            orderTypeClass = "express"
        }
        else if (accounting.orderType === "Ready to Ship") {
            orderTypeClass = "readyToShip"
        }*/

        let typeOfJob = ""
        if (accounting.typeOfService === "Post Sale Service"
            && accounting.postSaleServiceModel !== null) {
            typeOfJob = accounting.postSaleServiceModel.typeOfJob
        }

        let name = ""
        if (accounting.salesModel !== null) {
            name = accounting.salesModel.name
        }
        if (accounting.postSaleServiceModel !== null) {
            name = accounting.postSaleServiceModel.name
        }

        let onlineOrderNumber = ""
        if (accounting.salesModel !== null) { 
            onlineOrderNumber = accounting.salesModel.onlineOrderNumber
        }
        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                onlineOrderNumber = accounting.postSaleServiceModel.salesModel.onlineOrderNumber
            }
        }

        let saleType = ""
        if (accounting.salesModel !== null) {
            saleType = accounting.salesModel.saleType
        }
        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                saleType = accounting.postSaleServiceModel.salesModel.saleType
            }
        }

        let settingStockNumber = false
        if (accounting.salesModel !== null) {
            if (accounting.salesModel.workflowSaleModels.length > 0) {
                if (accounting.salesModel.workflowSaleModels[0].workflowActivityModels.length > 0) {
                    settingStockNumber = accounting.salesModel.workflowSaleModels[0].workflowActivityModels[0].stockNumbe
                }
            }
        }

        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                settingStockNumber = accounting.postSaleServiceModel.salesModel.settingStockNumber
            }
        }

        let settingCostPrice = ''
        if (accounting.salesModel !== null) {
            if (accounting.salesModel.workflowSaleModels.length > 0) {
                if (accounting.salesModel.workflowSaleModels[0].workflowActivityModels.length > 0) {
                    settingCostPrice = accounting.salesModel.workflowSaleModels[0].workflowActivityModels[0].cost
                }
            }
        }

        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                settingCostPrice = accounting.postSaleServiceModel.salesModel.settingCostPrice
            }
        }

        let melesCost = ""
        if (accounting.salesModel !== null) {
            if (accounting.salesModel.workflowSaleModels.length > 0) {
                if (accounting.salesModel.workflowSaleModels[0].workflowActivityModels.length > 0) {
                    melesCost = accounting.salesModel.workflowSaleModels[0].workflowActivityModels[0].melees_cos
                }
            }
        }
        
        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                melesCost = accounting.postSaleServiceModel.salesModel.melesCost
            }
        }

        let diamondDetails = ""
        if (accounting.salesModel !== null) {
            diamondDetails = accounting.salesModel.diamondDetails
        }
        if (accounting.postSaleServiceModel !== null) {
            if (accounting.postSaleServiceModel.salesModel !== null) {
                diamondDetails = accounting.postSaleServiceModel.salesModel.diamondDetails
            }
        }

        let accountingStatus = ""
        if (accounting.salesModel !== null) { 
            accountingStatus = accounting.salesModel.accountingStatus
        }

        return(
            <tr className={row_class} onClick={() => this.props.handleRowSelection(this.props.index)}>
                <td className="saleDate">
                    { saleDate !== null ? 
                        <div className="saleDate">
                            {saleDate}
                        </div>
                        :('')
                    }
                </td>
                <td className="addToMyList" onClick={e => e.stopPropagation()}>
                    <Form.Check
                        type="checkbox"
                        name="addToMyList"
                        id={"addToMyList" + this.props.index}
                        checked={this.state.addToMyList === true ? true : false}
                        onChange={this.handleChangeCheckbox}
                    />
                </td>
                <td className="name">
                    { name }
                </td>
                <td className="onlineOrderNumber">
                    { onlineOrderNumber }
                </td>
                <td className="saleType">
                    { saleType }
                </td>
                <td className="typeOfService">
                    { (accounting.typeOfService === "Post Sale Service") ? "Post Sale Service - " + typeOfJob : "Sale" } 
                </td>
                <td className="settingStockNumber">
                    { settingStockNumber }
                </td>
                <td className="settingCostPrice">
                    { settingCostPrice }
                </td>
                <td className="melesCost">
                    { melesCost }
                </td>
                <td className="diamondDetails">
                    { diamondDetails }
                </td>
                <td className="accountingStatus">
                    { accountingStatus }
                </td>
            </tr>
        )
    }

    formatCurrency(value) {
        if (typeof value === "undefined")
        {
            return "";
        }
        else if (value === null || value === "") {
            return "";
        }
        else if (isNaN(Number(value))) {
            return value;
        }

        value = value.toString();
        value = value.replace(",","");
        value = (Math.round(value * 100) / 100).toFixed(2);
        const currencyFormatter = Intl.NumberFormat(process.env.REACT_APP_NUMBER_FROMAT, { style: 'currency', currency: process.env.REACT_APP_CURRENCY });
        let formattedValue = currencyFormatter.format(value).replace("$","");
        if (typeof formattedValue === "undefined") {
            return Number(0).toFixed(2);
        }
        if (formattedValue === "NaN") {
            return Number(0).toFixed(2);
        }
        return formattedValue;
    }


    highlightRow(){
        this.setState({
            highlight_row : !this.state.highlight_row
        });
    }

    removeSaleFromCustomisedList() {
        let saleId = this.state.accounting.id;
        this.props.removeSaleFromCustomisedList(saleId);
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return{
            accounting: props.accounting,
            selected_accounting : props.selected_accounting,
            addToMyList : props.addToMyList,
            currentTab : props.currentTab
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    
}

export default SaleRow;