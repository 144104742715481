import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import Util from '../../../Util'
import { GetSaleDiamondDetail } from '../detail/Actions/GetSaleDiamonds'

const SaleDiamondInformation = props => {
    const { diamonds, sale, searchDetailDiamond } = props

    return (
        diamonds.map(diamond => {
            if (!diamond.deletedFromSales) {
                return (
                    <Row key={diamond.id} className='m-2 overflow-hidden'>
                        <h2>
                            Diamond: {
                                GetSaleDiamondDetail(
                                    diamond?.diamondProductModel?.certNo,
                                    sale,
                                    searchDetailDiamond
                                )
                            }
                        </h2>

                        <Row>
                            <Col lg="6" className={'table_title'}>Cost</Col>
                            <Col lg="6">{Util.formatCurrency(diamond?.cost)}</Col>
                        </Row>
                        <Row>
                            <Col lg="6" className={'table_title'}>Internal Invoice</Col>
                            <Col lg="6">{diamond?.internalInvoice}</Col>
                        </Row>
                        <Row>
                            <Col lg="6" className='table_title'>Accounting Status</Col>
                            <Col lg="6">{diamond?.accountingStatus}</Col>
                        </Row>
                    </Row>
                )
            }

            return null
        })
    )
}

SaleDiamondInformation.propTypes = {
    diamonds: PropTypes.array.isRequired,
    sale: PropTypes.any.isRequired,
    searchDetailDiamond: PropTypes.bool
}

SaleDiamondInformation.defaultProps = {
    searchDetailDiamond: true
}

export default SaleDiamondInformation