import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
import { dateFormatCustom } from "../../../utils/dateUtils";
import FormDatePicker from "../../Form/FormDatePicker";

const ResultAnalysisDateFilter = (props) => {
  const { onResponse } = props;
  const currentYear = new Date().getFullYear();
  const defaultMinDate = new Date(currentYear, 0, 1);
  defaultMinDate.setHours(0, 0, 0, 0);

  const defaultMaxDate = new Date();
  defaultMaxDate.setHours(23, 59, 59, 999);

  const [sendData, setSendData] = useState(defaultMinDate);
  const [selectedMinDate, setSelectedMinDate] = useState(defaultMinDate);
  const [selectedMaxDate, setSelectedMaxDate] = useState(defaultMaxDate);

  const handleMinDateChange = async (date) => {
    if (date) {
      date.setHours(0, 0, 0, 0);
      setSendData(true);
      setSelectedMinDate(date);
    }
  };

  const handleMaxDateChange = async (date) => {
    if (date) {
      date.setHours(23, 59, 59, 999);
      setSendData(true);
      setSelectedMaxDate(date);
    }
  };

  const responseDate = useCallback(() => {
    onResponse({
      startDate: dateFormatCustom(selectedMinDate),
      endDate: dateFormatCustom(selectedMaxDate),
    });
  }, [onResponse, selectedMinDate, selectedMaxDate]);

  useEffect(() => {
    if ((selectedMinDate || selectedMaxDate) && sendData) {
      responseDate();
      setSendData(false);
    }
  }, [selectedMinDate, selectedMaxDate, sendData, responseDate]);

  return (
    <div>
      <Form>
        <Row className="align-items-end">
          <Col md={6}>
            <FormDatePicker
              label="Date form"
              nameInput="startDate"
              value={selectedMinDate ?? ""}
              onResponse={handleMinDateChange}
              dateFormat="MM/dd/yyyy"
              columnsSize={"12"}
              maxDate={selectedMaxDate}
            />
          </Col>
          <Col md={6}>
            <FormDatePicker
              label="Date To"
              nameInput="endDate"
              value={selectedMaxDate ?? ""}
              onResponse={handleMaxDateChange}
              columnsSize={"12"}
              dateFormat="MM/dd/yyyy"
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

ResultAnalysisDateFilter.propTypes = {
  onResponse: PropTypes.func.isRequired,
};

export default ResultAnalysisDateFilter;
