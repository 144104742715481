import { useEffect, useState } from "react";
import axiosServices from "../../../axios";
import Auth from "../../../Auth";
import { formatDate } from "../../../utils/dateUtils";

export default function DocumentInfo({ document }) {
  const [ history, setHistory ] = useState([]);

  useEffect(() => {
    axiosServices.get(`/api/v1/documents/document/history/${document.id}`).then(response => {
      setHistory(response.data);
    });
  }, [document]);

  const buildMiddleRow = (name, value) => {
    return (
      <div key={name} className="row border-bottom">
        <div className="p-1 col-sm table_title border-right ">
          <span className="table_title">{name}</span>
        </div>
        <div className="p-1 col-sm">{value}</div>
      </div>
    );
  };

  const isHistoryVisible = () => {
    // Super admins and admins can edit all files
    const user = Auth.getAuthenticatedUser();
    // check if user created this file
    if (user.employeeModel.id === document.uploadedById) {
      return true;
    }
    return Auth.isAuthorised('view_document_history');
  }

  return (
    <>
      <div className="general_information document-details-card">
        <h2>Document</h2>
        <div className="customer-details-container p-2">
          <div className="container">
            {buildMiddleRow("Title", document.title)}
            {buildMiddleRow("Filename", document.filename)}
            {buildMiddleRow("Description", document.description)}
            {buildMiddleRow(
              "Created",
              formatDate(document.created)
            )}
            {buildMiddleRow(
              "Access",
              document.roles.map((e) => e).join(", ")
            )}
            {buildMiddleRow(
              "",
              document.employeeDocumentAccesses.map((e) => e.employee.name).join(", ")
            )}
          </div>
        </div>
      </div>
      { isHistoryVisible() &&
        <div className="general_information document-details-card">
          <h2>History</h2>
          <div className="customer-details-container p-2">
            <div className="">
              <table className="w-100">
                <tr className="border-bottom">
                  <th>Name</th>
                  <th>IP</th>
                  <th>Country</th>
                  <th>Date</th>
                </tr>
                {
                  history.map((e) => {
                    console.log(e.created);
                    return <tr key={e.id} className="border-bottom">
                      <td>{e.employee.name}</td>
                      <td>{e.ip}</td>
                      <td>{e.country}</td>
                      <td>{formatDate(e.created)}</td>
                    </tr>
                  })
                }
              </table>
            </div>
          </div>
        </div>
      }
    </>
  );
}
