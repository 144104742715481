import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    Col,
    Form,
    FormLabel,
    Row
} from 'react-bootstrap'

const SaleCreateAccountingData = props => {
    const { saleData, onResponse } = props
    const [sale, setSale] = useState(saleData)

    const handleChange = (e) => {
        const data = sale

        const { value, name } = e.target

        data[name] = value

        setSale(prevState => ({
            ...prevState,
            [name]: value
        }))

        onResponse(value, name)
    }

    return (
        <Card className='mt-2'>
            <Card.Body>
                <Card.Title>
                    Accounts Information
                </Card.Title>
                <Row>
                    <Col sm="12" lg="12">
                        <Form.Group className="form-group">
                            <FormLabel>Billing Information</FormLabel>
                            <Form.Control
                                type="text"
                                name="billingInformation"
                                value={sale.billingInformation ?? ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

SaleCreateAccountingData.propTypes = {
    saleData: PropTypes.any.isRequired,
    onResponse: PropTypes.func.isRequired
}

export default SaleCreateAccountingData