import React, {Component} from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';
import Auth from "../../../Auth";
import axios from 'axios';
import * as globalConst from '../../../global/const';

class SaleMultimediaDelete extends Component {
    constructor(props){
        super(props);
        this.state = {
            delete_media : false
        }
        this.closeModal = this.closeModal.bind(this)
    }

    closeModal(){
        this.props.closeMediaDeleteModal()
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        return(
            <div id="SaleMultimediaDelete_component">
                <Modal 
                    show={true}
                    onHide={this.closeModal}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        Confirm
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className='form-group'>
                                <FormLabel>Are you sure you want to remove the multimedia from the list?</FormLabel>
                            </Form.Group>
                            <Button 
                                type="button" className='btn btn-default confirm-btn'
                                onClick={this.removeFromList}
                                disabled={this.state.delete_media}
                            >
                                Yes
                            </Button>
                            <Button className='btn btn-default disagree-btn'
                                type="button"
                                onClick={this.closeModal}
                            >
                                No
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    removeFromList = () => {
        this.setState({
            delete_media: true
        })
        let sale_media_id = this.props.media_delete_selected.id;
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICE_MULTIMEDIA_DELETE
        url = url.replace("{id}", sale_media_id)
        
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${Auth.getToken()}`
        }

        axios.delete(
            url, {headers}
        )
        .then(
            api_response => {
                const data = api_response.data
                if(data !== undefined && data.code === 200){
                    var deleted_sale_media = data.result;
                    this.generateDeletionLog(deleted_sale_media);
                    this.props.updateSaleMultimediaEditRequirementsList()
                }
                else{
                    alert('error')
                }
            }
        )
        .catch(
            err => {
                if (err.response) {
                    if('status' in err.response && err.response.status === 401){
                        alert('User not authorised. Please reload the page');
                    }
                    else{
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                }
                //console.log(err);
                return null;
            }
        )
    }

     //Generate log after editing element
     axioRequest = null;
     last_sale_media_reported_for_log = null
     generateDeletionLog(deleted_sale_media) {
         //AJAX CONTROLS
         //cancel previous ajax request if exists
         if(this.axioRequest !== null){
            this.axioRequest.cancel();
         }
         // creates a new token for upcomming ajax (overwrite the previous one)
         this.axioRequest = axios.CancelToken.source();
         
         let sale_media_before_delete = this.last_sale_media_reported_for_log
         if(sale_media_before_delete === null){
            sale_media_before_delete = this.props.media_delete_selected
         }

         const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_LOG_NEW
         const params = new URLSearchParams()
 
         const user = Auth.getAuthenticatedUser()
         
         params.append('postSaleServiceId', sale_media_before_delete.postSaleServiceId)
         params.append('employeeId', user.employeeModel.id)
         params.append('oldInformation', JSON.stringify(sale_media_before_delete))
         params.append('newInformation', null)
         params.append('action', 'DELETE_MEDIA')
 
         const config = {
             headers: {
                 'Content-Type': 'application/x-www-form-urlencoded',
                 Authorization: `Bearer ${Auth.getToken()}`
             }
         }
         axios.post(
             url, 
             params, 
             config
         )
         .then(
             (response) => {
                 const data = response.data
                 if(data !== undefined && data.code === 200){
                     //window.location.reload(false);
                 }
                 else{
                     console.log('Error storing post_sales_multimedia_log')
                 }
                 this.axioRequest = null
             }
         )
         .catch(
             err => {
                 console.log('Error storing post_sales_multimedia_log.')
                 if (err.response) {
                     let error_messages = []
                     if('status' in err.response && err.response.status === 401){
                         console.log('Not authorised.')
                     }
                     else{
                         console.log('There was a problem processing the information. Please reload this page and try again.')
                     }
                 } else if (err.request) {
                     console.log('There was a problem processing the information. Please reload this page and try again.')
                 } else {
                     //error not detected. Request could have been cancelled and it's waiting for second
                     //request to finish
                 }
                 //console.log((err);
                 return null;
             }
         )
         
     }
}

export default SaleMultimediaDelete;