import { Component } from "react";
import axios from 'axios';
import Auth from '../../../Auth';
import LoadingLayer from '../../LoadingLayer';
import * as globalConst from '../../../global/const';
import MultimediaList from '../saleMultimedia/MultimediaList';

// SCSS
import '../../../css/sales/sale_detail.scss';

class PostSaleServicesDetail extends Component {

    ajaxRequest = null;

    constructor(props){
        super(props);
        const isAuth = Auth.getIsAuth()
        if(isAuth === false){
            props.history.push('/')
        }
        this.state = {
            sale : null,
            loading_page : true,
            url_params : props.match.params
        }
    }

    ajaxRequestForDetail = null;
    fetchSaleDetailWithId(sale_id){
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if(this.ajaxRequestForDetail !== null){
            this.ajaxRequestForDetail.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.ajaxRequestForDetail = axios.CancelToken.source();
        //FINISH AJAX CONTROLS
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_DETAIL
        url = url.replace("{id}", sale_id)

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            },
            {
                cancelToken: this.ajaxRequestForDetail.token
            }
        )
        .then(
            api_response => {
                const sale = api_response.data.result
                this.setState({
                    sale : sale,
                    loading_page: false
                })
            }
        )
        .catch(
            err => {
                if (err.response) {
                    if('status' in err.response){
                        if(err.response.status === 401){
                            alert('User not authorised.');
                        }
                        else if(err.response.status === 404){
                            alert('Sale not found.');
                        }
                    }
                    else{
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                    this.setState(
                        {
                            loading_page: true
                        }
                    )
                }
                console.log(err);
                return null;
            }
        )
    }

    render(){
        const sale = this.state.sale;
        return(
            <div id="SaleDetail_component">
                <div className="container">
                    { this.state.loading_page === true ? <LoadingLayer/> : ('') }
                    {
                        sale === null ?
                        (''):
                        (
                            <div className="detail_components">
                                <h1 className="order-number">ORDER #{ sale.salesModel !== null ? sale.salesModel.onlineOrderNumber : 'N/A' }</h1>
                                <div className="row">
                                    <div className="col-sm">
                                        {
                                            <MultimediaList
                                            post_sale_id = { sale.id}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

     //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount(){
        let url_params = this.state.url_params;
        if('id' in url_params){
            this.fetchSaleDetailWithId(url_params.id);
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
}

export default PostSaleServicesDetail;