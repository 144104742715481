import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'
import FieldsSaleDiamondCost from './FieldsSaleDiamondCost'

const SaleDiamondCost = props => {
    const { diamonds, sale, onResponse } = props

    const changeDataDiamond = (value, name, index) => {
        diamonds[index][name] = value

        onResponse(diamonds)
    }

    return (
        <>
            {
                diamonds.length ?
                    <Card className="mt-2">
                        <Card.Body>
                            <Card.Title>
                                Price Diamonds
                            </Card.Title>
                            {
                                diamonds.map((diamond, index) => {
                                    return (
                                        <div key={diamond.id}>
                                            <FieldsSaleDiamondCost
                                                diamond={diamond}
                                                sale={sale}
                                                index={index}
                                                onResponse={changeDataDiamond}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Card.Body>
                    </Card>
                    :
                    null
            }
        </>
    )
}

SaleDiamondCost.propTypes = {
    diamonds: PropTypes.array.isRequired,
    sale: PropTypes.any.isRequired,
    onResponse: PropTypes.func.isRequired,
}

export default SaleDiamondCost