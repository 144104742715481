import React, {Component} from 'react';
import axios from 'axios';
import Auth from '../../../Auth';
import Util from '../../../Util'
import * as globalConst from '../../../global/const';

// SCSS
import '../../../css/sales/sale_information.scss'

class PostSaleInformationProduct extends Component {

    constructor(props){
        super();
        this.state = {
            sale: props.sale,
            product : null,
            download_product : true,
            product_not_found : false
        }
        this.fetchJewelleryByReference = this.fetchJewelleryByReference.bind(this)
        this.getProductPictureUrl = this.getProductPictureUrl.bind(this)
    }

    ajaxRequest = null;
    fetchJewelleryByReference(){
        const reference = this.state.sale.ringPreference
        if(
            Util.wordInArray(this.state.sale.saleType, ['engagement ring','wedding band','jewellery']) === true &&
            reference !== '' &&
            reference !== null &&
            reference !== undefined &&
            this.state.download_product === true
        )
        {
            //AJAX CONTROLS
            //cancel previous ajax request if exists
            if(this.ajaxRequest !== null){
                this.ajaxRequest.cancel();
            }
            // creates a new token for upcomming ajax (overwrite the previous one)
            this.ajaxRequest = axios.CancelToken.source();
            //FINISH AJAX CONTROLS
            const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_PRODUCT_LIST

            const parameters = {
                reference : reference
            }
            axios.get(
                url,
                {
                    params: parameters,
                    headers: { Authorization: `Bearer ${Auth.getToken()}` }
                },
                {
                    cancelToken: this.ajaxRequest.token
                }
            )
            .then(api_response => {
                const result = api_response.data.result;
                const items = result.rows;
                if(Array.isArray(items) === true && items.length > 0){
                    const product = items[0]
                    this.setState({
                            product : product,
                            download_product : false,
                            product_not_found : false
                        }
                    )
                }
                else{
                    this.setState(
                        {
                            product : null,
                            download_product : false,
                            product_not_found : true
                        }
                    )
                }
            })
            .catch(err => {
                if (err.response) {
                    if('status' in err.response && err.response.status === 401){
                        console.log('User not authorised. Please reload the page');
                    }
                    else{
                        console.log('There was a problem processing the information. Please reload this page and try again.');
                    }
                } else if (err.request) {
                    console.log('There was a problem with your request. Please reload this page and try again.');
                }
                console.log(err);
            })
        }
    }

    render(){
        let sale = null
        let settingHeightClass = 'col-sm'
        let clawTypeClass = 'col-sm'
        let clawType = ''
        let saleType = null
        let diamondDetails = null
        let ringPreference = null
        let settingHeight = null
        let engraving = null
        let metal = null
        let ringSize = null
        let product_name = null
        let product_image_url = null
        let product_website_url = null

        if (this.state.sale.salesModel !== null) {
            sale = this.state.sale.salesModel

            if ("settingHeight" in sale) {
                if(!Util.wordInArray(sale.settingHeight, ['standard'])){
                    settingHeightClass = 'col-sm red_colour'
                }
            }

            //
            if ("clawType" in sale) {
                clawType = sale.clawType
                if(!Util.wordInArray(sale.clawType, ['round'])){
                    clawTypeClass = 'col-sm red_colour'
                }
            }

            if ("saleType" in sale) {
                saleType = sale.saleType
            }

            if ("diamondDetails" in sale) {
                diamondDetails = sale.diamondDetails
            }

            if ("ringPreference" in sale) {
                ringPreference = sale.ringPreference
            }

            if ("settingHeight" in sale) {
                settingHeight = sale.settingHeight
            }

            if ("engraving" in sale) {
                engraving = sale.engraving
            }

            if ("metal" in sale) {
                metal = sale.metal
            }

            if ("metal" in sale) {
                ringSize = sale.ringSize
            }

            //
            const product = this.state.product
            if(product !== null && product !== undefined){
                if(product.name !== undefined){
                    product_name = product.name
                    product_website_url = globalConst.REACT_APP_NOVITA_WEBSITE_DOMAIN
                    if(product.productCategoryId === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_ER){
                        product_website_url = product_website_url+globalConst.REACT_APP_NOVITA_WEBSITE_ENG_RING_DETAIL
                        product_website_url = product_website_url.replace('{id}', product.id)
                    }
                    else if(product.productCategoryId === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_RING){
                        product_website_url = product_website_url+globalConst.REACT_APP_NOVITA_WEBSITE_RING_DETAIL
                        product_website_url = product_website_url.replace('{id}', product.id)
                    }
                    else if(
                        product.productCategoryId === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_EARRING ||
                        product.productCategoryId === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_BRACELET ||
                        product.productCategoryId === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_NECKLACE
                    ){
                        product_website_url = product_website_url+globalConst.REACT_APP_NOVITA_WEBSITE_JEWELLERY_DETAIL
                        product_website_url = product_website_url.replace('{id}', product.id)
                    }

                    
                }
                const productPictures = product.productPictureModels
                if(Array.isArray(productPictures) === true && productPictures.length > 0){
                    for(let key in productPictures){
                        const productPicture = productPictures[key];
                        if(productPicture.mainPicture === true && productPicture.realLivePicture !== true){
                            product_image_url = this.getProductPictureUrl(productPicture, product.productCategoryId)
                        }
                    }
                }
            }
        }

        //
        return(
            <div className='sale_information_subcontainer'>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-4 border-right'>
                        <div className="row">
                            {
                                (
                                    product_image_url !== null
                                ) ? 
                                    
                                        <div className="col-sm align-center">
                                            <img src={product_image_url} alt="Picture not available"/>
                                        </div>
                                    
                                :(null)
                            }
                            {
                                (
                                    product_name !== null
                                ) ? 
                                    <div className="col-sm align-center">
                                        <div 
                                            dangerouslySetInnerHTML=
                                            {
                                                {
                                                    __html: '<a href="'+product_website_url+'" target="_blank">'+product_name+'</a>'
                                                }
                                            }
                                        >
                                        </div>
                                    </div>
                                :(null)
                            }
                        </div>
                        </div>
                        <div className='col-md-8'>
                            {
                                (Util.wordInArray(saleType, ['engagement ring', 'jewellery', 'diamond']) === true) ? 
                                    <div className="row">
                                        <div className="col-sm text-center">
                                            <span className='table_title'>Diamond cert #, Shape ct Colour Clarity</span>
                                            <br/>
                                            <b>
                                            {
                                                (diamondDetails === '' || diamondDetails === null) ? (
                                                    'Diamond not included'
                                                ):
                                                (diamondDetails)
                                            }
                                            </b>
                                        </div>
                                    </div>
                                :(null)      
                            }
                            
                            
                            {
                                (Util.wordInArray(saleType, ['engagement ring', 'wedding band', 'jewellery']) === true) ? 
                                    <div className="row">
                                        <div className="col-sm  border-right"><span className='table_title'>Product's Reference</span></div>
                                        <div className="col-sm">{ringPreference}</div>
                                    </div>
                                :(null)
                            }
                            {
                                (Util.wordInArray(saleType, ['engagement ring']) === true) ?
                                    <div className="row">
                                        <div className="col-sm  border-right"><span className='table_title'>Type of Claw</span></div>
                                        <div className={clawTypeClass}>
                                            {clawType}
                                        </div>
                                    </div>       
                                :(null)
                            }
                            {
                                (Util.wordInArray(saleType, ['engagement ring']) === true) ?
                                (
                                    <div className="row">
                                        <div className="col-sm  border-right">
                                            <span className='table_title'>Setting Height</span>
                                        </div>
                                        <div className={settingHeightClass}>
                                            {settingHeight}
                                        </div>
                                    </div>
                                ):(null)
                            }
                            {
                                (Util.wordInArray(saleType, ['engagement ring', 'wedding band']) === true) ?
                                    <div className="row">
                                        <div className="col-sm  border-right"><span className='table_title'>Engraving</span></div>
                                        <div className="col-sm">{engraving}</div>
                                    </div>
                                :(null)
                            }
                            {
                                (Util.wordInArray(saleType, ['engagement ring', 'wedding band', 'jewellery']) === true) ?
                                    <div className="row">
                                        <div className="col-sm border-right"><span className='table_title'>Band Metal</span></div>
                                        <div className="col-sm">{metal}</div>
                                    </div>
                                :(null)
                            }
                            {
                                (Util.wordInArray(saleType, ['engagement ring', 'wedding band']) === true) ?
                                (
                                    <div className="row">
                                        <div className="col-sm border-right"><span className='table_title'>Ring size</span></div>
                                        <div className="col-sm">{ringSize}</div>
                                    </div>
                                ):(null)
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }


    getProductPictureUrl(productPicture, procuct_category_id){
        let product_picture_url = globalConst.REACT_APP_NOVITA_DOCS_DOMAIN
        if(procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_RING){
            product_picture_url = product_picture_url + globalConst.REACT_APP_NOVITA_DOCS_RINGS
        }
        else if(procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_EARRING){
            product_picture_url = product_picture_url + globalConst.REACT_APP_NOVITA_DOCS_EARRINGS
        }
        else if(procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_BRACELET){
            product_picture_url = product_picture_url + globalConst.REACT_APP_NOVITA_DOCS_BRACELETS
        }
        else if(procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_NECKLACE){
            product_picture_url = product_picture_url + globalConst.REACT_APP_NOVITA_DOCS_NECKLACES
        }
        else if(procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_ER){
            product_picture_url = product_picture_url + globalConst.REACT_APP_NOVITA_DOCS_ENG_RINGS
        }
        product_picture_url = product_picture_url + productPicture.filename
        return product_picture_url
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    componentDidMount(){
        this.fetchJewelleryByReference()
    }

    componentDidUpdate(){
        if (this.state.sale.salesModel !== null) {
            if ("ringPreference" in this.state.sale.salesModel) {
                this.fetchJewelleryByReference(this.state.sale.salesModel.ringPreference)
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        //
        let download_product = false
        let product = null
        if (props.sale.salesModel !== null && state.sale.salesModel !== null) {
            if ("saleType" in props.sale.salesModel) {
                if(
                    (
                        state.product === null && state.product_not_found !== true
                    ) &&
                    Util.wordInArray(props.sale.salesModel.saleType, ['engagement ring', 'wedding band', 'jewellery']) === true
                ){
                    download_product = true
                }
            }

            //
            if (typeof state.sale.salesModel !== "undefined" && typeof props.sale.salesModel !== "undefined") {
                if ("ringPreference" in props.sale.salesModel
                    && "ringPreference" in state.sale.salesModel
                    && "saleType" in props.sale.salesModel) {
                    if(
                        (state.sale.salesModel.ringPreference !== props.sale.salesModel.ringPreference) &&
                        Util.wordInArray(props.sale.salesModel.saleType, ['engagement ring', 'wedding band', 'jewellery']) === true
                    ){
                        download_product = true
                    }
                }
            }

            //
            if ("saleType" in props.sale.salesModel) {
                if(
                    state.product !== null && 
                    Util.wordInArray(props.sale.salesModel.saleType, ['engagement ring', 'wedding band', 'jewellery']) === true
                ){
                    product = state.product
                }
            }
        }

        return{
            sale: props.sale,
            download_product : download_product,
            product : product
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

}

export default PostSaleInformationProduct;