import React, { useState } from "react";
import DocumentationCategories from "./categories/index";
import { DocumentsList } from "./files";
import DocumentationHeader from "./header";
import '../../css/documentation/main.scss';
import DocumentInformation from "./information";

export default function Documentation() {
  const [ selectedDocument, setSelectedDocument] = useState(null);

  return (
    <div className="">
      <div className="row no-gutters">
        <div className="col-sm-8 p-0">
          <DocumentationHeader />
          <div className="row flex-nowrap h-200 pt-2">
            <div className="col-4"><DocumentationCategories/></div>
            <div className="col-8 documents-list"><DocumentsList selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument}/></div>
          </div>
        </div>
        <div className="col-sm-4">
          <DocumentInformation document={selectedDocument} />
        </div>
      </div>
    </div>
  );
}
