import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function DateField (props) {
    const [selectedDate, setSelectedDate] = useState(props.currentDate)

    const handleDateChange = (date) => {
        setSelectedDate(date)
        props.handleChangeDateField(date, props.field_name)
    }

    const showTimeSelect = props.showTimeSelect !== undefined ? props.showTimeSelect : false
    const isClearable = props.isClearable !== undefined ?  props.isClearable : true
    const dateFormat = props.dateFormat !== undefined ? props.dateFormat : 'dd/MM/yyyy'

    return(
        <div className='dateField'>
            <DatePicker
                selected={selectedDate}
                onChange={date => handleDateChange(date)}
                dateFormat={dateFormat}
                isClearable={isClearable}
                closeOnScroll={true}
                showTimeSelect={showTimeSelect}
            />
        </div>
    )

}




export default DateField;