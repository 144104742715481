import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'
import { faArrowCircleRight, faExternalLinkAlt, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

class OrderToInformation extends Component {

    constructor(props){
        super();
        this.state = {
            order: props.order,
            website_url_for_diamond_detail : document.getElementById('website_url_for_diamond_detail').value,
            website_url_for_setting_detail : document.getElementById('website_url_for_setting_detail').value,
            website_url_for_jewellery_detail : document.getElementById('website_url_for_jewellery_detail').value,
            website_url_for_ring_detail : document.getElementById('website_url_for_ring_detail').value
        }
    }

    render(){
        const order = this.state.order;
        // console.log('order information')
        // console.log(order)
        
        if(order === null){
            // return(
            // <section className="right-section">
            //     <div className="order_information">
            //         <div className="choose_message">
            //             Choose an order from the list to find more
            //             <br/>
            //             <span>
            //                 <FontAwesomeIcon icon={faHandPointLeft} />
            //             </span>
            //         </div>
            //     </div>
            // </section>
            // );
            return(
                <section className="right-section">
                    <div className="order_information">
                        <div className="order-head">
                            <div className="order-number">
                                <h1>ORDER <br /> #"" order.reference "</h1>
                            </div>
                            <div className="link_to_detail">
                                <Link to={`/orderDetailsto`}>
                                    <div className="button_link">
                                        + Details <span className="details-icon"><FontAwesomeIcon icon={faArrowCircleRight} /></span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="order-country-date-data">
                            <div className="date-div">
                                <p className="mb-0">Date: <span className="date">12/10/12</span></p>
                            </div>
                            <div className="country-div">
                                <p className="mb-0">Country: <span className="country">AU</span></p>
                            </div>
                            <div className="general_information">
                                " this.getGeneralContent(order) "
                            </div>
                        </div>
                        <div className="customers_information">
                            <h2 className="customer-heading">Customer's Information</h2>
                            <div className="customer-data">
                                <div className="shipping-info">
                                    <h4 className="shipping-info-heading">Shipping Information</h4>
                                    <p className="name">name</p>
                                    <p className="address">s-5,h-56, r- 6/a, dhaka 1230</p>
                                    <p className="number">01708336869</p>
                                </div>
                                <div className="billing-info">
                                    <h4 className="shipping-info-heading">Billing Information</h4>
                                    <p className="name">name</p>
                                    <p className="address">s-5,h-56, r- 6/a, dhaka 1230</p>
                                    <p className="number">01708336869</p>
                                </div>
                            " this.getCustomersInformation(order) "
                            </div>
                        </div>
                        <div className="products_information">
                            <h2 className="product-heading">Products</h2>
                            <div className="product-details">
                                <div className="diamond">
                                    <h4>Diamond</h4>
                                    <a href="#" className="product-link">ND-9268 <FontAwesomeIcon icon={faExternalLinkSquareAlt} /></a>
                                    <p className="product-data">1.50ct round</p>
                                </div>
                                <div className="setting">
                                    <h4>Setting</h4>
                                    <a href="#" className="product-link">ND-9268 <FontAwesomeIcon icon={faExternalLinkSquareAlt} /></a>
                                    <p className="product-data">1.50ct round</p>
                                </div>
                                {/* { this.getEngagementRingsContent(order) } */}
                                " this.getProductsContent(order) "
                            </div>
                        </div>
                        <div className="payment_information">
                            <h2 className="payment-heading">Payments</h2>
                            <div className="payment-details">
                                <h4 className="total-to-pay">Total To Pay: <span className="paying-amount">$4545454</span></h4>
                                <h4 className="total-pay">Total Pay : <span className="paying-amount">$120,12</span></h4>
                                
                            ' this.getPaymentsContent(order) '
                            </div>
                        </div>
                    </div>
                </section>
            );
            
        }

        return(
        <section className="right-section">
            <div className="order_information">
                <div className="link_to_detail">
                    <Link to={`/order/${order.id}`}>
                        <div className="button_link">
                            Get more details <FontAwesomeIcon icon={faArrowAltCircleRight} />
                        </div>
                    </Link>
                </div>
                <h1>ORDER #{ order.reference }</h1>
                <div className="general_information">
                    { this.getGeneralContent(order) }
                </div>
                <div className="customers_information">
                    <h2>Customer's Information</h2>
                    { this.getCustomersInformation(order) }
                </div>
                <div className="products_information">
                    <h2>Products</h2>
                    <div className="details">
                        {/* { this.getEngagementRingsContent(order) } */}
                        { this.getProductsContent(order) }
                    </div>
                </div>
                <div className="payment_information">
                    <h2>Payments</h2>
                    { this.getPaymentsContent(order) }
                </div>
            </div>
        </section>)
    }

    getGeneralContent = (order) => {
        const created = new Date(order.created);
        return (
            <table>
                <tbody>
                    <tr>
                        <th>Date</th><td>{created.toLocaleString('default', { day: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE })} / {created.toLocaleString('default', { month: 'short', timeZone: process.env.REACT_APP_TIMEZONE })} / { created.toLocaleString('default', { year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE }) }</td>
                    </tr>
                    <tr>
                        <th>Country</th><td>{order.countryOrigin}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    getCustomersInformation = (order) => {
        let pick_up_in_showroom = null;
        if(order.pickUpAdelaide === true){
            pick_up_in_showroom = 'Adelaide';
        } else if(order.pickUpSydney === true){
            pick_up_in_showroom = 'Sydney';
        } else if(order.pickUpMelbourne === true){
            pick_up_in_showroom = 'Melbourne';
        }
        return(
            <div className="details">
                <div className="shipping_informaiton">
                    <h3>Shipping Information</h3>
                    {
                        pick_up_in_showroom !== null ? (
                            <div className="pick_up_in_showroom">
                                Pick up at { pick_up_in_showroom }'s Showroom 
                            </div>
                        ):(
                            <div className="detail">
                                {order.shippingFirstName} {order.shippingLastName}.<br/>
                                {order.shippingAddressLineOne}. {order.shippingSuburb} {order.shippingState} {order.shippingPostCode}<br/>
                                {order.shippingPhone}
                            </div>
                        )
                    }
                </div>
                <div className="billing_information">
                    <h3>Billing Information</h3>
                    {order.billingFirstName} {order.billingLastName}.<br/>
                    {order.billingAddressLineOne}. {order.billingSuburb} {order.billingState} {order.billingPostCode}<br/>
                    {order.billingPhone}
                </div>
            </div>
        );
    }

    getEngagementRingsContent = (order) => {
        const engagementRingPurchaseOrders = order.engRingPurOrs;
        return engagementRingPurchaseOrders.map((engagementRingPurchaseOrder) => {
            const productDiamond = engagementRingPurchaseOrder.prodDiam;

            const diamondProduct = productDiamond.diamProd;
            const productSetting = engagementRingPurchaseOrder.prodSett;
            const diamond_link = this.state.website_url_for_diamond_detail.replace("{:id}", diamondProduct.id);
            const setting_link = this.state.website_url_for_setting_detail.replace("{:id}", productSetting.id);
            return(
                <table key={engagementRingPurchaseOrder.id}>
                    <thead>
                        <tr>
                            <th>
                                Diamond
                            </th>
                            <th>
                                Setting
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="diamond">
                                <a href={diamond_link} target="_blank" rel="noreferrer">
                                    ND-{ diamondProduct.id } <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                                <br/>
                                { productDiamond.name }
                            </td>
                            <td className="setting">
                                <a href={setting_link} target="_blank" rel="noreferrer">
                                    { productSetting.reference } <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                                <br/>
                                { productSetting.name }
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        })
    }

    getProductsContent = (order) => {
        const productPurchaseOrders = order.prodPurOrs;
        return productPurchaseOrders.map((productPurchaseOrder) => {
            const product = productPurchaseOrder.prod;
            let product_link = this.state.website_url_for_jewellery_detail.replace("{:id}", product.id);
            let title = 'Diamond Jewellery'
            const productCategory = product.prodCat;
            if(productCategory.slug === 'ring'){
                product_link = this.state.website_url_for_ring_detail.replace("{:id}", product.id);
                title = 'Wedding Band'
            }
            const product_name =  product.name;
            return(
                <table key={productPurchaseOrder.id}>
                    <thead>
                        <tr>
                            <th>
                                {title}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <a href={product_link} target="_blank" rel="noreferrer">
                                    { product.reference } <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </a>
                                <br/>
                                <div dangerouslySetInnerHTML={{__html: product_name}}></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        })
    }

    getPaymentsContent(order){
        return(
            <div className="details">
                <table>
                    <tbody>
                        <tr>
                            <th>Total to Pay</th>
                            <td>$12000.00 {process.env.REACT_APP_CURRENCY}</td>
                        </tr>
                        <tr>
                            <th>Total Paid</th>
                            <td>$4000.00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    // componentDidMount(props){
    //     console.log(props);
    //     this.setState({
    //         order: props.order
    //     })
    // }

    componentWillReceiveProps(props){
            console.log(props);
            this.setState({
                order: props.order
            })
    }

}

export default OrderToInformation;