import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import FormSaleCommissions from "./FormSaleCommissions";
import TableResultSaleCommissions from "./TableResultSaleCommissions";
import Util from "../../../../Util";

const BodySaleCommissions = () => {
  const [showResults, setShowResults] = useState(false);
  const [showInputFile, setShowInputFile] = useState(true);

  const [file, setFile] = useState(null);
  const [type, setType] = useState("");

  const onResponse = async (file, type) => {
    setFile(null);
    setType("");
    setShowResults(false);

    await Util.timeAwait();

    if (file) {
      setType(type);
      setFile(file);
      setShowResults(true);
    }
  };

  const onResponseUpdate = async (value) => {
    setShowInputFile(false);

    await Util.timeAwait();

    if (value) {
      setShowInputFile(true);
    }
  };

  return (
    <Col className="mt-2" xs={12} lg={12}>
      <Row className="center-element mt-1">
        <Col lg={11}>
          <Card>
            <Card.Body>              
              <Row className="mt-1">
                {showInputFile ? (
                  <FormSaleCommissions onResponse={onResponse} />
                ) : null}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={11}>
          {showResults ? (
            <TableResultSaleCommissions
              file={file}
              type={type}
              onResponse={onResponseUpdate}
            />
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

export default BodySaleCommissions;
