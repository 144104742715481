import axios from "axios"
import { sprintf } from "sprintf-js"
import * as globalConst from '../../../../global/const';
import Auth from "../../../../Auth";

const CancelToken = axios.CancelToken;
let cancel;

export const getWorkflowSale = async (
    sale
) => {
    try {
        const url = sprintf(
            '%s%s',
            process.env.REACT_APP_NOVITAERP_API_DOMAIN,
            globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_SALE_LIST
        )

        if (cancel !== undefined) {
            cancel();
        }

        const { id } = sale
        const params = new URLSearchParams()
        params.append('saleId', id)

        return await axios.get(
            url,
            {
                params: params,
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        ).then(response => {
            const { data } = response
            const { result } = data
            const { rows } = result

            if (data.code === 200 && Array.isArray(rows)) {
                return rows
            }

            return []
        }).catch(error => {
            console.log(error);
            return []
        })
    } catch (error) {
        console.log(error);
        return []
    }
}