import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { getEmployeeRates } from "./Data/FindSaleCommission";
import CommissionConfirmChange from "./CommissionConfirmChange";
import { updateSaleCommission } from "./Data/UpdateSaleCommission";
import {
  calculateCommissionValue,
  getPriceExtGst,
} from "./Data/ActionSaleCommission";
import Util from "../../../Util";

const CommissionFormSelect = ({
  saleCommission,
  onResponseUpdateCommission,
  sale,
  total,
}) => {
  const { employeeModel = {} } = saleCommission;

  const [employeeRate, setEmployeeRate] = useState("");
  const [preEmployeeRate, setPreEmployeeRate] = useState(null);
  const [showConfirm, setShowConfirm] = useState(null);

  const employeeRates = getEmployeeRates(employeeModel);

  const handleChangeSelect = (event) => {
    const { value } = event.target;

    setPreEmployeeRate(value);

    setShowConfirm(true);
  };

  useEffect(() => {
    if (!employeeRate) {
      const { employeeCommissionRateModel = {} } = saleCommission;

      setEmployeeRate(employeeCommissionRateModel?.name);
    }
  }, [employeeRate, saleCommission]);

  const onResponseChangeCommission = async (
    value,
    employeeRate,
    employeeRateResponse
  ) => {
    setShowConfirm(false);

    if (value) {
      setEmployeeRate(employeeRate);

      saleCommission.commissionPercentage = employeeRateResponse.rate;
      saleCommission.employeeCommissionRateId = employeeRateResponse.id;
      saleCommission.commissionValue = calculateValue();

      const info = await updateSaleCommission(saleCommission);

      onResponseUpdateCommission(info);
    }
  };

  const calculateValue = () => {
    const totalIncGst = getPriceExtGst(sale);

    const value = calculateCommissionValue(
      saleCommission.commissionPercentage,
      totalIncGst
    );

    const totalCommission = Util.numberDecimal(value / total);

    return totalCommission;
  };

  return (
    <>
      <Form.Select onChange={handleChangeSelect} value={employeeRate ?? ""}>
        {employeeRates.map((employeeRate) => {
          return (
            <option key={employeeRate.id} value={employeeRate.name}>
              {employeeRate.name}
            </option>
          );
        })}
      </Form.Select>

      {showConfirm && (
        <CommissionConfirmChange
          employeeRate={employeeRate ?? ""}
          preEmployeeRate={preEmployeeRate}
          onResponse={onResponseChangeCommission}
          employeeRates={employeeRates}
          onResponseUpdateCommission={onResponseUpdateCommission}
        />
      )}
    </>
  );
};

CommissionFormSelect.propTypes = {
  sale: PropTypes.object.isRequired,
  saleCommission: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
};

export default CommissionFormSelect;
