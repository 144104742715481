const Util = {
  wordInArray(word, array_options) {
    // this function verifies that the word is in any of the array_options
    let word_is_included = false;
    if (typeof word === "string" || word instanceof String) {
      array_options.forEach(function (array_option) {
        if (word.toLowerCase().includes(array_option) === true) {
          word_is_included = true;
        }
      });
    }
    return word_is_included;
  },
  statusAsSlug(status) {
    let status_as_slug = "";
    if (typeof status === "string" || status instanceof String) {
      status = status.toLowerCase();
      if (status === "new order") {
        status_as_slug = "new-order";
      } else if (status === "order in process") {
        status_as_slug = "order-in-process";
      } else if (status === "order ready") {
        status_as_slug = "order-ready";
      } else if (status === "collected/shipped") {
        status_as_slug = "collected-shipped";
      } else if (status === "waiting on deposit/payment") {
        status_as_slug = "waiting-on-deposit-payment";
      } else if (status === "order on hold") {
        status_as_slug = "order-on-hold";
      } else if (status === "pending change/update") {
        status_as_slug = "pending-change-update";
      } else if (status === "cancelled") {
        status_as_slug = "cancelled";
      } else if (status === "returned") {
        status_as_slug = "returned";
      } else if (status === "follow up: remaking/fixing") {
        status_as_slug = "follow-up-remaking-fixing";
      }

      //legacy
      else if (status === "ready to be shipped") {
        status_as_slug = "ready-to-be-shipped";
      } else if (status === "in process - ready to be shipped") {
        status_as_slug = "ready-to-be-shipped";
      } else if (status === "awaiting collection / to send") {
        status_as_slug = "awaiting-collection-to-send";
      } else if (status === "awaiting collection - to send") {
        status_as_slug = "awaiting-collection-to-send";
      } else if (status === "waiting on deposit") {
        status_as_slug = "waiting-on-deposit";
      } else if (status === "in process") {
        status_as_slug = "in-process";
      } else if (status === "collected / sent") {
        status_as_slug = "collected-sent";
      } else if (status === "in process - express") {
        status_as_slug = "in-process-express";
      } else if (status === "in process - standard") {
        status_as_slug = "in-process-standard";
      }
      status_as_slug = "status_coloured_box " + status_as_slug;
    }
    return status_as_slug;
  },
  DC2TypeToArray(dc2type_value) {
    const re = /"(.*?)"/g;
    const result = [];
    let current;
    while ((current = re.exec(dc2type_value))) {
      result.push(current.pop());
    }
    return result.length > 0 ? result : [];
  },
  objectsToDC2Type(roles_objecs) {
    let roles_dc2type = "";
    const objects_length = Object.keys(roles_objecs).length;
    if (objects_length > 0) {
      roles_dc2type = "a:" + objects_length + ":{";
    }
    let count = 0;
    for (let key in roles_objecs) {
      const user_role = roles_objecs[key];
      roles_dc2type =
        roles_dc2type +
        "i:" +
        count +
        ";s:" +
        user_role.length +
        ':"' +
        user_role +
        '";';
      count++;
    }
    roles_dc2type = roles_dc2type + "}";
    return roles_dc2type;
  },
  tryParseJSONObject(jsonString) {
    try {
      var o = JSON.parse(jsonString);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === "object") {
        return o;
      }
    } catch (e) {}

    return false;
  },
  formatCurrency(value, clearSimbol = true) {
    if (typeof value === "undefined") {
      return "";
    } else if (value === null || value === "") {
      return "";
    } else if (isNaN(Number(value))) {
      return value;
    }

    value = value.toString();
    value = value.replace(",", "");
    value = (Math.round(value * 100) / 100).toFixed(2);

    const currencyFormatter = Intl.NumberFormat(
      process.env.REACT_APP_NUMBER_FROMAT,
      { style: "currency", currency: process.env.REACT_APP_CURRENCY }
    );

    let formattedValue = currencyFormatter.format(value);

    if (clearSimbol) {
      formattedValue = formattedValue.replace("$", "");
    }

    if (typeof formattedValue === "undefined") {
      return Number(0).toFixed(2);
    }

    if (formattedValue === "NaN") {
      return Number(0).toFixed(2);
    }

    return formattedValue;
  },
  chunk(arr, size) {
    return Array.from(
      {
        length: Math.ceil(arr.length / size),
      },
      (v, i) => {
        return arr.slice(i * size, i * size + size);
      }
    );
  },
  getShippingAddressFromSale(sale) {
    let shipping_information = sale.shippingTo;
    if (
      (sale.shippingAddress !== null && sale.shippingAddress !== "") ||
      (sale.shippingSuburb !== null && sale.shippingSuburb !== "") ||
      (sale.shippingPostCode !== null && sale.shippingPostCode !== "") ||
      (sale.shippingState !== null && sale.shippingState !== "") ||
      (sale.shippingCountry !== null && sale.shippingCountry !== "")
    ) {
      shipping_information = "";
      shipping_information +=
        sale.shippingAddress != null ? sale.shippingAddress + " " : "";
      shipping_information +=
        sale.shippingSuburb != null ? sale.shippingSuburb + " " : "";
      shipping_information +=
        sale.shippingPostCode != null ? sale.shippingPostCode + " " : "";
      shipping_information +=
        sale.shippingState != null ? sale.shippingState + " " : "";
      shipping_information +=
        sale.shippingCountry != null ? sale.shippingCountry : "";
    }
    return shipping_information;
  },
  isShippingAddress(sale) {
    if (!sale) {
      return true;
    }

    const {
      shippingAddress,
      shippingSuburb,
      shippingPostCode,
      shippingState,
      shippingCountry,
    } = sale;

    if (
      shippingAddress ||
      shippingSuburb ||
      shippingPostCode ||
      shippingState ||
      shippingCountry
    ) {
      return true;
    }

    return false;
  },
  isShippingShowroom(sale) {
    if (!sale) {
      return false;
    }

    const {
      shippingAddress,
      shippingSuburb,
      shippingPostCode,
      shippingState,
      shippingCountry,
    } = sale;

    if (
      !shippingAddress &&
      !shippingSuburb &&
      !shippingPostCode &&
      !shippingState &&
      !shippingCountry
    ) {
      return true;
    }

    return false;
  },
  showURLApp() {
    let url = process.env.REACT_APP_NOVITA_WEBSITE_DOMAIN;

    return url.replace("https://", "").toUpperCase();
  },
  numberDecimal(value, decimals = 2) {
    if (!value) {
      return 0;
    }

    value = value.toString().replace(",", "");
    value = parseFloat(Number(value));

    if (value) {
      return value.toFixed(decimals);
    }

    return 0;
  },
  removeHTMLTags(value) {
    if (!value) {
      return "";
    }

    const cleanText = value
      .replace(/<\/?[^>]+(>|$)/g, " ")
      .replace("  ", " ")
      .trim();

    return cleanText;
  },
  async timeAwait(time = 1) {
    return setTimeout(async () => {}, time);
  },
  onlyNumber(value = "") {
    value = value.toString();
    value = value.replace(/,/g, ".").replace(/[^0-9.]/g, "");

    return value;
  },
  splitDiamonds(value = "") {
    if (!value) {
      return "";
    }

    const regex = /[\d+,\s.*']/g;
    const position = value.search(regex);

    if (position <= 0) {
      return value.split(" ");
    }

    return value.split(",");
  },
};

export default Util;
