import React, { Component } from "react";

class DiamondRow extends Component {
  constructor(props) {
    super();
    this.state = {
      diamond: props.diamond,
      selected_diamond: props.selected_diamond,
    };
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const diamond = this.state.diamond;
    const { foundInND = false } = diamond;

    let row_class = "diamond_row";
    if (this.state.highlight_row === true) {
      row_class = "diamond_row highlighted_row";
    }

    if (
      this.state.selected_diamond !== null &&
      this.state.selected_diamond !== undefined &&
      diamond !== null &&
      diamond.id === this.state.selected_diamond.id
    ) {
      row_class = row_class + " highlighted_row";
    }

    if (foundInND) {
      row_class += " add-task-button";
    }

    return (
      <tr className={row_class} onClick={() => this.selectDiamond(diamond)}>
        <td className="certNo">{diamond.certificate_number}</td>
        <td className="shape">{diamond.shape_name}</td>
        <td className="carat">{diamond.carat}</td>
        <td className="colour">{diamond.color_name}</td>
        <td className="clarity">{diamond.clarity_name}</td>
        <td className="cut">{diamond.cut_name}</td>
        <td className="codeGD">{diamond.reference}</td>
        <th className="location">
          {diamond.location !== null ? diamond.location : "N/A"}
        </th>
        <td className="foundInGD">Yes</td>
        <td className={foundInND ? "foundInND" : "foundInND text-danger"}>
          {foundInND ? "Yes" : "No"}
        </td>
      </tr>
    );
  }

  selectDiamond = (diamond) => {
    const { foundInND = false } = diamond;

    if (foundInND) {
      this.props.handleRowSelection(this.props.index);
    }
  };

  highlightRow() {
    this.setState({
      highlight_row: !this.state.highlight_row,
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    return {
      diamond: props.diamond,
      selected_diamond: props.selected_diamond,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default DiamondRow;
