import { configureStore } from "@reduxjs/toolkit";
import customersReducer from "./slices/customerSlice";
import employeesReducer from "./slices/employeeSlice";
import customerCustomizedListsReducer from "./slices/customerCustomizedListsSlice";
import customerCommentsReducer from "./slices/commentsSlice";
import categoriesReducer from "./slices/categories";
import reportsReducer from "./slices/reportsSlice";
import customerLogsSlice from "./slices/customerLogsSlice";
import workflowReturnSlice from "./slices/workflowReturnSlice";
import jewellerySlice from "./slices/jewellerySlice";
import workflowSaleSlice from "./slices/workflowSaleSlice";

export const store = configureStore({
  reducer: {
    customers: customersReducer,
    employees: employeesReducer,
    customerCustomizedLists: customerCustomizedListsReducer,
    customerComments: customerCommentsReducer,
    categories: categoriesReducer,
    reports: reportsReducer,
    customerLogs: customerLogsSlice,
    workflowReturn: workflowReturnSlice,
    jewellery: jewellerySlice,
    workflowSale: workflowSaleSlice
  },
});
