import axios from "axios"
import { sprintf } from "sprintf-js";
import * as globalConst from '../../global/const';
import Auth from "../../Auth";

const CancelToken = axios.CancelToken;
let cancel;

export const FindEmployeSales = async () => {
    try {
        const url = sprintf(
            '%s%s',
            process.env.REACT_APP_NOVITAERP_API_DOMAIN,
            globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_LIST
        );

        const parameters = {
            roles: 'ROLE_SALES'
        }

        if (cancel !== undefined) {
            cancel();
        }

        return await axios.get(
            url,
            {
                params: parameters,
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        ).then(response => {
            const { data } = response;
            const { result } = data;
            const { rows } = result

            if (Array.isArray(rows) === true && rows.length > 0) {
                return rows
            }

            return []
        }).catch(error => {
            return []
        })
    } catch (error) {
        return []
    }
}