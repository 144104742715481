/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback } from "react";
import { debounce } from "lodash";

export default function DocumentsListHeader({
  onFilterChange,
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(
    debounce((text, columnName, returnEmptyValues) => onFilterChange(text, columnName, returnEmptyValues), 1000),
    []
  );

  const buildFilter = (columnName) => {
    return (
      <div>
        <input
          type="text"
          onChange={(e) => handler(e, columnName, "false")}
        />
      </div>
    );
  };

  return (
    <thead className="thead-dark">
      <tr key={"documents-list"} className="titles">
        <th className="name"></th>
        <th className="name">Title</th>
        <th className="name">Category</th>
        <th className="name">Created</th>
        <th className="name" style={{width: "80px"}}>Actions</th>
      </tr>
      <tr className="filters">
        <th className="thumbnail"></th>
        <th className="title">{buildFilter("title")}</th>
        <th className="category">{buildFilter("category")}</th>
        <th className="created">{buildFilter("created")}</th>
        <th className="actions"></th>
      </tr>
    </thead>
  );
}
