import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Auth from "../../../Auth";
import axios from "axios";
import * as globalConst from "../../../global/const";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

// SCSS
import "../../../css/sales/sale_information.scss";

class SaleWorkflowActivities extends Component {
  last_rendered_sale = null;

  constructor(props) {
    super();
    this.state = {
      sale: props.sale,
      workflowSales: [],
      loadWorkflowSales: false,
      openWorkflowEditionComponent: props.openWorkflowEditionComponent,
      openWorkflowModal: props.openWorkflowModal,
      clearWorkflowSales: true,
    };

    this.clearWorkflowSales = true;
    this.editSale = this.editSale.bind(this);
    this.resetWorkflowList = this.resetWorkflowList.bind(this);

    this.loadGetWorkflowSales = true;
  }

  componentWillUnmount = () => {
    this.loadGetWorkflowSales = false;
  };

  componentDidUpdate = (nextProps) => {
    this.clearWorkflowSales = true;

    if (nextProps.sale.id === this.state.sale.id) {
      this.clearWorkflowSales = false;
    }
  };

  resetWorkflowList() {
    this.getWorkflowSales();
  }

  editSale() {
    this.state.openEditionComponent(this.state.sale);
  }

  render() {
    return (
      <div className="workflow-activities">
        <div className="information">
          <div className="details-top-heading">
            <div className="order-no-section">
              <h1>Workflow Activities</h1>
            </div>
          </div>
          {this.state.workflowSales.length > 0
            ? this.state.workflowSales.map((workflowSale, index) => {
                let workflowActivityModels =
                  workflowSale.workflowActivityModels;

                let saleWorkflowManufacturing = false;
                let saleWorkflowResizing = false;
                let saleWorkflowSetting = false;
                let saleWorkflowEngraving = false;
                let saleWorkflowDeliveringToCustomer = false;
                let saleWorkflowCAD = false;
                let saleWorkflowFixing = false;
                let saleWorkflowPhotos = false;
                let saleWorkflowPolishing = false;
                let saleWorkflowValuation = false;
                let saleWorkflowReturn = false;

                workflowActivityModels.map((activity) => {
                  switch (activity.type) {
                    case "Manufacturing":
                      saleWorkflowManufacturing = activity;
                      break;
                    case "Resizing":
                      saleWorkflowResizing = activity;
                      break;
                    case "Setting":
                      saleWorkflowSetting = activity;
                      break;
                    case "Engraving":
                      saleWorkflowEngraving = activity;
                      break;
                    case "Delivering to customer":
                      saleWorkflowDeliveringToCustomer = activity;
                      break;
                    case "CAD":
                      saleWorkflowCAD = activity;
                      break;
                    case "Fixing":
                      saleWorkflowFixing = activity;
                      break;
                    case "Photos":
                      saleWorkflowPhotos = activity;
                      break;
                    case "Polish":
                      saleWorkflowPolishing = activity;
                      break;
                    case "Return":
                      saleWorkflowReturn = activity;
                      break;
                    case "Appraisal":
                    case "Valuation":
                      activity.type = "Appraisal";
                      saleWorkflowValuation = activity;
                      break;
                    default:
                      break;
                  }
                  return null;
                });
                let workflowStatusClass = "";
                switch (workflowSale.status) {
                  case "In transit":
                    workflowStatusClass = "inTransit";
                    break;
                  case "Collected/Completed":
                    workflowStatusClass = "completed";
                    break;
                  case "Cancelled":
                    workflowStatusClass = "cancelled";
                    break;
                  default:
                    break;
                }

                return (
                  <div className="workflow-activity-card" key={index}>
                    <div className="row">
                      <div className="col-9">
                        <p>Provider: {this.getNameJeweller(workflowSale)}</p>
                        <p>
                          {saleWorkflowReturn
                            ? "Date received from customer"
                            : "Date given to provider"}
                          : {workflowSale.providerGivenDate}
                        </p>
                        {!saleWorkflowReturn && (
                          <p>
                            Expected date from provider:{" "}
                            {workflowSale.providerExpectedDate}
                          </p>
                        )}
                        <p>
                          Status:
                          <span className={workflowStatusClass}>
                            {workflowSale.status}
                            {workflowSale.collectedCompletedDate ? (
                              <span>
                                &nbsp;-&nbsp;
                                {this.dateFormat(
                                  workflowSale.collectedCompletedDate
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="col-3">
                        {Auth.canEditField("rolesInteractiveWorkflow") ? (
                          <Link
                            to=""
                            onClick={(e) => {
                              e.preventDefault();
                              this.editWorkflow(workflowSale);
                            }}
                          >
                            Edit
                          </Link>
                        ) : null}
                      </div>
                    </div>
                    {saleWorkflowManufacturing ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowManufacturing.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowResizing ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowResizing.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowSetting ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowSetting.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowEngraving ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowEngraving.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowDeliveringToCustomer ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowDeliveringToCustomer.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowCAD ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowCAD.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowFixing ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowFixing.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowPhotos ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowPhotos.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowPolishing ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowPolishing.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowValuation ? (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowValuation.type}
                        </span>
                      </div>
                    ) : null}
                    {saleWorkflowReturn && (
                      <div className="general_information">
                        <span className="saleSubWorkflowHeading">
                          {saleWorkflowReturn.type}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })
            : ""}

          <div>
            {Auth.canEditField("rolesInteractiveWorkflow") ? (
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  this.state.openWorkflowModal(e);
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> add workflow activity
              </Link>
            ) : null}
          </div>

          {this.state.loadWorkflowSales ? (
            <div className="center-element m-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  dateFormat(date) {
    return moment(date).format("YYYY-MM-DD h:mm:ss");
  }

  editWorkflow(workflowSale) {
    this.state.openWorkflowEditionComponent(workflowSale);
  }

  ajaxRequest = null;
  getWorkflowSales() {
    if (this.clearWorkflowSales) {
      this.setState({
        workflowSales: [],
        loadWorkflowSales: true,
      });
    }

    const user = Auth.getAuthenticatedUser();
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else {
      //AJAX CONTROLS
      //cancel previous ajax request if exists
      if (this.ajaxRequest !== null) {
        this.ajaxRequest.cancel();
      }
      // creates a new token for upcomming ajax (overwrite the previous one)
      this.ajaxRequest = axios.CancelToken.source();
      //FINISH AJAX CONTROLS

      let url =
        process.env.REACT_APP_NOVITAERP_API_DOMAIN +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_SALE_LIST;
      const params = new URLSearchParams();
      params.append("saleId", this.state.sale.id);

      axios
        .get(
          url,
          {
            params: params,
            headers: { Authorization: `Bearer ${Auth.getToken()}` },
          },
          {
            cancelToken: this.ajaxRequest.token,
          }
        )
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            var workflowSales = data.result.rows;

            if (this.loadGetWorkflowSales) {
              this.setState({
                workflowSales: workflowSales,
              });
            }
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            alert(JSON.stringify(error_messages));
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            alert(JSON.stringify(error_messages));
          } else {
            //error not detected. Request could have been cancelled and it's waiting for second
            //request to finish
          }
          //console.log((err);
          return null;
        })
        .finally((data) => {
          this.setState({
            loadWorkflowSales: false,
          });
        });
    }
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    //execute once the first time
  }

  componentDidUpdate() {
    if (this.state.workflowSales !== null) {
      this.last_rendered_workflow_sales = this.state.workflowSales;
    }
  }

  getNameJeweller = (workflowSale) => {
    const jeweller = workflowSale.jewellerModel;

    if (jeweller) {
      return jeweller.name + " (" + jeweller.contactName + ")";
    }

    return "";
  };

  static getDerivedStateFromProps(props, state) {
    //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
    //return values for the state
    return {
      sale: props.sale,
      //workflowSales: props.workflowSales
    };
  }
  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleWorkflowActivities;
