import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AddFieldSearch extends Component {

    constructor(props) {
        super();

        this.state = {
            showModalAddField: props.showModalAddField,
            fields: props.fields,
            typeFields: [],
            field: {}
        }
    }

    render() {
        return (
            <div className='row'>
                <div className="col-8">
                    <Form.Group className="form-group">
                        <Form.Select onChange={(e) => this.changeFieldAdd(e.target.value)}>
                            <option value=''>Select value</option>
                            {
                                (this.state.fields).map(field => {
                                    return field.showInAddFilter ?
                                        <option
                                            key={field.label}
                                            value={JSON.stringify(field)}>
                                            {field.label}
                                        </option>
                                        :
                                        ''
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className="col-4">
                    <a className={"add_link"} onClick={this.addFilter}>
                        <FontAwesomeIcon icon={faPlus} /> Add filter
                    </a>
                </div>
            </div>
        )
    }

    changeFieldAdd = (value) => {
        let fieldData = JSON.parse(value)
        fieldData.showInAddFilter = false

        this.setState({
            field: fieldData
        })
    }

    addFilter = () => {
        let field = this.state.field
        field.isSelected = true
        field.isSelectedImportant = true

        this.props.responseField(field);
    }
}

export default AddFieldSearch;