import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CategorySelect from './category-select';
import { createCategory, editCategory } from '../../../store/slices/categories';

export default function CreateCategory({ editingCategory, isOpen, handleClose }) {
  const { categories } = useSelector((state) => state.categories);
  const [ selectedCategoryId, setSelectedCategoryId ] = useState(undefined);

  useEffect(()=>{
    if (editingCategory) {
      setSelectedCategoryId(editingCategory.parentCategoryId);
    }
  },[editingCategory]);

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const categoryName = event.target.elements.formName.value;
    if (!categoryName) {
      alert("Please enter a category name");
      return;
    }

    if (editingCategory) {
      dispatch(editCategory({
        id: editingCategory.id,
        name: categoryName,
        parentCategoryId: selectedCategoryId ? parseInt(selectedCategoryId) : undefined,
      }));
    } else {
      dispatch(createCategory({
        name: categoryName,
        parentCategoryId: selectedCategoryId ? parseInt(selectedCategoryId) : undefined,
      }));
    }

    setSelectedCategoryId(undefined);
    // Do something with the form data here
    handleClose();
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{editingCategory ? "Edit Category" : "Create New Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className='mb-3' controlId="formName">
              <Form.Control defaultValue={editingCategory?.name} type="text" placeholder="Enter category name" />
            </Form.Group>
            <Form.Group controlId="formParent">
              <CategorySelect
                removeCategoryId={editingCategory?.id}
                selectedCategoryId={selectedCategoryId}
                prompt={"Select parent category"}
                categories={categories}
                onChange={(event)=>{setSelectedCategoryId(event.target.value)}}
              />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{
            setSelectedCategoryId(undefined);
            handleClose();
          }}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

