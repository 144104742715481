import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row } from "react-bootstrap";

const ConfirmSendProdcutToGD = ({ message, onResponse, type }) => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = (value) => {
    setShowModal(false);

    onResponse(value, type);
  };

  return (
    <Modal show={showModal} size="md" centered>
      <Modal.Header>{message}</Modal.Header>
      <Modal.Footer>
        <Row lg={12}>
          <Col lg={6}>
            <Button variant="secondary" onClick={() => closeModal(false)}>
              No
            </Button>
          </Col>
          <Col lg={6}>
            <Button variant="primary" onClick={() => closeModal(true)}>
              Yes
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmSendProdcutToGD.propTypes = {
  message: PropTypes.string.isRequired,
  onResponse: PropTypes.func.isRequired,
};

export default ConfirmSendProdcutToGD;
