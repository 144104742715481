import React, { Component } from 'react';

class PostSaleRow extends Component {

    constructor(props) {
        super();
        this.state = {
            sale: props.sale,
            selected_sale: props.selected_sale,
        }
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        const sale = this.state.sale;

        let created = null
        if (sale.created !== null && sale.created !== '') {
            created = new Date(sale.created).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            created = created.slice(0, -5) + ", " + created.slice(-4)
        }

        let dateReceivedInBackOffice = null
        if (sale.dateReceivedInBackOffice !== null && sale.dateReceivedInBackOffice !== '') {
            dateReceivedInBackOffice = new Date(sale.dateReceivedInBackOffice).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            dateReceivedInBackOffice = dateReceivedInBackOffice.slice(0, -5) + ", " + dateReceivedInBackOffice.slice(-4)
        }

        let dueDate = null
        if (sale.dueDate !== null && sale.dueDate !== '') {
            dueDate = new Date(sale.dueDate).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            dueDate = dueDate.slice(0, -5) + ", " + dueDate.slice(-4)
        }

        let jewellers
        let jewellersString = ""
        if (typeof sale.jewellers !== "undefined" && sale.jewellers !== null) {
            if (sale.jewellers !== "") {
                try {
                    jewellers = JSON.parse(sale.jewellers)
                    jewellers.map((jeweller, index) => {
                        if (index > 0 && index <= (jewellers.length - 1)) {
                            jewellersString += ", "
                        }
                        jewellersString += jeweller.jeweller
                    })
                }
                catch (e) {
                    console.log("Error: " + e)
                }
            }
        }

        let row_class = "sale_row";
        if (this.state.highlight_row === true) {
            row_class = "sale_row highlighted_row";
        }

        if (this.state.selected_sale !== null && this.state.selected_sale !== undefined && sale !== null && sale.id === this.state.selected_sale.id) {
            row_class = row_class + " highlighted_row"
        }

        let statusClassName = ""
        if (sale.status !== null && sale.status !== '') {
            switch (sale.status) {
                case "Pending":
                    statusClassName = "pending"
                    break
                case "Ready":
                    statusClassName = "ready"
                    break
                case "Internal transit":
                    statusClassName = "internal-transit"
                    break
                case "Cancelled":
                    statusClassName = "cancelled"
                    break
                case "Collected/Shipped":
                    statusClassName = "collected-shipped"
                    break
                default:
                    break;
            }
        }

        let customerNeedsToPay = "No"
        let customerNeedsToPayHighlight = ""
        if (sale.customerNeedsToPay === true) {
            customerNeedsToPay = "Yes"
            customerNeedsToPayHighlight = "highlight"
        }

        let orderReference = ''
        if (sale.salesModel) {
            orderReference = sale.salesModel.onlineOrderNumber
        }

        return (
            <tr className={row_class} onClick={() => this.props.handleRowSelection(this.props.index)}>
                <td className="created">
                    {created !== null ?
                        <div className="saleDate">
                            {created}
                        </div>
                        : ('')
                    }
                </td>
                <td className="onlineOrderNumber">
                    {orderReference}
                </td>
                <td className="dateReceivedInBackOffice">
                    {dateReceivedInBackOffice !== null ?
                        <div className="saleDate">
                            {dateReceivedInBackOffice}
                        </div>
                        : ('')
                    }
                </td>
                <td className="dueDate">
                    {dueDate !== null ?
                        <div className="saleDate">
                            {dueDate}
                        </div>
                        : ('')
                    }
                </td>
                <td className={"status " + statusClassName}>
                    {sale.status}
                </td>
                <td className="name">
                    {sale.name}
                </td>
                <td className="handledVia">
                    {sale.showroom}
                </td>
                <td className="destination">
                    {sale.destination}
                </td>
                <td className="jewellers">
                    {jewellersString}
                </td>
                <td className={"customerNeedsToPay " + customerNeedsToPayHighlight}>
                    {customerNeedsToPay}
                </td>
            </tr>
        )
    }


    highlightRow() {
        this.setState({
            highlight_row: !this.state.highlight_row
        });
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return {
            sale: props.sale,
            selected_sale: props.selected_sale
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

}

export default PostSaleRow;