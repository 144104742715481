import { dateFormatCustom } from "../../../../utils/dateUtils";

export default function CustomerSales({ customerSales }) {
  const buildSales = () => {
    return customerSales.map((sale) => {
      return (
        <tr key={sale.id}>
          <td>{dateFormatCustom(sale.sale.saleDate, "DD MMM, YYYY")}</td>
          <td>{sale.sale.onlineOrderNumber}</td>
          <td>{sale.sale.saleType}</td>
          <td>{sale.sale.status}</td>
          <td>{sale.sale.totalIncGst}</td>
        </tr>
      );
    });
  };

  return (
    <div className="general_information customer-details-card">
      <h2
        style={{ height: "42px" }}
        className="d-flex flex-row justify-content-between align-items-center"
      >
        <p className="align-self-center mt-3">Sales</p>
      </h2>
      <div className="customer-details-container p-2">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Date of Sale</th>
              <th scope="col">Order No.</th>
              <th scope="col">Type</th>
              <th scope="col">Status</th>
              <th scope="col">Total (Inc. GST)</th>
            </tr>
          </thead>
          <tbody id="sales_list_information">{buildSales()}</tbody>
        </table>
      </div>
    </div>
  );
}
