import React, {Component} from 'react';
import LeftbarDashboard from'../dashboard/leftbarDashboard';
// import OrderDetailto from'../sales/OrderDetailsTo';
import '../../css/dashboard.scss';

// import { Switch, Route } from 'react-router-dom';

class singleOrderDetails  extends Component {
    render(){
        return(
            <div className="dashboard-view">
                <LeftbarDashboard/>
                {/* <OrderDetailto/> */}
            </div>
        )
    }
}

export default singleOrderDetails;