import Util from "../../../../../Util"

export const ValidSaleIsClear = (
    sale
) => {
    const {
        totalSettingWogst,
        expressManufacturingWogst,
        engravingWogst
    } = sale

    if (
        totalSettingWogst <= 0 &&
        expressManufacturingWogst <= 0 &&
        engravingWogst <= 0
    ) {
        return {
            show: false,
            message: ''
        }
    }

    return {
        show: true,
        message: 'Sales that have jewellery costs in the Accounting section can not be merged.'
    }
}

export const ValidSaleType = (
    sale
) => {
    const {
        saleType
    } = sale

    if (
        Util.wordInArray(saleType, ['engagement ring', 'loose diamond'])
    ) {
        return {
            show: false,
            message: ''
        }
    }

    return {
        show: true,
        message: 'Only sales that are of type "Loose Diamond" or "Engagement Ring" can be merged.'
    }
}

export const ValidSaleDiamondNotNull = (
    sale
) => {
    const {
        diamondDetails
    } = sale

    if (
        diamondDetails
    ) {
        return {
            show: false,
            message: ''
        }
    }

    return {
        show: true,
        message: 'The sale not have information for diamond, Merging is not possible.'
    }
}

export const ValidOnlyOneDiamond = (
    sale
) => {
    const {
        diamondDetails
    } = sale

    const chunks = diamondDetails.split(',')

    if (
        chunks.length === 1
    ) {
        return {
            show: false,
            message: ''
        }
    }

    return {
        show: true,
        message: 'The sale has more than one diamond. Merging is not possible.'
    }
}