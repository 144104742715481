import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import TableTotalSalesReport from "../components/TableTotalSales";
import TotalSalesReport from "../components/total_sales";
import { useSelector } from "react-redux";

const SalesCharts = () => {
  const { resultAnalysis } = useSelector((state) => state.reports);

  return (
    <Card className="mt-2">
      <Card.Body>
        <Row className="card-sale-commissions">
          <Row>
            <Col xs={12} lg={6}>
              <TotalSalesReport jsonData={resultAnalysis ?? {}} />
            </Col>
            <Col xs={12} lg={6}>
              <TableTotalSalesReport jsonData={resultAnalysis ?? []} />
            </Col>
          </Row>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SalesCharts;
