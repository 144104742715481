import React, {Component} from 'react';
import PostSaleCommentForm from './PostSaleCommentForm'
import PostSaleCommentsList from './PostSaleCommentsList'
import axios from 'axios';
import Auth from '../../../Auth';
import Accordion from 'react-bootstrap/Accordion';
import * as globalConst from '../../../global/const';

// SCSS
import '../../../css/sales/sale_comments.scss'

class PostSaleComments extends Component {

    constructor(props){
        super();
        this.state = {
            sale: null,
            sale_comments: [],
            loading_comments: true,
            topic_filter: 'all',
            isAccounting : (props.isAccounting ? true : false),
            isCosts : (props.isCosts ? true : false),
        }
        this.fetchPostSaleComments = this.fetchPostSaleComments.bind(this)
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        var access_to_comments_list = Auth.isAuthorised("post_sales_comments_list");
        var access_to_comments_add = Auth.isAuthorised("post_sales_comments_create");
        var accounting_access_to_comments_list = Auth.isAuthorised("accounting_comments_list");
        var accounting_access_to_comments_add = Auth.isAuthorised("accounting_comments_create");
        var costs_access_to_comments_list = Auth.isAuthorised("costs_comments_list");
        var costs_access_to_comments_add = Auth.isAuthorised("costs_comments_create");
        
        let sale_comments_count = 0;
        if (this.state.sale_comments !== null) {
            sale_comments_count = this.state.sale_comments.length;
        }
        return(
            <div id="SaleComments_component">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>VIEW COMMENTS ({ sale_comments_count })</Accordion.Header>
                        <Accordion.Body>
                            {
                                access_to_comments_list === true 
                                || accounting_access_to_comments_list
                                || costs_access_to_comments_list ?
                                    <PostSaleCommentsList
                                        sale = {this.state.sale}
                                        sale_comments = {this.state.sale_comments}
                                        loading_comments = {this.state.loading_comments}
                                        topic_filter = {this.state.topic_filter}
                                        fetchPostSaleComments = {this.fetchPostSaleComments}
                                        isAccounting = { this.state.isAccounting }
                                        isCosts = { this.state.isCosts }
                                    />
                                : (null)
                            }
                            {
                                access_to_comments_add === true 
                                || accounting_access_to_comments_add
                                || costs_access_to_comments_add ?
                                    <PostSaleCommentForm
                                        sale= {this.state.sale}
                                        reloadSaleComments = {this.reloadSaleComments}
                                        isAccounting = { this.state.isAccounting }
                                        isCosts = { this.state.isCosts }
                                    />
                                : (null)
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount(){
        //execute once the first time
        this.fetchPostSaleComments()
    }

    componentDidUpdate(){
        if(this.state.loading_comments === true){
            this.fetchPostSaleComments()
        }
        
    }

    static getDerivedStateFromProps(props, state) {
        let loading_comments = true
        let topic_filter = state.topic_filter
        if(state.sale !== null && props.sale.id === state.sale.id){
            loading_comments = false
        }
        else{
            topic_filter = 'all'
        }
        return{
            sale: props.sale,
            loading_comments : loading_comments,
            topic_filter : topic_filter
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    

    reloadSaleComments = () => {
        this.fetchPostSaleComments();
    }

    axioRequestComments = null;
    fetchPostSaleComments(topic_filter = 'all'){
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if(this.axioRequestComments !== null){
            this.axioRequestComments.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.axioRequestComments = axios.CancelToken.source();
        //FINISH AJAX CONTROLS
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_COMMENT_LIST
        const params = new URLSearchParams()
        params.append('postSaleServiceId', this.state.sale.id)
        params.append('order_by', 'created')
        params.append('order_type', 'ASC')
        if(topic_filter !== 'all'){
            params.append('topic', topic_filter)
        }
        axios.get(
            url,
            {
                params: params,
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            },
            {
                cancelToken: this.axioRequestComments.token
            }
        )
        .then(
            api_response => {
                this.axioRequestComments = null
                const data = api_response.data
                if(data !== undefined && data.code === 200){
                    // const result = data.result.rows
                    this.fetching_comments = false
                    this.request_render_comments = true
                    this.setState(
                        {
                            sale_comments : data.result.rows,
                            topic_filter: topic_filter
                        }
                    )
                }
                else{
                    alert('error')
                }
            }
        )
        .catch(
            err => {
                if (err.response) {
                    if('status' in err.response && err.response.status === 401){
                        alert('User not authorised. Please reload the page');
                    }
                    else{
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                    this.setState(
                        {
                            loading_comments: false
                        }
                    )
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                    this.setState(
                        {
                            loading_comments: false
                        }
                    )
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                    this.setState(
                        {
                            loading_comments: true
                        }
                    )
                }
                //console.log(err);
                return null;
            }
        )
    }

}

export default PostSaleComments;