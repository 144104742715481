import React, {Component} from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';

// SCSS
import '../../css/sales/sale_create.scss'

class CostsRemoveMyListModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            
        }
        this.closeModal = this.closeModal.bind(this)
    }

    closeModal(){
        this.props.closeCostsRemoveMyListModal()
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        return(
            <div id="SaleEditionRequirementModal_component">
                <Modal 
                    show={true}
                    onHide={this.closeModal}
                    dialogClassName="modal-90w"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        Confirm
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className='form-group'>
                                <FormLabel>Are you sure that you want to remove all the elements from your list?</FormLabel>
                            </Form.Group>
                            <Button 
                                type="button" className='btn btn-default confirm-btn'
                                onClick={this.props.uncheckAllMyList}
                            >
                                Yes
                            </Button>
                            <Button className='btn btn-default disagree-btn'
                                type="button"
                                onClick={this.closeModal}
                            >
                                No
                            </Button>
                        </Form>



                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    removeFromList = () => {
        
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    

}

export default CostsRemoveMyListModal;