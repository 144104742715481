import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomersFromString,
  removeCustomerCustomizedLists,
} from "../../../store/slices";
import CustomersListHeader from "./header";
import axiosServices from "../../../axios";

export const fetchSheet = async (id) => {
  const response = await axiosServices.get(
    `/api/v1/customers/customized-lists/export-list/${id}`,
    {
      responseType: "arraybuffer", // Tell axios to expect a binary file
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }
  );
  return response.data;
};

export default function CustomizedList({
  list,
  filterChanged,
  selectedCustomer,
  onSelected,
  extractEmployeeNames,
  extractTypes,
  extractAppointmentLatestDate,
}) {
  const dispatch = useDispatch();
  const { listCustomers } = useSelector(
    (state) => state.customerCustomizedLists
  );

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    dispatch(fetchCustomersFromString({ id: list.id }));
  }, [dispatch, list]);

  useEffect(() => {
    if (listCustomers[list.id]) {
      setCustomers(
        listCustomers[list.id].map((c) => {
          return { ...c, selected: true };
        })
      );
    }
  }, [list.id, listCustomers]);

  const getCustomerCity = (customer) => {
    if (
      customer.customerAppointments &&
      customer.customerAppointments.length > 0
    ) {
      return customer.customerAppointments[0].showroomCity;
    }
    return "";
  };

  const onResponse = (listId, order) => {
    if (listCustomers[listId]) {
      let list = listCustomers[listId];

      const sortArray = (key, order) => {
        const compareFunction = (a, b) => {
          if (order === "asc") {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
          } else if (order === "desc") {
            if (a[key] > b[key]) return -1;
            if (a[key] < b[key]) return 1;
          }
          return 0;
        };

        const sortedArray = [...list].sort(compareFunction);

        setCustomers(sortedArray);
      };

      sortArray("created", order);
    }
  };

  return (
    <InfiniteScroll
      dataLength={list.customerIds.length}
      next={() => {}}
      hasMore={false}
      height={"calc(100vh - 100px)"}
    >
      <table
        className="table table-striped table-sm table-hover"
        id="table_sales"
      >
        <CustomersListHeader
          onFilterChange={filterChanged}
          isPersonalTab={true}
          listId={list.id}
          onResponseList={onResponse}
        />
        <tbody>
          {customers &&
            customers.map((customer) => {
              return (
                <tr
                  className={`customer_row ${
                    selectedCustomer?.id === customer.id
                      ? "highlighted_row"
                      : ""
                  }`}
                  key={customer.id}
                  onClick={(e) => {
                    onSelected(customer);
                  }}
                >
                  <td>
                    <div className="form-check custom-from-check">
                      <Form.Check
                        type="checkbox"
                        name="filterEmptyName"
                        id={"filterEmptyName"}
                        className="advanced_filter_empty_customer_name"
                        defaultChecked={customer.selected}
                        onChange={(e) => {
                          e.stopPropagation();
                          // find customer in customers and update selection status
                          setCustomers(
                            customers.map((c) => {
                              if (c.id === customer.id) {
                                return { ...c, selected: e.target.checked };
                              }
                              return c;
                            })
                          );
                          dispatch(
                            removeCustomerCustomizedLists({
                              listId: list.id,
                              customerId: customer.id,
                            })
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td>{extractAppointmentLatestDate(customer)}</td>
                  <td>{extractTypes(customer)}</td>              
                  <td>{customer.name}</td>
                  <td>{customer.email}</td>
                  <td>{customer.phone}</td>
                  <td>{getCustomerCity(customer)}</td>
                  <td>{extractEmployeeNames(customer)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
}
