import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import HistoryTableRow from "./HistoryTableRow";

const HistoryTable = ({ customerLogs }) => {
  return (
    <Table striped bordered hover>
      <tbody>
        <HistoryTableRow customerLogs={customerLogs} />
      </tbody>
    </Table>
  );
};

HistoryTable.propTypes = {
  customerLogs: PropTypes.array.isRequired,
};

export default HistoryTable;
