import { Button, Modal } from "react-bootstrap";

const ConfirmMergeCustomer = ({onShow, responseConfirmMergeCustomer}) => {
    
    return (
        <Modal
            show={onShow}
            centered
        >
            <Modal.Header>
                This action can not be undone. Are you sure you want to merge the customers?
            </Modal.Header>
            <Modal.Body>
                <Button
                    onClick={() => responseConfirmMergeCustomer(true)}
                    className="m-2"
                    variant="outline-primary">
                    Yes
                </Button>
                <Button
                    onClick={() => responseConfirmMergeCustomer(false)}
                    variant="outline-secondary"
                    className='m-2'>
                    No
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                onClick={() => responseConfirmMergeCustomer(false)}
                >Close</Button>
            </Modal.Footer>
        </Modal>
    )
};
export default ConfirmMergeCustomer;