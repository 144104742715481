import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_SUCCESS,
  API_STATUS_PENDING,
  API_STATUS_FAILED,
} from "../constants";
import { REACT_APP_WORKFLOW_TYPE_MANUFACTURING } from "../../global/const";

const initialState = {
  error: null,
  status: "",
  workflowSaleData: {
    totalSucessSave: null,
    errors: [],
    reload: false,
  },
  formData: {
    activity: REACT_APP_WORKFLOW_TYPE_MANUFACTURING,
    status: "",
    salesIds: [],
  },
};

export const fetchWorkflowSaleUpdate = createAsyncThunk(
  "/workflow-sale/update/multiples",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/workflow-sale/update/multiples`,
        {
          activity: args.activity,
          expectedDate: args.expectedDate,
          givenDate: args.givenDate,
          provider: args.provider,
          salesIds: args.salesIds,
          status: args.status,
        }
      );

      const { data } = response;
      const { value = null } = data;

      if (value) {
        return value;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const workflowSaleSlice = createSlice({
  name: "workflowSale",
  initialState,
  reducers: {
    clear(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.formData = initialState.formData;
      state.workflowSaleData = initialState.workflowSaleData;
    },
    setFormData(state, action) {
      state.formData = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchWorkflowSaleUpdate.fulfilled, (state, action) => {
      state.error = null;
      state.status = API_STATUS_SUCCESS;
      state.workflowSaleData = action.payload;
      state.workflowSaleData.reload = true;
    });
    builder.addCase(fetchWorkflowSaleUpdate.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
      state.workflowSaleData = initialState.workflowSaleData;
      state.workflowSaleData.reload = false;
    });
    builder.addCase(fetchWorkflowSaleUpdate.pending, (state) => {
      state.status = API_STATUS_PENDING;
      state.workflowSaleData.reload = false;
    });
  },
});

export const { clear, setFormData } = workflowSaleSlice.actions;

export default workflowSaleSlice.reducer;
