import React, {Component} from 'react';
import OrdersTo from'../sales/orderTo';
import '../../css/dashboard.scss';

// import { Switch, Route } from 'react-router-dom';

class orderToList extends Component {
    render(){
        return(
            <div id="OrdersListTo_component">
                <div className="dashboard-view">
                    <OrdersTo/>
                </div>
            </div>
        )
    }
}

export default orderToList;