import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_FAILED,
  API_STATUS_IDLE,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
  CUSTOMERS_LIST_TYPE_DEFAULT,
  CUSTOMERS_LIST_TYPE_SEARCH,
} from "../constants";
import { dateFormatCustom } from "../../utils/dateUtils";

// eslint-disable-next-line no-extend-native
Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i].id === a[j].id) a.splice(j--, 1);
    }
  }

  return a;
};

const initialState = {
  error: null,
  customers: [],
  searchResults: [],
  totalCustomers: 0,
  status: API_STATUS_IDLE,
  customerListType: CUSTOMERS_LIST_TYPE_DEFAULT,
  selectedCustomer: null,
  selectedOriginCustomer: null,
  selectedDestinationCustomer: null,
  filterColumns: [],
  filterText: "",
  filterResultsCount: 0,
  minCreatedDate: 0,
  maxCreatedDate: 0,
  createdSort: "desc",
  createCustomerShow: false,
  customerCreateFind: [],
  advanceFilter: false,
  mergeStatus: false,
};

export const createCustomer = createAsyncThunk(
  "/customers/create-customer",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(
        `/api/v1/customers/create-customer`,
        args
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editCustomerMutation = createAsyncThunk(
  "/customers/edit-customer",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/customers/update-customer/${args.id}`,
        args
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCustomers = createAsyncThunk(
  "/customers",
  async (args, thunkAPI) => {
    const { maxResults, page, listType, cancelTokenSource, createdSort } = args;
    try {
      const response = await axios.get(
        `/api/v1/customers?max_results=${maxResults}&page=${page}&listType=${listType}&order_by=created&order_type=${
          createdSort ?? "DESC"
        }`,
        { cancelToken: cancelTokenSource?.token }
      );

      response.data.createdSort = createdSort;

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const searchCustomers = createAsyncThunk(
  "/customers/filter",
  async (args, thunkAPI) => {
    const {
      columns,
      filterText,
      cancelToken,
      returnEmptyValues,
      minDate,
      maxDate,
      createdSort,
      exportCSV,
      page = 1,
      maxResults,
      advanceFilter,
    } = args;

    try {
      const response = await axios.post(
        `/api/v1/customers/advanced-filter`,
        {
          columns: columns,
          text: filterText,
          returnEmptyValues: returnEmptyValues,
          minDate: minDate === 0 ? undefined : minDate,
          maxDate: maxDate === 0 ? undefined : maxDate,
          createdSort: createdSort ?? "DESC",
          exportCSV: exportCSV,
          page: page ?? 1,
          maxResults: maxResults ?? 100,
          advanceFilter: advanceFilter,
        },
        { cancelToken: cancelToken?.token }
      );

      const { status = null } = response;
      const { data = {} } = response;
      const { rows } = data;
      const { count } = data;

      if (Object.keys(rows).length > 0 && status === 200) {
        return {
          data: rows,
          columns,
          filterText,
          count: count,
          page: page ?? 1,
          advanceFilter,
        };
      }

      return {
        data: [],
        columns,
        filterText,
        count: count,
        page: page,
        advanceFilter,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const findCustomers = createAsyncThunk(
  "/customers/find",
  async (args, thunkAPI) => {
    const {
      columns,
      filterText,
      cancelToken,
      returnEmptyValues,
      minDate,
      maxDate,
      createdSort,
      exportCSV,
      page,
      maxResults,
      advanceFilter,
    } = args;

    try {
      const response = await axios.post(
        `/api/v1/customers/advanced-filter`,
        {
          columns: columns,
          text: filterText,
          returnEmptyValues: returnEmptyValues,
          minDate: minDate === 0 ? undefined : minDate,
          maxDate: maxDate === 0 ? undefined : maxDate,
          createdSort: createdSort ?? "DESC",
          exportCSV: exportCSV,
          page: page ?? 1,
          maxResults: maxResults ?? 100,
          advanceFilter: advanceFilter,
        },
        { cancelToken: cancelToken?.token }
      );

      const { status = null } = response;
      const { data = {} } = response;
      const { rows } = data;

      if (Object.keys(rows).length > 0 && status === 200) {
        return rows;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteSaleCustomerRelation = createAsyncThunk(
  "/customers/delete-sale-customer-relation",
  async (args, thunkAPI) => {
    const { customerId, saleId } = args;
    try {
      const response = await axios.delete(
        `/api/v1/customers/delete-sale-customer-relation/${customerId}/${saleId}`
      );
      return { customerId, saleId, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateCustomerSalesRelation = createAsyncThunk(
  "/customers/update-customer-sales-relation",
  async (args, thunkAPI) => {
    const { customerId, saleId } = args;
    try {
      const response = await axios.put(
        `/api/v1/customers/attach-sales/${customerId}/${saleId}`
      );
      return { customerId, saleId, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const mergeCustomerOriginIntoDestination = createAsyncThunk(
  "/customers/merge-customer-origin-into-destination",
  async (args, thunkAPI) => {
    const { originCustomerId, destinationCustomerId, } = args;
    try {
      const response = await axios.put(
        `/api/v1/customers/merge-customer-origin-into-destination/${originCustomerId}/${destinationCustomerId}`
      );
      return { originCustomerId, destinationCustomerId, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addAppointments = createAsyncThunk(
  "/customers/appointments/add",
  async (args, thunkAPI) => {
    try {
      const date = dateFormatCustom(args.appointmentDate, "MM-DD-YYYY HH:mm");

      const data = {
        customerId: args.customerId,
        showroomCity: args.showroomCity,
        lookingFor: args.lookingFor,
        whoCame: args.whoCame,
        bookingRequestMethod: args.bookingRequestMethod,
        isZoom: args.isZoom.toString(),
        appointmentDate: date,
        appointmentNumber: args.appointmentNumber,
        previousAppointmentId: args.previousAppointmentId
          ? args.previousAppointmentId.toString()
          : args.previousAppointmentId,
        status: args.status,
        type: args.type,
        notes: args.notes,
        employeeIds: args.employeeIds,
      };

      const response = await axios.post(
        `/api/v1/customers/appointments/add`,
        data
      );

      return { customerId: args.customerId, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateAppointments = createAsyncThunk(
  "/customers/appointments/update",
  async (args, thunkAPI) => {
    try {
      const date = dateFormatCustom(args.appointmentDate, "MM-DD-YYYY HH:mm");

      const data = {
        customerId: args.customerId,
        appointmentId: args.id,
        showroomCity: args.showroomCity,
        lookingFor: args.lookingFor,
        whoCame: args.whoCame,
        bookingRequestMethod: args.bookingRequestMethod,
        isZoom: args.isZoom.toString(),
        appointmentDate: date,
        appointmentNumber: args.appointmentNumber,
        previousAppointmentId: args.previousAppointmentId
          ? args.previousAppointmentId.toString()
          : args.previousAppointmentId,
        status: args.status,
        type: args.type,
        notes: args.notes,
        employeeIds: args.employeeIds,
      };

      const response = await axios.put(
        `/api/v1/customers/appointments/update/${args.id}`,
        data
      );
      return { customerId: args.customerId, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addFollowup = createAsyncThunk(
  "/customers/followups/add",
  async (args, thunkAPI) => {
    try {
      const date = dateFormatCustom(args.date, "MM-DD-YYYY");

      const data = {
        customerId: args.customerId,
        appointmentId: args.appointmentId,
        notes: args.notes,
        date: date,
      };

      const response = await axios.post(`/api/v1/customers/followup/add`, data);
      return { customerId: args.customerId, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateFollowup = createAsyncThunk(
  "/customers/followups/update",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/customers/followup/update/${args.id}`,
        {
          customerId: args.customerId,
          appointmentId: args.appointmentId,
          followupId: args.id,
          notes: args.notes,
          date: args.date,
        }
      );
      return { customerId: args.customerId, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    clear(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.customers = initialState.customers;
      state.customerListType = initialState.customerListType;
      state.totalCustomers = initialState.totalCustomers;
      state.searchResults = initialState.searchResults;
      state.minCreatedDate = initialState.minCreatedDate;
      state.maxCreatedDate = initialState.maxCreatedDate;
      state.filterColumns = initialState.filterColumns;
      state.filterText = initialState.filterText;
      state.createdSort = initialState.createdSort;
      state.createCustomerShow = false;
      state.customerCreateFind = initialState.customerCreateFind;
      state.advanceFilter = initialState.advanceFilter;
    },
    resetSearch(state, action) {
      state.customerListType = CUSTOMERS_LIST_TYPE_DEFAULT;
      state.filterColumns = action.payload;
    },
    selectCustomer(state, action) {
      state.selectedCustomer = action.payload;
    },
    setOriginCustomer(state, action) {
      state.selectedOriginCustomer = action.payload;
    },
    setDestinationCustomer(state, action) {
      state.selectedDestinationCustomer = action.payload;
    },
    setMinDate(state, action) {
      state.minCreatedDate = action.payload;
    },
    setMaxDate(state, action) {
      state.maxCreatedDate = action.payload;
    },
    setCreatedSort(state, action) {
      state.createdSort = action.payload;
    },
    resetCustomers(state, action) {
      state.customers = [];
      state.totalCustomers = 0;
    },
    createCustomerShowAction(state, action) {
      state.createCustomerShow = action.payload;
    },
    resetCustomerCreateFind(state) {
      state.customerCreateFind = initialState.customerCreateFind;
    },
    resetFindCustomer(state) {
      state.selectedOriginCustomer = initialState.selectedOriginCustomer;
      state.selectedDestinationCustomer = initialState.selectedDestinationCustomer;
      state.mergeStatus = initialState.mergeStatus;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      state.totalCustomers = action.payload.count;
      state.customers = [...state.customers, ...action.payload.rows].unique();
      state.customerListType = CUSTOMERS_LIST_TYPE_DEFAULT;
      state.error = null;
      state.status = API_STATUS_SUCCESS;
      state.searchResults = [];
      state.filterColumns = [];
      state.createCustomerShow = false;
    });
    builder.addCase(fetchCustomers.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchCustomers.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // search customers
    builder.addCase(searchCustomers.fulfilled, (state, action) => {
      state.customerListType = CUSTOMERS_LIST_TYPE_SEARCH;
      state.searchResults = [
        ...state.searchResults,
        ...action.payload.data,
      ].unique();

      if (action.payload.page <= 1) {
        state.searchResults = [...[], ...action.payload.data].unique();
      }

      state.filterColumns = [...action.payload.columns];
      state.error = null;
      state.status = API_STATUS_SUCCESS;
      state.customers = state.searchResults;
      state.totalCustomers = action.payload.count;
      state.filterResultsCount = action.payload.count;
      state.filterText = action.payload.filterText;
      state.createCustomerShow = false;
      state.advanceFilter = action.payload.advanceFilter;
    });
    builder.addCase(searchCustomers.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(searchCustomers.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // create customer
    builder.addCase(createCustomer.fulfilled, (state, action) => ({
      customerListType: state.customerListType,
      searchResults: state.searchResults,
      error: null,
      status: API_STATUS_SUCCESS,
      customers: [...state.customers, action.payload],
      totalCustomers: state.totalCustomers + 1,
    }));
    builder.addCase(createCustomer.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(createCustomer.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //deleteSaleCustomerRelation
    builder.addCase(deleteSaleCustomerRelation.fulfilled, (state, action) => {
      const { customerId, saleId } = action.payload;
      const customerIndex = state.customers.findIndex(
        (customer) => customer.id === customerId
      );
      if (customerIndex !== -1) {
        state.customers[customerIndex].customerSales = state.customers[
          customerIndex
        ].customerSales.filter(
          (customerSale) => customerSale.saleId !== saleId
        );
      }
      state.status = API_STATUS_SUCCESS;
      state.selectedCustomer = state.customers[customerIndex];
    });
    builder.addCase(deleteSaleCustomerRelation.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(deleteSaleCustomerRelation.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //updateCustomerSalesRelation
    builder.addCase(updateCustomerSalesRelation.fulfilled, (state, action) => {
      const { customerId, data } = action.payload;
      const customerIndex = state.customers.findIndex(
        (customer) => customer.id === customerId
      );
      if (customerIndex !== -1) {
        state.customers[customerIndex].customerSales.push(data);
      }
      state.status = API_STATUS_SUCCESS;
      state.selectedCustomer = state.customers[customerIndex];
    });
    builder.addCase(updateCustomerSalesRelation.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(updateCustomerSalesRelation.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    builder.addCase(mergeCustomerOriginIntoDestination.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.status = API_STATUS_SUCCESS;
      state.mergeStatus = data.value;
    });
    builder.addCase(mergeCustomerOriginIntoDestination.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(mergeCustomerOriginIntoDestination.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //editCustomer
    builder.addCase(editCustomerMutation.fulfilled, (state, action) => {
      const editedCustomer = action.payload;
      const customerIndex = state.customers.findIndex(
        (customer) => customer.id === editedCustomer.id
      );
      if (customerIndex !== -1) {
        state.customers[customerIndex] = editedCustomer;
        state.selectedCustomer = state.customers[customerIndex];
      }
      state.status = API_STATUS_SUCCESS;
    });
    builder.addCase(editCustomerMutation.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(editCustomerMutation.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //addAppointments
    builder.addCase(addAppointments.fulfilled, (state, action) => {
      const { customerId, data } = action.payload;
      const customerIndex = state.customers.findIndex(
        (customer) => customer.id === customerId
      );
      if (customerIndex !== -1) {
        state.customers[customerIndex] = data;
      }
      state.status = API_STATUS_SUCCESS;
      state.selectedCustomer = state.customers[customerIndex];
    });
    builder.addCase(addAppointments.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(addAppointments.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //updateAppointments
    builder.addCase(updateAppointments.fulfilled, (state, action) => {
      const { customerId, data } = action.payload;
      const customerIndex = state.customers.findIndex(
        (customer) => customer.id === customerId
      );
      if (customerIndex !== -1) {
        state.customers[customerIndex] = data;
      }
      state.status = API_STATUS_SUCCESS;
      state.selectedCustomer = state.customers[customerIndex];
    });
    builder.addCase(updateAppointments.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(updateAppointments.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //addFollowup
    builder.addCase(addFollowup.fulfilled, (state, action) => {
      const { customerId, data } = action.payload;
      const customerIndex = state.customers.findIndex(
        (customer) => customer.id === customerId
      );
      if (customerIndex !== -1) {
        state.customers[customerIndex] = data;
      }
      state.status = API_STATUS_SUCCESS;
      state.selectedCustomer = state.customers[customerIndex];
    });
    builder.addCase(addFollowup.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(addFollowup.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //updateFollowup
    builder.addCase(updateFollowup.fulfilled, (state, action) => {
      const { customerId, data } = action.payload;
      const customerIndex = state.customers.findIndex(
        (customer) => customer.id === customerId
      );
      if (customerIndex !== -1) {
        state.customers[customerIndex] = data;
      }
      state.status = API_STATUS_SUCCESS;
      state.selectedCustomer = state.customers[customerIndex];
    });
    builder.addCase(updateFollowup.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(updateFollowup.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    builder.addCase(findCustomers.fulfilled, (state, action) => {
      state.customerCreateFind = action.payload;
      state.status = API_STATUS_SUCCESS;
    });
    builder.addCase(findCustomers.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(findCustomers.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const {
  clear,
  resetSearch,
  selectCustomer,
  setOriginCustomer,
  setDestinationCustomer,
  setMinDate,
  setMaxDate,
  setCreatedSort,
  resetCustomers,
  createCustomerShowAction,
  resetCustomerCreateFind,
  resetFindCustomer,
} = customersSlice.actions;

export default customersSlice.reducer;
