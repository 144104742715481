import React, { Component } from 'react';
import { Modal, Button, Table, Form, FormLabel } from 'react-bootstrap';
import axios from 'axios';
import * as globalConst from '../../../../global/const';
import { sprintf } from "sprintf-js";
import Spinner from 'react-bootstrap/Spinner';
import Auth from "../../../../Auth";
import ConfirmStockInShowroom from '../Actions/ConfirmStockInShowroom';

const CancelToken = axios.CancelToken;
let cancel;

class SearchStockNumber extends Component {
    filter = {
        stockNumber: ''
    }

    constructor(props) {
        super();

        this.state = {
            showListStockNumber: props.showListStockNumber,
            stockNumber: props.stockNumber,
            stockNumberData: null,
            isStockInShowroom: false,
            showIsStockInShowroom: false,
            loader: false,
            items: [],
            searchActive: false
        }
    }

    isStockInShowroom = (value = null) => {
        this.setState({
            showIsStockInShowroom: false,
            isStockInShowroom: value
        })

        this.props.parentResponseStockNumber(this.state.stockNumberData)
        this.props.parentResponseIsStockInShowroom(value)
        this.props.parentClose()
    }

    render() {
        return (
            <Modal
                show={this.state.showListStockNumber}
                centered
                size="lg"
            >
                <Modal.Header>
                    List stock
                </Modal.Header>
                <Modal.Body>
                    <div className='row m-2'>
                        <div className='col-4'>
                            <FormLabel>Stock number</FormLabel>
                        </div>
                        <div className='col-8'>
                            <Form.Group className="form-group">

                                <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    value={this.state.stockNumber}
                                    onChange={e => this.changeStockNumber(e)}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    {
                        (this.state.loader) ?
                            <div className='center-element'>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                            :
                            (null)
                    }

                    {
                        (this.state.items.length > 0) ?
                            this.rendeTable()
                            :
                            (null)
                    }

                    {
                        (
                            this.state.items.length <= 0 &&
                            !this.state.loader &&
                            this.state.searchActive
                        ) ?
                            <div className='center-element'>
                                No found results
                            </div>
                            :
                            (null)
                    }

                    {
                        this.state.showIsStockInShowroom ?
                            <ConfirmStockInShowroom
                                message="Is this stock located in Novita's showrooms?"
                                onResponse={this.isStockInShowroom.bind(this)}
                            />
                            :
                            null
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.close(this.state.stockNumber)}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    changeStockNumber = (e) => {
        const value = e.target.value

        this.setState({
            stockNumber: value
        })

        this.filter.stockNumber = value

        this.searchStockNumber()
    }

    rendeTable = () => {
        return (
            <div className='content-table-stock-number'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Location</th>
                            <th>Provider</th>
                            <th>Stock number</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRow()}
                    </tbody>
                </Table>
            </div>
        )
    }

    renderRow = () => {
        return (
            this.state.items.map(item => {
                return (
                    <tr key={item.stock_number}>
                        <td>{item.category}</td>
                        <td>{item.location_name}</td>
                        <td>{item.provider}</td>
                        <td>{item.stock_number}</td>
                        <td>
                            <button
                                onClick={e => { this.selectStockNumber(item) }}
                                className='btn btn-primary btn-novita-pink'>
                                Select
                            </button>
                        </td>
                    </tr>
                )
            })
        )
    }

    searchStockNumber = () => {
        if (!this.filter.stockNumber) {
            this.setState({
                loader: false,
                items: [],
                searchActive: false
            })

            return
        }

        this.setState({
            loader: true,
            searchActive: true
        })

        if (cancel !== undefined) {
            cancel();
        }

        const url = sprintf(
            '%s%s',
            process.env.REACT_APP_NOVITAERP_API_DOMAIN,
            globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_SEARCH_STOCK_NUMBER
        )

        let data = {
            "query": this.filter.stockNumber
        }

        const config = {
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }

        axios.post(
            url,
            data,
            config
        ).then(response => {
            this.setState({
                loader: false,
                items: response.data.value
            })
        }).catch((error) => {
            this.setState({
                loader: false
            })

            if (axios.isCancel(error)) {
                return;
            }
        }).finally(reponse => {
            this.setState({
                loader: false
            })
        })
    }

    selectStockNumber = (value) => {
        this.setState({
            stockNumberData: value,
            showIsStockInShowroom: true
        })
    }

    close = () => {
        this.props.parentClose();
    }
}

export default SearchStockNumber;