import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap';

const AlertMessage = props => {
    const {
        message,
        type,
        infoHeading,
        autoClose,
        timeClose,
        onResponse
    } = props

    const [show, setShow] = useState(true);

    useEffect(() => {
        if (autoClose) {
            const autoCloseAlert = () => {
                if (show) {
                    setTimeout(() => {
                        setShow(false)

                        if (onResponse) {
                            onResponse(false)
                        }
                    }, timeClose)
                }
            }

            autoCloseAlert()
        }

        return () => {
            setShow(false)
        };
    }, [
        message, type, infoHeading, autoClose, timeClose, show, onResponse
    ])

    const handleClose = () => {
        setShow(false)

        if (onResponse) {
            onResponse(false)
        }
    }

    return (
        (show) ?
            <Alert variant={type} onClose={handleClose} dismissible>
                <Alert.Heading>
                    {infoHeading}
                </Alert.Heading>
                <p>
                    {message}
                </p>
            </Alert>
            :
            null
    )
}

AlertMessage.propTypes = {
    type: PropTypes.string.isRequired,
    infoHeading: PropTypes.string,
    message: PropTypes.string.isRequired,
    autoClose: PropTypes.bool,
    timeClose: PropTypes.number,
    onResponse: PropTypes.func
}

AlertMessage.defaultProps = {
    type: 'success',
    infoHeading: '',
    autoClose: false,
    timeClose: 5000,
    onResponse: null
};

export default AlertMessage