import React, { Component } from 'react';
import Util from '../../../Util';
import Auth from '../../../Auth';
import { Link } from 'react-router-dom';
import detailsIcon from '../../../image/see_details.png';
import axios from 'axios';
import * as globalConst from '../../../global/const';

// SCSS
import '../../../css/sales/sale_information.scss'

const MANUFACTURING = "Manufacturing"

class SaleInformationProduction extends Component {

    constructor(props) {
        super();
        this.state = {
            sale: props.sale,
            workflowSales: props.workflowSales ?? [],
            isAccounting: (props.isAccounting ? true : false),
            isCosts: (props.isCosts ? true : false),
        }

        this.getWorkflowSales()
    }

    render() {
        const sale = this.state.sale
        var access_to_accounting_columns = Auth.isAuthorised("sales_accounting_columns");

        let customerNeedsOnDate = null
        if (sale.customerNeedsOn !== null && sale.customerNeedsOn !== '') {
            customerNeedsOnDate = new Date(sale.customerNeedsOn).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            customerNeedsOnDate = customerNeedsOnDate.slice(0, -5) + ", " + customerNeedsOnDate.slice(-4)
        }

        let urgentDateRequestedByCustomer = "No"
        if (sale.urgentDateRequestedByCustomer === true) {
            urgentDateRequestedByCustomer = "Yes"
        }

        let urgentDateRequestedByCustomerClass = ""
        if (urgentDateRequestedByCustomer === "Yes") {
            urgentDateRequestedByCustomerClass = "highlighted"
        }

        let weNeedByDate = null
        if (sale.dateWeNeedBy !== null && sale.dateWeNeedBy !== '') {
            weNeedByDate = new Date(sale.dateWeNeedBy).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            weNeedByDate = weNeedByDate.slice(0, -5) + ", " + weNeedByDate.slice(-4)
        }

        let orderTypeClass = ""
        if (sale.orderType === "Express") {
            orderTypeClass = "express"
        }
        else if (sale.orderType === "Ready to Ship") {
            orderTypeClass = "readyToShip"
        }

        return (
            <div className='sale_information_subcontainer'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm border-right"><span className='table_title'>Order Status</span></div>
                        <div className="col-sm">
                            <div className={Util.statusAsSlug(sale.status)}></div>
                            {sale.status}
                        </div>
                    </div>
                    {(!this.state.isAccounting && !this.state.isCosts) ? (
                        <div className="row">
                            <div className="col-sm border-right"><span className='table_title'>Manufacturing Location</span></div>
                            <div className="col-sm">{sale.manufacturingLocation}</div>
                        </div>
                    ) : (null)}
                    {
                        (Util.wordInArray(sale.saleType, ['engagement ring', 'jewellery', 'wedding']) === true) ?
                            <div className="row">
                                <div className="col-sm border-right bold"><span className='table_title'>Jeweller</span></div>
                                <div className="col-sm">{this.showSaleJeweller()}</div>
                            </div>
                            : (null)
                    }
                    {(!this.state.isAccounting && !this.state.isCosts) ? (
                        <div className="row">
                            <div className="col-sm border-right bold"><span className='table_title'>Setting Status</span></div>
                            <div className="col-sm">{sale.settingStatus}</div>
                        </div>
                    ) : (null)}
                    {
                        (Util.wordInArray(sale.saleType, ['engagement ring', 'jewellery', 'diamond']) === true) ?
                            <div className="row">
                                <div className="col-sm">

                                    <div className='grid_title align-center'>Diamond Status</div>
                                    <div className="row">
                                        <div className="col-sm border-right bold">Diamond Location</div>
                                        <div className="col-sm">{sale.diamondLocation}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm border-right bold">In Transit to</div>
                                        <div className="col-sm">{(sale.inTransitTo === '' || sale.inTransitTo === null) ? 'Not registered' : sale.inTransitTo}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm border-right bold">Requested to overseas provider</div>
                                        <div className="col-sm">{sale.requestedToOverseasProvider === true ? 'Yes' : 'No'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm border-right bold">Confirmed from overseas provider</div>
                                        <div className="col-sm">{sale.confirmedFromOverseasProvider === true ? 'Yes' : 'No'}</div>
                                    </div>
                                </div>
                            </div>
                            : (null)
                    }
                    {(!this.state.isAccounting && !this.state.isCosts) ? (
                        <div className="row">
                            <div className="col-sm border-right bold"><span className='table_title'>Date customer needs by</span></div>
                            <div className={"col-sm dateCustomerNeedsBy " + urgentDateRequestedByCustomerClass}>
                                {customerNeedsOnDate !== null ?
                                    <span>
                                        {customerNeedsOnDate}
                                    </span>
                                    : ('Not registered')
                                }
                            </div>
                        </div>
                    ) : (null)}
                    {(!this.state.isAccounting && !this.state.isCosts &&
                        access_to_accounting_columns === true) ? (
                        <div className="row">
                            <div className="col-sm border-right bold"><span className='table_title'>Urgent Date - requested by Customer</span></div>
                            <div className={"col-sm urgentDateRequestedByCustomer " + urgentDateRequestedByCustomerClass}>
                                <span>
                                    {urgentDateRequestedByCustomer}
                                </span>
                            </div>
                        </div>
                    ) : (null)}
                    {(!this.state.isAccounting && !this.state.isCosts) ? (
                        <div className="row">
                            <div className="col-sm border-right bold"><span className='table_title'>Date we need by</span></div>
                            <div className="col-sm">
                                {
                                    weNeedByDate !== null ?
                                        <span>
                                            {weNeedByDate}
                                        </span>
                                        : ('Not registered')
                                }
                            </div>
                        </div>
                    ) : (null)}
                    <div className="row">
                        <div className="col-sm border-right bold">
                            <div className='grid_title'>Type of Order</div>
                        </div>
                        <div className={"col-sm orderType " + orderTypeClass}>{sale.orderType}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm border-right bold">
                            <div className='grid_title'>Customer's Notes</div>
                            {sale.notes}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm border-right bold">
                            <div className='grid_title'>Customer's Notes Proposal Date</div>
                            {sale.customerNotesProposalDate}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm border-right bold">
                            <div className="link_to_detail">
                                <Link to={`/sale/${sale.id}`} target="_blank">
                                    Multimedia <img src={detailsIcon} alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return {
            sale: props.sale
        }
    }

    ajaxRequest = null;
    getWorkflowSales = () => {
        const user = Auth.getAuthenticatedUser()
        if (user === null || user === undefined || user.employeeModel === null || user.employeeModel === undefined) {
            this.setState(
                {
                    error_messages: ['Authentication error. Reload the page and try again.']
                }
            )
        }
        else {
            //AJAX CONTROLS
            //cancel previous ajax request if exists
            if (this.ajaxRequest !== null) {
                this.ajaxRequest.cancel();
            }
            // creates a new token for upcomming ajax (overwrite the previous one)
            this.ajaxRequest = axios.CancelToken.source();
            //FINISH AJAX CONTROLS

            let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_SALE_LIST
            const params = new URLSearchParams()
            params.append('saleId', this.state.sale.id)

            axios.get(
                url,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${Auth.getToken()}` }
                },
                {
                    cancelToken: this.ajaxRequest.token
                }
            )
                .then(
                    (response) => {
                        const data = response.data
                        if (data !== undefined && data.code === 200) {
                            var workflowSales = data.result.rows;
                            this.setState(
                                {
                                    workflowSales: workflowSales
                                }
                            )
                        }
                        else {
                            console.log('There was a problem processing the information. Please reload this page and try again.')
                        }
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            let error_messages = []
                            if ('status' in err.response && err.response.status === 401) {
                                error_messages = ['Not authorised.']
                            }
                            else {
                                error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                            }
                            alert(JSON.stringify(error_messages))
                        } else if (err.request) {
                            const error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                            alert(JSON.stringify(error_messages))
                        } else {
                            //error not detected. Request could have been cancelled and it's waiting for second
                            //request to finish
                        }
                        //console.log((err);
                        return null;
                    }
                )
        }
    }

    showSaleJeweller() {
        if (this.state.sale.workflowLatestProvider) {
            let sale = this.state.sale
            return `${sale?.workflowLatestProvider} - (${sale?.workflowLatestProviderContactName})`
        }

        return ''
    }

    componentDidUpdate(previData, nextData, info) {
        if (previData.sale.id != this.state.sale.id) {
            this.getWorkflowSales()
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    
}

export default SaleInformationProduction;