import { Button, Modal } from "react-bootstrap";

export default function ConfirmRemoveFilters(
    { 
        showModal,
        removeMultiple,
        parentRemoveFilters
    }) {
        const responseRemoveFilter = (value) => {
            parentRemoveFilters(value);
        }

        return (
            <Modal
                show={showModal}
                centered
            >
                <Modal.Header>
                    {
                        removeMultiple  ?
                            'Are you sure to remove the filters?'
                            :
                            'Are you sure to remove the filter?'
                    }
                </Modal.Header>
                <Modal.Body>
                    <Button
                        onClick={() => responseRemoveFilter(true)}
                        className="m-2"
                        variant="outline-primary">
                        Yes
                    </Button>
                    <Button
                        onClick={() => responseRemoveFilter(false)}
                        variant="outline-secondary"
                        className='m-2'>
                        No
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => responseRemoveFilter(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
};