import { dateFormatCustom } from "../../../../utils/dateUtils";

export default function PostSalesServices({ customerSales }) {
  const buildPostSalesService = () => {
    let components = [];
    for (let customerSale of customerSales) {
      for (let service of customerSale.sale.postSaleServices) {
        components.push(
          <tr key={service.id}>
            <td>{dateFormatCustom(service.created, "DD MMM, YYYY")}</td>
            <td>{service.typeOfJob}</td>
          </tr>
        );
      }
    }
    return components;
  };

  return (
    <div className="general_information customer-details-card">
      <h2
        style={{ height: "42px" }}
        className="d-flex flex-row justify-content-between align-items-center"
      >
        <p className="align-self-center mt-3">Post Sales Services</p>
      </h2>
      <div className="customer-details-container p-2">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Created</th>
              <th scope="col">Type</th>
            </tr>
          </thead>
          <tbody id="sales_list_information">{buildPostSalesService()}</tbody>
        </table>
      </div>
    </div>
  );
}
