import React, {Component} from 'react';
import * as globalConst from '../../../global/const';
import Auth from '../../../Auth';
import axios from 'axios';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MediaCreate from './create/MediaCreate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SaleMultimediaRow from './SaleMultimediaRow';
import SaleMultimediaDelete from './SaleMultimediaDelete';
import SaleMultimediaEdit from './edit/SaleMultimediaEdit';
import {Container, Row} from 'react-bootstrap';
import MediaPreview from './MediaPreview';

class MultimediaList extends Component {
    constructor(props){
        super(props)
        const isAuth = Auth.getIsAuth()
        if(isAuth === false){
            props.history.push('/')
        }

        this.state = {
            multimedia : [],
            loading_page : false,
            selected_media : null,
            selected_media_index : null,
            total_results : 0,
            api_has_more : false,
            show_create_media_modal : false,
            show_media_delete_modal: false,
            show_media_edit_modal: false,
            media_delete_selected: null,
            show_media_preview_modal: false,
            media_preview_url: null,
            media_type: false,
            media_edit_selected: null,
            saleId: props.saleId            
        }

        this.resetFilters();
        this.filter_params['generalSearch'] = null;
        if (this.filter_params['generalSearch'] === null) {
            this.filter_params['generalSearch'] = "";
        }

        this.closeCreateComponent = this.closeCreateComponent.bind(this)
        this.closeMediaDeleteModal = this.closeMediaDeleteModal.bind(this)
        this.closeMediaEditModal = this.closeMediaEditModal.bind(this)
        this.closeMediaPreviewModal = this.closeMediaPreviewModal.bind(this)
    }

    filter_params = {}

    resetFilters = () => {
        this.filter_params = {
            page : 1,
            max_results : 100,
            order_by : 'id',
            order_type : 'DESC',
            salesId: this.state.saleId,
            generalSearch: this.filter_params["generalSearch"]
        }
        Object.keys(this.filter_params).forEach(key => this.filter_params[key] === undefined ? delete this.filter_params[key] : {});
    }

    ajaxRequest = null;
    fetchMultimedia(){
        this.setState(
            {
                loading_page: true
            }
        )
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if(this.ajaxRequest !== null){
            this.ajaxRequest.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.ajaxRequest = axios.CancelToken.source();
        //FINISH AJAX CONTROLS
        const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALES_MULTIMEDIA_LIST
        axios.get(
            url,
            {
                params: this.filter_params,//this params are sent as query. In post requests they should go as body params in the following block
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            },
            {
                cancelToken: this.ajaxRequest.token
            })
            .then(api_response => {
                const result = api_response.data.result;
                const items = result.rows;

                // add list to previous items
                let list = []
                if(this.filter_params.page === 1){
                    list = items;
                    // window.scrollTo(0,0);
                    window.scrollTo({ top: 0 });
                }
                else{
                    let current_list = [
                        ...this.state.multimedia
                    ]
                    list = current_list.concat(items);
                }

                this.setState({
                    multimedia: list,
                    loading_page: false,
                    total_results: result.count,
                    api_has_more: result.amount_results > 0 ? true : false
                })
            })
            .catch(err => {
                if (err.response) {
                    if('status' in err.response && err.response.status === 401){
                        alert('User not authorised. Please reload the page');
                    }
                    else{
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                    this.setState(
                        {
                            loading_page: true
                        }
                    )
                }
                console.log(err);
            })
    }
    render(){
        var access_to_sales_multimedia_create = Auth.isAuthorised("sales_multimedia_create");
        return(
            <div id="SaleDetailGeneralInfo_component">
                {
                    this.state.show_create_media_modal === true ?
                        <MediaCreate
                            closeCreateComponent = {this.closeCreateComponent}
                            saleId = {this.state.saleId}
                            updateSaleMultimediaEditRequirementsList = {this.updateSaleMultimediaEditRequirementsList}
                        />
                    :(null)
                }
                {
                    this.state.show_media_delete_modal === true ?
                        <SaleMultimediaDelete
                            closeMediaDeleteModal = {this.closeMediaDeleteModal}
                            updateSaleMultimediaEditRequirementsList = {this.updateSaleMultimediaEditRequirementsList}
                            media_delete_selected = {this.state.media_delete_selected}
                        />
                    :(null)
                }
                {
                    this.state.show_media_edit_modal === true ?
                        <SaleMultimediaEdit
                            closeMediaEditModal = {this.closeMediaEditModal}
                            updateSaleMultimediaEditRequirementsList = {this.updateSaleMultimediaEditRequirementsList}
                            media_edit_selected = {this.state.media_edit_selected}
                        />
                    :(null)
                }
                {
                    this.state.show_media_preview_modal === true ?
                        <MediaPreview
                            closeMediaPreviewModal = {this.closeMediaPreviewModal}
                            media_preview_url = {this.state.media_preview_url}
                            media_type = {this.state.media_type}
                        />
                    :(null)
                }

                <div className='sale_data'>
                    <h2 className='heading'>MULTIMEDIA 
                    {(access_to_sales_multimedia_create === true) && (
                        <button className='btn btn-default' onClick={this.openCreateComponent}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    )}</h2>
                   <Container className="text-center">
                        <Row xs={2} md={3} lg={6} xl={6}>
                        {
                            this.state.multimedia.map((media, index) => {
                                return (
                                        <SaleMultimediaRow
                                            ref={ "saleMediaRow" + media.id }
                                            key={ media.id }
                                            media={ media }
                                            index={ index }                                    
                                            selected_media={this.state.selected_media}
                                            removeFromList= { this.removeFromList }
                                            editFromList= { this.editFromList }
                                            mediaPreview= { this.mediaPreview }
                                        />
                                )
                            })
                        }
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }

    updateSaleMultimediaEditRequirementsList = () => {
        setTimeout(() => {
            this.closeCreateComponent();
            this.closeMediaEditModal();
            this.closeMediaDeleteModal();
            this.fetchMultimedia();  
        }, 1000);   
    }

    openCreateComponent = () => {
        this.setState(
            {
                show_create_media_modal: true
            }
        )
    }

    closeCreateComponent = () => {
        this.setState(
            {
                show_create_media_modal: false
            }
        )
    }

    removeFromList = (media) => {
        this.setState(
            {
                show_media_delete_modal: true,
                media_delete_selected: media
            }
        )
    }

    editFromList = (media) => {
        this.setState(
            {
                show_media_edit_modal: true,
                media_edit_selected: media
            }
        )
    }

    mediaPreview = (url, isVideo) => {
        this.setState(
            {
                show_media_preview_modal: true,
                media_preview_url: url,
                media_type: isVideo
            }
        )
    }

    closeMediaPreviewModal = () => {
        this.setState(
            {
                show_media_preview_modal: false,
                media_preview_url: null,
                media_type: false
            }
        )
    }

    openMediaDeleteModal = () => {
        this.setState(
            {
                show_media_delete_modal: true
            }
        )
    }

    closeMediaDeleteModal = () => {
        this.setState(
            {
                show_media_delete_modal: false
            }
        )
    }

    closeMediaEditModal = () => {
        this.setState(
            {
                show_media_edit_modal: false
            }
        )
    }

     //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount(){
        //execute once the first time
        this.fetchMultimedia();
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
}
export default MultimediaList;