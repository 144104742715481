import { SALE_COMMISSION_TYPE_REVIEWS } from "../../../../global/const";

export const validateCommissionWasPaid = (sale) => {
  if (!sale) {
    return false;
  }

  let commissionWasPaid = false;

  const { saleCommissionModels = [] } = sale;

  if (saleCommissionModels.length > 0) {
    saleCommissionModels.forEach((saleCommission) => {
      const { paidDate = null } = saleCommission;

      if (paidDate) {
        commissionWasPaid = true;
      }
    });
  }

  return commissionWasPaid;
};

export const validateCommissionWasPaidReviews = (sale) => {
  if (!sale) {
    return false;
  }

  let commissionWasPaid = false;

  const { saleCommissionModels = [] } = sale;

  if (saleCommissionModels.length > 0) {
    saleCommissionModels.forEach((saleCommission) => {
      const { paidDate = null, type } = saleCommission;

      if (paidDate && type === SALE_COMMISSION_TYPE_REVIEWS) {
        commissionWasPaid = true;
      }
    });
  }

  return commissionWasPaid;
};
