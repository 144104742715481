import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Tabs,
  Tab,
  FormLabel,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS_PENDING } from "../../../store/constants";
import {
  createCustomer,
  createCustomerShowAction,
  editCustomerMutation,
} from "../../../store/slices/customerSlice";

const FormCustomerDialog = ({ customer, show, onHide }) => {
  const dispatch = useDispatch();

  const { status } = useSelector((state) => state.customers);
  const [isDirty, setIsDirty] = useState(false);
  const [validated, setValidated] = useState(false);
  const [initialCustomer, setInitialCustomer] = useState({ ...customer });
  const [editedCustomer, setEditorCustomer] = useState(initialCustomer);

  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    // When the customer prop changes, reset initialCustomer and editedCustomer
    setInitialCustomer({ ...customer });
    setEditorCustomer({ ...customer });
  }, [customer]);

  const setCustomerEmployeeName = (customerEmployees) => {
    if (!customerEmployees) {
      setEditorCustomer((prevState) => ({
        ...prevState,
        employeeNames: "",
      }));
      return;
    }

    let names = [];
    customerEmployees.map((customerEmployee) => {
      const { employee } = customerEmployee;

      if (employee) {
        const { name } = employee;
        names.push(name);
      }

      return null;
    });

    setEditorCustomer((prevState) => ({
      ...prevState,
      employeeNames: names.length > 0 ? names.join(",") : "",
    }));
  };

  useEffect(() => {
    if (editedCustomer.employeeNames === undefined) {
      const { customerEmployees } = editedCustomer;

      setCustomerEmployeeName(customerEmployees);
    }
  }, [editedCustomer]);

  const handleChange = (e) => {
    setEditorCustomer((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    setIsDirty(true);
  };

  const validateSubmit = async (e) => {
    e.preventDefault();

    setValidated(false);
    const form = e.currentTarget;
    const check = form.checkValidity();

    if (!check) {
      setValidated(true);
      return;
    }

    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editedCustomer.id) {
      dispatch(editCustomerMutation(editedCustomer));
    } else {
      dispatch(createCustomer(editedCustomer));
    }

    onHide();
  };

  const onCloseEdit = () => {
    if (isDirty) {
      setShowConfirmation(true);
    } else {
      setEditorCustomer(initialCustomer);
      dispatch(createCustomerShowAction(false));
      onHide();
    }
  };

  const handleCloseWithoutSave = () => {
    setShowConfirmation(false);
    setEditorCustomer(initialCustomer);
    onHide();
  };

  return (
    <Modal show={show} onHide={onCloseEdit} dialogClassName="modal-95w">
      <Modal.Header closeButton>
        <Modal.Title>
          {editedCustomer.id ? "Edit Customer" : "Create Customer"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs id="SaleEditTabs" className="mb-3">
          <Tab
            eventKey="general"
            title="General"
            tabClassName={isDirty ? "pending-changes" : ""}
          >
            <Form onSubmit={validateSubmit} validated={validated} noValidate>
              <Card>
                <Card.Body>
                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Group controlId="customerName">
                        <Form.Label>Name *</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={editedCustomer.name ?? ""}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group controlId="customerPhone">
                        <Form.Label>Phone *</Form.Label>
                        <Form.Control
                          type="tel"
                          name="phone"
                          value={editedCustomer.phone ?? ""}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Group controlId="customerEmail">
                        <Form.Label>Email *</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={editedCustomer.email ?? ""}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {editedCustomer.id && (
                    <>
                      <Row>
                        <Col xs={12} lg={6}>
                          <Form.Group controlId="customerEthnicity">
                            <Form.Label>Ethnicity</Form.Label>
                            <Form.Control
                              type="text"
                              name="ethnicity"
                              value={editedCustomer.ethnicity ?? ""}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                          <Form.Group controlId="customerHowHeardAboutUs">
                            <Form.Label>How Heard About Us</Form.Label>
                            <Form.Control
                              type="text"
                              name="howHeardAboutUs"
                              value={editedCustomer.howHeardAboutUs ?? ""}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} lg={6}>
                          <Form.Group controlId="customerReasonNotBuying">
                            <Form.Label>Reason Not Buying</Form.Label>
                            <Form.Control
                              type="text"
                              name="reasonNotBuying"
                              value={editedCustomer.reasonNotBuying ?? ""}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card.Body>
              </Card>
              <Row>
                <Col className="d-flex align-items-center justify-content-end">
                  <Button
                    disabled={status === API_STATUS_PENDING}
                    className="mt-2"
                    variant="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal
        className="sale-edit-close-confirmation"
        show={showConfirmation}
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header closeButton>Confirm</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="form-group">
              <FormLabel>
                You still have pending changes. Are you sure you want to close?
              </FormLabel>
            </Form.Group>
            <Button
              type="button"
              className="btn btn-default confirm-btn"
              onClick={handleCloseWithoutSave}
            >
              Discard Changes
            </Button>
            <Button
              className="btn btn-default disagree-btn"
              type="button"
              onClick={() => {
                setShowConfirmation(false);
              }}
            >
              Continue Editing
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Modal>
  );
};

export default FormCustomerDialog;
