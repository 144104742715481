import React, {Component} from 'react';
import axios from 'axios';
// import { Link, NavLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Leftbardashboard from'../dashboard/leftbarDashboard';
import OrderToInformation from '../sales/orderToInformation';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

// SCSS
import '../../css/orders.scss'

// import { Switch, Route } from 'react-router-dom';

class OrdersListTo extends Component {

    
    ajaxRequest = null;

    constructor(){
        super();
        this.state = {
            orders: [],
            loading_list: false,
            selected_order : null
        }
        this.resetFilters();
    }

    filter_params = {}

    resetFilters = () => {
        this.filter_params = {
            page : 1,
            max_results : 100
        }
    }

    fetchMoreData = () => {
        this.filter_params['page'] = this.filter_params['page']+1
        this.getListFromApi();
    }

    getListFromApi(){
        this.setState(
            {
                loading_list: true
            }
        )
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if(this.ajaxRequest !== null){
            this.ajaxRequest.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.ajaxRequest = axios.CancelToken.source();
        //FINISH AJAX CONTROLS
        const api_routes_base = document.getElementById('api_routes_base');
        const api_routes_orders = document.getElementById('api_routes_orders');
        const url = api_routes_base.value+api_routes_orders.value;
        

        // console.log('List with filters:')
        // console.log(this.filter_params)
        axios.get(
            url,
            {
                params: this.filter_params,//this params are sent as query. In post requests they should go as body params in the following block
                headers: { Authorization: `Bearer ${window.user_token}` }
            },
            {
                cancelToken: this.ajaxRequest.token
            })
            .then(api_response => {
                console.log('api_response');
                console.log(api_response);
                const data = api_response.data;
                const items = data.result.rows;
                // console.log(items);

                // add list to previous items
                let list = []
                if(this.filter_params.page === 1){
                    list = items;
                    // window.scrollTo(0,0);
                    window.scrollTo({ top: 0 });
                }
                else{
                    let current_list = [
                        ...this.state.orders
                    ]
                    list = current_list.concat(items);
                }

                this.setState({
                    orders: list,
                    loading_page: false,
                    total_results: data.count,
                    api_has_more: data.amount_results > 0 ? true : false
                })
            })
            .catch(err => {
                if (err.response) {
                    if('status' in err.response && err.response.status === 401){
                        alert('User not authorised');
                    }
                    else{
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                    this.setState(
                        {
                            loading_page: true
                        }
                    )
                }
                console.log(err);
            })
    }

    

    render(){
        return(
            <div className="test">
                <Leftbardashboard/>
                <section className="center-section">
                    <div className="container_orders">
                    {
                        this.state.loading_page === true ?
                            (
                                <div className="general_spinner">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )
                            :
                            ('')
                    }
                    <div className="header">
                        <div className="dropdown sort_container">
                            <div className="total_items">
                                Total orderss: {this.state.total_results}
                            </div>
                            {/* <button className="btn btn-secondary dropdown-toggle sort_by_button" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Sort by: { this.getSortByActiveOption().name }
                            </button>
                            <div id="order_dropdown" className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {
                                    this.sort_by_options.map((sort_by_option) => {
                                        let btnClass = [
                                            'dropdown-item',
                                            sort_by_option.id
                                        ]
                                        btnClass = btnClass.join(' ')
                                        return(
                                            <NavLink key={sort_by_option.id} isActive={() => {return false}} className={btnClass} to="#" onClick={(e) => this.orderListBy(e, sort_by_option)}>
                                                {sort_by_option.name}
                                            </NavLink>
                                        )
                                    })
                                }
                            </div> */}
                        </div>
                    </div>
                    <div className="list_and_information">
                        <div className="orders_list">
                            <InfiniteScroll
                                dataLength={this.state.orders.length}
                                next={this.fetchMoreData}
                                hasMore={this.state.api_has_more}
                                height={'calc(100vh - 200px)'}
                            >
                                <table className="table table-sm table-hover" id="table_orders">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th className="reference th-with-input">
                                                Reference
                                                <input className="form-control custom-search-input" type="text" name="" id="" />
                                            </th>
                                            <th className="created th-with-input">
                                                Date
                                                <input className="form-control custom-search-input" type="text" name="" id="" />
                                            </th>
                                            <th className="name th-with-input">
                                                Customer
                                                <input className="form-control custom-search-input" type="text" name="" id="" />
                                            </th>
                                            <th className="payment_method">
                                                Manufacturing Process
                                            </th>
                                            <th className="first_payment_status">
                                                Total Order
                                            </th>
                                            <th className="paid_price">
                                                Paid Price
                                            </th>
                                            <th className="detail">
                                                Detail
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            //this.state.orders.map((order, index) => {
                                                // return (
                                                //     <OrderRow
                                                //         key={ order.id }
                                                //         order={ order }
                                                //         index={ index }
                                                //         handleRowSelection={ this.handleRowSelection }
                                                //     />
                                                // )
                                            //})
                                        }
                                        <tr>
                                            <td>RD1037</td>
                                            <td>9/12/2020</td>
                                            <td>Username</td>
                                            <td>Received by Jeweller</td>
                                            <td>$12000.00</td>
                                            <td>$4000.00</td>
                                            <td><a href="#" className="details-link">Details</a></td>
                                        </tr>
                                        <tr>
                                            <td>RD1037</td>
                                            <td>9/12/2020</td>
                                            <td>Username</td>
                                            <td>Received by Jeweller</td>
                                            <td>$12000.00</td>
                                            <td>$4000.00</td>
                                            <td><a href="#" className="details-link">Details</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>
                        
                    </div>
                    {/*<Link className={"add_link"} to='/admin/inventory/list/add'>*/}
                        {/*<FontAwesome name="plus-circle" />*/}
                    {/*</Link>*/}
                    </div>





                    </section>
                    <OrderToInformation order = {this.state.selected_order} />
            </div>
           
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount(){
        //execute once the first time
        this.getListFromApi();
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    
    handleRowSelection = (index) => {
        this.setState({
            selected_order : this.state.orders[index]
        })
    }

}

export default OrdersListTo;