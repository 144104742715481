import React, { useState } from 'react';
import { faFilter, faClose} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Button, Row, Col, Tab, Tabs } from 'react-bootstrap';

// css
import '../../../css/advanced-filter.scss'
import ConfirmRemoveFilters from './confirm-remove-filters';

// Data Json
import ListFavouriteFilter from './favouriteFilter/list-favourite-filter';


const CustomToggleFilter = React.forwardRef(({ children, onClick }, ref) => (
    <Button
        className='btn-active-filter'
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
        ref={ref}
    >
        <FontAwesomeIcon icon={faFilter} className="btn-fa-filter" />
    </Button>
));

export default function AdvancedFilter({advanceFilterChanged, advanceFilterActive}) {
    const [showRemoveFilter, setShowRemoveFilter] = useState(false);
    const [tabKey, setTabKey] = useState('personalFilter');
    const {dropdownIsOpen} = useState(true);

    const handleSelect = (key) => {
        setTabKey(key);
    };

    const clearFilters = () => {
        setShowRemoveFilter(true);
    }

    const parentRemoveFilters = (value) => {
        if (value === true) {
            window.location = window.location.pathname;
        }
        setShowRemoveFilter(false);
    }

    return (
        <Dropdown
            className={'mx-2 advanced-search' + (advanceFilterActive ? ' advanced-search-active' : ' ')}
            autoClose={false}
            align="end"
            open={dropdownIsOpen}>
            <Dropdown.Toggle
                as={CustomToggleFilter}
                id={"dropdown-autoclose-false" + advanceFilterActive ? ' advanced-search-active' : ''}>
            </Dropdown.Toggle>

            <Dropdown.Menu className='menu-advanced-filter'>
                <Dropdown.Header id="item" href="#">
                    <div className='row'>
                        <Tabs
                            activeKey={tabKey}
                            onSelect={handleSelect}
                            defaultActiveKey="personalFilter"
                            id="tab-advanced-filter"
                        >
                            <Tab
                                    eventKey="personalFilter"
                                    title="Personal filter"
                                    className='title-tab-advanced-filter'>
                                    
                                    {
                                        (
                                            tabKey === 'personalFilter'
                                        ) ?
                                            (
                                                <ListFavouriteFilter
                                                    advanceFilterChanged={advanceFilterChanged}
                                                />
                                            ) :
                                            (null)
                                    }
                            </Tab>
                        </Tabs>
                        
                        <Dropdown.Divider></Dropdown.Divider>

                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <Row>
                                        <Col lg="12">
                                            <Row>
                                                <Col lg="6">
                                                    <button
                                                        type='button'
                                                        className='btn btn-primary btn-novitadimonds btn-advanced-search m-1'
                                                        onClick={(e) => clearFilters()}>
                                                        <FontAwesomeIcon icon={faClose} /> {' '}
                                                        Clear filters
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <Dropdown.Divider></Dropdown.Divider>

                        {showRemoveFilter === true ?
                            <ConfirmRemoveFilters
                                showModal={showRemoveFilter}
                                removeMultiple={true}
                                parentRemoveFilters={parentRemoveFilters} />
                            :
                            ('')
                        }

                        <div className='col-12'></div>
                    </div>
                </Dropdown.Header>
            </Dropdown.Menu>
        </Dropdown>
    );
}