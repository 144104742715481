import { useEffect, useState } from "react";
import { Modal, Card, Row, Col, Button } from "react-bootstrap";
import SearchCustomers from "../search_customer";
import { mergeCustomerOriginIntoDestination, resetFindCustomer, searchCustomers, setDestinationCustomer, setOriginCustomer } from "../../../store/slices";
import { useDispatch, useSelector } from "react-redux";
import CustomerCard from "../information/customer-card";
import MergeCustomerCard from "./merge-customer-card";
import ConfirmMergeCustomer from "./confirm-merge-customer";
import CustomerComments from "./comments";
import { clear, fetchAllCommentsByIds } from "../../../store/slices/commentsSlice";
import AppointmentInformation from "./appointments";
import CustomerSales from "./sales";
import PostSalesServices from "./post-sales-services";
import Result from "./result";
import { CUSTOMERS_TYPE_DESTINATION, CUSTOMERS_TYPE_ORIGIN } from "../../../store/constants";

const MergeCustomerDialog = ({ show, onHide, handleShowMergeCustomer, onSelected }) => {
    const [showAddCustomers, setShowAddCustomers] = useState(false);
    const [showConfirmMergeCustomer, setShowConfirmMergeCustomer] = useState(false);
    const {
        selectedOriginCustomer,
        selectedDestinationCustomer,
        mergeStatus,
        error
    } = useSelector((state) => state.customers); 
    const [customerType, setCustomerType] = useState(CUSTOMERS_TYPE_ORIGIN);
    const [showResult, setShowResult] = useState(false);
    const { originCustomerComments, destinationCustomerComments, mergeCustomerComments } = useSelector((state) => state.customerComments);
    const [disableConfirmMerge, setDisableConfirmMerge] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if(mergeStatus === true){
            handleShowMergeCustomer(false);
            handleSearchByEmail(selectedDestinationCustomer.email);
            dispatch(clear());
            onSelected(selectedDestinationCustomer);
            dispatch(resetFindCustomer());
        }
    }, [mergeStatus, dispatch]);

    useEffect(() => {
        if (selectedOriginCustomer || selectedDestinationCustomer) {
            dispatch(resetFindCustomer());
        }
    }, [dispatch]);

    useEffect(() => {
        if (selectedOriginCustomer?.id && selectedDestinationCustomer?.id) {
            dispatch(fetchAllCommentsByIds({ customerIds: [selectedOriginCustomer.id, selectedDestinationCustomer.id], customerType: '' }));
            setShowResult(true);
            setDisableConfirmMerge(false);
        }
    }, [selectedOriginCustomer, selectedDestinationCustomer, showResult, dispatch]);

    const handleSearchByEmail = (email) => {
        let generalSearch = document.getElementsByClassName('advanced_filter_customerModel_general_search');
        generalSearch[0].value = email;
        dispatch(
            searchCustomers({
                columns: [],
                filterText: email,
            })
        );
    }

    const handleSelection = (customer) => {
        if (customerType === CUSTOMERS_TYPE_ORIGIN){
            dispatch(setOriginCustomer(customer));
        }
        if (customerType === CUSTOMERS_TYPE_DESTINATION){
            dispatch(setDestinationCustomer(customer));
        }
        dispatch(fetchAllCommentsByIds({ customerIds: [customer.id], customerType: customerType }));
    };

    const responseConfirmMergeCustomer = (value) => {
        if(value === true) {
            setShowConfirmMergeCustomer(false);
            setDisableConfirmMerge(true);
            dispatch(
                mergeCustomerOriginIntoDestination({
                  originCustomerId: selectedOriginCustomer.id,
                  destinationCustomerId: selectedDestinationCustomer.id,
                })
            );
        } else {
            setShowConfirmMergeCustomer(value);
        }
    }

    return (
        <>
            <Modal show={show} onHide={onHide} dialogClassName="modal-95w">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Merge Customers
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body id="CRMInformation_component_merge_customer">
                    {error && (
                        <div className="alert alert-danger" role="alert">
                        {error.toString()}
                        </div>
                    )}
                    <Row>
                        <Col xs={4} lg={4}>
                            <Card border="danger">
                                <Card.Header>
                                    <Card.Title>Origin customer</Card.Title>
                                    <Card.Text className="text-danger">
                                        This customer will be removed after merging.
                                    </Card.Text>
                                </Card.Header>
                                <Card.Body>
                                    {selectedOriginCustomer &&
                                        <>
                                            <CustomerCard customer={selectedOriginCustomer} />
                                            <CustomerComments comments={originCustomerComments}/>
                                            <AppointmentInformation customerAppointments={selectedOriginCustomer.customerAppointments} />
                                            <CustomerSales customerSales={selectedOriginCustomer.customerSales} />
                                            <PostSalesServices customerSales={selectedOriginCustomer.customerSales} />
                                        </>
                                    }
                                    <div className="text-center">
                                        <Button className="submit-btn"
                                            onClick={() => {
                                                setShowAddCustomers(true);
                                                setCustomerType(CUSTOMERS_TYPE_ORIGIN);
                                            }}>
                                            Select origin customer
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4} lg={4}>
                            <Card border="warning">
                                <Card.Header>
                                    <Card.Title>Destination customer</Card.Title>
                                    <Card.Text className="text-warning">
                                        This customer will get the information from the origin customer after merging.
                                    </Card.Text>
                                </Card.Header>
                                <Card.Body>
                                    {selectedDestinationCustomer &&
                                        <>
                                            <CustomerCard customer={selectedDestinationCustomer} />
                                            <CustomerComments comments={destinationCustomerComments}/>
                                            <AppointmentInformation customerAppointments={selectedDestinationCustomer.customerAppointments} />
                                            <CustomerSales customerSales={selectedDestinationCustomer.customerSales} />
                                            <PostSalesServices customerSales={selectedDestinationCustomer.customerSales} />
                                        </>
                                    }
                                    <div className="text-center">
                                        <Button className="submit-btn"
                                            disabled={(selectedOriginCustomer) ? false : true}
                                            onClick={() => {
                                                setShowAddCustomers(true);
                                                setCustomerType(CUSTOMERS_TYPE_DESTINATION);
                                            }}>
                                            Select destination customer
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4} lg={4}>
                            <Card border="success">
                                <Card.Header>
                                    <Card.Title>Result customer</Card.Title>
                                    <Card.Text className="text-success">
                                        This will be the information of the customer after merging.
                                    </Card.Text>
                                </Card.Header>
                                <Card.Body>
                                    {showResult && selectedOriginCustomer && selectedDestinationCustomer &&
                                        <>
                                            <MergeCustomerCard originCustomer={selectedOriginCustomer} destinationCustomer={selectedDestinationCustomer} />
                                            <CustomerComments comments={mergeCustomerComments}/>
                                            <Result originCustomer={selectedOriginCustomer} destinationCustomer={selectedDestinationCustomer}/>
                                        </>
                                    }
                                    <div className="text-center">
                                        <Button 
                                            disabled={disableConfirmMerge} 
                                            onClick={() => {
                                                setShowConfirmMergeCustomer(true);
                                            }}
                                            className="submit-btn">
                                            Confirm merge
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {
                showAddCustomers && (
                    <SearchCustomers
                        show={showAddCustomers}
                        onHide={() => {
                            setShowAddCustomers(false);
                        }}
                        handleSelection={handleSelection}
                        selectedOriginCustomer={selectedOriginCustomer}
                        selectedDestinationCustomer={selectedDestinationCustomer}
                        customerType={customerType}
                    />
                )
            }
            {
                showConfirmMergeCustomer && (
                    <ConfirmMergeCustomer
                        onShow={showConfirmMergeCustomer}
                        responseConfirmMergeCustomer={responseConfirmMergeCustomer}
                    />
                )
            }
        </>
    );
};

export default MergeCustomerDialog;