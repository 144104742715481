import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Form, FormLabel, Row } from "react-bootstrap";
import SaleFindCustomer from "./SaleFindCustomer";

const SaleCreateCustomerData = (props) => {
  const { saleData, onResponse } = props;

  saleData.customerSearch = true;
  saleData.customerCreate = false;

  const [sale, setSale] = useState(saleData);

  const handleChange = (e) => {
    const data = sale;

    const { value, name } = e.target;

    data[name] = value;

    setSale((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    onResponse(value, name);
  };

  const onResponseCustomer = (customer) => {
    if (!customer) {
      return;
    }

    const inputs = ["name", "email", "phone"];

    const { id } = customer;
    onResponse(id, "customerId");

    inputs.map((input) => {
      const value = customer[input];
      setSale((prevState) => ({
        ...prevState,
        [input]: value,
      }));

      onResponse(value, input);
      return null;
    });
  };

  const activeInput = (value, name) => {
    setSale((prevState) => ({
      ...prevState,
      customerCreate: false,
      customerSearch: false,
    }));

    if (name === "customerCreate" && value === false) {
      setSale((prevState) => ({
        ...prevState,
        customerSearch: true,
      }));
    }

    if (name === "customerSearch" && value === false) {
      setSale((prevState) => ({
        ...prevState,
        customerCreate: true,
      }));
    }

    setSale((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "customerCreate") {
      setSale((prevState) => ({
        ...prevState,
        name: "",
        phone: "",
        email: "",
      }));
    }
  };

  return (
    <Card className="mt-2">
      <Card.Body>
        <Card.Title>Customer Information</Card.Title>

        <SaleFindCustomer
          onResponseCustomer={onResponseCustomer}
          onResponseInput={activeInput}
        />

        <Row className="mt-2">
          <Col sm="12" lg="4">
            <Form.Group className="form-group" controlId="validationName">
              <FormLabel>
                Customer's Name {!sale.customerSearch ? "*" : ""}
              </FormLabel>
              <Form.Control
                required
                minLength={3}
                type="text"
                name="name"
                disabled={sale.customerSearch}
                value={sale.name ?? ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Customer's name is required and should have at least 3
                characters
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm="12" lg="4">
            <Form.Group className="form-group">
              <FormLabel>Phone {!sale.customerSearch ? "*" : ""}</FormLabel>
              <Form.Control
                required
                minLength={3}
                type="text"
                name="phone"
                disabled={sale.customerSearch}
                value={sale.phone ?? ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Phone is required and should have at least 3 characters
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm="12" lg="4">
            <Form.Group className="form-group">
              <FormLabel>Email {!sale.customerSearch ? "*" : ""}</FormLabel>
              <Form.Control
                required
                minLength={3}
                type="email"
                name="email"
                disabled={sale.customerSearch}
                placeholder="name@example.com"
                value={sale.email ?? ""}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Email is required and should have at least 3 characters
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

SaleCreateCustomerData.propTypes = {
  saleData: PropTypes.any.isRequired,
  onResponse: PropTypes.func.isRequired,
};

export default SaleCreateCustomerData;
