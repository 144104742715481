import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Auth from '../../Auth';
import axios from 'axios';
import Tasks from './Tasks';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as globalConst from '../../global/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../../css/dashboard.scss';
import Notifications from '../../Notifications';

class Rightbardashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sale_edition_requirements_unassigned: [],
            sale_edition_requirements_assigned: [],
            sale_edition_requirements_history: [],
            saleEditionRequirementsUnassignedHasMore: false,
            saleEditionRequirementsAssignedHasMore: false,
            saleEditionRequirementsHistoryHasMore: false,
            currentTab: "activity",
            employeeTaskList: [],
            employeeTaskListHasMore: false,
            myTasksDoneConfirmation: [],
            myTasksPendingCount: 0,
            activityUnassignedCount: 0,
            activityAssignedCount: 0,
            activityHistoryCount: 0,
            search: "",
            employees: [],
            employeeId: "",
            showTask: true
        }
        this.getSaleEditionRequirementListUnassigned = this.getSaleEditionRequirementListUnassigned.bind(this)
        this.getSaleEditionRequirementListAssigned = this.getSaleEditionRequirementListAssigned.bind(this)
        this.getSaleEditionRequirementListHistory = this.getSaleEditionRequirementListHistory.bind(this)
        this.getEmployeeTaskList = this.getEmployeeTaskList.bind(this)
        this.resetFiltersActivityUnassigned = this.resetFiltersActivityUnassigned.bind(this)
        this.resetFiltersActivityAssigned = this.resetFiltersActivityAssigned.bind(this)
        this.resetFiltersActivityHistory = this.resetFiltersActivityHistory.bind(this)
        this.resetFiltersTasks = this.resetFiltersTasks.bind(this)
    }

    filter_params_activity_unassigned = {
        page: 1,
        max_results: 10,
    }

    filter_params_activity_assigned = {
        page: 1,
        max_results: 10,
    }

    filter_params_activity_history = {
        page: 1,
        max_results: 10,
    }

    filter_params_tasks = {
        page: 1,
        max_results: 10,
        filterStatus: "assigned",
        employeeId: Auth.getAuthenticatedUser().employeeModel.id ?? '',
    }

    render() {
        /*var access_to_activity = Auth.isAuthorised("dashboard_activity_list");
        var access_to_activity_complete = Auth.isAuthorised("dashboard_activity_complete");
        var access_to_activity_assign_list = Auth.isAuthorised("dashboard_activity_assign_task");
        var access_to_activity_assign_own_task = Auth.isAuthorised("dashboard_activity_assign_own_task");
        var access_to_unarchive = Auth.isAuthorised("dashboard_activity_complete_uncomplete");
        var access_to_my_tasks = Auth.isAuthorised("dashboard_my_tasks_list");*/
        //var access_to_tasks = Auth.isAuthorised("dashboard_tasks_list");

        return (
            <section className="right-section">
                <Notifications
                    parentNotificationsNumber={this.numberNotification}
                />
                { /*
                <div className="search-section">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control search-input" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        <div className="input-group-append">
                            <span className="input-group-text search-input-group-text" id="basic-addon2">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                        </div>
                    </div>
                </div>
                */ }
                <div className="tab-section">
                    <Tabs defaultActiveKey="tasks" id="rightDashboardTabs" className="mb-3" onSelect={this.handleSelect}>
                        <Tab className="tab-my-tasks" eventKey="tasks" title={<span>Tasks</span>}>
                            <div className='general_searcj search-section'>
                                <div className='row'>
                                    <div className='col-10'>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control search-input" placeholder="Search" value={this.state.search} onChange={(e) => this.handleFilterChange(e, 'generalSearch')} />
                                            <div className="input-group-append">
                                                <span className="input-group-text search-input-group-text">
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <span className="card-link-icon add-task-button" onClick={this.props.assignTask}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <select name="filterStatus" className='select-box w-100 ml-0' value={this.filter_params_tasks.filterStatus} onChange={(e) => this.handleFilterChange(e, 'filterStatus')}>
                                        <option value="">All</option>
                                        {/*<option value="Unassigned">Unassigned</option>*/}
                                        <option value="assigned">Assigned</option>
                                        {/*<option value="Accepted">Accepted</option>*/}
                                        {/*<option value="Started">Started</option>*/}
                                        <option value="completed">Completed</option>
                                        {/*<option value="Rejected">Rejected</option>*/}
                                    </select>
                                </div>
                                <div className='col-6'>
                                    <select name="employeeId" className='select-box w-100 ml-0' value={this.filter_params_tasks.employeeId} onChange={(e) => this.handleFilterChange(e, 'employeeId')}>
                                        <option value="All">All</option>
                                        {
                                            this.state.employees.map((row, index) => {
                                                return (
                                                    <option key={index} value={!!row.id ? row.id : ""}>{!!row.name ? row.name : ""}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div id="tasksScrollableDiv" className="infinite-scroll-parent-div">
                                {
                                    (this.state.showTask) ?
                                        <InfiniteScroll
                                            dataLength={this.state.employeeTaskList.length}
                                            next={this.fetchMoreEmployeeTask}
                                            hasMore={this.state.employeeTaskListHasMore}
                                            scrollableTarget="tasksScrollableDiv"
                                        >
                                            <div className="my-task-items-list">
                                                {
                                                    (this.state.employeeTaskList) ? (
                                                        this.state.employeeTaskList.map((row, index) => {
                                                            return (
                                                                <Tasks
                                                                    key={row.id}
                                                                    task={row}
                                                                    getEmployeeTaskList={this.getEmployeeTaskList}
                                                                    resetFilterTasks={this.resetFiltersTasks}
                                                                />
                                                            )
                                                        })
                                                    ) : (null)
                                                }
                                            </div>
                                        </InfiniteScroll>
                                        :
                                        null
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section>
        )
    }

    numberNotification = (value) => {
        if (value > this.state.employeeTaskList.length) {
            this.getEmployeeTaskList()
        }
    }

    handleFilterChange = (e, field_name) => {
        let filter_value = e.target.value;

        if (field_name === "generalSearch") {
            this.setState({
                search: filter_value
            })
        }
        /*let state = {}
        state[field_name] = filter_value
        this.setState(state)*/

        this.resetFiltersTasks();
        this.filter_params_tasks[field_name] = filter_value;
        this.getEmployeeTaskList();
    }

    /*handleFilterChange = (e) => {
        let filter_value = e.target.value;

        this.resetFiltersActivityUnassigned();
        this.resetFiltersActivityAssigned();
        this.resetFiltersActivityHistory();

        this.setState({
            search: e.target.value
        })
        
        this.filter_params_activity_unassigned["generalSearch"] = filter_value;
        this.filter_params_activity_assigned["generalSearch"] = filter_value;
        this.filter_params_activity_history["generalSearch"] = filter_value;
        this.getSaleEditionRequirementListUnassigned()
        this.getSaleEditionRequirementListAssigned()
        this.getSaleEditionRequirementListHistory()
    }*/

    resetFiltersActivityUnassigned = () => {
        this.filter_params_activity_unassigned = {
            page: 1,
            max_results: 10,
        }
        this.setState({
            sale_edition_requirements_unassigned: []
        })
        window.scrollTo({ top: 0 });
    }

    resetFiltersActivityAssigned = () => {
        this.filter_params_activity_assigned = {
            page: 1,
            max_results: 10,
        }
        this.setState({
            sale_edition_requirements_assigned: []
        })
        window.scrollTo({ top: 0 });
    }

    resetFiltersActivityHistory = () => {
        this.filter_params_activity_history = {
            page: 1,
            max_results: 10,
        }
        this.setState({
            sale_edition_requirements_history: []
        })
        window.scrollTo({ top: 0 });
    }

    resetFiltersTasks = () => {
        let currentStatusFilter = this.filter_params_tasks.filterStatus
        let currentEmployeeFilter = this.filter_params_tasks.employeeId
        let currentGeneralSearch = this.filter_params_tasks.generalSearch

        this.filter_params_tasks = {
            page: 1,
            max_results: 10,
            filterStatus: currentStatusFilter,
            employeeId: currentEmployeeFilter,
            generalSearch: currentGeneralSearch,
        }
        this.setState({
            employeeTaskList: []
        })
        window.scrollTo({ top: 0 });
    }

    getSaleEditionRequirementListUnassigned = () => {
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALE_EDITION_REQUIREMENT_LIST
        const user = Auth.getAuthenticatedUser()
        let userRoles = "";
        let userRolesArray = user.roles.split('"');
        for (let i = 0; i < userRolesArray.length; i++) {
            if (userRolesArray[i].includes("ROLE")) {
                userRoles = userRoles + userRolesArray[i] + ",";
            }
        }
        if (userRolesArray.length > 0) {
            userRoles = userRoles.slice(0, -1);
        }

        const params = new URLSearchParams()
        params.append('verificatorRoles', userRoles)
        params.append('type', 'unassigned')
        params.append('page', this.filter_params_activity_unassigned.page)
        params.append('max_results', this.filter_params_activity_unassigned.max_results)
        if ("generalSearch" in this.filter_params_activity_unassigned) {
            if (this.filter_params_activity_unassigned.generalSearch !== null
                && this.filter_params_activity_unassigned.generalSearch !== "") {
                params.append('generalSearch', this.filter_params_activity_unassigned.generalSearch)
            }
        }
        axios.get(
            url,
            {
                params: params,
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        )
            .then(
                api_response => {
                    const data = api_response.data

                    if (data !== undefined && data.code === 200) {
                        let saleEditionRequirementListUnassigned = []
                        if (this.filter_params_activity_unassigned.page === 1) {
                            saleEditionRequirementListUnassigned = data.result.rows;
                            window.scrollTo({ top: 0 });
                        }
                        else {
                            let currentSaleEditionRequirementListUnassigned = [
                                ...this.state.sale_edition_requirements_unassigned
                            ]
                            saleEditionRequirementListUnassigned = currentSaleEditionRequirementListUnassigned.concat(data.result.rows);
                        }

                        this.setState({
                            sale_edition_requirements_unassigned: saleEditionRequirementListUnassigned,
                            saleEditionRequirementsUnassignedHasMore: data.result.amount_results > 0 ? true : false,
                        })

                        let activityCount = data.result.amount_results

                        if (activityCount >= 100) {
                            activityCount = "100+"
                        }

                        this.setState({
                            activityUnassignedCount: activityCount
                        })
                    }
                    else {
                        alert('error')
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            console.log('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    //console.log(err);
                    return null;
                }
            )
    }

    getSaleEditionRequirementListAssigned = () => {
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALE_EDITION_REQUIREMENT_LIST
        const user = Auth.getAuthenticatedUser()
        let userRoles = "";
        let userRolesArray = user.roles.split('"');
        for (let i = 0; i < userRolesArray.length; i++) {
            if (userRolesArray[i].includes("ROLE")) {
                userRoles = userRoles + userRolesArray[i] + ",";
            }
        }
        if (userRolesArray.length > 0) {
            userRoles = userRoles.slice(0, -1);
        }

        const params = new URLSearchParams()
        params.append('verificatorRoles', userRoles)
        params.append('type', 'assigned')
        params.append('page', this.filter_params_activity_assigned.page)
        params.append('max_results', this.filter_params_activity_assigned.max_results)
        if ("generalSearch" in this.filter_params_activity_assigned) {
            if (this.filter_params_activity_assigned.generalSearch !== null
                && this.filter_params_activity_assigned.generalSearch !== "") {
                params.append('generalSearch', this.filter_params_activity_assigned.generalSearch)
            }
        }
        axios.get(
            url,
            {
                params: params,
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        )
            .then(
                api_response => {
                    const data = api_response.data

                    if (data !== undefined && data.code === 200) {
                        let saleEditionRequirementListAssigned = []
                        if (this.filter_params_activity_assigned.page === 1) {
                            saleEditionRequirementListAssigned = data.result.rows;
                            window.scrollTo({ top: 0 });
                        }
                        else {
                            let currentSaleEditionRequirementListAssigned = [
                                ...this.state.sale_edition_requirements_assigned
                            ]
                            saleEditionRequirementListAssigned = currentSaleEditionRequirementListAssigned.concat(data.result.rows);
                        }

                        this.setState({
                            sale_edition_requirements_assigned: saleEditionRequirementListAssigned,
                            saleEditionRequirementsAssignedHasMore: data.result.amount_results > 0 ? true : false,
                        })

                        let activityCount = data.result.count

                        if (activityCount >= 100) {
                            activityCount = "100+"
                        }

                        this.setState({
                            activityAssignedCount: activityCount
                        })
                    }
                    else {
                        alert('error')
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            console.log('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    //console.log(err);
                    return null;
                }
            )
    }

    getSaleEditionRequirementListHistory = () => {
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALE_EDITION_REQUIREMENT_LIST
        const user = Auth.getAuthenticatedUser()
        let userRoles = "";
        let userRolesArray = user.roles.split('"');
        for (let i = 0; i < userRolesArray.length; i++) {
            if (userRolesArray[i].includes("ROLE")) {
                userRoles = userRoles + userRolesArray[i] + ",";
            }
        }
        if (userRolesArray.length > 0) {
            userRoles = userRoles.slice(0, -1);
        }

        const params = new URLSearchParams()
        params.append('verificatorRoles', userRoles)
        params.append('type', 'history')
        params.append('page', this.filter_params_activity_history.page)
        params.append('max_results', this.filter_params_activity_history.max_results)
        if ("generalSearch" in this.filter_params_activity_history) {
            if (this.filter_params_activity_history.generalSearch !== null
                && this.filter_params_activity_history.generalSearch !== "") {
                params.append('generalSearch', this.filter_params_activity_history.generalSearch)
            }
        }
        axios.get(
            url,
            {
                params: params,
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        )
            .then(
                api_response => {
                    const data = api_response.data

                    if (data !== undefined && data.code === 200) {
                        let saleEditionRequirementListHistory = []
                        if (this.filter_params_activity_history.page === 1) {
                            saleEditionRequirementListHistory = data.result.rows;
                            window.scrollTo({ top: 0 });
                        }
                        else {
                            let currentSaleEditionRequirementListHistory = [
                                ...this.state.sale_edition_requirements_history
                            ]
                            saleEditionRequirementListHistory = currentSaleEditionRequirementListHistory.concat(data.result.rows);
                        }

                        this.setState({
                            sale_edition_requirements_history: saleEditionRequirementListHistory,
                            saleEditionRequirementsHistoryHasMore: data.result.amount_results > 0 ? true : false,
                        })

                        let activityCount = data.result.count

                        if (activityCount >= 100) {
                            activityCount = "100+"
                        }

                        this.setState({
                            activityHistoryCount: activityCount
                        })
                    }
                    else {
                        alert('error')
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            console.log('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    //console.log(err);
                    return null;
                }
            )
    }

    handleSelect = (key) => {
        /*if (key === "activity") {
            this.setState({
                currentTab: "activity"
            })
        }
        else if (key === "myTasks") {
            this.getEmployeeTaskList()
            this.setState({
                currentTab: "myTasks"
            })
        }
        else if (key === "unassigned") {
            this.setState({
                currentTab: "unassigned"
            })
        }
        else if (key === "assigned") {
            this.setState({
                currentTab: "assigned"
            })
        }
        else if (key === "history") {
            this.setState({
                currentTab: "history"
            })
        }*/
        if (key === "tasks") {
            this.setState({
                currentTab: "tasks"
            })
        }
    }

    fetchMoreEmployeeTask = () => {
        this.filter_params_tasks['page'] = this.filter_params_tasks['page'] + 1
        this.getEmployeeTaskList();
    }

    fetchMoreSaleEditionRequirementsUnassigned = () => {
        this.filter_params_activity_unassigned['page'] = this.filter_params_activity_unassigned['page'] + 1
        this.getSaleEditionRequirementListUnassigned();
    }

    fetchMoreSaleEditionRequirementsAssigned = () => {
        this.filter_params_activity_assigned['page'] = this.filter_params_activity_assigned['page'] + 1
        this.getSaleEditionRequirementListAssigned();
    }

    fetchMoreSaleEditionRequirementsHistory = () => {
        this.filter_params_activity_history['page'] = this.filter_params_activity_history['page'] + 1
        this.getSaleEditionRequirementListHistory();
    }

    getEmployeeTaskList = () => {
        const user = Auth.getAuthenticatedUser()
        let is_leader = false;
        if ("employeeModel" in user) {
            if ("isLeader" in user.employeeModel) {
                is_leader = user.employeeModel.isLeader
            }
        }

        const params = new URLSearchParams()
        params.append('roles', user.roles)
        params.append('is_leader', is_leader)

        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_LIST

        if (this.filter_params_tasks.employeeId !== null && this.filter_params_tasks.employeeId !== "" && this.filter_params_tasks.employeeId !== "All") {
            params.append('employeeId', this.filter_params_tasks.employeeId)
            //params.append('employeeId', user.employeeModel.id)
        }
        params.append('page', this.filter_params_tasks.page)
        params.append('max_results', this.filter_params_tasks.max_results)
        params.append('employeeTaskStatusSlug', this.filter_params_tasks.filterStatus)
        if (typeof this.filter_params_tasks.generalSearch !== "undefined") {
            params.append('generalSearch', this.filter_params_tasks.generalSearch)
        }
        axios.get(
            url,
            {
                params: params,
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        )
            .then(
                api_response => {
                    const data = api_response.data
                    if (data !== undefined && data.code === 200) {
                        let employeeTaskList = []
                        if (this.filter_params_tasks.page === 1) {
                            employeeTaskList = data.result.rows;
                            window.scrollTo({ top: 0 });
                        }
                        else {
                            let currentemployeeTaskList = [
                                ...this.state.employeeTaskList
                            ]
                            employeeTaskList = currentemployeeTaskList.concat(data.result.rows);
                        }

                        //Delete duplicates
                        employeeTaskList = employeeTaskList.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.id === value.id
                            ))
                        )

                        this.setState({
                            employeeTaskList: employeeTaskList,
                            employeeTaskListHasMore: data.result.amount_results > 0 ? true : false,
                            showTask: false
                        })

                        let myTasksPendingCount = data.result.count
                        this.setState({
                            myTasksPendingCount: myTasksPendingCount
                        })
                        
                        setTimeout(() => {
                            this.setState({
                                showTask: true
                            })
                        }, 1);
                    }
                    else {
                        alert('error')
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            console.log('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    //console.log(err);
                    return null;
                }
            )
    }

    getEmployees = () => {
        const user = Auth.getAuthenticatedUser()
        let is_leader = false;
        if ("employeeModel" in user) {
            if ("isLeader" in user.employeeModel) {
                is_leader = user.employeeModel.isLeader
            }
        }

        let url;
        if (is_leader) {
            url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_LIST
        }
        else {
            url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_GET_OWN_USER
        }

        const params = new URLSearchParams()
        params.append('roles', user.roles)

        axios.get(
            url,
            {
                params: params,
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        )
            .then(
                api_response => {
                    const data = api_response.data
                    this.setState({
                        employees: data.result.rows,
                        employeeId: data.result.rows[0].id
                    })
                    if (data !== undefined && data.code === 200) {

                    }
                    else {
                        alert('error')
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            alert('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    //console.log(err);
                    return null;
                }
            )
    }

    componentDidMount() {
        this.getEmployeeTaskList();
        this.getEmployees();
        //this.props.getEmployeeTaskList(this.getEmployeeTaskList);
        //this.props.resetFiltersActivityUnassigned(this.resetFiltersActivityUnassigned);
        //this.props.resetFiltersActivityAssigned(this.resetFiltersActivityAssigned);
        //this.props.resetFiltersActivityHistory(this.resetFiltersActivityHistory);
        //this.props.resetFiltersMyTasks(this.resetFiltersTasks);
        //this.getSaleEditionRequirementListAssigned();
        //this.getSaleEditionRequirementListUnassigned();
        //this.getSaleEditionRequirementListHistory();
        //this.props.getSaleEditionRequirementListUnassigned(this.getSaleEditionRequirementListUnassigned);
        //this.props.getSaleEditionRequirementListAssigned(this.getSaleEditionRequirementListAssigned);
        //this.props.getSaleEditionRequirementListHistory(this.getSaleEditionRequirementListHistory);
    }
}

export default Rightbardashboard;