import React, { Component } from 'react';
import Util from '../../../Util'
import { addWorkflowType } from './Actions/FindTypeActivity';

class SaleDetailLog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sale: props.sale,
            sales_logs: props.sales_logs,
            loading_log: props.loading_log,
            error_loading_log: props.error_loading_log,
            titleSales: props.titleSales ?? false
        }
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        const sales_logs = this.state.sales_logs
        let sales_logs_rows = []
        if (Array.isArray(sales_logs) === true && sales_logs.length > 0) {
            sales_logs_rows = this.getRowsFromSalesLogs(sales_logs)
        }
        return (
            <div id="SaleDetailSalesLog_component">

                <div className='sale_data'>

                    <h2 className='heading'>
                        {
                            (this.state.titleSales) ?
                                'HISTORY'
                                :
                                ('HISTORY WORKFLOW')
                        }
                    </h2>
                    <div className="container mb-4">
                        {
                            this.state.loading_log === true ?
                                (
                                    <div className="row">
                                        <div className="col-sm">
                                            Loading...
                                        </div>
                                    </div>
                                ) :
                                (
                                    this.state.error_loading_log === true ?
                                        (
                                            <div className="row">
                                                <div className="col-sm">
                                                    Error loading log.
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            sales_logs_rows.map(function (sales_logs_row, index) {
                                                let log_date = null

                                                if (sales_logs_row.created !== null && sales_logs_row.created !== '') {
                                                    log_date = new Date(sales_logs_row.created);
                                                }

                                                return (
                                                    <div className='row' key={index}>
                                                        <div className="col-sm">
                                                            {
                                                                log_date !== null ?
                                                                    <span>
                                                                        {
                                                                            log_date.toLocaleString(
                                                                                'default', { day: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE }
                                                                            )
                                                                        }
                                                                        {
                                                                            log_date.toLocaleString(
                                                                                'default', { month: 'short', timeZone: process.env.REACT_APP_TIMEZONE }
                                                                            )
                                                                        },
                                                                        {
                                                                            log_date.toLocaleString(
                                                                                'default', { year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE }
                                                                            )
                                                                        } -
                                                                        {
                                                                            log_date.toLocaleTimeString(
                                                                                'en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: process.env.REACT_APP_TIMEZONE }
                                                                            )
                                                                        }
                                                                    </span>
                                                                    : ('')
                                                            }
                                                        </div>
                                                        <div className="col-sm"
                                                            dangerouslySetInnerHTML={{ __html: sales_logs_row.message }}>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                )
                        }
                    </div>
                </div>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
        //return values for the state
        return {
            sales_logs: props.sales_logs,
            loading_log: props.loading_log,
            error_loading_log: props.error_loading_log
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

    getRowsFromSalesLogs = (sales_logs) => {
        const sales_logs_rows = []
        if (Array.isArray(sales_logs) === true) {
            sales_logs.map((sales_log) => {
                let name_editor = 'An user'
                const userModel = sales_log.userModel
                if (userModel !== undefined && userModel?.employeeModel !== undefined) {
                    name_editor = userModel?.employeeModel?.name
                }
                const old_information = sales_log.oldInformation
                const new_information = sales_log.newInformation

                if (sales_log.action === "CREATE") { //recently created log
                    let sales_logs_row_message = 'Created by: ' + name_editor

                    if (sales_log.model) {
                        sales_logs_row_message += ' - Module: ' + sales_log.model

                        sales_logs_row_message = addWorkflowType(
                            sales_logs_row_message,
                            sales_log,
                            sales_logs
                        )
                    }

                    const sales_logs_row = {
                        message: sales_logs_row_message,
                        created: sales_log.created
                    }
                    sales_logs_rows.push(sales_logs_row)
                } else if (sales_log.action === "CREATE_MEDIA") {
                    let sales_logs_row_message = name_editor + ' created multimedia element (<span class="activity-new-value">' + new_information.filename + '</span>)'
                    const sales_logs_row = {
                        message: sales_logs_row_message,
                        created: sales_log.created
                    }
                    sales_logs_rows.push(sales_logs_row)
                } else if (sales_log.action === "DELETE_MEDIA") {
                    let sales_logs_row_message = name_editor + ' deleted multimedia element (<span class="activity-old-value">' + old_information.filename + '</span>)'
                    const sales_logs_row = {
                        message: sales_logs_row_message,
                        created: sales_log.created
                    }
                    sales_logs_rows.push(sales_logs_row)
                } else {
                    const old_information_keys = Object.keys(old_information)
                    old_information_keys.map((old_information_key) => {
                        if (Array.isArray(new_information[old_information_key])) {
                            return null
                        }
                        
                        try {
                            if (old_information_key in new_information && old_information_key !== 'created' && old_information_key !== 'updated') {
                                // const camelcase_key = this.camelize(old_information_key)
                                if (old_information[old_information_key] !== new_information[old_information_key]) {
    
                                    if (old_information_key === 'sheetType') {
                                        if (old_information[old_information_key] === 'ACTIVE') {
                                            old_information[old_information_key] = 'Current Responses'
                                        }
                                        else if (old_information[old_information_key] === 'FINALISED') {
                                            old_information[old_information_key] = 'Finished Jobs'
                                        }
    
                                        if (new_information[old_information_key] === 'ACTIVE') {
                                            new_information[old_information_key] = 'Current Responses'
                                        }
                                        else if (new_information[old_information_key] === 'FINALISED') {
                                            new_information[old_information_key] = 'Finished Jobs'
                                        }
                                    }
    
                                    const date_fields = [
                                        "customerNeedsOn",
                                        "dateWeNeedBy",
                                        "reviewRequestDateSent",
                                        "collectedShippedDate"
                                    ]
                                    if (date_fields.indexOf(old_information_key) > -1) {
                                        if (!isNaN(Date.parse(old_information[old_information_key]))) {
                                            old_information[old_information_key] = new Date(old_information[old_information_key])
                                            old_information[old_information_key] = old_information[old_information_key].toLocaleString('default', { day: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE }) + " " + old_information[old_information_key].toLocaleString('default', { month: 'short', timeZone: process.env.REACT_APP_TIMEZONE }) + ", " + old_information[old_information_key].toLocaleString('default', { year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE })
                                        }
                                        if (!isNaN(Date.parse(new_information[old_information_key]))) {
                                            new_information[old_information_key] = new Date(new_information[old_information_key])
                                            new_information[old_information_key] = new_information[old_information_key].toLocaleString('default', { day: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE }) + " " + new_information[old_information_key].toLocaleString('default', { month: 'short', timeZone: process.env.REACT_APP_TIMEZONE }) + ", " + new_information[old_information_key].toLocaleString('default', { year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE })
                                        }
                                    }
                                    let sales_logs_row_message = '';
                                    if (Util.wordInArray(old_information_key, ['filename', 'description'])) {
                                        sales_logs_row_message = name_editor + ' changed multimedia element. ' + old_information_key + ' from <span class="activity-old-value">' + old_information[old_information_key] + '</span> to <span class="activity-new-value">' + new_information[old_information_key] + '</span>'
                                    } else {
                                        sales_logs_row_message = name_editor + ' changed ' + old_information_key + ' from <span class="activity-old-value">' + old_information[old_information_key] + '</span> to <span class="activity-new-value">' + new_information[old_information_key] + '</span>'
                                    }
                                    if (old_information[old_information_key] === undefined || old_information[old_information_key] === 'undefined' || old_information[old_information_key] === '') {
                                        sales_logs_row_message = name_editor + ' assigned the value ' + new_information[old_information_key] + ' to ' + old_information_key
                                    }
                                    if (new_information[old_information_key] === undefined || new_information[old_information_key] === 'undefined' || new_information[old_information_key] === '') {
                                        sales_logs_row_message = name_editor + ' deleted the value ' + old_information[old_information_key] + ' from ' + old_information_key
                                    }
                                    if (sales_log.model) {
                                        sales_logs_row_message += ' - Module: ' + sales_log.model
    
                                        sales_logs_row_message = addWorkflowType(
                                            sales_logs_row_message,
                                            sales_log,
                                            sales_logs
                                        )
                                    }
    
                                    const sales_logs_row = {
                                        message: sales_logs_row_message,
                                        created: sales_log.created
                                    }
    
                                    sales_logs_rows.push(sales_logs_row)
                                }
                            }   
                        } catch (error) {}

                        return null
                    })
                }

                return null
            })
        }
        return sales_logs_rows
    }

    camelize = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

}

export default SaleDetailLog;