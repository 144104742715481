export const saleIsReturn = (sale) => {
  if (!sale) {
    return false;
  }

  const { saleReturnModels = [] } = sale;

  if (saleReturnModels.length > 0) {
    return true;
  }

  return false;
};

export const getSaleReturnData = (sale) => {
  if (!sale) {
    return null;
  }

  const { saleReturnModels = [] } = sale;

  if (saleReturnModels.length > 0) {
    return saleReturnModels[0];
  }

  return null;
};

export const getOnlineOrderNumberparent = (sale) => {
  if (!sale) {
    return "";
  }

  const { saleReturnModels = [] } = sale;

  if (saleReturnModels.length > 0) {
    return saleReturnModels[0]?.salesModel?.onlineOrderNumber ?? "";
  }

  return "";
};
