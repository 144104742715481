import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_FAILED,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
} from "../constants";

const initialState = {
  error: null,
  customerLogs: [],
  status: "",
};

export const fetchCustomerLogs = createAsyncThunk(
  "/customer-log/custemerID",
  async (args, thunkAPI) => {
    try {
      const response = await axios.get(`/api/v1/customers/customer-logs/${args.id}`);
      const { data } = response;

      if (Array.isArray(data)) {
        return data;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const customerLogsSlice = createSlice({
  name: "customerLogs",
  initialState,
  reducers: {
    clear(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.customerLogs = initialState.customerLogs;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCustomerLogs.fulfilled, (state, action) => ({
      error: null,
      status: API_STATUS_SUCCESS,
      customerLogs: action.payload,
    }));
    builder.addCase(fetchCustomerLogs.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchCustomerLogs.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const { clear } = customerLogsSlice.actions;

export default customerLogsSlice.reducer;
