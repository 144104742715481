import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_FAILED,
  API_STATUS_IDLE,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
} from "../constants";

const initialState = {
  error: null,
  jewelleries: [],
  status: API_STATUS_IDLE,
};

export const fetchJewellery = createAsyncThunk(
  "/employee",
  async (args, thunkAPI) => {
    try {
      let url = `/api/v1/jeweller`;

      const response = await axios.get(url);
      const { data } = response;

      if (!data) {
        return [];
      }

      const { result } = data;
      const { rows } = result;

      if (Array.isArray(rows)) {
        return rows;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const jewellerySlice = createSlice({
  name: "jewellery",
  initialState,
  reducers: {
    clear(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.jewelleries = initialState.jewellerys;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchJewellery.fulfilled, (state, action) => ({
      jewelleries: action.payload,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(fetchJewellery.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchJewellery.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const { clear } = jewellerySlice.actions;

export default jewellerySlice.reducer;
