import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import TableSearchSaleDiamond from './TableSearchSaleDiamond'

const MergeSearchSaleDiamond = props => {

    const { sale, onClose } = props

    const [show, setShow] = useState(true);
    const [acceptMerge, setAcceptMerge] = useState(false);
    const [saleSelected, setSaleSelected] = useState(null)

    const handleClose = () => {
        setShow(false);

        onClose(null)
    }

    const sendSaleMerge = (sale) => {
        setShow(false);

        onClose(sale)
    }

    const onResponse = (sale) => {
        if (!sale) {
            setAcceptMerge(false)
        }

        setSaleSelected(sale)
    }

    const changeRadio = (event) => {
        setAcceptMerge(false)

        const { checked } = event.target

        setAcceptMerge(checked)
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    List Sale
                    &nbsp;
                    <Form.Text muted>
                        Current Sale: <b>({sale.onlineOrderNumber})</b>
                    </Form.Text>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg="12">
                        <TableSearchSaleDiamond
                            sale={sale}
                            onResponse={onResponse}
                        />
                    </Col>
                    <Col lg="12">
                        <Form.Check
                            type="checkbox"
                            name="acceptMerge"
                            label="I understand that the merged sale will be cancelled and all prices will be mixed in the accounting section"
                            value={acceptMerge}
                            checked={acceptMerge ? true : false}
                            disabled={!saleSelected ? true : false}
                            onChange={(e) => changeRadio(e)}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-secondary"
                    onClick={handleClose}>
                    Close
                </Button>
                <Button
                    className='btn-novita-pink'
                    onClick={(e) => sendSaleMerge(saleSelected)}
                    disabled={!acceptMerge ? true : false}
                >
                    Merge
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

MergeSearchSaleDiamond.propTypes = {
    sale: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired
}

export default MergeSearchSaleDiamond