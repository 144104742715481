import React, { Component } from 'react';
import SaleComment from './PostSaleComment'
import { Col, Form, Row } from 'react-bootstrap';

class PostSaleCommentsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            topic_filter: props.topic_filter,
            isAccounting: (props.isAccounting ? true : false),
            isCosts: (props.isCosts ? true : false),
        }
        this.handleTopicFilter = this.handleTopicFilter.bind(this)

        this.loadListInformation()
    }

    loadListInformation = () => {
        this.listShowTopic = process.env.REACT_APP_POST_SALE_SHOW_TOPIC.split(',')
    }

    handleTopicFilter(event) {
        this.setState(
            {
                topic_filter: event.target.value
            }
        )
        this.props.fetchPostSaleComments(event.target.value)
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        return (
            <div id="SaleCommentsList_component">
                {
                    this.props.loading_comments === true ?
                        (
                            'Loading list of comments...'
                        ) :
                        (<form>
                            <div className='form-group mb-2 text-right'>
                                <Row>
                                    <Col xs={12} lg={6}></Col>
                                    <Col xs={12} lg={6}>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <label className='box-label'>Show topic(s):</label>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Select
                                                    onChange={(e) => this.handleTopicFilter(e)}
                                                    value={this.state.topic_filter ?? ''}
                                                >
                                                    <option value=""></option>
                                                    {
                                                        this.listShowTopic.map(listShowTopic => {
                                                            return (
                                                                <option
                                                                    key={listShowTopic}
                                                                    value={listShowTopic}>
                                                                    {listShowTopic}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className='form-group'>
                                {
                                    this.props.sale_comments.length > 0 ?
                                        (
                                            this.props.sale_comments.map(function (sale_comment) {
                                                return (
                                                    <SaleComment
                                                        key={sale_comment.id}
                                                        sale_comment={sale_comment}
                                                    />
                                                )
                                            })
                                        ) :
                                        <span className='mt-2 no-comments-section'>No comments yet</span>
                                }
                            </div>
                        </form>
                        )
                }
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return {
            topic_filter: props.topic_filter
        }
    }


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************



}

export default PostSaleCommentsList;