import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row } from "react-bootstrap";

const CommissionConfirmChange = ({
  employeeRate,
  preEmployeeRate,
  onResponse,
  employeeRates,
}) => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = (value) => {
    setShowModal(false);

    const employeeRateResponse = employeeRates.filter(
      (employeeRate) => employeeRate.name === preEmployeeRate
    );

    onResponse(
      value,
      preEmployeeRate,
      employeeRateResponse.length > 0 ? employeeRateResponse[0] : {}
    );
  };

  return (
    <Modal show={showModal} size="md" centered>
      <Modal.Body>
        <Col>
          The commission rate will change from <b>"{`${employeeRate}`}"</b> to{" "}
          <b>"{`${preEmployeeRate}`}"</b>.
        </Col>
        <br />
        <Col>Are you sure that you want to apply the change?</Col>
      </Modal.Body>
      <Modal.Footer>
        <Row lg={12}>
          <Col lg={8}>
            <Button variant="secondary" onClick={() => closeModal(false)}>
              Cancel
            </Button>
          </Col>
          <Col lg={4}>
            <Button variant="primary" onClick={() => closeModal(true)}>
              Save
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

CommissionConfirmChange.propTypes = {
  onResponse: PropTypes.func.isRequired,
  employeeRate: PropTypes.string.isRequired,
  preEmployeeRate: PropTypes.string.isRequired,
};

export default CommissionConfirmChange;
