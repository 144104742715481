import React, { Component } from 'react';
import { Tabs, Tab, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import MyListTab from './MyListTab';
import CostsRow from './CostsRow';
import CostsInformation from './costsInformation/CostsInformation';
import CostsInformationModal from './costsInformation/CostsInformationModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import Auth from '../../Auth';
import LoadingLayer from '../LoadingLayer';
import { Link } from 'react-router-dom';
import DateFilter from './DateFilter';
import CostsEdit from './edit/CostsEdit';
//import Cookies from 'js-cookie';
import { Form } from "react-bootstrap";
import { faPlus, faEraser, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as globalConst from '../../global/const';
import BackIcon from '../../image/backArrow.png';
import CostsRemoveMyListModal from './CostsRemoveMyListModal';
import AddToMyListModal from './AddToMyListModal';
import ConfirmRemoveCustomListModal from './ConfirmRemoveCustomListModal';

// SCSS
import '../../css/sales/sales.scss'
import Notifications from '../../Notifications';
import ExportReport from '../Export/ExportReport';
import { ExportAccountingSales, ExportAccountingWorkflowCost } from '../actions/ExportReportSales';

const CancelToken = axios.CancelToken;
let cancel;

class CostsList extends Component {

    constructor(props) {
        super(props)
        const isAuth = Auth.getIsAuth()
        if (isAuth === false) {
            props.history.push('/')
        }

        var addToMyListSelectedSales = [];
        addToMyListSelectedSales = [];

        const order_ref = new URLSearchParams(this.props.location.search).get("order-ref");

        this.state = {
            accounting: [],
            loading_page: false,
            selected_accounting: null,
            selected_accounting_index: null,
            total_results: 0,
            api_has_more: false,
            sheetType: "ACTIVE",
            accounting_to_edit: null,
            show_create_accounting_modal: false,
            show_information_accounting_modal: false,
            show_remove_my_list_modal: false,
            show_confirm_remove_custom_list_modal: false,
            search: ((order_ref !== null) ? order_ref : ""),
            addToMyListSelectedSales: addToMyListSelectedSales,
            addToMyListSelectedSalesSortedBy: "",
            addToMyListSelectedSalesSortedType: "DESC",
            currentTab: "results",
            myListTabs: [],
            newTabName: "",
            show_add_to_my_list_modal: false,
            selectedTabToAdd: null,
            selectedTab: "",
            checkedSales: [],
            show_information_panel: true
        }

        this.resetFilters();
        this.filter_params['generalSearch'] = order_ref;
        if (this.filter_params['generalSearch'] === null) {
            this.filter_params['generalSearch'] = "";
        }

        this.openEditionComponent = this.openEditionComponent.bind(this)
        this.closeEditionComponent = this.closeEditionComponent.bind(this)
        this.closeCreateComponent = this.closeCreateComponent.bind(this)
        this.closeInformationComponent = this.closeInformationComponent.bind(this)
        this.closeCostsRemoveMyListModal = this.closeCostsRemoveMyListModal.bind(this)
        this.closeConfirmRemoveCustomListModal = this.closeConfirmRemoveCustomListModal.bind(this)
        this.addMyListTab = this.addMyListTab.bind(this)
        this.removeCurrentMyListTab = this.removeCurrentMyListTab.bind(this)
        this.handleNewTabNameChange = this.handleNewTabNameChange.bind(this)
        this.openAddToMyListModal = this.openAddToMyListModal.bind(this)
        this.closeAddToMyListModal = this.closeAddToMyListModal.bind(this)
        this.setTabToAdd = this.setTabToAdd.bind(this)
        this.saleIsChecked = this.saleIsChecked.bind(this)
        this.removeSaleFromCustomisedList = this.removeSaleFromCustomisedList.bind(this)
        this.handleSelectMyListTab = this.handleSelectMyListTab.bind(this)
        this.fetchAccountingDetailWithId = this.fetchAccountingDetailWithId.bind(this)

        this.optionsReports = [
            'Sales report',
            'Sale Workflow Costs'
        ]
    }

    filter_params = {}

    resetFilters = () => {
        this.filter_params = {
            page: 1,
            max_results: 100,
            order_by: 'created',
            order_type: 'DESC',
            id: this.filter_params["id"],
            generalSearch: this.filter_params["generalSearch"],
            isCosts: true,
        }
        Object.keys(this.filter_params).forEach(key => this.filter_params[key] === undefined ? delete this.filter_params[key] : {});
    }

    fetchMoreData = () => {
        this.filter_params['page'] = this.filter_params['page'] + 1
        this.fetchAccounting();
    }

    fetchAccounting() {
        this.setState(
            {
                loading_page: true
            }
        )

        if (cancel !== undefined) {
            cancel();
        }

        const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_ACCOUNTING_LIST
        axios.get(
            url,
            {
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
                params: this.filter_params,
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            })
            .then(api_response => {
                const result = api_response.data.result;
                const items = result.rows;

                let list = []
                if (this.filter_params.page === 1) {
                    list = items;
                    window.scrollTo({ top: 0 });
                }
                else {
                    let current_list = [
                        ...this.state.accounting
                    ]
                    list = current_list.concat(items);
                }


                this.setState({
                    accounting: list,
                    loading_page: false,
                    total_results: result.count,
                    api_has_more: result.amount_results > 0 ? true : false,
                })
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    return;
                }

                if (err.response) {
                    if ('status' in err.response && err.response.status === 401) {
                        alert('User not authorised. Please reload the page');
                    }
                    else {
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else {
                    this.setState(
                        {
                            loading_page: true
                        }
                    )
                }
                console.log(err);
            })
    }



    render() {
        return (
            <div id="SalesList_component">
                {
                    this.state.show_remove_my_list_modal === true ?
                        <CostsRemoveMyListModal
                            closeCostsRemoveMyListModal={this.closeCostsRemoveMyListModal}
                            uncheckAllMyList={this.uncheckAllMyList}
                        />
                        : (null)
                }
                {
                    this.state.show_confirm_remove_custom_list_modal === true ?
                        <ConfirmRemoveCustomListModal
                            closeConfirmRemoveCustomListModal={this.closeConfirmRemoveCustomListModal}
                            listName={this.state.currentTab}
                            removeCurrentMyListTab={this.removeCurrentMyListTab}
                        />
                        : (null)
                }
                {
                    this.state.accounting_to_edit !== null ?
                        <CostsEdit
                            accounting={this.state.accounting_to_edit}
                            closeEditionComponent={this.closeEditionComponent}
                            selected_accounting_index={this.state.selected_accounting_index}
                            openEditCloseConfirmationModal={this.openEditCloseConfirmationModal}
                        />
                        : (null)
                }
                {
                    this.state.show_information_accounting_modal === true ?
                        <CostsInformationModal
                            selected_accounting={this.state.selected_accounting}
                            openEditionComponent={this.openEditionComponent}
                            closeInformationComponent={this.closeInformationComponent}
                        />
                        : (null)
                }
                {
                    this.state.show_add_to_my_list_modal === true ?
                        <AddToMyListModal
                            closeAddToMyListModal={this.closeAddToMyListModal}
                            setTabToAdd={this.setTabToAdd}
                            myListTabs={this.state.myListTabs}
                        />
                        : (null)
                }
                <div className="container_sales">
                    {this.state.loading_page === true ? <LoadingLayer /> : ('')}
                    {this.state.loading_accounting_excel === true ? <LoadingLayer /> : ('')}
                    {this.state.loading_excel === true ? <LoadingLayer /> : ('')}
                    <div className="list_and_information">
                        <div className='row'>
                            <div className="sales_list">
                                <div className="header">
                                    <div className="row header_tools">
                                        <div className="">
                                            <div className="col-12">
                                                <div className='link_to_dashboard'>
                                                    <Link to="/dashboard">
                                                        <img height="50px" src={BackIcon} alt="" />
                                                    </Link>
                                                </div>
                                                <div className='db-top-heading'>
                                                    <h2 className='sales-title'>
                                                        COSTS
                                                    </h2>
                                                    <Notifications />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='add_new_sale row-sales-controls'>
                                    <div className="customised-sales-controls">
                                        <div className="input-group add-new-tab">
                                            <input type="text" placeholder="Add new tab" className='form-control box-bg' value={this.state.newTabName} onChange={this.handleNewTabNameChange} />
                                        </div>
                                        <button className='btn btn-default' onClick={this.addMyListTab} disabled={(this.state.myListTabs.length >= 5) ? true : false}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </button>
                                        <button className='btn btn-default' onClick={this.openConfirmRemoveCustomListModal}>
                                            <FontAwesomeIcon icon={faEraser} />
                                        </button>
                                    </div>
                                    {(this.state.currentTab === "results") ? (
                                        <div>
                                            <InputGroup className='input-group-pink'>
                                                <Form.Control
                                                    placeholder="Search..."
                                                    value={this.state.search}
                                                    onChange={(e) => this.handleFilterChange(e, 'generalSearch')}
                                                />
                                                <InputGroup.Text id="basic-addon2">
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </InputGroup.Text>
                                            </InputGroup>

                                            <ExportReport
                                                options={this.optionsReports}
                                                onResponse={this.downloadReport.bind(this)}
                                            />
                                        </div>
                                    ) : (null)}
                                </div>
                                <Tabs defaultActiveKey="results" activeKey={this.state.currentTab} id="SalesListTabs" className="border-none" onSelect={this.handleSelect}>
                                    <Tab eventKey="results" title={"All Results (" + this.state.total_results + ")"} >

                                        <InfiniteScroll
                                            dataLength={this.state.accounting.length}
                                            next={this.fetchMoreData}
                                            hasMore={this.state.api_has_more}
                                            height={'calc(100vh - 150px)'}
                                        >
                                            <table className="table table-striped table-sm table-hover" id="table_sales">
                                                <thead className="thead-dark">
                                                    <tr className='titles'>
                                                        <th className="saleDate" onClick={this.sortDateOfSale}>
                                                            Date
                                                            {
                                                                this.filter_params.order_by === "created"
                                                                    ? (this.filter_params.order_type === "DESC" ? " ▼" : " ▲")
                                                                    : (null)
                                                            }
                                                        </th>
                                                        <th className="addToMyList">
                                                            My List
                                                        </th>
                                                        <th className="name">
                                                            Name
                                                        </th>
                                                        <th className="onlineOrderNumber">
                                                            Order Number
                                                        </th>
                                                        <th className="orderStatus">
                                                            Order Status
                                                        </th>
                                                        <th className="saleType">
                                                            Type of Product
                                                        </th>
                                                        <th className="typeOfService">
                                                            Type of Service
                                                        </th>
                                                        <th className="diamondDetails">
                                                            Diamond's info
                                                        </th>
                                                        <th className="manufacturingStockNumber">
                                                            Manufacturing Stock Number
                                                        </th>
                                                        <th className="manufacturingCost">
                                                            Manufacturing Cost
                                                        </th>
                                                        <th className="melesCost">
                                                            Meles Cost
                                                        </th>
                                                        <th className="costReady">
                                                            Cost Ready
                                                        </th>
                                                        <th className="accountingStatus">
                                                            Accounting Status
                                                        </th>
                                                    </tr>
                                                    <tr className='filters'>
                                                        <th className="saleDate">
                                                            <DateFilter
                                                                handleSaleMinDateFilter={this.handleSaleMinDateFilter}
                                                                handleSaleMaxDateFilter={this.handleSaleMaxDateFilter}
                                                            />
                                                        </th>
                                                        <th className="addToMyList">
                                                            <select value={this.state.selectedTab} onChange={(e) => this.handleSelectMyListTab(e)} className='select-box'>
                                                                <option value=""></option>
                                                                {
                                                                    this.state.myListTabs.map((row, index) => {
                                                                        return (
                                                                            <option key={index} value={!!row.name ? row.name : ""}>{!!row.name ? row.name : ""}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            <div className=''>
                                                                <a className='formatLink check-all-link' onClick={(e) => this.checkAll(e)}>Check all</a>
                                                            </div>
                                                        </th>
                                                        <th className="name">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'name')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptyName"
                                                                    id={"filterEmptyName"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'nameEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                        <th className="onlineOrderNumber">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'onlineOrderNumber')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptyOnlineOrderNumber"
                                                                    id={"filterEmptyOnlineOrderNumber"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'onlineOrderNumberEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                        <th className="status">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'status')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptyOrderStatus"
                                                                    id={"filterEmptyOrderStatus"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'orderStatusEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                        <th className="saleType">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'saleType')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptySaleType"
                                                                    id={"filterEmptySaleType"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'saleTypeEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                        <th className="typeOfService">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'typeOfService')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptyTypeOfService"
                                                                    id={"filterEmptyTypeOfService"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'typeOfServiceEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                        <th className="diamondDetails">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'diamondDetails')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptyDiamondDetails"
                                                                    id={"filterEmptyDiamondDetails"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'diamondDetailsEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                        <th className="manufacturingStockNumber">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'manufacturingStockNumber')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptyManufacturingStockNumber"
                                                                    id={"filterEmptyManufacturingStockNumber"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'manufacturingStockNumberEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                        <th className="manufacturingCost">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'manufacturingCost')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptyManufacturingCost"
                                                                    id={"filterEmptyManufacturingCost"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'manufacturingCostEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                        <th className="melesCost">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'melesCost')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptyMelesCost"
                                                                    id={"filterEmptyMelesCost"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'melesCostEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                        <th className="costReady">
                                                            <Form.Select
                                                                onChange={(e) => this.handleFilterChange(e, 'costReady')}
                                                                name="costReady">
                                                                <option value=""></option>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </Form.Select>
                                                        </th>
                                                        <th className="accountingStatus">
                                                            <input type="text" onChange={(e) => this.handleFilterChange(e, 'accountingStatus')} />
                                                            <div className='form-check custom-from-check'>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    name="filterEmptyAccountingStatus"
                                                                    id={"filterEmptyAccountingStatus"}
                                                                    onChange={(e) => this.handleFilterChange(e, 'accountingStatusEmpty')}
                                                                />
                                                                <span className='empty-text'>Empty</span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.accounting.map((accounting, index) => {
                                                            let isChecked = this.saleIsChecked(accounting.id);
                                                            return (
                                                                <CostsRow
                                                                    ref={"saleRow" + accounting.id}
                                                                    key={accounting.id}
                                                                    accounting={accounting}
                                                                    index={index}
                                                                    handleRowSelection={this.handleRowSelection}
                                                                    handleChangeCheckbox={this.handleChangeCheckbox}
                                                                    selected_accounting={this.state.selected_accounting}
                                                                    addToMyList={isChecked}
                                                                    currentTab={this.state.currentTab}
                                                                    selectedTabToAdd={this.state.selectedTabToAdd}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </Tab>
                                    {
                                        this.state.myListTabs.map((tab, index) => {
                                            let tabCheckedSales = this.state.myListTabs.filter(function (item) { return item.name === tab.name; })
                                            let tabLength = 0
                                            try {
                                                if (typeof tabCheckedSales[0] !== "undefined") {
                                                    if (typeof tabCheckedSales[0].salesIds !== "undefined") {
                                                        if (tabCheckedSales[0].salesIds !== null) {
                                                            tabLength = JSON.parse(tabCheckedSales[0].salesIds).length
                                                        }
                                                    }
                                                }
                                            }
                                            catch (e) { }
                                            return (
                                                <Tab eventKey={tab.name} title={tab.name + " (" + tabLength + ")"} key={"tab" + index} >
                                                    <MyListTab
                                                        checkedSales={tabCheckedSales}
                                                        key={tab.name}
                                                        index={index}
                                                        name={tab.name}
                                                        selected_accounting={this.state.selected_accounting}
                                                        removeSaleFromCustomisedList={this.removeSaleFromCustomisedList}
                                                        fetchSaleDetailWithId={this.fetchAccountingDetailWithId}
                                                    />
                                                </Tab>
                                            )
                                        })
                                    }
                                </Tabs>
                            </div>
                            {
                                (this.state.show_information_panel)
                                    ? (
                                        <CostsInformation
                                            accounting={this.state.selected_accounting}
                                            openEditionComponent={this.openEditionComponent}
                                        />
                                    ) : (null)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    downloadReport = async (value) => {
        this.setState({
            loading_page: true
        })

        if (value === this.optionsReports[0]) {
            await ExportAccountingSales(this.filter_params, value)
        }

        if (value === this.optionsReports[1]) {
            await ExportAccountingWorkflowCost(this.filter_params, value)
        }

        this.setState({
            loading_page: false
        })
    }

    resetInformation = () => {
        this.setState({
            highlight_row: false,
            show_information_panel: false
        });

        setTimeout(() => {
            this.setState({
                selected_accounting: null,
                show_information_panel: true
            });
        }, 1);
    }

    sortDateOfSale = () => {
        if (typeof this.filter_params.order_type !== 'undefined') {
            if (this.filter_params.order_type === 'DESC') {
                this.filter_params.order_type = 'ASC';
            }
            else {
                this.filter_params.order_type = 'DESC';
            }
        }
        this.filter_params.page = 1;
        this.filter_params.max_results = 100;
        this.filter_params.order_by = 'created';
        this.fetchAccounting();
    }

    sortDateOfSaleMyList = () => {
        let addToMyListSelectedSales = this.state.addToMyListSelectedSales;
        let addToMyListSelectedSalesSortedType = this.state.addToMyListSelectedSalesSortedType

        if (addToMyListSelectedSalesSortedType === "DESC") {
            addToMyListSelectedSalesSortedType = "ASC";
            addToMyListSelectedSales.sort(function (a, b) {
                var keyA = new Date(a.saleDate);
                var keyB = new Date(b.saleDate);

                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;

                return 0;
            });
        }
        else {
            addToMyListSelectedSalesSortedType = "DESC";
            addToMyListSelectedSales.sort(function (a, b) {
                var keyA = new Date(a.saleDate);
                var keyB = new Date(b.saleDate);

                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;

                return 0;
            });
        }

        this.setState({
            addToMyListSelectedSales: addToMyListSelectedSales,
            addToMyListSelectedSalesSortedType: addToMyListSelectedSalesSortedType,
            addToMyListSelectedSalesSortedBy: 'saleDate'
        });
    }

    sortDateCustomerNeedsByMyList = () => {
        let addToMyListSelectedSales = this.state.addToMyListSelectedSales;
        let addToMyListSelectedSalesSortedType = this.state.addToMyListSelectedSalesSortedType

        if (addToMyListSelectedSalesSortedType === "DESC") {
            addToMyListSelectedSalesSortedType = "ASC";
            addToMyListSelectedSales.sort(function (a, b) {
                var keyA = new Date(a.customerNeedsOn);
                var keyB = new Date(b.customerNeedsOn);

                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;

                return 0;
            });
        }
        else {
            addToMyListSelectedSalesSortedType = "DESC";
            addToMyListSelectedSales.sort(function (a, b) {
                var keyA = new Date(a.customerNeedsOn);
                var keyB = new Date(b.customerNeedsOn);

                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;

                return 0;
            });
        }

        this.setState({
            addToMyListSelectedSales: addToMyListSelectedSales,
            addToMyListSelectedSalesSortedType: addToMyListSelectedSalesSortedType,
            addToMyListSelectedSalesSortedBy: 'customerNeedsOn'
        });
    }

    closeEditionComponent = (edited_sale, edited_post_sale, selected_accounting_index, no_changes = false) => {
        if (!no_changes) {
            this.fetchAccounting()

            const accounting_list = this.state.accounting
            let selected_accounting = this.state.selected_accounting

            if (edited_sale !== null) {
                accounting_list[selected_accounting_index]["salesModel"] = edited_sale
                selected_accounting = accounting_list[selected_accounting_index]
            }
            if (edited_post_sale !== null) {
                accounting_list[selected_accounting_index]["postSaleServiceModel"] = edited_post_sale
                selected_accounting = accounting_list[selected_accounting_index]
            }

            this.setState(
                {
                    myListTabs: [],
                }, this.getcustomisedList()
            )

            this.setState(
                {
                    accounting: accounting_list,
                    selected_accounting: selected_accounting
                }
            )
        }
        this.setState(
            {
                accounting_to_edit: null,
            }
        )
    }

    openEditionComponent = (accounting_to_edit) => {
        this.setState(
            {
                accounting_to_edit: accounting_to_edit
            }
        )
    }

    closeCreateComponent = () => {
        this.setState(
            {
                show_create_accounting_modal: false
            }
        )
    }

    openCreateComponent = () => {
        this.setState(
            {
                show_create_accounting_modal: true
            }
        )
    }

    closeInformationComponent = () => {
        this.setState(
            {
                show_information_accounting_modal: false
            }
        )
    }

    openInformationComponent = () => {
        this.setState(
            {
                show_information_accounting_modal: true
            }
        )
    }

    closeCostsRemoveMyListModal = () => {
        this.setState(
            {
                show_remove_my_list_modal: false
            }
        )
    }

    openCostsRemoveMyListModal = () => {
        this.setState(
            {
                show_remove_my_list_modal: true
            }
        )
    }

    closeConfirmRemoveCustomListModal = () => {
        this.setState(
            {
                show_confirm_remove_custom_list_modal: false
            }
        )
    }

    openConfirmRemoveCustomListModal = () => {
        if (this.state.currentTab === "results") {
            alert("To delete a list, first choose the “Tab” you want to delete.")
        }
        else {
            this.setState(
                {
                    show_confirm_remove_custom_list_modal: true
                }
            )
        }
    }

    addMyListTab() {
        this.createcustomisedList();
    }

    removeCurrentMyListTab() {
        this.removecustomisedList();
        this.closeConfirmRemoveCustomListModal()
        window.location.reload()
    }

    createcustomisedList() {
        const user = Auth.getAuthenticatedUser()

        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_CREATE
        const params = new URLSearchParams()
        params.append('name', this.state.newTabName)
        params.append('employeeId', user.employeeModel.id)
        params.append('listType', 'costs')

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${Auth.getToken()}`
            },
        }

        axios.post(
            url,
            params,
            config
        )
            .then(
                (response) => {
                    const data = response.data
                    if (data !== undefined && data.code === 200) {
                        console.log("Customised sale created");
                        this.getcustomisedList()
                        this.setState(
                            {
                                newTabName: "",
                            }
                        )
                    }
                    else {
                        console.log('There was a problem processing the information. Please reload this page and try again.');
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        console.log(err.response)
                        if (err.response.data.error === "No name defined") {
                            alert("No name defined")
                        }
                        if (err.response.data.error === "Tab name already exists") {
                            alert("Tab name already exists")
                        }
                    } else if (err.request) {
                        console.log(err.request)
                    } else {
                        console.log(err)
                    }
                    this.setState({
                        loading_excel: false
                    })
                    return null;
                }
            )
    }

    removecustomisedList() {
        const user = Auth.getAuthenticatedUser()

        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_DELETE
        const params = new URLSearchParams()
        params.append('name', this.state.currentTab)
        params.append('employeeId', user.employeeModel.id)

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${Auth.getToken()}`
            },
        }

        axios.put(
            url,
            params,
            config
        )
            .then(
                (response) => {
                    const data = response.data
                    if (data !== undefined && data.code === 200) {
                        console.log("Customised sale deleted");
                        this.getcustomisedList()
                        this.setState(
                            {
                                currentTab: "results"
                            }
                        )
                    }
                    else {
                        console.log('There was a problem processing the information. Please reload this page and try again.');
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        console.log(err.response)
                    } else if (err.request) {
                        console.log(err.request)
                    } else {
                        console.log(err)
                    }
                    this.setState({
                        loading_excel: false
                    })
                    return null;
                }
            )
    }

    removeSaleFromCustomisedList(saleId, checkedSales) {
        if (this.state.currentTab !== "results" ||
            (this.state.currentTab === "results" && (this.state.selectedTabToAdd !== null && this.state.selectedTabToAdd !== ""))) {
            let newSalesIds = checkedSales

            let tabName
            if (this.state.currentTab !== "results") {
                tabName = this.state.currentTab
            }
            else {
                tabName = this.state.selectedTabToAdd
            }

            const user = Auth.getAuthenticatedUser()

            let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_UPDATE
            const params = new URLSearchParams()
            params.append('name', tabName)
            params.append('employeeId', user.employeeModel.id)
            params.append('salesIds', JSON.stringify(newSalesIds))

            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${Auth.getToken()}`
                },
            }

            axios.put(
                url,
                params,
                config
            )
                .then(
                    (response) => {
                        const data = response.data
                        if (data !== undefined && data.code === 200) {
                            this.getcustomisedList()
                            this.resetFilters()
                            this.setState(
                                {
                                    sales: []
                                },
                                this.getCheckedSales(null, true)
                            )
                        }
                        else {
                            console.log('There was a problem processing the information. Please reload this page and try again.');
                        }
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            console.log(err.response)
                        } else if (err.request) {
                            console.log(err.request)
                        } else {
                            console.log(err)
                        }
                        return null;
                    }
                )
        }
    }

    handleSaleMinDateFilter = (date) => {
        if (date !== null && date !== '') {
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const year = date.getFullYear()
            this.filter_params.saleMinDate = year + '-' + month + '-' + day
        }
        else {
            this.filter_params.saleMinDate = null
        }
        this.fetchAccounting()

        this.resetInformation();
    }

    handleSaleMaxDateFilter = (date) => {
        this.filter_params.page = 1
        if (date !== null && date !== '') {
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const year = date.getFullYear()
            this.filter_params.saleMaxDate = year + '-' + month + '-' + day
        }
        else {
            this.filter_params.saleMaxDate = null
        }
        this.fetchAccounting()

        this.resetInformation();
    }

    checkAll = (e) => {
        if (this.state.selectedTabToAdd === null || this.state.selectedTabToAdd === "") {
            e.preventDefault()
            alert("You need to select a personalised list from the dropdown to add elements to it.")
        }
        else {
            let newCheckedSales = []
            this.state.accounting.map((el) => (
                newCheckedSales.push(el.id)
            ))
            this.setState(
                {
                    checkedSales: newCheckedSales,
                    myListTabs: []
                },
                this.removeSaleFromCustomisedList(null, newCheckedSales)
            )
        }
    }

    handleFilterChange = (e, field_name) => {
        let filter_value = e.target.value;
        let execute_filter = false;

        if (e.target.type === 'checkbox') {
            field_name = field_name.replace("Empty", "")
            if (typeof this.filter_params[field_name] === "undefined") {
                this.filter_params[field_name] = "";
            }
            if (e.target.checked === true) {
                this.filter_params[field_name] = this.filter_params[field_name] + "&empty_filter";
                execute_filter = true;
            }
            else {
                this.filter_params[field_name] = this.filter_params[field_name].replace("&empty_filter", "");
                execute_filter = true;
            }
        }
        else {
            if (field_name === 'generalSearch') {
                this.setState({
                    search: e.target.value
                })
            }
            if (
                field_name === 'id' ||
                field_name === 'name' ||
                field_name === 'onlineOrderNumber' ||
                field_name === 'status' ||
                field_name === 'saleType' ||
                field_name === 'typeOfService' ||
                field_name === 'diamondDetails' ||
                field_name === 'manufacturingStockNumber' ||
                field_name === 'manufacturingCost' ||
                field_name === 'melesCost' ||
                field_name === 'costReady' ||
                field_name === 'accountingStatus' ||
                field_name === 'generalSearch'
            ) {
                if (typeof this.filter_params[field_name] !== "undefined") {
                    if (this.filter_params[field_name] === "") {
                        this.filter_params[field_name] = filter_value;
                    }
                    else {
                        let temp_field_params = this.filter_params[field_name].replace("&empty_filter", "");
                        this.filter_params[field_name] = this.filter_params[field_name].replace(temp_field_params, filter_value);
                    }
                }
                else {
                    this.filter_params[field_name] = filter_value;
                }
                execute_filter = true;
            }
        }
        this.filter_params['page'] = 1;
        if (execute_filter === true) {
            this.fetchAccounting();
        }

        this.resetInformation();
    }

    handleNewTabNameChange(e) {
        this.setState({
            newTabName: e.target.value,
        })
    }

    uncheckAllMyList = () => {
        try {
            for (var i = 0; i < this.state.addToMyListSelectedSales.length; i++) {
                if (this.state.addToMyListSelectedSales[i] !== null) {
                    if (typeof this.refs["saleRow" + this.state.addToMyListSelectedSales[i].id] !== "undefined") {
                        this.refs["saleRow" + this.state.addToMyListSelectedSales[i].id].setState({
                            addToMyList: false
                        })
                    }
                }
            }

            this.setState({
                addToMyListSelectedSales: [],
                show_remove_my_list_modal: false
            })
        }
        catch (err) {
        }
    }

    handleSheetTypeChange = (event) => {
        if (event.target.value !== this.filter_params.sheetType) {
            this.resetFilters()
            this.filter_params.sheetType = event.target.value
            this.fetchAccounting()
        }
    }

    async fetchCheckedSales() {
        var addToMyListSelectedSales = [];
        let tempAddToMyListIds = {}

        Object.keys(tempAddToMyListIds).forEach(index => {
            if (this.ajaxRequestForDetail !== null) {
                this.ajaxRequestForDetail.cancel();
            }

            this.ajaxRequestForDetail = axios.CancelToken.source();

            let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_ACCOUNTING_DETAIL
            url = url.replace("{id}", tempAddToMyListIds[index])

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${Auth.getToken()}` }
                },
                {
                    cancelToken: this.ajaxRequestForDetail.token
                }
            )
                .then(
                    api_response => {
                        let saleTemp = api_response.data.result;
                        addToMyListSelectedSales.push(saleTemp);
                        this.setState(
                            {
                                addToMyListSelectedSales: addToMyListSelectedSales
                            }
                        )
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            if ('status' in err.response && err.response.status === 401) {
                                alert('User not authorised. Please reload the page');
                            }
                            else {
                                alert('There was a problem processing the information. Please reload this page and try again.');
                            }
                            this.setState(
                                {
                                    loading_page: false
                                }
                            )
                        } else if (err.request) {
                            alert('There was a problem with your request. Please reload this page and try again.');
                            this.setState(
                                {
                                    loading_page: false
                                }
                            )
                        } else {
                            this.setState(
                                {
                                    loading_page: true
                                }
                            )
                        }
                        console.log(err);
                        return null;
                    }
                )

        });
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount() {
        //execute once the first time
        this.fetchAccounting();
        this.getcustomisedList();
        this.getCheckedSales();
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

    handleRowSelection = (index) => {
        const accounting = this.state.accounting[index]
        const accounting_id = accounting.id;
        this.fetchAccountingDetailWithId(accounting_id, index)
        /*if (window.innerWidth <= 600) {
            this.openInformationComponent()
        }*/
    }

    /*handleRowSelectionMyList = (index) => {
        const sale = this.state.addToMyListSelectedSales[index]
        const sale_id = sale.id;
        this.fetchAccountingDetailWithId(sale_id, index)
        if (window.innerWidth <= 600) {
            this.openInformationComponent()
        }
    }*/

    handleChangeCheckbox = (index, state) => {
        const accounting = this.state.accounting[index];
        var accounting_id = accounting.id;
        /*const typeOfService = accounting.typeOfService;

        if (typeOfService === "Post Sale Service") {
            accounting_id += "p";
        }*/

        var newCheckedSales = this.state.checkedSales

        if (this.state.checkedSales.includes(accounting_id)) {
            newCheckedSales = newCheckedSales.filter(function (e) { return e !== accounting_id })
        }
        else {
            newCheckedSales.push(accounting_id)
        }

        this.setState({
            checkedSales: newCheckedSales,
            myListTabs: []
        }, this.removeSaleFromCustomisedList(accounting_id, newCheckedSales));
    }

    handleChangeCheckboxMyList = (index, state) => {
        const sale = this.state.addToMyListSelectedSales[index];
        const sale_id = sale.id;


        let temp = this.state.addToMyListSelectedSales.filter(function (addToMyListSale) {
            return addToMyListSale.id !== sale_id;
        });
        this.setState({
            addToMyListSelectedSales: temp
        });
        let tempIds = [];
        Object.keys(temp).forEach(index => {
            tempIds.push(temp[index].id);
        });

        try {
            this.refs["saleRow" + sale_id].setState({
                addToMyList: false
            })

            setTimeout(
                function () {
                    this.handleAddToMyList(sale.id);
                }
                    .bind(this),
                5000
            );
        }
        catch (err) {

        }

        setTimeout(
            function () {
                this.handleAddToMyList(sale.id);
            }
                .bind(this),
            5000
        );
    }

    handleSelect = (key) => {
        this.setState({
            currentTab: key
        })
    }

    handleSelectMyListTab = (event) => {
        this.setState({
            selectedTab: event.target.value
        })
        this.setTabToAdd(event.target.value)
    }

    handleAddToMyList = (sale_id) => {
        for (var i = 0; i < this.state.addToMyListSelectedSales.length; i++) {
            if (this.state.addToMyListSelectedSales[i] !== null) {
                if (this.state.addToMyListSelectedSales[i].id === sale_id) {
                    return true;
                }
            }
        }
        return false;
    }

    openAddToMyListModal() {
        this.setState(
            {
                show_add_to_my_list_modal: true
            }
        )
    }

    closeAddToMyListModal() {
        this.setState(
            {
                show_add_to_my_list_modal: false
            }
        )
    }

    setTabToAdd(selectedTab) {
        this.setState(
            {
                selectedTabToAdd: selectedTab
            }
        )
        if (selectedTab === "" || selectedTab === null) {
            this.resetFilters()
            this.setState(
                {
                    checkedSales: [],
                    accounting: []
                },
                this.fetchAccounting()
            )
        }
        else {
            this.resetFilters()
            this.setState(
                {
                    checkedSales: [],
                    accounting: []
                },
                this.getCheckedSales(selectedTab, true)
            )
        }
    }

    getcustomisedList() {
        const user = Auth.getAuthenticatedUser()

        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_LIST
        const params = new URLSearchParams()
        params.append('employeeId', user.employeeModel.id)
        params.append('listType', "costs")

        axios.get(
            url,
            {
                params: params,
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        )
            .then(
                (response) => {
                    const data = response.data
                    if (data !== undefined && data.code === 200) {
                        var tabs = [];
                        data.result.rows.map((tab) => {
                            tabs.push({ name: tab.name, salesIds: tab.salesIds, created: tab.created });

                            return null
                        })
                        tabs.sort((a, b) => (a.created.localeCompare(b.created)))
                        this.setState(
                            {
                                myListTabs: tabs
                            }
                        )

                    }
                    else {
                        console.log('There was a problem processing the information. Please reload this page and try again.');
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        console.log(err.response)
                    } else if (err.request) {
                        console.log(err.request)
                    } else {
                        console.log(err)
                    }
                    this.setState({
                        loading_excel: false
                    })
                    return null;
                }
            )
    }

    getCheckedSales(selectedTab = null, fetchSales = false) {
        var tabName = selectedTab;
        if (tabName === null) {
            tabName = this.state.selectedTabToAdd;
        }
        if (tabName !== null) {

            const user = Auth.getAuthenticatedUser()

            let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_DETAIL
            const params = new URLSearchParams()
            params.append('name', tabName)
            params.append('employeeId', user.employeeModel.id)

            axios.get(
                url,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${Auth.getToken()}` }
                }
            )
                .then(
                    (response) => {
                        const data = response.data
                        if (data !== undefined && data.code === 200) {
                            if ("salesIds" in data.result) {
                                this.resetFilters()
                                if (Array.isArray(JSON.parse(data.result.salesIds))) {
                                    var checkedSales = JSON.parse(data.result.salesIds);
                                    this.setState(
                                        {
                                            checkedSales: checkedSales
                                        },
                                        fetchSales ? this.fetchAccounting() : null
                                    )
                                }
                                else {
                                    this.setState(
                                        {
                                            checkedSales: []
                                        },
                                        fetchSales ? this.fetchAccounting() : null
                                    )
                                }
                            }
                        }
                        else {
                            console.log('There was a problem processing the information. Please reload this page and try again.');
                        }
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            console.log(err.response)
                        } else if (err.request) {
                            console.log(err.request)
                        } else {
                            console.log(err)
                        }
                        this.setState({
                            loading_excel: false
                        })
                        return null;
                    }
                )
        }
    }

    saleIsChecked(saleId) {
        try {
            return this.state.checkedSales.includes(saleId);
        }
        catch (e) {
            console.log(e);
            return false;
        }
    }

    ajaxRequestForDetail = null;
    fetchAccountingDetailWithId(accounting_id, selected_accounting_index) {
        this.setState(
            {
                loading_page: true,
                show_information_panel: false
            }
        )

        if (this.ajaxRequestForDetail !== null) {
            this.ajaxRequestForDetail.cancel();
        }

        this.ajaxRequestForDetail = axios.CancelToken.source();

        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_ACCOUNTING_DETAIL
        url = url.replace("{id}", accounting_id)

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            },
            {
                cancelToken: this.ajaxRequestForDetail.token
            }
        )
            .then(
                api_response => {
                    const selected_accounting = api_response.data.result
                    this.setState({
                        selected_accounting: selected_accounting,
                        selected_accounting_index: selected_accounting_index,
                        loading_page: false,
                        show_information_panel: true
                    })
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            alert('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                        this.setState(
                            {
                                loading_page: false
                            }
                        )
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                        this.setState(
                            {
                                loading_page: false
                            }
                        )
                    } else {
                        this.setState(
                            {
                                loading_page: true
                            }
                        )
                    }
                    console.log(err);
                    return null;
                }
            )
    }

    getTabCheckedSale(tabName) {
        return tabName;
    }
}

export default CostsList;