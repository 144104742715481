import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row } from 'react-bootstrap'
import { GetSaleDiamondDetail } from '../../../detail/Actions/GetSaleDiamonds'
import { CalculateValueDiscountPercentage, CalculateValueDiscountPrice } from '../../Acctions/SaleDiamondCostCalculate'
import Util from '../../../../../Util'
import FormInputText from '../../../../Form/FormInputText'

const SOLD_PRICE = 'soldPrice'
const DISCOUNT_PERCENTAGE = 'discountPercentage'
const DISCOUNT_PRICE = 'discountPrice'

const FieldsSaleDiamondCost = props => {
    const { diamond, sale, index, onResponse } = props

    const [diamondData, setDiamondData] = useState(diamond)

    const responseChange = (value, name) => {
        onResponse(
            value,
            name,
            index
        )
    }

    const timeAwait = (time = 1) => {
        return setTimeout(async () => { }, time)
    }

    const onChangeInput = async (value, name) => {
        if (value.length <= 0) {
            value = ''
        }

        setDiamondData(prevState => ({
            ...prevState,
            [name]: value
        }))

        if (
            name === DISCOUNT_PERCENTAGE ||
            name === SOLD_PRICE
        ) {
            onChangeDiscountPrice(diamond)
        }

        if (name === DISCOUNT_PRICE) {
            onChangeDiscountPercentage(diamond)
        }

        responseChange(value, name)
    }

    const onChangeDiscountPrice = async (diamond) => {
        await timeAwait()

        const result = CalculateValueDiscountPrice(diamond)
        
        setDiamondData(prevState => ({
            ...prevState,
            [DISCOUNT_PRICE]: result
        }))

        diamond[DISCOUNT_PRICE] = result

        setDiamondData(diamond)

        responseChange(result, DISCOUNT_PRICE)
    }

    const onChangeDiscountPercentage = async (diamond) => {
        await timeAwait()

        const result = CalculateValueDiscountPercentage(diamond)
        
        setDiamondData(prevState => ({
            ...prevState,
            [DISCOUNT_PERCENTAGE]: result
        }))

        diamond[DISCOUNT_PERCENTAGE] = result

        setDiamondData(diamond)

        responseChange(result, DISCOUNT_PERCENTAGE)
    }

    return (
        <>
            <Col lg="12">
                <b>
                    {
                        GetSaleDiamondDetail(
                            diamond?.diamondProductModel?.certNo,
                            sale,
                            true
                        )
                    }
                </b>
                &nbsp;
                <Form.Text muted>
                    (
                    Total price{' '}
                    <b>
                        {
                            Util.numberDecimal(
                                diamond.soldPrice - diamond.discountPrice
                            )
                        }
                    </b>
                    )
                </Form.Text>
            </Col>
            <Col lg="12" className='mt-2'>
                <Row>
                    <Col sm="12" lg="4">
                        <FormInputText
                            onlyNumber={true}
                            label="Sold Price *"
                            nameInput="soldPrice"
                            value={diamondData.soldPrice ?? ''}
                            onResponse={onChangeInput}
                            columnsSize={"12"}
                            required={true}
                        />
                    </Col>
                    <Col sm="12" lg="4">
                        <FormInputText
                            onlyNumber={true}
                            label="Discount Percentage %"
                            nameInput="discountPercentage"
                            value={diamondData.discountPercentage ?? ''}
                            onResponse={onChangeInput}
                            columnsSize={"12"}
                            required={false}
                        />
                    </Col>
                    <Col sm="12" lg="4">
                        <FormInputText
                            onlyNumber={true}
                            label="Discount Price"
                            nameInput="discountPrice"
                            value={diamondData.discountPrice ?? ''}
                            onResponse={onChangeInput}
                            columnsSize={"12"}
                            required={false}
                        />
                    </Col>
                </Row>
            </Col>
        </>
    )
}

FieldsSaleDiamondCost.propTypes = {
    diamond: PropTypes.any.isRequired,
    sale: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    onResponse: PropTypes.func.isRequired,
}

export default FieldsSaleDiamondCost