import { Form, FormLabel, Button } from "react-bootstrap"
import React, { Component } from 'react';
import LoadingLayer from '../../LoadingLayer'
import FlashMessages from "../../FlashMessages";
import Auth from "../../../Auth";
import axios from 'axios'
import * as globalConst from '../../../global/const';

// SCSS
import '../../../css/sales/sale_edit.scss'

class PostSaleRequestEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sale: props.sale,
            sheetType: props.sale.sheetType,
            error_messages: [],
            warning_messages: [],
            success_messages: [],
            updating_sale: false,
            fields_no_updated: true,
            name: props.sale.name,
            phone: props.sale.phone,
            email: props.sale.email,
            typeOfJob: props.sale.typeOfJob,
            requestMethod: props.sale.requestMethod,
            pickupAddress: props.sale.pickupAddress,
            dropOffAddress: props.sale.dropOffAddress,
            requestDescription: props.sale.requestDescription,
        }
        this.editSale = this.editSale.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

        this.loadListInformation()
    }

    loadListInformation = () => {
        this.listRequestMethod = process.env.REACT_APP_REQUEST_METHOD.split(',')
        this.listTypeJob = process.env.REACT_APP_TYPE_JOB.split(',')
    }

    handleChange(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        if (event.target.value !== this.state[field_name]) {
            state["fields_no_updated"] = false
            this.props.handleTabChange("request", true)
        }
        this.setState(state)
    }

    handleChangeSelect(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        if (event.target.value !== this.state[field_name]) {
            state["fields_no_updated"] = false
            this.props.handleTabChange("request", true)
        }
        this.setState(state)
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        return (
            <div id="SaleGeneralEdit_component">
                {
                    this.state.updating_sale === true ?
                        (
                            <LoadingLayer />
                        )
                        :
                        ('')
                }
                {
                    <FlashMessages
                        error_messages={this.state.error_messages}
                        warning_messages={this.state.warning_messages}
                        success_messages={this.state.success_messages}
                    />
                }
                <Form>
                    <div className="row">
                        <div className="col-6">
                            <Form.Group>
                                <FormLabel>Name</FormLabel>
                                <Form.Control
                                    required={true}
                                    type="text"
                                    name="name"
                                    value={this.state.name === null ? '' : this.state.name}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <FormLabel>Phone</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={this.state.phone === null ? '' : this.state.phone}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <FormLabel>Email</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={this.state.email === null ? '' : this.state.email}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <FormLabel className="d-block">Type of Job</FormLabel>
                                <Form.Select
                                    onChange={(e) => this.handleChangeSelect(e)}
                                    value={this.state.typeOfJob ?? ''}
                                    name="typeOfJob"
                                >
                                    <option value=""></option>
                                    {
                                        this.listTypeJob.map(listTypeJob => {
                                            return (
                                                <option
                                                    key={listTypeJob}
                                                    value={listTypeJob}>
                                                    {listTypeJob}
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <FormLabel className="d-block">Request Method</FormLabel>
                                <Form.Select
                                    onChange={(e) => this.handleChangeSelect(e)}
                                    value={this.state.requestMethod ?? ''}
                                    name="requestMethod"
                                >
                                    <option value=""></option>
                                    {
                                        this.listRequestMethod.map(listRequestMethod => {
                                            return (
                                                <option
                                                    key={listRequestMethod}
                                                    value={listRequestMethod}>
                                                    {listRequestMethod}
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <FormLabel>Pickup Address</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="pickupAddress"
                                    value={this.state.pickupAddress === null ? '' : this.state.pickupAddress}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <FormLabel>Drop off Address</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="dropOffAddress"
                                    value={this.state.dropOffAddress === null ? '' : this.state.dropOffAddress}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <FormLabel>Request Description</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="requestDescription"
                                    value={this.state.requestDescription === null ? '' : this.state.requestDescription}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <Button
                        type="submit"
                        onClick={this.editSale}
                        disabled={this.state.creating_sale}
                    >
                        Update
                    </Button>
                </Form>
            </div>
        )
    }

    axioRequest = null;
    editSale(event) {
        event.preventDefault();
        this.setState({
            updating_sale: true
        })
        const user = Auth.getAuthenticatedUser()
        const sale = this.props.sale
        if (user === null || user === undefined || user.employeeModel === null || user.employeeModel === undefined) {
            this.setState(
                {
                    error_messages: ['Authentication error. Reload the page and try again.']
                }
            )
        }
        else if (sale === null || sale === undefined) {
            this.setState(
                {
                    error_messages: ['Sale identification error. Reload the page and try again.']
                }
            )
        }
        else {
            let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_EDIT
            url = url.replace("{id}", sale.id)
            const params = new URLSearchParams()

            let saleId = this.state.sale.id;
            let name = this.state.name;
            if (name === null || typeof name === 'undefined') {
                name = this.state.sale.name
            }
            let phone = this.state.phone;
            if (phone === null || typeof phone === 'undefined') {
                phone = this.state.sale.phone
            }
            let email = this.state.email;
            if (email === null || typeof email === 'undefined') {
                email = this.state.sale.email
            }
            let typeOfJob = this.state.typeOfJob;
            if (typeOfJob === null || typeof typeOfJob === 'undefined') {
                typeOfJob = this.state.sale.typeOfJob
            }
            let requestMethod = this.state.requestMethod;
            if (requestMethod === null || typeof requestMethod === 'undefined') {
                requestMethod = this.state.sale.requestMethod
            }
            let pickupAddress = this.state.pickupAddress;
            if (pickupAddress === null || typeof pickupAddress === 'undefined') {
                pickupAddress = this.state.sale.pickupAddress
            }
            let dropOffAddress = this.state.dropOffAddress;
            if (dropOffAddress === null || typeof dropOffAddress === 'undefined') {
                dropOffAddress = this.state.sale.dropOffAddress
            }
            let requestDescription = this.state.requestDescription;
            if (requestDescription === null || typeof requestDescription === 'undefined') {
                requestDescription = this.state.sale.requestDescription
            }

            params.append('id', saleId)
            params.append('typeOfJob', typeOfJob)
            params.append('name', name)
            params.append('phone', phone)
            params.append('email', email)
            params.append('pickupAddress', pickupAddress)
            params.append('dropOffAddress', dropOffAddress)
            params.append('requestDescription', requestDescription)
            params.append('requestMethod', requestMethod)

            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }

            axios.put(
                url,
                params,
                config
            )
                .then(
                    (response) => {
                        const data = response.data
                        if (data !== undefined && data.code === 200) {
                            this.setState(
                                {
                                    updating_sale: false,
                                    success_messages: ['Post sale updated']
                                }
                            )
                            this.props.handleTabChange("request", false)
                            this.props.saleUpdated(data.result)
                        }
                        else {
                            this.setState(
                                {
                                    updating_sale: false,
                                    error_messages: ['There was a problem processing the information. Please reload this page and try again.']
                                }
                            )
                        }
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            let error_messages = []
                            if ('status' in err.response && err.response.status === 401) {
                                error_messages = ['Not authorised.']
                            }
                            else {
                                error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                            }
                            this.setState(
                                {
                                    updating_sale: false,
                                    error_messages: error_messages
                                }
                            )
                        } else if (err.request) {
                            const error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                            this.setState(
                                {
                                    updating_sale: false,
                                    error_messages: error_messages
                                }
                            )
                        } else {
                            //error not detected. Request could have been cancelled and it's waiting for second
                            //request to finish
                            this.setState(
                                {
                                    updating_sale: true
                                }
                            )
                        }
                        //console.log((err);
                        return null;
                    }
                )
        }
    }

    updateGeneralTabSheetType(sheetType) {
        this.setState({
            sheetType: sheetType
        })
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount() {
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************


}

export default PostSaleRequestEdit;