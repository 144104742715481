export const API_STATUS_IDLE = "idle";
export const API_STATUS_PENDING = "pending";
export const API_STATUS_SUCCESS = "success";
export const API_STATUS_FAILED = "failed";

export const CUSTOMERS_LIST_TYPE_DEFAULT = "default";
export const CUSTOMERS_LIST_TYPE_SEARCH = "search";
export const CUSTOMERS_LIST_TYPE_COL_SEARCH = "column_search";

export const CUSTOMERS_TYPE_ORIGIN = "origin";
export const CUSTOMERS_TYPE_DESTINATION = "destination";
export const WORKFLOW_RETURN_TYPE_REMAKING = "Re-making";
export const WORKFLOW_RETURN_TYPE_REFUND = "Refund";
export const WORKFLOW_RETURN_TYPE_EXCHANGE = "Exchange";

export const STATUS_COLLECTED_SHIPPED = "Collected/Shipped";
export const STATUS_RETURNED = "Returned";
export const STATUS_CANCELLED = "Cancelled";
export const STATUS_PENDING_APPROVAL = "Pending for approval";

export const CONST_CUSTOMER = {
  REPORTS: {
    resultSales: "resultSales",
    resultAppointments: "resultAppointments",
    resultCommissionsSales: "resultCommissionsSales",
    resultCommissionsReviews: "resultCommissionsReviews",
  },
  TYPES: process.env.REACT_APP_CUSTOMER_APPOINTMENT_STATUS.split(","),
};

export const DOWNLOAD_CHOICE_REPORTS = ['Appointments Efficiency']
