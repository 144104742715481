import React, { useEffect, useState } from "react";
import ReportsHeader from "./components/header";
import { Accordion, ListGroup } from "react-bootstrap";
import "../../css/reports/reports.scss";
import { useDispatch } from "react-redux";
import { clearData, selectReport } from "../../store/slices/reportsSlice";
import MainReports from "./main";
import Auth from "../../Auth";
import { CONST_CUSTOMER } from "../../store/constants";

export const Reports = () => {
  const dispatch = useDispatch();

  const leaders = Auth.canAccessRolesAndLeader("rolesEditReports", true);
  const roleSales = Auth.canAccessRolesAndLeader("rolesIsSales");
  const roleAccounting = Auth.canAccessRolesAndLeader("accountingStatus");

  const [report, setReport] = useState(CONST_CUSTOMER.REPORTS.resultSales);
  const [showTime, setShowTime] = useState(true);

  const handlerReport = async (value) => {
    await setShowTime(false);
    await setReport(value);

    setShowTime(true);
  };

  useEffect(() => {
    dispatch(selectReport(report));

    return () => {
      dispatch(clearData());
    };
  }, [dispatch, report]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 text-white text-center p-0">
          <ReportsHeader />
        </div>
      </div>

      <div className="customer row mt-3 p-0 full-screen-height">
        <div className="col-md-3 border full-screen-height p-0">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Reports</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {(leaders || roleSales || roleAccounting) && (
                    <ListGroup.Item
                      active={CONST_CUSTOMER.REPORTS.resultSales === report}
                      className="add-task-button"
                      to="#"
                      onClick={() =>
                        handlerReport(CONST_CUSTOMER.REPORTS.resultSales)
                      }
                    >
                      Sales
                    </ListGroup.Item>
                  )}
                  {(leaders || roleSales) && (
                    <ListGroup.Item
                      active={
                        CONST_CUSTOMER.REPORTS.resultAppointments === report
                      }
                      className="add-task-button"
                      to="#"
                      onClick={() =>
                        handlerReport(CONST_CUSTOMER.REPORTS.resultAppointments)
                      }
                    >
                      Appointments
                    </ListGroup.Item>
                  )}
                  {(leaders || roleSales || roleAccounting) && (
                    <ListGroup.Item
                      active={
                        CONST_CUSTOMER.REPORTS.resultCommissionsSales === report
                      }
                      className="add-task-button"
                      to="#"
                      onClick={() =>
                        handlerReport(CONST_CUSTOMER.REPORTS.resultCommissionsSales)
                      }
                    >
                      Commissions for sales
                    </ListGroup.Item>
                  )}
                  {(leaders || roleSales || roleAccounting) && (
                    <ListGroup.Item
                      active={
                        CONST_CUSTOMER.REPORTS.resultCommissionsReviews === report
                      }
                      className="add-task-button"
                      to="#"
                      onClick={() =>
                        handlerReport(CONST_CUSTOMER.REPORTS.resultCommissionsReviews)
                      }
                    >
                      Commissions for reviews 
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="col-md-9 full-screen-height">
          {showTime && <MainReports />}
        </div>
      </div>
    </div>
  );
};
