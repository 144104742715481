import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ConfirmRemoveFavoriteFilters extends Component {

    constructor(props) {
        super();

        this.state = {
            removeMultiple: props.removeMultiple,
            showModal: props.showModal
        }

    }

    render() {
        return (
            <Modal
                show={this.state.showModal}
                centered
            >
                <Modal.Header>
                    Are you sure to remove the personal filters?
                </Modal.Header>
                <Modal.Body>
                    <Button
                        onClick={() => this.responseRemoveFilter(true)}
                        className="m-2"
                        variant="outline-primary">
                        Yes
                    </Button>
                    <Button
                        onClick={() => this.responseRemoveFilter(false)}
                        variant="outline-secondary"
                        className='m-2'>
                        No
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.responseRemoveFilter(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    responseRemoveFilter = (value) => {
        this.props.parentConfirmRemoveItem(value);
    }
}

export default ConfirmRemoveFavoriteFilters;