import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Table } from "react-bootstrap";
import Auth from "../../../../Auth";

export default function ListFavouriteFilter({advanceFilterChanged}) {
    const access = Auth.canAccessRolesAndLeader("rolesAccessMyFollowUps");
    const rendeTable = () => {
        return (
            <div className='mt-2'>
                <Table striped bordered hover className='overflow-hidden'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Apply</th>
                        </tr>
                    </thead>
                    <tbody>
                        { access &&
                            <tr>
                                <td>
                                    My follow ups
                                </td>
                                <td>
                                    <button style={{ zIndex: '9999' }}
                                        className='btn btn-primary btn-novita-square-pink'
                                            onClick={() => {
                                                advanceFilterChanged();
                                            }}>
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </button>
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <Row lg="12" className='overflow-hidden'>
            <Col lg="12">
                {
                    rendeTable()
                }
            </Col>
        </Row>
    )
}