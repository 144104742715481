import React, {Component} from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';
import Auth from "../../Auth";
import axios from 'axios';
import * as globalConst from '../../global/const';

// SCSS
import '../../css/sales/sale_create.scss'

class SaleEditionUnarchiveCompletedModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            
        }
        this.closeModal = this.closeModal.bind(this)
    }

    closeModal(){
        this.props.closeSaleEditionUnarchiveCompletedModal()
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        return(
            <div id="SaleEditionRequirementModal_component">
                <Modal 
                    show={true}
                    onHide={this.closeModal}
                    dialogClassName="modal-90w"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        Confirm
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className='form-group'>
                                <FormLabel>Are you sure that you want to update the event as not completed?</FormLabel>
                            </Form.Group>
                            <Button 
                                type="button" className='btn btn-default confirm-btn'
                                onClick={this.unarchiveCompleted}
                            >
                                Yes
                            </Button>
                            <Button className='btn btn-default disagree-btn'
                                type="button"
                                onClick={this.closeModal}
                            >
                                No
                            </Button>
                        </Form>



                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    unarchiveCompleted = () => {
        let sale_edition_id = this.props.sale_edition_unarchive_completed_selected_id;
        let sale_edition_tasks = this.props.sale_edition_unarchive_completed_selected_item_tasks;

        let requiresAction = null;
        if (sale_edition_tasks !== null) {
            if (sale_edition_tasks.length > 0) {
                requiresAction = true;
            }
        }

        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALE_EDITION_REQUIREMENT_EDIT
        url = url.replace("{id}", sale_edition_id)
        const params = new URLSearchParams()
        params.append('requiresAction', requiresAction)
        
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }

        axios.put(
            url, 
            params, 
            config
        )
        .then(
            api_response => {
                const data = api_response.data
                if(data !== undefined && data.code === 200){
                    this.props.updateSaleEditRequirementsList()
                    this.props.closeSaleEditionUnarchiveCompletedModal()
                }
                else{
                    alert('error')
                }
            }
        )
        .catch(
            err => {
                if (err.response) {
                    if('status' in err.response && err.response.status === 401){
                        alert('User not authorised. Please reload the page');
                    }
                    else{
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                }
                //console.log(err);
                return null;
            }
        )
    }

    handleChange(event){
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        console.log(state);
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    

}

export default SaleEditionUnarchiveCompletedModal;