import React, { useState } from "react";

const CustomerComment = ({ comment }) => {
  const [commentDate, setCommentDate] = useState(null);
  useState(() => {
    if (comment.created !== null) {
      setCommentDate(new Date(comment.created));
    }
  }, [comment.created]);

  return (
    <div key={comment.id} className="SaleComment_component">
      <div className="row">
        <div className="col-12 mb-2">
          <div className="sale_comment_subject">
            <b>{comment.topic}</b>
          </div>
        </div>
        <div className="col-12 mb-2">
          <div className="sale_comment_text">{comment.text}</div>
        </div>
        <div className="col-6  mb-2">
          <div className="sale_comment_employee">
            <b>
              {" "}
              Added By:
              {comment.employee !== null && comment.employee.name !== undefined
                ? comment.employee.name
                : ""}
            </b>
          </div>
        </div>
        <div className="col-6  mb-2 text-right">
          <div className="sale_comment_created">
            <b>
              {commentDate !== null ? (
                <span>
                  {commentDate.toLocaleString("default", {
                    day: "numeric",
                    timeZone: "Australia/Sydney",
                  })}{" "}
                  {commentDate.toLocaleString("default", {
                    month: "short",
                    timeZone: "Australia/Sydney",
                  })}
                  ,{" "}
                  {commentDate.toLocaleString("default", {
                    year: "numeric",
                    timeZone: "Australia/Sydney",
                  })}{" "}
                  -{" "}
                  {commentDate.toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                    timeZone: "Australia/Sydney",
                  })}
                </span>
              ) : (
                ""
              )}
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerComment;
