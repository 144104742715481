import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import Util from "../../../Util";
import { CONST_CUSTOMER } from "../../../store/constants";

const CUSTOMER_PLACE = CONST_CUSTOMER.TYPES[1];
const CUSTOMER_NOT_BUY = CONST_CUSTOMER.TYPES[2];
const FOLLOWING_UP = CONST_CUSTOMER.TYPES[3];

const PieChartSaleReport = ({ data }) => {
  let totalAppointments = 0;
  let totalBuy = 0;
  let totalFollowingUp = 0;

  data.map((item) => {
    totalAppointments += parseInt(item.total);
    totalBuy += parseInt(item.ca_sale);
    totalFollowingUp += parseInt(item.ca_follow_up);

    return item;
  });

  const totalNotBuy = totalAppointments - (totalBuy + totalFollowingUp);

  const totals = {
    totalCustomerPlaceOrder: totalBuy,
    totalCustomerNotBuy: totalNotBuy,
    totalCustomerFollowingUp: totalFollowingUp,
  };

  const {
    totalCustomerPlaceOrder = 0,
    totalCustomerNotBuy = 0,
    totalCustomerFollowingUp = 0,
  } = totals;

  const totalPercentageBuy = (totalBuy * 100) / totalAppointments;
  const totalPercentageNotBuy = (totalNotBuy * 100) / totalAppointments;
  const totalPercentageFollowingUp =
    (totalFollowingUp * 100) / totalAppointments;

  const percentages = {
    percentageCustomerPlaceOrder: totalPercentageBuy,
    percentageCustomerNotBuy: totalPercentageNotBuy,
    percentageCustomerFollowingUp: totalPercentageFollowingUp,
  };

  let {
    percentageCustomerPlaceOrder = 0,
    percentageCustomerNotBuy = 0,
    percentageCustomerFollowingUp = 0,
  } = percentages;

  percentageCustomerPlaceOrder = Util.numberDecimal(
    percentageCustomerPlaceOrder
  );
  percentageCustomerNotBuy = Util.numberDecimal(percentageCustomerNotBuy);
  percentageCustomerFollowingUp = Util.numberDecimal(
    percentageCustomerFollowingUp
  );

  const graphs = {
    labels: [
      `${CUSTOMER_PLACE} ${totalCustomerPlaceOrder}`,
      `${CUSTOMER_NOT_BUY} ${totalCustomerNotBuy}`,
      `${FOLLOWING_UP} ${totalCustomerFollowingUp}`,
    ],
    datasets: [
      {
        label: "Percentage %",
        data: [
          percentageCustomerPlaceOrder,
          percentageCustomerNotBuy,
          percentageCustomerFollowingUp,
        ],
        backgroundColor: [
          "rgba(0, 255, 0, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 128, 0, 0.2)",
        ],
        borderColor: [
          "rgba(0, 255, 0, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 128, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Card className="char-pie-sales">
      <Card.Body>
        <Card.Title>
          <Row>
            <Col xs={12} lg={6}>
              <h2>Sales Efficiency</h2>
            </Col>
            <Col xs={12} lg={6}>
              {totalCustomerFollowingUp +
                totalCustomerNotBuy +
                totalCustomerPlaceOrder}
              &nbsp; Appointments Found
            </Col>
          </Row>
        </Card.Title>
        <Row className="center-element">
          <Col style={{ maxWidth: "70%" }}>
            {Object.keys(data).length > 0 && <Pie data={graphs} />}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

PieChartSaleReport.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PieChartSaleReport;
