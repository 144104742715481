import React, {Component} from 'react';
import { Modal } from 'react-bootstrap'
import CostsInformation from './CostsInformation';

// SCSS
import '../../../css/sales/sale_edit.scss'

class CostsInformationModal extends Component {

    constructor(props){
        super(props);
        this.state = {
        }
        this.closeModal = this.closeModal.bind(this)
    }

    closeModal(){
        this.props.closeInformationComponent()
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        return(
                <Modal 
                    show={true}
                    onHide={this.closeModal}
                    dialogClassName="modal-90w"
                >
                    <Modal.Header closeButton>
                        Accounting Information
                    </Modal.Header>
                    <Modal.Body>   
                        <CostsInformation
                            sale = {this.props.selected_sale}
                            openEditionComponent = {this.props.openEditionComponent}
                        />
                    </Modal.Body>
                </Modal>
        )
    }

}

export default CostsInformationModal;