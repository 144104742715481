import axios from "axios";
import { sprintf } from "sprintf-js";
import * as globalConst from "../../../../global/const";
import Auth from "../../../../Auth";
import { getSaleReturnData, saleIsReturn } from "./SaleRetutn";

export const workflowReturnPrepareData = (workflowReturnModel) => {
  let workflowReturn = Object.assign({}, workflowReturnModel);

  if (typeof workflowReturn.stepOneQuestions === "string") {
    workflowReturn.stepOneQuestions = JSON.parse(
      workflowReturn.stepOneQuestions
    );

    workflowReturn.stepOneQuestions.reason = workflowReturn.reason;
  }

  if (typeof workflowReturn.stepTwoQuestions === "string") {
    workflowReturn.stepTwoQuestions = JSON.parse(
      workflowReturn.stepTwoQuestions
    );
  }

  if (typeof workflowReturn.stepThreeQuestions === "string") {
    workflowReturn.stepThreeQuestions = JSON.parse(
      workflowReturn.stepThreeQuestions
    );
  }

  return workflowReturn;
};

export const getInformationWorkflowReturn = async (sale) => {
  if (!saleIsReturn(sale)) {
    return;
  }

  const getSaleReturn = getSaleReturnData(sale);

  const url = sprintf(
    "%s%s%s",
    process.env.REACT_APP_NOVITAERP_API_DOMAIN,
    globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_RETUNR_SALE_ORIGIN,
    getSaleReturn.originalSaleId
  );

  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${Auth.getToken()}` },
  });

  if (response.status === 200) {
    const { data = {} } = response;
    const { value = null } = data;

    let workflowReturn = {};

    if (value) {
      workflowReturn = workflowReturnPrepareData(value);
    }

    if (!workflowReturn.stepTwoQuestions) {
      workflowReturn.stepTwoQuestions = {
        valueCostsGeneratedReturn: 0,
        descriptionCostsGeneratedReturn: "",
      };
    }

    return workflowReturn;
  }

  return {
    stepTwoQuestions: {
      valueCostsGeneratedReturn: 0,
      descriptionCostsGeneratedReturn: "",
    },
  };
};
