import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppointments,
  updateAppointments,
} from "../../../../store/slices/customerSlice";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { CONST_CUSTOMER } from "../../../../store/constants";
import { dateFormatCustom } from "../../../../utils/dateUtils";
import moment from "moment";
import { fetchEmployees } from "../../../../store/slices";
import FormDatePicker from "../../../Form/FormDatePicker";
import FormSelectMultiple from "../../../Form/FormSelectMultiple";
import Auth from "../../../../Auth";

const APPOINTMENT_FRIST = "1st";
const APPOINTMENT_NUMBER = "appointmentNumber";

const AddAppointmentsForm = ({
  show,
  onHide,
  customer,
  editingAppointment,
}) => {
  const { employees } = useSelector((state) => state.employees);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const showroomCityOptions =
    process.env.REACT_APP_SHOWROOM_CITY_OPTIONS.split(",");
  const listBookingMethods = process.env.REACT_APP_BOOKING_METHODS.split(",");
  const listAppointments = process.env.REACT_APP_APPOINTMENTS.split(",");
  const listLookingFors = process.env.REACT_APP_PRODUCT_TYPE.split(",");
  const listWhoCame =
    process.env.REACT_APP_CUSTOMER_APPOINTMENT_WHO_CAME.split(",");
  const listAppoinmentTypes =
    process.env.REACT_APP_TYPE_CUSTOMER_APPOINMENT.split(",");
  const listCustomerAppointments = CONST_CUSTOMER.TYPES;

  const dispatch = useDispatch();

  const { customerAppointments } = customer;

  const customerAppointmentsFilter = customerAppointments.filter(
    (item) => item.id !== editingAppointment?.id
  );

  let validShowPrevius = false;
  let employeeNames = "";

  if (editingAppointment) {
    if (
      editingAppointment[APPOINTMENT_NUMBER] &&
      APPOINTMENT_FRIST !== editingAppointment[APPOINTMENT_NUMBER]
    ) {
      validShowPrevius = true;
    }

    const { customerEmployees = [] } = editingAppointment;

    customerEmployees.forEach((customerEmployee) => {
      if (employeeNames.length > 0) {
        employeeNames += ",";
      }

      employeeNames += customerEmployee.employee.name;
    });
  }

  let appointmentDate = new Date();

  if (editingAppointment?.appointmentDate) {
    appointmentDate = moment(
      editingAppointment?.appointmentDate,
      "YYYY/MM/DD HH:mm"
    );
  }

  const [showAppointmentPrevius, setShowAppointmentPrevius] =
    useState(validShowPrevius);
  const [validated, setValidated] = useState(false);

  const [appointment, setAppointment] = useState({
    customerId: customer.id,
    showroomCity: editingAppointment?.showroomCity ?? "",
    bookingRequestMethod: editingAppointment?.bookingRequestMethod ?? "",
    lookingFor: editingAppointment?.lookingFor ?? "",
    whoCame: editingAppointment?.whoCame ?? "",
    isZoom: editingAppointment?.isZoom ?? "",
    appointmentDate: appointmentDate,
    appointmentNumber: editingAppointment?.appointmentNumber ?? 0,
    previousAppointmentId: editingAppointment?.previousAppointmentId ?? null,
    status: editingAppointment?.status ?? "",
    type: editingAppointment?.type ?? "",
    employeeNames: employeeNames,
  });

  useEffect(() => {
    if (editingAppointment) {
      setAppointment({ ...editingAppointment, customerId: customer.id });
    }

    if (employeeNames && !editingAppointment.employeeNames) {
      setAppointment({ ...editingAppointment, employeeNames: employeeNames });
    }
  }, [customer.id, editingAppointment, employeeNames]);

  useEffect(() => {
    if (employees.length <= 0) {
      dispatch(fetchEmployees({ flag: true }));
    } else {
      const employeeNames = employees.map((employee) => {
        return employee.name;
      });

      setSelectedEmployees(employeeNames);
    }
  }, [dispatch, employees]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidated(false);
    const form = e.currentTarget;
    const check = form.checkValidity();

    if (!check) {
      setValidated(true);
      return;
    }

    appointment.employeeIds = [];

    if (appointment.employeeNames) {
      appointment.employeeNames = appointment.employeeNames.split(",");

      appointment.employeeNames.map((employeeSelected) => {
        employees.map((employee) => {
          if (employee.name === employeeSelected) {
            appointment.employeeIds.push(employee.id);
          }
          return null;
        });
        return null;
      });

      appointment.employeeNames = appointment.employeeNames.join(",");
    }

    if (editingAppointment) {
      dispatch(
        updateAppointments({
          ...appointment,
          id: editingAppointment.id,
        })
      );
    } else {
      dispatch(addAppointments(appointment));
    }
  };

  const onChangeSelect = (event) => {
    const { value, name } = event.target;

    setAppointment({
      ...appointment,
      [name]: value,
    });

    if (name === APPOINTMENT_NUMBER) {
      activeAppointmentPrevius(value);
    }

    changeValuePreviousAppointment(value);
  };

  const changeValuePreviousAppointment = (value) => {
    if (!value || value === APPOINTMENT_FRIST) {
      setAppointment({
        ...appointment,
        previousAppointmentId: null,
        appointmentNumber: value,
      });
    }
  };

  const activeAppointmentPrevius = (value) => {
    setShowAppointmentPrevius(false);

    if (value && value !== APPOINTMENT_FRIST) {
      setShowAppointmentPrevius(true);
    }
  };

  const changeSelectMultiple = (selected, name) => {
    if (selected === appointment[name]) {
      return;
    }

    setAppointment({
      ...appointment,
      [name]: selected,
    });
  };

  const onChangeDate = (value, name) => {
    if (appointment[name] === value) {
      return;
    }

    setAppointment({
      ...appointment,
      [name]: value,
    });
  };

  const accesEditSalesRepresentative = () => {
    const access = Auth.canAccessRolesAndLeader("rolesAccessCRMCreate", false);
    const accessOperation = Auth.canAccessRolesAndLeader(
      "rolesAccessCRMOperation",
      false
    );

    if (access || accessOperation) {
      return false;
    }

    return true;
  };

  const messageTimezone = process.env.REACT_APP_MESSAGE_TIMEZONE;

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {editingAppointment ? "Edit Appointment" : "Add Appointment"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group controlId="validationName">
                <Form.Label>Sales Representative *</Form.Label>
                <FormSelectMultiple
                  options={selectedEmployees}
                  onChange={changeSelectMultiple}
                  nameField="employeeNames"
                  data={appointment.employeeNames ?? ""}
                  labelKey={"name"}
                  disabled={accesEditSalesRepresentative()}
                />
                <Form.Control
                  name="employeeNames"
                  hidden={true}
                  value={appointment.employeeNames ?? ""}
                  onChange={(e) => {
                    setAppointment({
                      ...appointment,
                      showroomCity: e.target.value,
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  <ul>
                    <li>The Sales representative is required</li>
                  </ul>
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group
                className="d-flex align-items-center mt-2"
                controlId="customerThereWasSale"
              >
                <Form.Check
                  type="checkbox"
                  name="isZoom"
                  checked={appointment.isZoom}
                  onChange={(e) => {
                    setAppointment({
                      ...appointment,
                      isZoom: e.target.checked,
                    });
                  }}
                />
                <Form.Label className="mb-0 mr-2 ms-2">Is Zoom</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group controlId="customerCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                  as="select"
                  name="city"
                  value={appointment.showroomCity ?? ""}
                  onChange={(e) => {
                    setAppointment({
                      ...appointment,
                      showroomCity: e.target.value,
                    });
                  }}
                >
                  <option value=""></option>
                  {showroomCityOptions.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mt-2" controlId="customerName">
                <Form.Label>Looking For</Form.Label>

                <FormSelectMultiple
                  required
                  options={listLookingFors}
                  onChange={changeSelectMultiple}
                  nameField="lookingFor"
                  data={appointment.lookingFor ?? ""}
                  labelKey={"name"}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group className="mt-2" controlId="customerName">
                <Form.Label>Who Came</Form.Label>
                <FormSelectMultiple
                  required
                  options={listWhoCame}
                  onChange={changeSelectMultiple}
                  nameField="whoCame"
                  data={appointment.whoCame ?? ""}
                  labelKey={"name"}
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mt-2" controlId="customerName">
                <Form.Label>Booking Request Method *</Form.Label>
                <FormSelectMultiple
                  required
                  options={listBookingMethods}
                  onChange={changeSelectMultiple}
                  nameField="bookingRequestMethod"
                  data={appointment.bookingRequestMethod ?? ""}
                  labelKey={"name"}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group
                controlId="customerAppointmentStatus"
                className="mt-2"
              >
                <Form.Label>Appointment status</Form.Label>
                <Form.Select
                  value={appointment.status ?? ""}
                  name="status"
                  onChange={(e) => onChangeSelect(e)}
                >
                  <option value=""></option>
                  {listCustomerAppointments.map((listCustomerAppointment) => {
                    return (
                      <option
                        key={listCustomerAppointment}
                        value={listCustomerAppointment}
                      >
                        {listCustomerAppointment}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group
                controlId="customerFirstAppointmentDate"
                className="mt-2"
              >
                <FormDatePicker
                  label={"Appointment Date (" + messageTimezone + ") *"}
                  nameInput="appointmentDate"
                  value={appointment.appointmentDate ?? new Date()}
                  onResponse={onChangeDate}
                  columnsSize={"12"}
                  required={true}
                  showTimeSelect={true}
                  format={"dd/MM/yyyy HH:mm"}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group
                controlId="customerFirstAppointmentNumber"
                className="mt-2"
              >
                <Form.Label>Appointment number *</Form.Label>
                <Form.Select
                  required
                  value={appointment.appointmentNumber ?? ""}
                  name="appointmentNumber"
                  onChange={(e) => onChangeSelect(e)}
                >
                  <option value=""></option>
                  {listAppointments.map((listAppointment) => {
                    return (
                      <option key={listAppointment} value={listAppointment}>
                        {listAppointment}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  The field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              {showAppointmentPrevius && (
                <Form.Group
                  className="mt-2"
                  controlId="customerAppointmentPreviousAppointment"
                >
                  <Form.Label>What was the previous appointment? *</Form.Label>
                  <Form.Select
                    required
                    value={appointment.previousAppointmentId ?? ""}
                    name="previousAppointmentId"
                    onChange={(e) => onChangeSelect(e)}
                  >
                    <option value=""></option>
                    {customerAppointmentsFilter.map((customerAppointment) => {
                      return (
                        <option
                          key={customerAppointment.id}
                          value={customerAppointment.id}
                        >
                          {dateFormatCustom(
                            customerAppointment.appointmentDate,
                            "DD MMM, YYYY"
                          )}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    The field is required
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <Form.Group className="mt-2" controlId="type">
                <Form.Label>Type *</Form.Label>
                <Form.Select
                  required
                  value={appointment.type ?? ""}
                  name="type"
                  onChange={(e) => onChangeSelect(e)}
                >
                  <option value=""></option>
                  {listAppoinmentTypes.map((listAppoinmentType) => {
                    return (
                      <option
                        key={listAppoinmentType}
                        value={listAppoinmentType}
                      >
                        {listAppoinmentType}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  The field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} lg={12}>
              <Form.Group className="mt-2">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  name="notes"
                  as="textarea"
                  value={appointment.notes ?? ""}
                  onChange={(e) => {
                    setAppointment({
                      ...appointment,
                      notes: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button className="mt-2" variant="primary" type="submit">
            {editingAppointment ? "Update" : "Add"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

AddAppointmentsForm.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  editingAppointment: PropTypes.object,
};

export default AddAppointmentsForm;
