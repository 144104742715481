import React, { Component } from 'react';
import DateField from '../../formComponents/DateField';
import { Form, FormLabel } from 'react-bootstrap';

// SCSS
import '../../../../css/sales/sale_edit.scss'

class SaleWorkflowEditPhotos extends Component {

    constructor(props) {
        super();
        this.state = {
            activity: props.activity,
            datePhotosRequested: props.activity.datePhotosRequested,
            photosCompleted: props.activity.photosCompleted,
        }

        this.state.datePhotosRequested = props.activity.datePhotosRequested 
            ? new Date(props.activity.datePhotosRequested) 
            : null

        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.handleChangeDateField = this.handleChangeDateField.bind(this)

    }

    render() {
        let datePhotosRequested = new Date(this.state.datePhotosRequested)

        return (
            <div className='sale_information_subcontainer'>
                <div className="container">

                    <Form.Group className="mb-3 form-group date-small">
                        <FormLabel>Date photos requested</FormLabel>
                        <DateField
                            handleChangeDateField={this.handleChangeDateField}
                            field_name='datePhotosRequested'
                            currentDate={this.state.datePhotosRequested}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            name="photosCompleted"
                            label="Photos completed"
                            checked={this.state.photosCompleted === true ? true : false}
                            onChange={this.handleChangeCheckbox}
                        />
                    </Form.Group>
                </div>
            </div>
        )
    }

    handleChangeCheckbox(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.checked
        this.setState(state)
    }

    handleChangeDateField = (date, field_name) => {
        const state = {}

        if (isNaN(Date.parse(date))) {
            state[field_name] = null
        }
        else {
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const year = date.getFullYear()
            const hours = date.getHours()
            const minutes = date.getMinutes()

            date = new Date(year, month - 1, day, hours, minutes)
            if (isNaN(Date.parse(date))) {
                state[field_name] = null
            }
            else {
                state[field_name] = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
            }
        }
        this.setState(state)
    }
}

export default SaleWorkflowEditPhotos;