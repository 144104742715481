import React, {Component} from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';

// SCSS
import '../../css/sales/sale_create.scss'

class AddToMyListModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            myListTabs: this.props.myListTabs,
            selectedTab: "",
        }
        this.closeModal = this.closeModal.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
    }

    closeModal(){
        this.props.closeAddToMyListModal()
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        return(
            <div id="SaleEditionRequirementModal_component">
                <Modal 
                    show={true}
                    onHide={this.closeModal}
                    dialogClassName="modal-90w"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        Confirm
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className='form-group'>
                                <FormLabel>Add the elements with the tick to the list:</FormLabel>
                            </Form.Group>
                            <div className="row">
                                <select value={this.state.selectedTab} onChange={(e) => this.handleSelect(e)} className='select-box'>
                                    <option value=""></option>
                                    {
                                        this.state.myListTabs.map((row, index) => {
                                            return(
                                                <option key={ index } value={ !!row.name ? row.name : "" }>{ !!row.name ? row.name : "" }</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="row">
                                <Button 
                                    type="button" className='btn btn-default confirm-btn'
                                    onClick={this.selectTabToAdd}
                                >
                                    Continue
                                </Button>
                            </div>
                        </Form>



                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    handleSelect = (event) => {
        this.setState({
            selectedTab: event.target.value
        })
    }

    selectTabToAdd = () => {
        console.log(this.state)
        this.props.setTabToAdd(this.state.selectedTab)
        this.closeModal()
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return{
            myListTabs: props.myListTabs,
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    

}

export default AddToMyListModal;