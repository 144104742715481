import axios from "axios";
import { sprintf } from "sprintf-js";
import * as globalConst from "../../global/const";
import Auth from "../../Auth";
import moment from "moment";

const CancelToken = axios.CancelToken;
let cancel;

export const ExportAccountingSales = async (filters, name) => {
  try {
    filters = ClearFilter(filters);

    const url = sprintf(
      "%s%s",
      process.env.REACT_APP_NOVITAERP_API_DOMAIN,
      globalConst.REACT_APP_NOVITAERP_API_ACCOUNTING_EXPORT_SALES
    );

    if (cancel !== undefined) {
      cancel();
    }

    return await axios
      .post(url, filters, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const { data } = response;

        DownloadField(data, name);
      })
      .catch(() => {
        return null;
      });
  } catch (error) {
    return null;
  }
};

export const ExportAccountingWorkflowCost = async (filters, name) => {
  try {
    filters = ClearFilter(filters);

    const url = sprintf(
      "%s%s",
      process.env.REACT_APP_NOVITAERP_API_DOMAIN,
      globalConst.REACT_APP_NOVITAERP_API_ACCOUNTING_EXPORT_WORKFLOW_COST
    );

    if (cancel !== undefined) {
      cancel();
    }

    return await axios
      .post(url, filters, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const { data } = response;

        DownloadField(data, name);
      })
      .catch(() => {
        return null;
      });
  } catch (error) {
    return [];
  }
};

export const ExportSaleCommissions = async (filters, name) => {
  try {
    filters = ClearFilter(filters);

    const url = sprintf(
      "%s%s",
      process.env.REACT_APP_NOVITAERP_API_DOMAIN,
      globalConst.REACT_APP_NOVITAERP_API_ACCOUNTING_EXPORT_SALE_COMMISSIONS
    );

    if (cancel !== undefined) {
      cancel();
    }

    return await axios
      .post(url, filters, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const { data } = response;

        DownloadField(data, name);
      })
      .catch(() => {
        return null;
      });
  } catch (error) {
    return [];
  }
};

export const ExportSaleCommissionsReviews = async (filters, name) => {
  try {
    filters = ClearFilter(filters);

    const url = sprintf(
      "%s%s",
      process.env.REACT_APP_NOVITAERP_API_DOMAIN,
      globalConst.REACT_APP_NOVITAERP_API_ACCOUNTING_EXPORT_SALE_COMMISSIONS_REVIEWS
    );

    if (cancel !== undefined) {
      cancel();
    }

    return await axios
      .post(url, filters, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const { data } = response;

        DownloadField(data, name);
      })
      .catch(() => {
        return null;
      });
  } catch (error) {
    return [];
  }
};

export const ClearFilter = (filters) => {
  delete filters.page;
  delete filters.order_type;
  delete filters.order_by;
  delete filters.max_results;

  return filters;
};

export const DownloadField = (data, name) => {
  let blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const nameFile = sprintf(
    "%s-%s.xlsx",
    name,
    moment(new Date()).format("YYYY-DD-MM-HH-mm-ss")
  );

  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", nameFile);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
