import moment from "moment";
import { useState } from "react";
import { AddPostSales } from "./add-post-sales";
import Auth from "../../../../Auth";
import { dateFormatCustom } from "../../../../utils/dateUtils";

export default function PostSalesServices({ customer }) {
  const accessInfo = Auth.canAccessRolesAndLeader("rolesAccessCRMSale");
  const [showAddPostSales, setshowAddPostSales] = useState(false);

  const openPostSale = (sale) => {
    if (accessInfo) {
      window.open(
        `/post_sale_services?order-ref=${sale.sale.onlineOrderNumber}`
      );
    }
  };

  const buildPostSalesService = () => {
    let components = [];
    for (let customerSale of customer.customerSales) {
      for (let service of customerSale.sale.postSaleServices) {
        components.push(
          <tr key={service.id} onClick={() => openPostSale(customerSale)}>
            <td>{dateFormatCustom(service.created, "DD MMM, YYYY")}</td>
            <td>{service.typeOfJob}</td>
          </tr>
        );
      }
    }
    return components;
  };

  const handleSelectedPostSale = () => {
    setshowAddPostSales(false);
  };

  return (
    <div className="general_information customer-details-card">
      <h2
        style={{ height: "42px" }}
        className="d-flex flex-row justify-content-between align-items-center"
      >
        <p className="align-self-center mt-3">Post Sales Services</p>
      </h2>
      <div className="customer-details-container p-2">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Created</th>
              <th scope="col">Type</th>
            </tr>
          </thead>
          <tbody id="sales_list_information">{buildPostSalesService()}</tbody>
        </table>
      </div>
      <AddPostSales
        show={showAddPostSales}
        onHide={(postSale) => {
          postSale
            ? handleSelectedPostSale(postSale)
            : setshowAddPostSales(false);
        }}
        customer={customer}
      />
    </div>
  );
}
