import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    Col,
    Form,
    FormLabel,
    Row
} from 'react-bootstrap'
import LoadingSpinnerLayer from '../../LoadingSpinnerLayer'
import { FindEmployeSales } from '../../actions/EmployeFind'
import FormSelectMultiple from '../../Form/FormSelectMultiple'

const SaleCreateSalesMarketingData = props => {
    const { saleData, onResponse } = props
    const [sale, setSale] = useState(saleData)
    const [employeeSales, setEmployeeSales] = useState([])

    useEffect(() => {
        if (employeeSales.length <= 0) {
            async function fetchData() {
                const employees = await FindEmployeSales()

                const employeesArray = employees.map(employee => {
                    return employee.name
                })

                setEmployeeSales(employeesArray)
            }

            fetchData()
        }
    })

    const soldFromList = process.env.REACT_APP_LIST_SOLD_FORM.split(',')

    const changeSelectMultiple = (selected, name) => {
        if (selected === sale[name]) {
            return
        }

        setSale(prevState => ({
            ...prevState,
            [name]: selected
        }))

        onResponse(selected, name)
    }

    return (
        <Card className='mt-2'>
            <Card.Body>
                <Card.Title>
                    Sales and Marketing Information
                </Card.Title>
                <Row>
                    <Col sm="6" lg="6">
                        <Form.Group className="form-group">
                            <FormLabel>Sold from</FormLabel>

                            <FormSelectMultiple
                                options={soldFromList}
                                onChange={changeSelectMultiple}
                                nameField='soldFrom'
                                data={sale.soldFrom ?? ''}
                                labelKey={'name'}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm="6" lg="6">
                        <Form.Group className="form-group">
                            <FormLabel>Sales Rep Name</FormLabel>
                            {
                                sale.loadDataEmployee ?
                                    <LoadingSpinnerLayer />
                                    :
                                    <FormSelectMultiple
                                        options={employeeSales}
                                        onChange={changeSelectMultiple}
                                        nameField='salesRepName'
                                        data={sale.salesRepName ?? ''}
                                        labelKey={'name'}
                                    />
                            }
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

SaleCreateSalesMarketingData.propTypes = {
    saleData: PropTypes.any.isRequired,
    onResponse: PropTypes.func.isRequired
}

export default SaleCreateSalesMarketingData