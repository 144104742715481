import React, { Component } from "react";
import Util from "../../../Util";
// SCSS

class SaleDetailGeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
    };
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const sale = this.state.sale;
    let saleDate = null;
    if (sale.saleDate !== null && sale.saleDate !== "") {
      saleDate = new Date(sale.saleDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZone: process.env.REACT_APP_TIMEZONE,
      });
      saleDate = saleDate.slice(0, -5) + ", " + saleDate.slice(-4);
    }

    return (
      <div id="SaleDetailGeneralInfo_component">
        <div className="sale_data">
          <h2 className="heading">GENERAL INFORMATION</h2>
          <div className="container mb-4">
            <div className="row">
              <div className="col-sm border-right bold">Unique #</div>
              <div className="col-sm">{sale.id}</div>
            </div>
            <div className="row">
              <div className="col-sm border-right bold">To be delivered to</div>
              <div className="col-sm">
                <div
                  dangerouslySetInnerHTML={{
                    __html: Util.getShippingAddressFromSale(sale),
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm border-right bold">Date of Sale</div>
              <div className="col-sm">
                {saleDate !== null ? (
                  <div className="saleDate">{saleDate}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm border-right bold">Name</div>
              <div className="col-sm">{sale.name}</div>
            </div>
            <div className="row">
              <div className="col-sm border-right bold">Phone</div>
              <div className="col-sm">{sale.phone}</div>
            </div>
            <div className="row">
              <div className="col-sm border-right bold">Email</div>
              <div className="col-sm">{sale.email}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleDetailGeneralInfo;
