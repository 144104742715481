import { faHandPointLeft } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerCard from "./customer-card";
import PostSalesServices from "./post-sales-services";
import CustomerSales from "./sales-card";
import CustomerComments from "./comments";
import { useSelector } from "react-redux";
import AppointmentInformation from "./appointments";
import EditIcon from "../../../image/Edit.png";
import { useEffect, useState } from "react";
import axiosServices from "../../../axios";
import FormCustomerDialog from "../edit";
import Auth from "../../../Auth";
import CustomerHistory from "./history";

const fetchCustomer = async (id) => {
  const response = await axiosServices.get(`/api/v1/customers/customer/${id}`);
  return response.data;
};

export default function CustomerInformation() {
  const { selectedCustomer: customer } = useSelector(
    (state) => state.customers
  );
  const [showEditCustomer, setshowEditCustomer] = useState(false);
  const [refreshedCustomer, setRefreshedCustomer] = useState(null);

  useEffect(() => {
    setRefreshedCustomer(null);
  }, [customer]);

  if (!customer) {
    return (
      <div id="SaleInformation_component">
        <div className="choose_message">
          Choose an item from the list to find more
          <br />
          <span>
            <FontAwesomeIcon icon={faHandPointLeft} />
          </span>
        </div>
      </div>
    );
  }

  if (!refreshedCustomer) {
    fetchCustomer(customer.id).then((data) => {
      setRefreshedCustomer(data);
    });
  }

  const editSale = () => {
    setshowEditCustomer(true);
  };

  if (!refreshedCustomer) {
    return (
      <div id="SaleInformation_component">
        <div className="choose_message">
          Choose an item from the list to find more
          <br />
          <span>
            <FontAwesomeIcon icon={faHandPointLeft} />
          </span>
        </div>
      </div>
    );
  }

  const accesEdit = () => {
    const access = Auth.canAccessRolesAndLeader("rolesAccessCRMCreate");

    const accessOperation = Auth.canAccessRolesAndLeader(
      "rolesAccessCRMOperation",
      true
    );

    if (access || accessOperation) {
      return true;
    }

    return false;
  };

  return (
    <div id="sales_card">
      <h1>{refreshedCustomer.name}</h1>
      <div className="d-flex flex-row">
        {accesEdit() && (
          <button className="btn" onClick={editSale}>
            EDIT Customer{" "}
            <img style={{ width: "40px" }} src={EditIcon} alt="" />
          </button>
        )}
      </div>
      <CustomerComments customer={refreshedCustomer} />
      <AppointmentInformation customer={refreshedCustomer} />
      <CustomerCard customer={refreshedCustomer} />
      <CustomerSales customer={refreshedCustomer} />
      <PostSalesServices customer={refreshedCustomer} />
      <CustomerHistory customer={refreshedCustomer} />
      {showEditCustomer && (
        <FormCustomerDialog
          customer={refreshedCustomer}
          show={showEditCustomer}
          onHide={() => {
            setshowEditCustomer(false);
          }}
        />
      )}
    </div>
  );
}
