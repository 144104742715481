import { faHandPointLeft } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentInfo from "./document-info";
import '../../../css/documentation/document_information.scss';
import { Figure } from "react-bootstrap";

export default function DocumentInformation({ document }) {
  if (document === null) {
    return (
      <div id="DocumentInformation_component">
        <div className="choose_message">
          Choose an item from the list to find more
          <br />
          <span>
            <FontAwesomeIcon icon={faHandPointLeft} />
          </span>
        </div>
      </div>
    );
  }

  return (
    <div id="DocumentInformation_component">
      <h1>{document.title}</h1>
      <Figure.Image
        className="pt-1 ps-1"
        width={50}
        height={60}
        alt={"Thumbnail"}
        src={document.thumbnail}
      />
      <DocumentInfo document={document} />
    </div>
  );
}
