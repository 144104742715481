import { Form, FormLabel, Button, Card, Col, Row } from "react-bootstrap";
import React, { Component } from "react";
import LoadingLayer from "../../LoadingLayer";
import FlashMessages from "../../FlashMessages";
import Auth from "../../../Auth";
import axios from "axios";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_edit.scss";
import Util from "../../../Util";
import { Typeahead } from "react-bootstrap-typeahead";

const SHIP_CUSTOMERS_ADDRESS = "shipCustomersAddress";
const PICK_UP_AT_SHOWROOM = "pickUpAtShowroom";

class SaleGeneralEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingAddress: props.sale.shippingAddress,
      shippingCountry: props.sale.shippingCountry,
      shippingPostCode: props.sale.shippingPostCode,
      shippingState: props.sale.shippingState,
      shippingSuburb: props.sale.shippingSuburb,
      shippingTo: Util.removeHTMLTags(props.sale.shippingTo),
      name: props.sale.name,
      phone: props.sale.phone,
      email: props.sale.email,
      sheetType: props.sale.sheetType,
      shipCustomersAddress: Util.isShippingAddress(props.sale),
      pickUpAtShowroom: Util.isShippingShowroom(props.sale),
      error_messages: [],
      warning_messages: [],
      success_messages: [],
      updating_sale: false,
      fields_no_updated: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.editSale = this.editSale.bind(this);

    this.listCountries = [];
    this.country = [];
    this.listShippingShowrooms = [];

    this.loadListInformation();
  }

  loadListInformation = () => {
    this.listShippingShowrooms =
      process.env.REACT_APP_LIST_SHIPPING_TO_SHOWROOM.split("~");

    this.listCountries = globalConst.LIST_COUNTRIES.map((country) => {
      return { value: country, id: country };
    });

    if (globalConst.LIST_COUNTRIES.includes(this.state.shippingCountry)) {
      this.country = [
        {
          value: this.state.shippingCountry,
          name: this.state.shippingCountry,
        },
      ];
    }
  };

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("general", true);
    }
    this.setState(state);
  }

  handleChangeSelect(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("general", true);
    }
    this.setState(state);
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    return (
      <div id="SaleGeneralEdit_component">
        {this.state.updating_sale === true ? <LoadingLayer /> : ""}
        {
          <FlashMessages
            error_messages={this.state.error_messages}
            warning_messages={this.state.warning_messages}
            success_messages={this.state.success_messages}
          />
        }
        <Form>
          <Form.Group className="form-group">
            <Form.Check type={"switch"}>
              <Form.Check.Input
                className="check-all-link"
                name="shipCustomersAddress"
                type="checkbox"
                id="shipCustomersAddress"
                checked={
                  this.state.shipCustomersAddress === true ? true : false
                }
                onChange={(e) =>
                  this.handleChangeCheckbox(e, "pickUpAtShowroom", true)
                }
              />
              <Form.Check.Label
                className="check-all-link"
                htmlFor="shipCustomersAddress"
              >
                Ship to customer's address
              </Form.Check.Label>
            </Form.Check>

            {this.state.shipCustomersAddress ? (
              <Card className="mt-2">
                <Card.Body>
                  <Card.Title>Shipping data</Card.Title>
                  <Row>
                    <Col sm="12" lg="4">
                      <FormLabel>Shipping address</FormLabel>
                      <Form.Control
                        type="text"
                        name="shippingAddress"
                        value={this.state.shippingAddress ?? ""}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col sm="12" lg="4">
                      <FormLabel>Shipping suburb</FormLabel>
                      <Form.Control
                        type="text"
                        name="shippingSuburb"
                        value={this.state.shippingSuburb ?? ""}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col sm="12" lg="4">
                      <FormLabel>Shipping Postcode</FormLabel>
                      <Form.Control
                        type="text"
                        name="shippingPostCode"
                        value={this.state.shippingPostCode ?? ""}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" lg="4">
                      <FormLabel>Shipping State</FormLabel>
                      <Form.Control
                        type="text"
                        name="shippingState"
                        value={this.state.shippingState ?? ""}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col sm="12" lg="4">
                      <Form.Group>
                        <FormLabel className="d-block">
                          Shipping country
                        </FormLabel>
                        <Typeahead
                          name="shippingCountry"
                          id="shipping-country"
                          labelKey="value"
                          options={this.listCountries}
                          placeholder="Choose a country..."
                          defaultSelected={this.country}
                          onChange={(e) =>
                            this.handleChangeCountry(e, "shippingCountry")
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ) : null}

            <Form.Check type={"switch"}>
              <Form.Check.Input
                className="check-all-link"
                name="pickUpAtShowroom"
                type="checkbox"
                id="pickUpAtShowroom"
                checked={this.state.pickUpAtShowroom === true ? true : false}
                onChange={(e) =>
                  this.handleChangeCheckbox(e, "shipCustomersAddress", true)
                }
              />
              <Form.Check.Label
                className="check-all-link"
                htmlFor="pickUpAtShowroom"
              >
                Pick up at showroom
              </Form.Check.Label>
            </Form.Check>

            {this.state.pickUpAtShowroom ? (
              <Card className="mt-2">
                <Card.Body>
                  <Card.Title>Showroom data</Card.Title>
                  <Row>
                    <Col sm="12" lg="6">
                      <Form.Select
                        onChange={(e) => this.handleChangeSelect(e)}
                        value={this.state.shippingTo ?? ""}
                        name="shippingTo"
                      >
                        <option value=""></option>
                        {this.listShippingShowrooms.map(
                          (listShippingShowroom) => {
                            return (
                              <option
                                key={listShippingShowroom}
                                value={listShippingShowroom}
                              >
                                {listShippingShowroom}
                              </option>
                            );
                          }
                        )}
                      </Form.Select>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ) : null}
          </Form.Group>
          <Form.Group className="form-group">
            <div className="row">
              <div className="col">
                <FormLabel>Name</FormLabel>
                <Form.Control
                  type="text"
                  name="name"
                  value={this.state.name === null ? "" : this.state.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col">
                <FormLabel>Phone</FormLabel>
                <Form.Control
                  type="text"
                  name="phone"
                  value={this.state.phone === null ? "" : this.state.phone}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col">
                <FormLabel>Email</FormLabel>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="name@example.com"
                  value={this.state.email === null ? "" : this.state.email}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group className="form-group">
            <div className="row">
              <div className="col">
                <FormLabel className="d-block">List Type</FormLabel>
                <select
                  name="sheetType"
                  value={this.state.sheetType}
                  onChange={(e) => this.handleChangeSelect(e)}
                  className="select-box ml-0"
                >
                  <option value="ACTIVE">Current Responses</option>
                  <option value="FINALISED">Finished Jobs</option>
                </select>
              </div>
            </div>
          </Form.Group>

          <Button
            className="btn-default save-information-btn"
            type="submit"
            onClick={this.editSale}
            disabled={this.state.updating_sale || this.state.fields_no_updated}
          >
            Save General Information
          </Button>
        </Form>
      </div>
    );
  }

  handleChangeCountry = (event, name) => {
    if (event.length <= 0) {
      return;
    }

    let state = {};
    const value = event[0].value;

    state[name] = value;

    if (value !== this.state[name]) {
      this.setState({
        shippingCountry: value,
        fields_no_updated: false,
      });

      this.props.handleTabChange("general", true);
    }
  };

  handleChangeCheckbox = (event, disabledField = "", clear = false) => {
    const { checked, name } = event.target;

    this.setState({
      [name]: checked,
    });

    if (disabledField) {
      this.setState({
        [disabledField]: false,
      });
    }

    if (clear) {
      this.clearDataShipping(name);
    }
  };

  clearDataShipping = (name) => {
    if (name === SHIP_CUSTOMERS_ADDRESS) {
      this.setState({
        shippingTo: null,
      });
    }

    if (name === PICK_UP_AT_SHOWROOM) {
      this.setState({
        shippingAddress: null,
        shippingCountry: null,
        shippingPostCode: null,
        shippingState: null,
        shippingSuburb: null,
      });
    }
  };

  updateGeneralTabSheetType(sheetType) {
    this.setState({
      sheetType: sheetType,
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    this.props.onRef(this);
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************

  axioRequest = null;
  editSale(event) {
    event.preventDefault();
    this.setState({
      updating_sale: true,
    });
    const user = Auth.getAuthenticatedUser();
    const sale = this.props.sale;
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (sale === null || sale === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        process.env.REACT_APP_NOVITAERP_API_DOMAIN +
        globalConst.REACT_APP_NOVITAERP_API_SALES_EDIT;
      url = url.replace("{id}", sale.id);
      const params = new URLSearchParams();

      params.append("shippingAddress", this.state.shippingAddress);
      params.append("shippingCountry", this.state.shippingCountry);
      params.append("shippingPostCode", this.state.shippingPostCode);
      params.append("shippingState", this.state.shippingState);
      params.append("shippingSuburb", this.state.shippingSuburb);
      params.append("name", this.state.name);
      params.append("phone", this.state.phone);
      params.append("email", this.state.email);
      params.append("sheetType", this.state.sheetType);
      params.append("shippingTo", this.state.shippingTo);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Sale updated"],
              fields_no_updated: true,
            });
            this.props.handleTabChange("general", false);
            this.props.saleUpdated(data.result);
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            //error not detected. Request could have been cancelled and it's waiting for second
            //request to finish
            this.setState({
              updating_sale: true,
            });
          }
          //console.log((err);
          return null;
        });
    }
  }
}

export default SaleGeneralEdit;
