import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_FAILED,
  API_STATUS_IDLE,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
} from "../constants";

const initialState = {
  error: null,
  categories: [],
  selectedCategory: null,
  status: API_STATUS_IDLE,
  documents: [],
  progress: 0,
};

export const fetchCategories = createAsyncThunk(
  "/documents/document-categories",
  async (args, thunkAPI) => {
    try {
      const response = await axios.get(`/api/v1/documents/document-categories`);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  "/documents/document-categories/create",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(`/api/v1/documents/document-categories/create`, {
        name: args.name,
        parentCategoryId: args.parentCategoryId
      });
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editCategory = createAsyncThunk(
  "/documents/document-categories/update",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(`/api/v1/documents/document-categories/update/${args.id}`, {
        name: args.name,
        parentCategoryId: args.parentCategoryId
      });
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createDocument = createAsyncThunk(
  "/documents/document/create",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(`/api/v1/documents/document/create`, args, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          thunkAPI.dispatch(categoriesSlice.actions.setProgress(percentCompleted));
        }
      });
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editDocument = createAsyncThunk(
  "/documents/document/update",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(`/api/v1/documents/document/update/${args.id}`, args.data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          thunkAPI.dispatch(categoriesSlice.actions.setProgress(percentCompleted));
        }
      });
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setProgress(state, action) {
      state.progress = action.payload;
    },
    clearDocumentation(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.categories = initialState.customers;
      state.selectedCategory = initialState.selectedCategory;
      state.documents = initialState.documents;
      state.progress = initialState.progress;
    },
    selectCategory(state, action) {
      state.selectedCategory = action.payload;
      state.documents = [];
      if (state.selectedCategory && state.selectedCategory.documents) {
        state.documents = [...state.selectedCategory.documents];
      }
    },
    filterDocumentsBy(state, action) {
      if (action.payload.columnName.toLowerCase() === 'title') {
        state.documents = [...state.selectedCategory.documents].filter((d) =>
          d[action.payload.columnName].toLowerCase().includes(action.payload.text.toLowerCase())
        );
      }
    },
    resetDocumentsSearch(state, action) {
      if (state.selectedCategory) {
        state.documents = [...state.selectedCategory.documents];
      } else {
        state.documents = [];
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCategories.fulfilled, (state, action) => ({
      categories: action.payload,
      documents: [],
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchCategories.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //Create category
    builder.addCase(createCategory.fulfilled, (state, action) => ({
      categories: action.payload,
      selectedCategory: state.selectedCategory,
      documents: state.documents,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(createCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(createCategory.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //Edit category
    builder.addCase(editCategory.fulfilled, (state, action) => ({
      categories: action.payload,
      selectedCategory: state.selectedCategory,
      documents: state.documents,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(editCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(editCategory.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // Create createDocument
    builder.addCase(createDocument.fulfilled, (state, action) => ({
      progress: 0,
      selectedCategory: state.selectedCategory,
      categories: updateCategory(state.categories, action.payload.documentCategoryId, action.payload),
      documents: action.payload.documentCategoryId === state.selectedCategory?.id ? [...state.documents, action.payload] : state.documents,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(createDocument.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(createDocument.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // Edit Document
    builder.addCase(editDocument.fulfilled, (state, action) => ({
      progress: 0,
      selectedCategory: state.selectedCategory,
      categories: updateCategoryAfterEdit(state.categories, action.payload.documentCategoryId, action.payload),
      documents: state.documents.map(d => d.id === action.payload.id ? action.payload : d),
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(editDocument.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(editDocument.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

function updateCategory(categories, categoryId, newDocument) {
  return categories.map(category => {
    if (category.id === categoryId) {
      return {
        ...category,
        documents: [...category.documents, newDocument]
      };
    } else if (category.documentCategories) {
      return {
        ...category,
        documentCategories: updateCategory(category.documentCategories, categoryId, newDocument)
      };
    }
    return category;
  });
}

function updateCategoryAfterEdit(categories, categoryId, updatedDocument) {
  return categories.map(category => {
    if (category.id === categoryId) {
      const documents = category.documents.map(document => {
        if (document.id === updatedDocument.id) {
          return updatedDocument;
        }
        return document;
      });
      return {
        ...category,
        documents: documents
      };
    } else if (category.documentCategories) {
      return {
        ...category,
        documentCategories: updateCategoryAfterEdit(category.documentCategories, categoryId, updatedDocument)
      };
    }
    return category;
  });
}


export const {
  clearDocumentation,
  selectCategory,
  filterDocumentsBy,
  resetDocumentsSearch,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
