import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';

// SCSS
import '../../../css/sales/sale_create.scss';

class MediaPreview extends Component {

    constructor(props){
        super(props);
        this.state = {
            mediaURL: props.media_preview_url,
            isVideo: props.media_type
        }

        this.closeModal = this.closeModal.bind(this)
    }

    closeModal(){
        this.props.closeMediaPreviewModal()
    }


    //*****************************************
    //RENDER
    //*****************************************
    render(){
        const source_url = this.state.mediaURL;
        return(
            <div id="SalesMediaPreview_component">
                <Modal 
                    show={true}
                    onHide={this.closeModal}
                >
                    <Modal.Header closeButton>
                        PREVIEW MEDIA
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        {this.state.isVideo ?
                            <video className="img-fluid" controls>
                                <source src={source_url} type="video/mp4"/>
                            </video>
                            : 
                            <img src={source_url} className="img-fluid"/>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        )
    }   
}
export default MediaPreview;