import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { dateFormatCustom } from "../../../../utils/dateUtils";

const ACTION_CEATE = "CREATE";

const MAP_MODEL = {
  Customer: "Customer",
  CustomerAppointment: "Appointment",
  CustomerFollowUp: "Follow up",
};

const HistoryTableRow = ({ customerLogs }) => {
  const [allCustomerLogs, setAllCustomerLogs] = useState([]);

  useEffect(() => {
    if (allCustomerLogs.length <= 0) {
      let processCustomerLogs = [];
      customerLogs.forEach((customerLog, index) => {
        const { oldInformation = {}, newInformation = {} } = customerLog;
        const arrayOldInformation = Object.keys(oldInformation);

        if (customerLog.action === ACTION_CEATE) {
          const data = {
            id: `${index}`,
            action: customerLog.action,
            created: customerLog.created,
            model: customerLog.model,
            email: customerLog?.appUser?.email ?? "",
          };

          processCustomerLogs.push(data);
        }

        arrayOldInformation.forEach((key) => {
          if (oldInformation[key] !== newInformation[key]) {
            const data = {
              id: `${index}-${key}`,
              action: customerLog.action,
              oldValue: oldInformation[key],
              newValue: newInformation[key],
              created: customerLog.created,
              field: key,
              model: customerLog.model,
              email: customerLog?.appUser?.email ?? "",
            };

            processCustomerLogs.push(data);
          }
        });
      });

      setAllCustomerLogs(processCustomerLogs);
    }
  }, [allCustomerLogs.length, customerLogs]);

  const renderCreate = (customerLog) => {
    return (
      <tr key={customerLog.id}>
        <td>
          <span>
            {dateFormatCustom(customerLog.created, "DD MMM, YYYY hh:mm A")}
          </span>
          <br />
          <b>Created by: </b>
          {customerLog.email}
        </td>
        <td>{MAP_MODEL[customerLog.model]} created.</td>
      </tr>
    );
  };

  const renderChanged = (customerLog) => {
    return (
      <tr key={customerLog.id}>
        <td>
          {dateFormatCustom(customerLog.created, "DD MMM, YYYY hh:mm A")}
          <br />
          <b>Changed by: </b>
          {customerLog.email}
        </td>
        <td>
          {MAP_MODEL[customerLog.model]} updated.
          <br />
          <span className="text-secondary"> {customerLog.field}</span>
          {" changed from "}
          <span className="text-primary">{customerLog.oldValue ?? '""'}</span>
          {" to "}
          <span className="text-success">{customerLog.newValue}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      {allCustomerLogs.map((customerLog) => {
        const { action } = customerLog;
        if (action === "CREATE") {
          return renderCreate(customerLog);
        } else {
          return renderChanged(customerLog);
        }
      })}
    </>
  );
};

HistoryTableRow.propTypes = {
  customerLogs: PropTypes.array.isRequired,
};

export default HistoryTableRow;
