import React, {Component} from 'react';

class LoginLoadingLayer extends Component {
    render(){
        return(
            <div id="LoginLoadingLayer_component">
                <div className="spinner-border" role="status">
                </div>
            </div>
        )
    }
}

export default LoginLoadingLayer;