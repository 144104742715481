import axios from "axios";
import * as XLSX from "xlsx";
import Util from "../../../../Util";
import * as globalConst from "../../../../global/const";
import { sprintf } from "sprintf-js";
import Auth from "../../../../Auth";

export const LoadDataField = async (file) => {
  try {
    if (file.target.files) {
      const reader = new FileReader();

      const promise = await new Promise((resolve, reject) => {
        let fileJson = [];

        reader.onload = (file) => {
          const data = file.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          fileJson = XLSX.utils.sheet_to_json(worksheet);

          resolve(fileJson);
        };

        reader.readAsArrayBuffer(file.target.files[0]);

        reader.onerror = (error) => {
          reject(error);
        };
      });

      return promise;
    }

    return [];
  } catch (error) {
    return [];
  }
};

export const SendDataUpdate = async (results, type, callback) => {
  try {
    let chunks = Util.chunk(results, 100);

    const promises = await Promise.all(
      chunks.map(async (items) => {
        return await callback(items, type);
      })
    );

    const merged = promises.reduce((prev, next) => {
      return prev.concat(next);
    });

    return merged;
  } catch (error) {
    return [];
  }
};

export const UpdateSaleCommissions = async (items, type) => {
  const url = sprintf(
    "%s%s",
    process.env.REACT_APP_NOVITAERP_API_DOMAIN,
    globalConst.REACT_APP_NOVITAERP_API_ACCOUNTING_SALE_COMMISSIONS_UPDATE
  );

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  };

  return await axios
    .post(
      url,
      {
        sales: items,
        type,
      },
      config
    )
    .then((response) => {
      const { data } = response;
      const { value } = data;

      if (value.length > 0) {
        return value;
      }

      return [];
    })
    .catch((error) => {
      return [];
    });
};
