import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import Util from "../../../Util";

const TotalSalesReport = (props) => {
  const { jsonData } = props;
  const { results = [], total = 0 } = jsonData;

  const totalAmount = results.reduce((acc, curr) => acc + curr.monthlyTotal, 0);

  return (
    <Card>
      <Card.Body>
        <h2>{total} Orders Found</h2>
        <h3>Total Amount: {Util.formatCurrency(totalAmount, false)}</h3>
      </Card.Body>
    </Card>
  );
};

TotalSalesReport.propTypes = {
  jsonData: PropTypes.object.isRequired,
};

export default TotalSalesReport;
