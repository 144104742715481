import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import CustomerCommentsList from './comments-list'
import AddCustomerComment from './add-comment'
import { useSelector } from 'react-redux'

const CustomerComments = ({ customer }) => {
  const { comments } = useSelector((state) => state.customerComments);
  const [customerComments, setCustomerComments] = useState();

  useEffect(() => {
    if (comments) {
      setCustomerComments(comments?.find((c) => c.customerId === customer.id));
    }
  }, [comments, customer.id]);

  return (
    <div className="mt-3 pb-3">
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>VIEW COMMENTS ({customerComments?.messages?.length ?? 0})</Accordion.Header>
                <Accordion.Body>
                    <CustomerCommentsList comments={customerComments?.messages} />
                    <AddCustomerComment customer={customer} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
  )
}

export default CustomerComments
