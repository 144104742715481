import React from 'react';

const CategorySelect = ({ selectedCategoryId, prompt, categories, onChange, removeCategoryId }) => {
  const value = selectedCategoryId === null || selectedCategoryId === undefined ? '' : selectedCategoryId;

  const renderCategoryOption = (category, level = 0) => {
    const prefix = level > 0 ? '-'.repeat(level) + ' ' : '';
    const name = prefix + category.name;
    return (
      <option key={category.id} value={category.id}>
        {name}
      </option>
    );
  };

  const renderCategoryOptions = (category, level = 0) => {
    if (category.id === removeCategoryId) {
      return null;
    }

    return (
      <React.Fragment key={category.id}>
        {renderCategoryOption(category, level)}
        {category.documentCategories.map((subCategory) =>
          renderCategoryOptions(subCategory, level + 1)
        )}
      </React.Fragment>
    );
  };

  const filteredCategories = categories.filter((category) => category.id !== removeCategoryId);

  return (
    <select className='rounded border mt-2 mb-2 w-full' value={value} onChange={onChange}>
      <option key={0}>
        -- {prompt} --
      </option>
      {filteredCategories.map((category) => renderCategoryOptions(category))}
    </select>
  );
};

export default CategorySelect;
