import axios from "axios"
import { sprintf } from "sprintf-js";
import * as globalConst from '../../../global/const';
import Auth from "../../../Auth";
import Util from "../../../Util";

const CancelToken = axios.CancelToken;
let cancel;

const STANDARD = "standard"

export const renderIsStandarTypeOfClaw = (value, typeAndClaw) => {
    const { typeOfClaw } = typeAndClaw

    if (!typeOfClaw) {
        return `<span>${value}</span>`
    }

    if (value.toLocaleUpperCase() === STANDARD.toLocaleUpperCase()) { 
        return `<span>${value}</span>`
    }

    if (value.toLocaleUpperCase() === typeOfClaw.toLocaleUpperCase()) {
        return `<span>${value} (standard)</span>`
    }

    return `<span class="red_colour">${value}</span>`
}

export const renderIsStandarMetalClaw = (value, typeAndClaw) => {
    if (!value) {
        return "N/A"
    }

    const { metalClawStandard } = typeAndClaw

    if (!metalClawStandard) {
        return `<span>${value}</span>`
    }

    if (value.toLocaleUpperCase() === metalClawStandard.toLocaleUpperCase()) {
        return `<span>${value} (standard)</span>`
    }

    return `<span class="red_colour">${value}</span>`
}

export const findTypeOfClawStandard = async (
    ringPreference = '',
    saleType = ''
) => {
    let loadDataStandard = false

    try {
        if (!Util.wordInArray(saleType, ['engagement ring'])) {
            return {
                metalClaw: '',
                metalClawStandard: '',
                typeOfClaw: '',
                loadDataStandard
            }
        }

        if (ringPreference === 'Custom') {
            return {
                metalClaw: '',
                metalClawStandard: '',
                typeOfClaw: '',
                loadDataStandard
            }
        }

        if (cancel !== undefined) {
            cancel();
        }

        const url = sprintf(
            '%s%s',
            process.env.REACT_APP_NOVITAERP_API_DOMAIN,
            globalConst.REACT_APP_NOVITAERP_API_PRODUCT_ENGAGEMENT_RING
        )

        return await axios.get(
            url,
            {
                params: {
                    ringPreference: ringPreference
                },
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        ).then(apiResponse => {
            const { data } = apiResponse
            const { value } = data
            const { metalClaw, typeOfClaw, metalClawStandard } = value

            return {
                metalClaw,
                metalClawStandard,
                typeOfClaw,
                loadDataStandard
            }
        }).catch(() => {
            return {
                metalClaw: '',
                typeOfClaw: '',
                loadDataStandard
            }
        })
    } catch (error) {
        console.log(error)

        return {
            metalClaw: '',
            metalClawStandard: '',
            typeOfClaw: '',
            loadDataStandard
        }
    }

}