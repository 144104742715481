import React, { Component } from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap'
import Auth from '../../../Auth';
import axios from 'axios'
import FlashMessages from "../../FlashMessages";
import LoadingLayer from '../../LoadingLayer'
import PostSaleCreateSaleSearch from './PostSaleCreateSaleSearch';
import * as globalConst from '../../../global/const';

// SCSS
import '../../../css/sales/sale_create.scss'
const COURIER_TRANSFER = "Courier transfer"

class PostSaleCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saleDate: new Date(),
            name: '',
            phone: '',
            email: '',
            typeOfJob: 'Resize Startrack',
            handledVia: 'Showroom',
            showroom: '',
            requestMethod: 'Showroom',
            requestDescription: '',
            customerNeedsToPay: false,
            creating_sale: false,
            disable_button: true,
            show_sale_search_modal: false,
            saleId: null,
            selected_sale: null,
            previousPostSaleServices: 0
        }
        this.closeModal = this.closeModal.bind(this)
        this.createPostSale = this.createPostSale.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeDateField = this.handleChangeDateField.bind(this)
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
        this.validateFieldsAndSave = this.validateFieldsAndSave.bind(this)
        this.selectSale = this.selectSale.bind(this)
        this.getSaleCount = this.getSaleCount.bind(this)

        this.setListShowroomCity()
        this.loadListInformation()
    }

    loadListInformation = () => {
        this.listRequestMethod = process.env.REACT_APP_REQUEST_METHOD.split(',')
        this.listTypeJob = process.env.REACT_APP_TYPE_JOB.split(',')
        this.listHandledVia = process.env.REACT_APP_HANDLED_VIA.split(',')
    }

    setListShowroomCity = () => {
        this.listOrderStatus = []

        if (process.env.REACT_APP_SHOWROOM_CITY_OPTIONS) {
            this.listShowroomCity = process.env.REACT_APP_SHOWROOM_CITY_OPTIONS.split(',')
        }

        this.listShowroomCity = this.listShowroomCity.sort()
    }

    handleChange(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    handleChangeDateField = (date, field_name) => {
        const state = {}
        if (date !== null && date !== '') {
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const year = date.getFullYear()
            state[field_name] = year + '-' + month + '-' + day
        }
        else {
            state[field_name] = null
        }
        this.setState(state)
    }

    handleChangeSelectHandledVia(event) {
        const field_name = event.target.name
        let state = {}

        const { value } = event.target

        state[field_name] = value
        this.setState(state)

        if (value === COURIER_TRANSFER) {
            this.setState({
                showroom: ''
            })
        }
    }

    handleChangeSelect(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    handleChangeCheckbox(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.checked
        this.setState(state)
    }

    closeModal() {
        this.props.closeCreateComponent()
    }

    validateFieldsAndSave(event) {
        event.preventDefault();
        let error_messages = []
        if (error_messages.length > 0) {
            this.setState({
                error_messages: error_messages
            })
        }
        else {
            this.createPostSale()
        }
    }

    axioRequest = null;
    createPostSale() {
        if (this.state.selected_sale !== null) {

            this.setState({
                creating_sale: true,
                error_messages: []
            })
            const user = Auth.getAuthenticatedUser()
            if (user === null || user === undefined || user.employeeModel === null || user.employeeModel === undefined) {
                this.setState(
                    {
                        error_messages: ['Authentication error. Reload the page and try again.']
                    }
                )
            }
            else {
                let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_CREATE
                const params = new URLSearchParams()

                let saleId = this.state.selected_sale.id;
                let name = this.state.name;
                if (name === "" || name === null || typeof name === 'undefined') {
                    name = this.state.selected_sale.name
                }
                let phone = this.state.phone;
                if (phone === "" || phone === null || typeof phone === 'undefined') {
                    phone = this.state.selected_sale.phone
                }
                let email = this.state.email;
                if (email === "" || email === null || typeof email === 'undefined') {
                    email = this.state.selected_sale.email
                }
                let typeOfJob = this.state.typeOfJob;
                let handledVia = this.state.handledVia;
                let showroom = this.state.showroom;
                let requestMethod = this.state.requestMethod;
                let requestDescription = this.state.requestDescription;
                let customerNeedsToPay = this.state.customerNeedsToPay;
                params.append('salesId', saleId)
                params.append('typeOfJob', typeOfJob)
                params.append('handledVia', handledVia)
                params.append('showroom', showroom)
                params.append('name', name)
                params.append('phone', phone)
                params.append('email', email)
                params.append('requestDescription', requestDescription)
                params.append('requestMethod', requestMethod)
                params.append('customerNeedsToPay', customerNeedsToPay)

                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `Bearer ${Auth.getToken()}`
                    }
                }

                axios.post(
                    url,
                    params,
                    config
                )
                    .then(
                        (response) => {
                            const data = response.data
                            if (data !== undefined && data.code === 200) {
                                this.setState(
                                    {
                                        creating_sale: false,
                                        success_messages: ['Post sale created'],
                                        error_messages: []
                                    }
                                )

                                var created_sale = data.result;
                                this.generateCreationLog(created_sale);
                            }
                            else {
                                this.setState(
                                    {
                                        creating_sale: false,
                                        error_messages: ['There was a problem processing the information. Please reload this page and try again.']
                                    }
                                )
                            }
                        }
                    )
                    .catch(
                        err => {
                            if (err.response) {
                                let error_messages = []
                                if ('status' in err.response && err.response.status === 401) {
                                    error_messages = ['Not authorised.']
                                }
                                else {
                                    error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                                }
                                this.setState(
                                    {
                                        creating_sale: false,
                                        error_messages: error_messages
                                    }
                                )
                            } else if (err.request) {
                                const error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                                this.setState(
                                    {
                                        creating_sale: false,
                                        error_messages: error_messages
                                    }
                                )
                            } else {
                                //error not detected. Request could have been cancelled and it's waiting for second
                                //request to finish
                                this.setState(
                                    {
                                        creating_sale: true
                                    }
                                )
                            }
                            //console.log((err);
                            return null;
                        }
                    )
            }
        }
    }

    //Generate log after editing element
    axioRequest = null;
    last_sale_reported_for_log = null
    generateCreationLog(edited_sale) {
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if (this.axioRequest !== null) {
            this.axioRequest.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.axioRequest = axios.CancelToken.source();

        /*let sale_before_update = this.last_sale_reported_for_log
        if(sale_before_update === null){
            sale_before_update = this.props.sale
        }*/

        const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_LOG_NEW
        const params = new URLSearchParams()

        const user = Auth.getAuthenticatedUser()

        delete edited_sale["salesModel"]

        params.append('postSaleServiceId', edited_sale.id)
        params.append('employeeId', user.employeeModel.id)
        params.append('oldInformation', JSON.stringify(edited_sale))
        params.append('newInformation', JSON.stringify(edited_sale))
        params.append('action', 'CREATE')

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }
        axios.post(
            url,
            params,
            config
        )
            .then(
                (response) => {
                    const data = response.data
                    if (data !== undefined && data.code === 200) {
                        console.log('sales_log stored')
                        window.location.reload(false);
                    }
                    else {
                        console.log('Error storing sales_log')
                    }
                    this.axioRequest = null
                }
            )
            .catch(
                err => {
                    console.log('Error storing sales_log.')
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            console.log('Not authorised.')
                        }
                        else {
                            console.log('There was a problem processing the information. Please reload this page and try again.')
                        }
                    } else if (err.request) {
                        console.log('There was a problem processing the information. Please reload this page and try again.')
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    //console.log((err);
                    return null;
                }
            )

    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        return (
            <div id="SaleCreate_component">
                <Modal
                    show={true}
                    onHide={this.closeModal}
                    dialogClassName="modal-90w"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        NEW POST SALE
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.creating_sale === true ?
                                (
                                    <LoadingLayer />
                                )
                                :
                                ('')
                        }
                        {
                            <FlashMessages
                                error_messages={this.state.error_messages}
                                warning_messages={this.state.warning_messages}
                                success_messages={this.state.success_messages}
                            />
                        }
                        {
                            this.state.show_sale_search_modal === true ?
                                <PostSaleCreateSaleSearch
                                    closeComponent={this.closeSaleSearchPopup}
                                    setSelectedSale={this.selectSale}
                                />
                                : (null)
                        }
                        <Form>
                            <div className="row">
                                <div className='col-6'>
                                    <Form.Group>
                                        <FormLabel>Sale</FormLabel>
                                        <Form.Control
                                            disabled
                                            type="text"
                                            name="saleId"
                                            value={this.state.selected_sale === null ? '' : this.state.selected_sale.name}
                                        />
                                        <Button
                                            className="btn-default save-information-btn"
                                            type="button"
                                            onClick={this.openSaleSearchPopup}
                                        >
                                            Select
                                        </Button>
                                    </Form.Group>
                                </div>
                                <div className='col-6'
                                    dangerouslySetInnerHTML={{ __html: "This sale was included in <span class='previous_post_sale_count'>" + this.state.previousPostSaleServices + "</span> previous post sale services" }}>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-6'>
                                    <Form.Group>
                                        <FormLabel>Name</FormLabel>
                                        <Form.Control
                                            required={true}
                                            type="text"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-6'>
                                    <Form.Group>
                                        <FormLabel>Phone</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-6'>
                                    <Form.Group>
                                        <FormLabel>Email</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-6'>
                                    <Form.Group>
                                        <FormLabel>Type of Job</FormLabel>

                                        <Form.Select
                                            onChange={(e) => this.handleChangeSelect(e)}
                                            value={this.state.typeOfJob ?? ''}
                                            name="typeOfJob"
                                        >
                                            <option value=""></option>
                                            {
                                                this.listTypeJob.map(listTypeJob => {
                                                    return (
                                                        <option
                                                            key={listTypeJob}
                                                            value={listTypeJob}>
                                                            {listTypeJob}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='col-6'>
                                    <Form.Group>
                                        <FormLabel>Handled via</FormLabel>
                                        <Form.Select
                                            onChange={(e) => this.handleChangeSelectHandledVia(e)}
                                            value={this.state.handledVia ?? ''}
                                            name="handledVia"
                                            required={true}
                                        >
                                            {
                                                this.listHandledVia.map(listHandledVia => {
                                                    return (
                                                        <option
                                                            key={listHandledVia}
                                                            value={listHandledVia}>
                                                            {listHandledVia}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                {
                                    (this.state.handledVia === "Showroom") ? (
                                        <div className='col-6'>
                                            <Form.Group>
                                                <FormLabel>Showroom</FormLabel>
                                                <Form.Select
                                                    onChange={(e) => this.handleChangeSelect(e)}
                                                    value={this.state.showroom ?? ''}
                                                    name="showroom"
                                                    required={true}
                                                >
                                                    <option value=""></option>
                                                    {
                                                        this.listShowroomCity.map(orderStatus => {
                                                            return (
                                                                <option
                                                                    key={orderStatus}
                                                                    value={orderStatus}>
                                                                    {orderStatus}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                    ) : (null)
                                }
                                <div className='col-6'>
                                    <Form.Group>
                                        <FormLabel>Request Method</FormLabel>
                                        <Form.Select
                                            onChange={(e) => this.handleChangeSelect(e)}
                                            value={this.state.requestMethod ?? ''}
                                            name="requestMethod"
                                        >
                                            {
                                                this.listRequestMethod.map(listRequestMethod => {
                                                    return (
                                                        <option
                                                            key={listRequestMethod}
                                                            value={listRequestMethod}>
                                                            {listRequestMethod}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='col-6'>
                                    <Form.Group>
                                        <FormLabel>Request Description</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="requestDescription"
                                            value={this.state.requestDescription}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-6'>
                                    <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            name="customerNeedsToPay"
                                            label="Customer needs to pay"
                                            checked={this.state.customerNeedsToPay === true ? true : false}
                                            onChange={this.handleChangeCheckbox}
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <Button
                                type="submit"
                                onClick={this.validateFieldsAndSave}
                                disabled={this.state.creating_sale || this.state.disable_button}
                            >
                                Create
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    selectSale(sale) {
        let name = this.state.name;
        let phone = this.state.phone;
        let email = this.state.email;

        if ('name' in sale) {
            name = sale.name;
        }
        if ('phone' in sale) {
            phone = sale.phone;
        }
        if ('email' in sale) {
            email = sale.email;
        }

        if ('id' in sale) {
            this.getSaleCount(sale.id);
        }

        this.setState(
            {
                disable_button: false,
                selected_sale: sale,
                name: name,
                phone: phone,
                email: email,
            }
        )
    }

    ajaxRequest = null;
    getSaleCount(saleId) {
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if (this.ajaxRequest !== null) {
            this.ajaxRequest.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.ajaxRequest = axios.CancelToken.source();
        //FINISH AJAX CONTROLS

        const params = new URLSearchParams()
        params.append('salesId', saleId)

        const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_LIST
        axios.get(
            url,
            {
                params: params,//this params are sent as query. In post requests they should go as body params in the following block
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            },
            {
                cancelToken: this.ajaxRequest.token
            })
            .then(api_response => {
                const result = api_response.data.result;

                this.setState(
                    {
                        previousPostSaleServices: result.count
                    }
                )
            })
            .catch(err => {
                if (err.response) {
                    if ('status' in err.response && err.response.status === 401) {
                        alert('User not authorised. Please reload the page');
                    }
                    else {
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                    this.setState(
                        {
                            loading_page: false
                        }
                    )
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                    this.setState(
                        {
                            loading_page: true
                        }
                    )
                }
                console.log(err);
            })
    }

    openSaleSearchPopup = () => {
        this.setState(
            {
                show_sale_search_modal: true
            }
        )
    }

    closeSaleSearchPopup = (sale) => {
        if (sale !== null
            && typeof sale !== "undefined") {
            this.setState(
                {
                    show_sale_search_modal: false
                },
                this.selectSale(sale)
            )
        }
        else {
            this.setState(
                {
                    show_sale_search_modal: false
                }
            )
        }
    }


    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************


}

export default PostSaleCreate;