import React, { Component } from 'react';
import { Form, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import ChangeCostreadyActivity from './ChangeCostreadyActivity';
import ChangeCostReadyActivityMelees from './ChangeCostReadyActivityMelees';

// SCSS
import '../../../../css/sales/sale_edit.scss'

class SaleWorkflowEditSetting extends Component {

    constructor(props) {
        super();
        this.state = {
            activity: props.activity,
            cost: props.activity.cost,
            invoiceNumber: props.activity.invoiceNumber,
            invoiceTo: props.activity.invoiceTo ?? '',
            readyToPayToProvider: props.activity.readyToPayToProvider,
            settingActivityCustomerUpdated: (props.activity.workflowSettingModel !== null) ? props.activity.workflowSettingModel.settingActivityCustomerUpdated : false,
            metalYellowWeight: (props.activity.workflowMetalModel !== null) ? props.activity.workflowMetalModel.metalYellowWeight : null,
            metalWhiteWeight: (props.activity.workflowMetalModel !== null) ? props.activity.workflowMetalModel.metalWhiteWeight : null,
            metalRoseWeight: (props.activity.workflowMetalModel !== null) ? props.activity.workflowMetalModel.metalRoseWeight : null,
            metalPlatinumWeight: (props.activity.workflowMetalModel !== null) ? props.activity.workflowMetalModel.metalPlatinumWeight : null,
            metalCost: (props.activity.workflowMetalModel !== null) ? props.activity.workflowMetalModel.metalCost : null,
            metalSupplierName: (props.activity.workflowMetalModel !== null) ? props.activity.workflowMetalModel.metalSupplierName : null,
            metalInvoiceNumber: (props.activity.workflowMetalModel !== null) ? props.activity.workflowMetalModel.metalInvoiceNumber : null,
            metalInvoiceTo: (props.activity.workflowMetalModel !== null) ? props.activity.workflowMetalModel.invoiceTo : null,
            metalCostReady: (props.activity.workflowMetalModel !== null) ? props.activity.workflowMetalModel.costReady : null,
            mmdiaInvoice: props.activity.mmdiaInvoice ?? '',
            novaInvoice: props.activity.novaInvoice ?? '',
            invoiceStatus: props.activity.invoiceStatus ?? '',
            melesSets: (Array.isArray(props.activity.workflowMelesModels)) ? props.activity.workflowMelesModels : [],
            showModalCostready: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeDateField = this.handleChangeDateField.bind(this)
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.handleChangeCheckboxCostReadyMelees = this.handleChangeCheckboxCostReadyMelees.bind(this)
        this.handleChangeMeles = this.handleChangeMeles.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
        this.addWorkflowSet = this.addWorkflowSet.bind(this)
        this.removeWorkflowSet = this.removeWorkflowSet.bind(this)

        this.listInvoicesTo = process.env.REACT_APP_PROVIDERS.split(',')
    }

    handleChangeNumber = (event) => {
        let state = {}
        let { value } = event.target
        const { name } = event.target

        value = value.replace(/[^\d.]/g, '')

        state[name] = value

        this.setState(state)
    }

    handleChange(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    handleChangeCheckboxMetalCostReady(event) {
        this.showModalMetalCostReady(true)
    }

    handleChangeSelectMeles(event, index) {
        try {
            const field_name = event.target.name
            let state = {
                melesSets: this.state.melesSets
            }
            state["melesSets"][index][field_name] = event.target.value
            this.setState(state)
        }
        catch (e) {
            alert("Error changing field")
            console.log(e)
        }
    }

    handleChangeDateField = (date, field_name) => {
        const state = {}

        if (isNaN(Date.parse(date))) {
            state[field_name] = null
        }
        else {
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const year = date.getFullYear()
            const hours = date.getHours()
            const minutes = date.getMinutes()

            date = new Date(year, month - 1, day, hours, minutes)
            if (isNaN(Date.parse(date))) {
                state[field_name] = null
            }
            else {
                state[field_name] = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
            }
        }
        this.setState(state)
    }

    handleChangeMeles(event, index) {
        try {
            const field_name = event.target.name
            let state = {
                melesSets: this.state.melesSets
            }
            state["melesSets"][index][field_name] = event.target.value
            this.setState(state)
        }
        catch (e) {
            alert("Error changing field")
            console.log(e)
        }
    }

    handleChangeMelesNumber = (event, index) => {
        try {
            const field_name = event.target.name
            let state = {
                melesSets: this.state.melesSets
            }

            let { value } = event.target
            value = value.replace(/[^\d.]/g, '')

            state["melesSets"][index][field_name] = value

            this.setState(state)
        } catch (e) {
            alert("Error changing field")
            console.log(e)
        }
    }

    handleChangeCheckbox(event) {
        const field_name = event.target.name
        let state = {}

        if (field_name === 'readyToPayToProvider' && event.target.checked) {
            this.showModalCostready(true)
        } else {
            state[field_name] = event.target.checked
            this.setState(state)
        }
    }

    handleChangeCheckboxCostReadyMelees(event, index) {
        this.showModalCostreadyMelees(true, index)
    }

    handleChangeSelect(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    render() {
        if (this.state.melesSets.length === 0) {
            this.addWorkflowSet()
        }

        return (
            <div className='sale_information_subcontainer'>
                <div className="container">
                    <div className="row">
                        <div className="col-4 workflow-edit-border">
                            <div className="row">
                                <p className="shipping-info-heading">
                                    <b>Labour</b>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Form.Group className="form-group">
                                        <FormLabel>Setting cost</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="cost"
                                            value={this.state.cost === null ? '' : this.state.cost}
                                            onChange={this.handleChangeNumber}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Group className="form-group">
                                        <FormLabel>Invoice number</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="invoiceNumber"
                                            value={this.state.invoiceNumber === null ? '' : this.state.invoiceNumber}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Group>
                                        <FormLabel className="d-block">Invoice to</FormLabel>
                                        <Form.Select
                                            name="invoiceTo"
                                            value={this.state.invoiceTo}
                                            onChange={(e) => this.handleChangeSelect(e)}
                                        >
                                            <option value=""></option>
                                            {
                                                this.listInvoicesTo.map(invoiceTo => {
                                                    return (
                                                        <option
                                                            key={invoiceTo}
                                                            value={invoiceTo}>
                                                            {invoiceTo}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Check
                                        type="checkbox"
                                        name="readyToPayToProvider"
                                        label="Cost ready"
                                        disabled={this.state.readyToPayToProvider}
                                        checked={this.state.readyToPayToProvider === true ? true : false}
                                        onChange={this.handleChangeCheckbox}
                                    />
                                </div>
                                <div className="col-12">
                                    <Form.Check
                                        type="checkbox"
                                        name="settingActivityCustomerUpdated"
                                        label="Customer updated"
                                        checked={this.state.settingActivityCustomerUpdated === true ? true : false}
                                        onChange={this.handleChangeCheckbox}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 workflow-edit-border">
                            <div className="row">
                                <p className="shipping-info-heading">
                                    <b>Metal</b>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <Form.Group className="form-group">
                                        <FormLabel>Yellow</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="metalYellowWeight"
                                            value={this.state.metalYellowWeight === null ? '' : this.state.metalYellowWeight}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-3">
                                    <Form.Group className="form-group">
                                        <FormLabel>White</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="metalWhiteWeight"
                                            value={this.state.metalWhiteWeight === null ? '' : this.state.metalWhiteWeight}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-3">
                                    <Form.Group className="form-group">
                                        <FormLabel>Rose</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="metalRoseWeight"
                                            value={this.state.metalRoseWeight === null ? '' : this.state.metalRoseWeight}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-3">
                                    <Form.Group className="form-group">
                                        <FormLabel>Platinum</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="metalPlatinumWeight"
                                            value={this.state.metalPlatinumWeight === null ? '' : this.state.metalPlatinumWeight}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Group className="form-group">
                                        <FormLabel>Metal cost</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="metalCost"
                                            value={this.state.metalCost === null ? '' : this.state.metalCost}
                                            onChange={this.handleChangeNumber}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Group className="form-group">
                                        <FormLabel>Metal supplier</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="metalSupplierName"
                                            value={this.state.metalSupplierName === null ? '' : this.state.metalSupplierName}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Group className="form-group">
                                        <FormLabel>Invoice number</FormLabel>
                                        <Form.Control
                                            type="text"
                                            name="metalInvoiceNumber"
                                            value={this.state.metalInvoiceNumber === null ? '' : this.state.metalInvoiceNumber}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Group>
                                        <FormLabel className="d-block">Invoice to</FormLabel>
                                        <Form.Select
                                            name="metalInvoiceTo"
                                            value={this.state.metalInvoiceTo === null ? '' : this.state.metalInvoiceTo}
                                            onChange={(e) => this.handleChangeSelect(e)}
                                        >
                                            <option value=""></option>
                                            {
                                                this.listInvoicesTo.map(invoiceTo => {
                                                    return (
                                                        <option
                                                            key={invoiceTo}
                                                            value={invoiceTo}>
                                                            {invoiceTo}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <Form.Group className="mb-3">
                                        <Form.Check
                                            type="checkbox"
                                            name="metalCostReady"
                                            label="Cost ready"
                                            disabled={this.state.metalCostReady}
                                            checked={this.state.metalCostReady === true ? true : false}
                                            onChange={(e) => this.handleChangeCheckboxMetalCostReady(e)}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            {
                                (this.state.showModalMetalCostready) ?
                                    <ChangeCostreadyActivity
                                        showModalCostready={this.state.showModalMetalCostready}
                                        parentResponseCostReady={this.responseMetalCostReady}
                                        cost={this.state.metalCost}
                                    />
                                    :
                                    (null)
                            }
                        </div>
                        {
                            this.state.melesSets.map((melesRow, index) => {
                                return (
                                    <div className="col-4 workflow-edit-border" key={index}>
                                        <div className="row">
                                            <p className="shipping-info-heading">
                                                <b>Melees</b>
                                            </p>
                                        </div>
                                        <div className="row">
                                            <button className='btn ms-auto btn-remove' onClick={() => this.removeWorkflowSet(index)}>
                                                <FontAwesomeIcon icon={faMinus} />
                                            </button>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <Form.Group className="form-group">
                                                    <FormLabel>Melees mm</FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="melesSize"
                                                        disabled={melesRow.costReady && melesRow.id > 0}
                                                        value={melesRow.melesSize === null ? '' : melesRow.melesSize}
                                                        onChange={e => this.handleChangeMeles(e, index)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-3">
                                                <Form.Group className="form-group">
                                                    <FormLabel>Melees carat</FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="melesWeight"
                                                        disabled={melesRow.costReady && melesRow.id > 0}
                                                        value={melesRow.melesWeight === null ? '' : melesRow.melesWeight}
                                                        onChange={e => this.handleChangeMeles(e, index)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-3">
                                                <Form.Group className="form-group">
                                                    <FormLabel>Melees quantity</FormLabel>
                                                    <Form.Control
                                                        type="number"
                                                        name="melesQuantity"
                                                        disabled={melesRow.costReady && melesRow.id > 0}
                                                        value={melesRow.melesQuantity === null ? '' : melesRow.melesQuantity}
                                                        onChange={e => this.handleChangeMeles(e, index)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-3">
                                                <Form.Group className="form-group">
                                                    <FormLabel>Melees cost</FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="melesCost"
                                                        disabled={melesRow.costReady && melesRow.id > 0}
                                                        value={melesRow.melesCost === null ? '' : melesRow.melesCost}
                                                        onChange={e => this.handleChangeMelesNumber(e, index)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="form-group">
                                                    <FormLabel>Melees reference</FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="melesReference"
                                                        disabled={melesRow.costReady && melesRow.id > 0}
                                                        value={melesRow.melesReference === null ? '' : melesRow.melesReference}
                                                        onChange={e => this.handleChangeMeles(e, index)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="form-group">
                                                    <FormLabel>Melees shape</FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="melesDetails"
                                                        disabled={melesRow.costReady && melesRow.id > 0}
                                                        value={melesRow.melesDetails === null ? '' : melesRow.melesDetails}
                                                        onChange={e => this.handleChangeMeles(e, index)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group>
                                                    <FormLabel className="d-block">Invoice to</FormLabel>
                                                    <Form.Select
                                                        name="invoiceTo"
                                                        value={melesRow.invoiceTo ?? ''}
                                                        onChange={(e) => this.handleChangeSelectMeles(e, index)}
                                                    >
                                                        <option value=""></option>
                                                        {
                                                            this.listInvoicesTo.map(invoiceTo => {
                                                                return (
                                                                    <option
                                                                        key={invoiceTo}
                                                                        value={invoiceTo}>
                                                                        {invoiceTo}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12">
                                                <Form.Group className="form-group">
                                                    <FormLabel>Cost ready</FormLabel>
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="costReady"
                                                        label="Cost ready"
                                                        disabled={melesRow.costReady}
                                                        checked={melesRow.costReady === true ? true : false}
                                                        onChange={e => this.handleChangeCheckboxCostReadyMelees(e, index)}
                                                    />
                                                </Form.Group>

                                                {
                                                    (melesRow.showModalCostReady) ?
                                                        <ChangeCostReadyActivityMelees
                                                            showModalCostready={melesRow.showModalCostReady}
                                                            parentResponseCostReadyMelees={e => this.responseCostReadyMelees(e, index)}
                                                            cost={melesRow.melesCost}
                                                        />
                                                        :
                                                        (null)
                                                }
                                            </div>
                                        </div>

                                        {
                                            (this.state.showModalCostready) ?
                                                <ChangeCostreadyActivity
                                                    showModalCostready={this.state.showModalCostready}
                                                    parentResponseCostReady={this.responseCostReady}
                                                    cost={this.state.cost}
                                                />
                                                :
                                                (null)
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row">
                        <div className="col-8">
                        </div>
                        <div className="col-4">
                            <div>
                                <a className={"add_link"} onClick={this.addWorkflowSet}>
                                    <FontAwesomeIcon icon={faPlus} /> Add Melees
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    showModalCostready = (value) => {
        this.setState({
            showModalCostready: value
        })
    }

    showModalMetalCostReady = (value) => {
        this.setState({
            showModalMetalCostready: value
        })
    }

    showModalCostreadyMelees = (value, index) => {
        let state = {
            melesSets: this.state.melesSets
        }

        state.melesSets[index].showModalCostReady = value

        this.setState(state)
    }

    responseCostReady = (value) => {
        this.setState({
            readyToPayToProvider: value
        })

        this.showModalCostready(false)
    }

    responseMetalCostReady = (value) => {
        this.setState({
            metalCostReady: value
        })

        this.showModalMetalCostReady(false)
    }

    responseCostReadyMelees = (value, index) => {
        let state = {
            melesSets: this.state.melesSets
        }

        state.melesSets[index]['costReady'] = value

        this.setState(state)

        this.showModalCostreadyMelees(false, index)
    }

    addWorkflowSet = () => {
        let prevMelesSets = this.state.melesSets

        if (!Array.isArray(prevMelesSets)) {
            prevMelesSets = []
        }

        setTimeout(() => {
            this.setState({
                melesSets: [
                    ...prevMelesSets,
                    {
                        id: null,
                        workflowActivityId: this.state.activity.id,
                        melesCost: 0,
                        melesReference: '',
                        melesDetails: '',
                        melesSize: 0,
                        melesWeight: 0,
                        melesQuantity: 0,
                        mmdiaInvoice: '',
                        novaInvoice: '',
                        invoiceStatus: '',
                        costReady: false,
                        showModalCostReady: false
                    }
                ]
            })
        }, 1);
    }

    removeWorkflowSet(index) {
        let newMelesSets = this.state.melesSets.map(object => ({ ...object }))
        if (Array.isArray(newMelesSets)) {
            newMelesSets.splice(index, 1)
        }
        else {
            newMelesSets = []
        }
        console.log(newMelesSets)
        this.setState({
            melesSets: newMelesSets
        })
    }

    updateMelees = (melees) => {
        if (melees.length > 0) {
            this.setState({
                melesSets: melees
            })
        }
    }
}

export default SaleWorkflowEditSetting;