import React, {Component} from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap'
import Auth from '../../../Auth';
import axios from 'axios'
import FlashMessages from "../../FlashMessages";
import LoadingLayer from '../../LoadingLayer'
import ProductReferenceRow from '../../sales/ProductReferenceRow';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as globalConst from '../../../global/const';

// SCSS
import '../../../css/sales/sale_create.scss'

class SaleEditProductReferenceSearch extends Component {

    constructor(props){
        super(props);
        this.state = {
            products_references: [],
            total_results : 0,
            api_has_more : false,
            loading_list : false,
            show_product_reference_search_modal: false,
            selected_product_reference_index: null,
            disable_button: true
        }
        this.closeModal = this.closeModal.bind(this)
        this.selectProductReference = this.selectProductReference.bind(this)
    }

    filter_params = {}

    closeModal(selected_product_reference = null){
        this.props.closeComponent(selected_product_reference)
    }

    selectProductReference(event){
        try {
            const product_reference = this.state.products_references[this.state.selected_product_reference_index]
            let reference = product_reference.reference
            this.closeModal(reference)
        }
        catch (e)
        {
            console.log(e);
        }
    }

    handleClose = () => {
        this.closeModal(null)
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        return(
            <div>
                <Modal 
                    show={true}
                    onHide={this.handleClose}
                    dialogClassName="modal-90w"
                >
                    <Modal.Header closeButton>
                        SEARCH PRODUCT REFERENCE
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.loading_list === true ? 
                            (
                                <LoadingLayer/>
                            )
                            :
                            ('')
                        }
                        {
                            <FlashMessages
                                error_messages = {this.state.error_messages}
                                warning_messages = {this.state.warning_messages}
                                success_messages = {this.state.success_messages}
                            />
                        }
                        <Form id="SaleEditProductReferenceSearch_component">
                            <Form.Group>
                                <FormLabel>Search product reference:</FormLabel>
                                <input className='box-bg' type="text" onChange={(e) => this.handleFilterChange(e, 'searchBox')} onKeyPress={e => { if (e.key === 'Enter') e.preventDefault(); }} />
                            </Form.Group>
                            <InfiniteScroll
                                dataLength={this.state.products_references.length}
                                next={this.fetchMoreData}
                                hasMore={this.state.api_has_more}
                                height={'calc(50vh - 200px)'}
                            >
                                <table className="table table-striped table-sm table-hover" id="table_product_reference">
                                <thead className="thead-dark">
                                    <tr className='titles'>
                                        <th className="name">
                                            Name
                                        </th>
                                        <th className="reference">
                                            Reference
                                        </th>
                                        <th className="metal">
                                            Metal
                                        </th>
                                        <th className="diamondShape">
                                            Diamond shape
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.products_references.map((product_reference, index) => {
                                            return (
                                                <ProductReferenceRow
                                                    ref={ "productReferenceRow" + product_reference.id }
                                                    key={ product_reference.id }
                                                    product_reference={ product_reference }
                                                    index={ index }
                                                    handleRowSelection={ this.handleRowSelection }
                                                    selected_product_reference={this.state.selected_product_reference}
                                                />
                                            )
                                        })
                                    }
                                </tbody>
                                </table>
                            </InfiniteScroll>
                            <Button 
                                className="btn-default save-information-btn"
                                type="button"
                                onClick={this.selectProductReference}
                                disabled={this.state.loading_list || this.state.disable_button}
                            >
                                Select
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    handleRowSelection = (index) => {
        this.setState({
            disable_button: false,
            selected_product_reference : this.state.products_references[index],
            selected_product_reference_index : index
        })
    }

    openProductReferenceSearchPopup = () => {
        this.setState(
            {
                show_product_reference_search_modal: true
            }
        )
    }

    closeProductReferenceSearchPopup = () => {
        this.setState(
            {
                show_product_reference_search_modal: false
            }
        )
    }

    handleFilterChange = (e, field_name) => {
        let filter_value = e.target.value;
        let execute_filter = false;

        if(field_name === 'searchBox')
        {
            this.filter_params["name"] = filter_value;
            this.filter_params["reference"] = filter_value;
            execute_filter = true;
        }
        this.filter_params['page'] = 1;
        this.filter_params['max_results'] = 10;
        this.filter_params['order_by'] = 'created';
        this.filter_params['order_type'] = 'DESC';
        if(execute_filter === true) {
            this.fetchProductReference();
        }
    }

    ajaxRequest = null;
    fetchProductReference(){
        this.setState(
            {
                loading_list: true
            }
        )
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if(this.ajaxRequest !== null){
            this.ajaxRequest.cancel();
        }
        // creates a new token for upcomming ajax (overwrite the previous one)
        this.ajaxRequest = axios.CancelToken.source();
        //FINISH AJAX CONTROLS
        const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_PRODUCT_LIST
        axios.get(
            url,
            {
                params: this.filter_params,//this params are sent as query. In post requests they should go as body params in the following block
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            },
            {
                cancelToken: this.ajaxRequest.token
            })
            .then(api_response => {
                const result = api_response.data.result;
                const items = result.rows;

                // add list to previous items
                let list = []
                if(this.filter_params.page === 1){
                    list = items;
                    // window.scrollTo(0,0);
                    window.scrollTo({ top: 0 });
                }
                else{
                    let current_list = [
                        ...this.state.products_references
                    ]
                    list = current_list.concat(items);
                }

                this.setState({
                    products_references: list,
                    loading_list: false,
                    total_results: result.count,
                    api_has_more: result.amount_results > 0 ? true : false,
                    sheetType : this.filter_params.sheetType
                })
            })
            .catch(err => {
                if (err.response) {
                    if('status' in err.response && err.response.status === 401){
                        alert('User not authorised. Please reload the page');
                    }
                    else{
                        alert('There was a problem processing the information. Please reload this page and try again.');
                    }
                    this.setState(
                        {
                            loading_list: false
                        }
                    )
                } else if (err.request) {
                    alert('There was a problem with your request. Please reload this page and try again.');
                    this.setState(
                        {
                            loading_list: false
                        }
                    )
                } else {
                    //error not detected. Request could have been cancelled and it's waiting for second
                    //request to finish
                    this.setState(
                        {
                            loading_list: true
                        }
                    )
                }
                console.log(err);
            })
    }


    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    

}

export default SaleEditProductReferenceSearch;