export const addWorkflowType = (
    sales_logs_row_message,
    sales_log,
    sales_logs
) => {
    let typeResponse = findWorkflowActivityType(
        sales_log
    )

    if (!typeResponse) {
        typeResponse = findParentWorkflowActivityType(
            sales_log,
            sales_logs
        )
    }

    if (typeResponse) {
        sales_logs_row_message += ` (${typeResponse})`
    }

    return sales_logs_row_message
}

export const findWorkflowActivityType = (
    saleLog
) => {
    const { newInformation } = saleLog

    if (!newInformation) {
        return null
    }

    const { type } = newInformation

    if (!type) {
        return null
    }

    return type
}

export const findParentWorkflowActivityType = (
    saleLog,
    salesLogs
) => {
    const { newInformation } = saleLog

    if (!newInformation) {
        return null
    }

    const { workflowActivityId } = newInformation

    if (!workflowActivityId) {
        return null
    }

    const saleData = salesLogs.filter(salesLog => {
        const { newInformation } = salesLog

        if (newInformation.id === workflowActivityId) {
            return salesLog
        }
    })

    if (saleData.length <= 0) {
        return null
    }

    return findWorkflowActivityType(saleData[0])
}