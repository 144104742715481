import { useSelector } from "react-redux";
import { dateFormatCustom } from "../../../../utils/dateUtils";
import { NavLink, Table } from "react-bootstrap";

const WorkflowTableErros = () => {
  const { workflowSaleData } = useSelector((state) => state.workflowSale);

  const openSales = (id) => {
    window.open(`/sales?order-ref=${id}`);
  };

  return (
    <div
      className="overflow-scroll"
      style={{
        minHeight: "20%",
        maxHeight: 500,
      }}
    >
      <Table striped bordered hover className id="table_sales">
        <thead className="thead-dark">
          <tr className="titles">
            <th className="saleDate">Date of sale</th>
            <th className="saleDate">Order No.</th>
            <th className="saleDate">Type</th>
            <th className="saleDate">Status</th>
            <th className="saleDate">Error</th>
            <th className="saleDate">
              {" "}
              <NavLink
                onClick={() =>
                  openSales(
                    workflowSaleData.errors
                      .map((item) => item.sale.onlineOrderNumber)
                      ?.join(",")
                  )
                }
                className="link-primary"
              >
                Show all
              </NavLink>
            </th>
          </tr>
        </thead>
        <tbody>
          {workflowSaleData.errors.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  {dateFormatCustom(item.sale.saleDate, "DD MMM, YYYY HH:mm")}
                </td>
                <td>{item.sale.onlineOrderNumber}</td>
                <td>{item.sale.saleType}</td>
                <td>{item.sale.status}</td>
                <td>{item.messageError ?? ""}</td>
                <td>
                  <NavLink
                    onClick={() => openSales(item.sale.onlineOrderNumber)}
                    className="link-primary"
                  >
                    Show more
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default WorkflowTableErros;
