import React, {Component} from 'react';
import Dlogo from '../../image/round-logo-icon.png'
import { Link } from 'react-router-dom'
import Auth from '../../Auth';
import signoutIcon from '../../image/logout_icon.png';
import Util from '../../Util';
import { clearDocumentation } from '../../store/slices/categories';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

class Leftbardashboard extends Component {

    constructor(props){
        super();
        this.logout = this.logout.bind(this)
    }

    logout(){
        this.props.clearDocumentation();
        Auth.signout();
        this.props.pushHistory('/')
    }

    render(){
        const user = Auth.getAuthenticatedUser()
        let is_leader = false;
        if ("employeeModel" in user) {
            if ("isLeader" in user.employeeModel) {
                is_leader = user.employeeModel.isLeader
            }
        }

        let user_name = "User"
        let user_initials = "U"
        let user_roles = ""
        let user_is_leader = ""
        try {
            user_name = user.employeeModel.name
            user_roles = Util.DC2TypeToArray(user.roles);

            var names_array = user_name.split(' ')
            if (names_array.length === 1) {
                user_initials = names_array[0].substring(0, 1).toUpperCase()
            }
            else if (names_array.length > 1) {
                user_initials = names_array[0].substring(0, 1).toUpperCase() + names_array[1].substring(0, 1).toUpperCase();
            }

            if (is_leader) {
                user_is_leader = "Leader"
            }
        }
        catch (e) {
            console.log(e)
        }

        return(
            <section className="left-section">
                {/* <Link to="/dashboard" className="dashboard-link">
                    <img src={Dlogo} alt=""  className="dashboard-logo"/>
                    CRM
                </Link> */}
                <div className="left-section-buttons">
                    <Link to="/sales" className="dashboard-link">
                        <img src={Dlogo} alt=""  className="dashboard-logo"/>
                        ERP
                    </Link>
                    <button className="btn btn-default logout-button" onClick={this.logout}>
                        <img src={signoutIcon} alt=""  />
                        <p className='mb-0'>Log-Out</p>
                    </button>
                </div>
                {/* <div className="left-section-welcome">
                    <p>Welcome { user_name }</p>
                </div> */}
                {/* <Link to="/customers" className="dashboard-link">
                    <img src={Dlogo} alt=""  className="dashboard-logo"/>
                    Customers
                </Link>
                <a href="#" className="dashboard-link">
                    <img src={Dlogo} alt=""  className="dashboard-logo"/>
                    Users
                </a>
                <a href="#" className="dashboard-link">
                    <img src={Dlogo} alt=""  className="dashboard-logo"/>
                    Reports
                </a> */}
                <div className="dashboard-bottom-link">
                    <span className='letter'>{ user_initials }</span>
                    <span className='name'>{ user_name }</span>
                    {/* <span className='welcome'>Welcome</span> <span className='name'>{ user_name }</span>
                    <span className='roles'>Your role(s):</span> */}
                    {
                        user_roles.map((role, index) => {
                            let role_string = role.replace("ROLE_", "")
                            role_string = role_string.charAt(0).toUpperCase() + role_string.slice(1).toLowerCase();
                            return (
                                <span key={index} className=''>({ role_string.replace("ROLE_", "") })</span>
                            )
                        })
                    }
                    <span className='name'>{ user_is_leader }</span>
                </div>
            </section>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    clearDocumentation
}, dispatch);

export default connect(null, mapDispatchToProps)(Leftbardashboard);
