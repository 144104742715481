import { formatCustomerCreationDate } from "../../../utils/dateUtils";

const MergeCustomerCard = ({ originCustomer, destinationCustomer }) => {
    const combineString = (stringOrigin, stringDestination) => {
        return (stringOrigin === stringDestination) ? stringOrigin : ((stringOrigin && stringDestination) ? `${stringOrigin}, ${stringDestination}` : stringOrigin || stringDestination || null);
    }
    const buildMiddleRow = (name, value) => {
        return (
            <div key={name} className="row border-bottom">
                <div className="p-1 col-sm table_title border-right ">
                    <span className="table_title">{name}</span>
                </div>
                <div className="p-1 col-sm">{value}</div>
            </div>
        );
    };

    return (
        <div className="general_information customer-details-card">
            <h2>{destinationCustomer.customerSales.length > 0 ? "Customer" : "Lead"}</h2>
            <div className="customer-details-container p-2">
                <div className="container">
                    {buildMiddleRow("Customer Name", combineString(destinationCustomer.name, originCustomer.name))}
                    {buildMiddleRow("Email", combineString(destinationCustomer.email, originCustomer.email))}
                    {buildMiddleRow("Phone", combineString(destinationCustomer.phone, originCustomer.phone))}
                    {buildMiddleRow("Ethnicity", combineString(destinationCustomer.ethnicity, originCustomer.ethnicity))}
                    {buildMiddleRow("How Heard About Us", combineString(destinationCustomer.howHeardAboutUs, originCustomer.howHeardAboutUs))}
                    {buildMiddleRow("Reasons for not buying", combineString(destinationCustomer.reasonNotBuying, originCustomer.reasonNotBuying))}
                    {buildMiddleRow(
                        "Created",
                        formatCustomerCreationDate(destinationCustomer.created)
                    )}
                </div>
            </div>
        </div>
    );
};
export default MergeCustomerCard;