import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axiosServices from "../../../axios";
import AppointmentInformation from "./appointments";
import CustomerSales from "./sales";
import PostSalesServices from "./post-sales-services";

const Result = ({ originCustomer, destinationCustomer }) => {
    const [customerDetails, setCustomerDetails] = useState(null);
    const dispatch = useDispatch();
    
    const fetchCombineCustomerDetails = async (originCustomerId, destinationCustomerId) => {
        const response = await axiosServices.get(
            `/api/v1/customers/combine-customer/${originCustomerId}/${destinationCustomerId}`
        );
        return response.data.value;
    };

    useEffect(() => {
        fetchCombineCustomerDetails(originCustomer.id, destinationCustomer.id).then((data) => {
            setCustomerDetails(data);
        });
    }, [originCustomer.id, destinationCustomer.id, dispatch]);

    return (
        <>
            {customerDetails &&
                <>
                    <AppointmentInformation customerAppointments={customerDetails.customerAppointments} />
                    <CustomerSales customerSales={customerDetails.customerSales} />
                    <PostSalesServices customerSales={customerDetails.customerSales} />
                </>
            }
        </>
    )
}

export default Result;