import Util from "../../../../Util";

export const GetSaleDiamonds = (sale) => {
  const saleDiamondExist = ExistSaleDiamonds(sale);
  let searchDetailDiamond = true;
  let { saleDiamondModels, sdm } = sale;

  let saleDiamonds = saleDiamondModels;

  if (sdm) {
    saleDiamonds = sdm;
  }

  if (!saleDiamondExist) {
    const diamondDetails = Util.splitDiamonds(sale.diamondDetails);

    searchDetailDiamond = false;
    saleDiamonds = [
      {
        id: sale.id,
        diamondProductModel: {
          certNo: diamondDetails.length > 0 ? diamondDetails[0] : null,
        },
        cost: sale.diamondCost,
        internalInvoice: sale.xeroCodeMmdia,
        novaInvoice: sale.xeroCodeNova,
        accountingStatus: sale.accountingStatus,
        deletedFromSales: false,
      },
    ];
  }

  const saleDiamondsResponse = saleDiamonds.filter(
    (saleDiamond) => !saleDiamond.deletedFromSales
  );

  return {
    saleDiamondModels: saleDiamondsResponse,
    searchDetailDiamond,
  };
};

export const ExistSaleDiamonds = (sale) => {
  const { saleDiamondModels, sdm } = sale;

  if (!saleDiamondModels && !sdm) {
    return false;
  }

  if (sdm && Array.isArray(sdm) && sdm.length > 0) {
    return true;
  }

  if (
    saleDiamondModels &&
    Array.isArray(saleDiamondModels) &&
    saleDiamondModels.length > 0
  ) {
    return true;
  }

  return false;
};

export const GetSaleDiamondDetail = (diamond, sale, searchDetailDiamond) => {
  if (!diamond) {
    return "";
  }

  if (!searchDetailDiamond) {
    return diamond;
  }

  let { diamondDetails } = sale;

  if (!diamondDetails) {
    return diamond;
  }

  diamondDetails = diamondDetails.split(",");

  let diamondDetailData = "";

  diamondDetails.map((diamondDetail) => {
    const position = diamondDetail.search(diamond);

    if (position > -1) {
      diamondDetailData = diamondDetail;
    }

    return null;
  });

  return diamondDetailData;
};

export const GetSaleForDetails = (sale) => {
  let { diamondDetails = "" } = sale;

  if (!diamondDetails) {
    return "";
  }

  return diamondDetails.split(",");
};

export const GetSaleDiamondDetailsObject = (diamondProduct) => {
  if (!diamondProduct) {
    return "";
  }

  if (typeof diamondProduct !== "object") {
    return "";
  }

  const { certNo = "", carat = "" } = diamondProduct;
  const shape = diamondProduct?.dsm?.name ?? "";
  const color = diamondProduct?.dc1m?.name ?? "";
  const clarity = diamondProduct?.dc2m?.name ?? "";

  return `${certNo} ${shape} ${carat} ${clarity} ${color}`;
};
