import { Form, FormLabel } from "react-bootstrap"
import React, {Component} from 'react';

class PostSaleCostsRowEdit extends Component {

    constructor(props){
        super();
        this.state = {
            index : props.index,
            cost : '',
            jewellersInvoice: ''
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event){
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        if (event.target.value !== this.state[field_name]) {
            state["fields_no_updated"] = false
        }
        this.setState(state)
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        return(
            <Form.Group className="form_group_create card mx-4">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-10">
                                <div className="sale_title alight-center bold_font">Jeweller #{(this.state.index + 1)}</div>
                            </div>
                        </div>
                        <Form.Group>
                            <FormLabel>Jeweller’s Cost</FormLabel>
                            <Form.Control
                                required={true}
                                type="number"
                                name="cost"
                                value={this.state.cost === null ? '' : this.state.cost}
                                onChange={this.handleChange}
                                onBlur={(e) => {
                                    this.props.updateJeweller(this.state.index, {"cost": this.state.cost})
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <FormLabel>Jeweller’s Invoice</FormLabel>
                            <Form.Control
                                type="text"
                                name="jewellersInvoice"
                                value={this.state.jewellersInvoice === null ? '' : this.state.jewellersInvoice}
                                onChange={this.handleChange}
                                onBlur={(e) => {
                                    this.props.updateJeweller(this.state.index, {"jewellersInvoice": this.state.jewellersInvoice})
                                }}
                            />
                        </Form.Group>
                    </div>
                </div>
            </Form.Group>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount(){
        this.setState({
            cost: this.props.jeweller.cost,
            jewellersInvoice: this.props.jeweller.jewellersInvoice,
        })
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    
}

export default PostSaleCostsRowEdit;