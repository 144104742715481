import React, { useState } from 'react'
import PropTypes from 'prop-types'

const RowSearchSaleDiamond = props => {
    const { sales, onResponse } = props

    const [saleSelected, setSaleSelected] = useState(null)

    const handleSaleSelected = async (sale) => {
        setSaleSelected(null)
        setSaleSelected(sale)

        onResponse(sale)
    }

    const isSelected = (sale) => {
        if (!sale) {
            return 'add-task-button'
        }

        if (!saleSelected) {
            return 'add-task-button'
        }

        if (sale.id === saleSelected.id) {
            return 'add-task-button highlighted_row'
        }

        return 'add-task-button'
    }

    return (
        <>
            {
                sales.map(sale => {
                    return (
                        <tr
                            key={sale.id}
                            className={isSelected(sale)}
                            onClick={(e) => handleSaleSelected(sale)}
                        >
                            <td>
                                {sale.name}
                            </td>
                            <td>
                                {sale.onlineOrderNumber}
                            </td>
                            <td>
                                {sale.diamondDetails}
                            </td>
                            <td>
                                {sale.totalDiamondWogst}
                            </td>
                            <td>
                                {sale.saleType}
                            </td>
                            <td>
                                {sale.totalSettingWogst}
                            </td>
                            <td>
                                {sale.expressManufacturingWogst}
                            </td>
                            <td>
                                {sale.engravingWogst}
                            </td>
                        </tr>
                    )
                })
            }
        </>
    )
}

RowSearchSaleDiamond.propTypes = {
    sales: PropTypes.any.isRequired,
    onResponse: PropTypes.func.isRequired,
}

export default RowSearchSaleDiamond