import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Util from '../../../../Util';

class ChangeCostReadyActivityMelees extends Component {

    constructor(props) {
        super();

        this.state = {
            showModalCostready: props.showModalCostready,
            cost: props.cost ?? 0,
            readyToPayToProvider: false
        }

    }

    render() {
        return (
            <Modal
                show={this.state.showModalCostready}
                centered
            >
                <Modal.Header>
                    Are you sure that the cost is {Util.formatCurrency(this.state.cost)} and it's ready?
                </Modal.Header>
                <Modal.Body>
                    <p>This can not be undone.</p>
                    <Button
                        onClick={() => this.responseCostReady(true)}
                        className="m-2"
                        variant="outline-primary">
                        Yes
                    </Button>
                    <Button
                        onClick={() => this.responseCostReady(false)}
                        variant="outline-secondary"
                        className='m-2'>
                        No
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.responseCostReady(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    responseCostReady = (value) => {
        this.props.parentResponseCostReadyMelees(value);
    }
}

export default ChangeCostReadyActivityMelees;