import React, {Component} from 'react';
import { Modal, Button, Form, FormLabel } from 'react-bootstrap';

// SCSS
import '../../css/sales/sale_create.scss'

class EditSettingStatusModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            listCount: props.listCount,
        }
        this.closeModal = this.closeModal.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.editSettingStatusCustomisedList = this.editSettingStatusCustomisedList.bind(this)
    }

    closeModal(){
        this.props.closeEditSettingStatusModal()
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        return(
            <div id="SaleEditionRequirementModal_component">
                <Modal 
                    show={true}
                    onHide={this.closeModal}
                    dialogClassName="modal-90w"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        Confirm
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className='form-group'>
                                <FormLabel>Are you sure you want to change the status for {this.state.listCount} records to "In transit"?</FormLabel>
                            </Form.Group>
                            <Button 
                                type="button" className='btn btn-default confirm-btn'
                                onClick={this.editSettingStatusCustomisedList}
                            >
                                Yes
                            </Button>
                            <Button className='btn btn-default disagree-btn'
                                type="button"
                                onClick={this.closeModal}
                            >
                                No
                            </Button>
                        </Form>



                    </Modal.Body>
                </Modal>
            </div>
        )
    }

    handleChange(event){
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    editSettingStatusCustomisedList = () => {
        this.props.editSettingStatusCustomisedList()
        this.closeModal()
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    

}

export default EditSettingStatusModal;