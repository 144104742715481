import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointLeft } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom';
import SalesComments from '../../sales/saleComment/SaleComments';
import SaleInformationAccounts from '../../sales/saleInformation/SaleInformationAccounts';
import SaleInformationGeneral from '../../sales/saleInformation/SaleInformationGeneral';
import SaleInformationProduct from '../../sales/saleInformation/SaleInformationProduct';
import SaleInformationProduction from '../../sales/saleInformation/SaleInformationProduction';
import SaleInformationSalesMarketing from '../../sales/saleInformation/SaleInformationSalesMarketing';
import SaleInformationCosts from '../../sales/saleInformation/SaleInformationCosts';
import PostSalesComments from '../../post_sale_services/postSaleComment/PostSaleComments';
import PostSaleInformationRequest from '../../post_sale_services/postSaleInformation/PostSaleInformationRequest';
import Auth from '../../../Auth';
import EditIcon from '../../../image/Edit.png';
import detailsIcon from '../../../image/see_details.png';

// SCSS
import '../../../css/sales/sale_information.scss'

class AccountingInformation extends Component {

    last_rendered_accounting = null

    constructor(props){
        super();
        this.state = {
            accounting: props.accounting,
            accounting_comments: [],
            loading_comments: false,
            openEditionComponent : props.openEditionComponent,
        }
        this.editAccounting = this.editAccounting.bind(this)
    }

    editAccounting(){
        this.state.openEditionComponent(this.state.accounting)
    }

    render(){
        const accounting = this.state.accounting;

        var access_to_edit_general = Auth.isAuthorised("sales_edit_general");
        var access_to_edit_product = Auth.isAuthorised("sales_edit_product");
        var access_to_edit_production = Auth.isAuthorised("sales_edit_production");
        var access_to_edit_accounts = Auth.isAuthorised("sales_edit_accounts");
        var access_to_edit_sales_and_marketing = Auth.isAuthorised("sales_edit_sales_and_marketing");
        var access_to_edit = true;
        if (!access_to_edit_general
            && !access_to_edit_product
            && !access_to_edit_production
            && !access_to_edit_accounts
            && !access_to_edit_sales_and_marketing) {
                access_to_edit = false;
        }

        if(accounting === null){
            return(
                <div id="SaleInformation_component">
                    <div className="choose_message">
                        Choose an item from the list to find more
                        <br/>
                        <span>
                            <FontAwesomeIcon icon={faHandPointLeft} />
                        </span>
                    </div>
                </div>
            );
        }

        let onlineOrderNumber
        if (this.state.accounting.salesModel !== null) {
            onlineOrderNumber = this.state.accounting.salesModel.onlineOrderNumber
        }
        if (this.state.accounting.postSaleServiceModel !== null
            && typeof this.state.accounting.postSaleServiceModel !== "undefined") {
            if (this.state.accounting.postSaleServiceModel.salesModel !== null
                && typeof this.state.accounting.postSaleServiceModel.salesModel !== "undefined") {
                if ('onlineOrderNumber' in this.state.accounting.postSaleServiceModel.salesModel) {
                    onlineOrderNumber = this.state.accounting.postSaleServiceModel.salesModel.onlineOrderNumber
                }
            }
        }

        return(
            <div id="SaleInformation_component">
                <div className='information'>
                    <div className='details-top-heading'>
                        <div className='order-no-section'>
                            <h1>ORDER #{ onlineOrderNumber }</h1>
                        </div>
                        <div className='order-action-section'>
                            {
                                access_to_edit === true ?
                                    <div className="link_to_edit">
                                        <button className='btn' onClick={this.editAccounting}>
                                           EDIT Accounting <img src={EditIcon} alt="" />
                                        </button>
                                    </div>
                                : (null)
                            }
                            {
                                (this.state.accounting.salesModel !== null) ? (
                                    <div className="link_to_detail">
                                        <Link to={`/sale/${accounting.salesModel.id}`} target="_blank">
                                            See All Details <img src={detailsIcon} alt="" />
                                        </Link>
                                    </div>
                                ) : (null)
                            }
                            {/*
                                (this.state.accounting.postSaleServiceModel !== null) ? (
                                    <div className="link_to_detail">
                                        <Link to={`/sale/${accounting.postSaleServiceModel.salesId}`} target="_blank">
                                            See All Details <img src={detailsIcon} alt="" />
                                        </Link>
                                    </div>
                                ) : (null)
                            */}
                            
                        </div>
                    </div>
                    {
                        (this.state.accounting.salesModel !== null) ? (
                            <SalesComments
                                sale={this.state.accounting.salesModel}
                                isAccounting = { true }
                            />
                        ) : (null)
                    }
                    {
                        (this.state.accounting.postSaleServiceModel !== null) ? (
                            <PostSalesComments
                                sale={this.state.accounting.postSaleServiceModel}
                                isAccounting = { true }
                            />
                        ) : (null)
                    }
                    {
                        (this.state.accounting.salesModel !== null) ? (
                            <div className="general_information sales-details-card">
                                <h2>General Information</h2>
                                <SaleInformationGeneral
                                    sale = {this.state.accounting.salesModel}
                                />
                            </div>
                        ) : (null)
                    }
                    {
                        (this.state.accounting.salesModel !== null) ? (
                            <div className="product_information sales-details-card">
                                <h2>Product</h2>
                                <SaleInformationProduct
                                    sale = {this.state.accounting.salesModel}
                                />
                            </div>
                        ) : (null)
                    }
                    {
                        (this.state.accounting.salesModel !== null) ? (
                            <div className="production_information sales-details-card">
                                <h2>Production</h2>
                                <SaleInformationProduction
                                    sale = {this.state.accounting.salesModel}
                                    isAccounting = {true}
                                />
                            </div>
                        ) : (null)
                    }
                    {
                        (this.state.accounting.salesModel !== null) ? (
                            <div className="accounts_information sales-details-card">
                                <h2>Accounts</h2>
                                <SaleInformationAccounts
                                    sale = {this.state.accounting.salesModel}
                                    isAccounting = {true}
                                />
                            </div>
                        ) : (null)
                    }
                    {
                        (this.state.accounting.salesModel !== null) ? (
                            <div className="sales_and_marketing_information sales-details-card">
                                <h2>Sales and Marketing</h2>
                                <SaleInformationSalesMarketing
                                    sale = {this.state.accounting.salesModel}
                                />
                            </div>
                        ) : (null)
                    }
                    {
                        (this.state.accounting.salesModel !== null) ? (
                            <div className="costs_information sales-details-card">
                                <h2>Costs</h2>
                                <SaleInformationCosts
                                    sale = {this.state.accounting.salesModel}
                                />
                            </div>
                        ) : (null)
                    }

                    {
                        (this.state.accounting.postSaleServiceModel !== null) ? (
                            <div className="accounts_information sales-details-card">
                                <h2>REQUEST</h2>
                                <PostSaleInformationRequest
                                    sale = {this.state.accounting.postSaleServiceModel}
                                />
                            </div>
                        ) : (null)
                    }
                    {/*
                    <div className="general_information sales-details-card">
                        <h2>General Information</h2>
                        <AccountingInformationGeneral
                            accounting = {this.state.accounting}
                        />
                    </div>
                    <div className="product_information sales-details-card">
                        <h2>Product</h2>
                        <AccountingInformationProduct
                            accounting = {this.state.accounting}
                        />
                    </div>
                    <div className="production_information sales-details-card">
                        <h2>Production</h2>
                        <AccountingInformationProduction
                            accounting = {this.state.accounting}
                        />
                    </div>
                    <div className="accounts_information sales-details-card">
                        <h2>Accounts</h2>
                        <AccountingInformationAccounts
                            accounting = {this.state.accounting}
                        />
                    </div>
                    <div className="sales_and_marketing_information sales-details-card">
                        <h2>Sales and Marketing</h2>
                        <AccountingInformationSalesMarketing
                            accounting = {this.state.accounting}
                        />
                        </div>*/}
                </div>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount(){
        //execute once the first time
    }

    componentDidUpdate(){
        if(this.state.accounting !== null){
            this.last_rendered_accounting = this.state.accounting
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        //only updates the render if the sale to show in the information is different than the one that is already shown
        const next_accounting = nextState.accounting
        if(nextState.accounting === null){
            return false;
        }
        else if(this.last_rendered_accounting === next_accounting){
            return false;
        }
        else{
            return true;
        }
    }

    static getDerivedStateFromProps(props, state) {
        //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
        //return values for the state
        return{
            accounting: props.accounting,
        }
    }
    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

}

export default AccountingInformation;