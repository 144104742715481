import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Table } from "react-bootstrap";
import CommissionsRow from "./CommissionsRow";
import {
  getSaleCommissions,
  getSaleCommissionsDetail,
} from "./Data/FindSaleCommission";
import CommissionsEdit from "./CommissionsEdit";
import LoadingSpinnerLayer from "../../LoadingSpinnerLayer";
import {
  SALE_COMMISSION_TYPE_REVIEWS,
  SALE_COMMISSION_TYPE_SALES,
} from "../../../global/const";

const Commissions = ({ sale, showAction, showAll, type }) => {
  const [saleCommissions, setSaleCommissions] = useState([]);
  const [findSaleCommissions, setFindSaleCommissions] = useState(false);
  const [saleCommission, setSaleCommission] = useState(null);
  const [showEdit, setShowEdit] = useState(false);

  const actionModel = (value, saleCommission) => {
    setSaleCommission(saleCommission);
    setShowEdit(value);

    saleCommissions.map((saleCommissionItem) => {
      if (saleCommissionItem === saleCommission.id) {
        saleCommissionItem.paidDate = saleCommission.paidDate;
        saleCommissionItem.commissionPercentage =
          saleCommission.commissionPercentage;
        saleCommissionItem.commissionValue = saleCommission.commissionValue;
      }

      return saleCommissionItem;
    });
  };

  useEffect(() => {
    if (!findSaleCommissions) {
      setFindSaleCommissions(true);

      async function fetchData() {
        if (showAll) {
          setSaleCommissions(await getSaleCommissions(sale, type));
        } else {
          setSaleCommissions(await getSaleCommissionsDetail(sale, type));
        }
      }

      fetchData();
    }
  }, [sale, saleCommissions, findSaleCommissions, showAll, type]);

  const onResponseCommissions = (value) => {
    setFindSaleCommissions(value);
  };

  return (
    <Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Employee</th>
            <th>Type</th>
            {type === SALE_COMMISSION_TYPE_SALES && (
              <>
                <th>Commission</th>
                <th>Commission percentage</th>
              </>
            )}
            {type === SALE_COMMISSION_TYPE_REVIEWS && (
              <>
                <th>Commission base</th>
              </>
            )}
            <th>Commission value</th>
            <th>Paid date</th>
            {showAction ? <th>Actions</th> : null}
          </tr>
        </thead>
        <tbody>
          <CommissionsRow
            sale={sale}
            saleCommissions={saleCommissions}
            onActionModel={actionModel}
            showAction={showAction}
            onResponse={onResponseCommissions}
            type={type}
          />

          {!findSaleCommissions && saleCommissions.length <= 0 ? (
            <tr>
              <td colSpan={showAction ? 5 : 4}>
                <div className="center-element">
                  <LoadingSpinnerLayer />
                </div>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>

      {showEdit ? (
        <CommissionsEdit
          sale={sale}
          showSaleComissionEdit={showEdit}
          saleCommission={saleCommission}
          onActionModel={actionModel}
          total={saleCommissions.length}
          type={type}
        />
      ) : null}
    </Row>
  );
};

Commissions.propTypes = {
  sale: PropTypes.any.isRequired,
  showAction: PropTypes.bool,
  showAll: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

Commissions.defaultProps = {
  showAction: true,
  showAll: true,
  type: SALE_COMMISSION_TYPE_SALES,
};

export default Commissions;
