import axios from "axios";
import { sprintf } from "sprintf-js";
import * as globalConst from "../../../../global/const";
import Auth from "../../../../Auth";

export const getSaleCommissions = async (sale, type) => {
  try {
    const { id } = sale;
    const url = sprintf(
      "%s%s",
      process.env.REACT_APP_NOVITAERP_API_DOMAIN,
      globalConst.REACT_APP_NOVITAERP_API_SALE_COMMISSIONS
    );

    const parameters = {
      saleId: id,
      type: type,
    };

    return await axios
      .get(url, {
        params: parameters,
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((response) => {
        const { data } = response;
        const { value } = data;

        if (Array.isArray(value) === true && value.length > 0) {
          return value;
        }

        return [];
      })
      .catch(() => {
        return [];
      });
  } catch (error) {
    return [];
  }
};

export const getSaleCommissionsDetail = async (sale, type) => {
  try {
    const { id } = sale;
    const url = sprintf(
      "%s%s",
      process.env.REACT_APP_NOVITAERP_API_DOMAIN,
      globalConst.REACT_APP_NOVITAERP_API_SALE_COMMISSIONS
    );

    const user = Auth.getAuthenticatedUser();

    const parameters = {
      saleId: id,
      employeeName: user.employeeModel.name,
      type: type,
    };

    return await axios
      .get(url, {
        params: parameters,
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((response) => {
        const { data } = response;
        const { value } = data;

        if (Array.isArray(value) === true && value.length > 0) {
          return value;
        }

        return [];
      })
      .catch(() => {
        return [];
      });
  } catch (error) {
    return [];
  }
};

export const getEmployeeRates = (employee) => {
  if (!employee) {
    return;
  }

  const { employeeCommissionRateModels = [] } = employee;

  return employeeCommissionRateModels;
};
