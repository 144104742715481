import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Form, FormLabel, Row } from "react-bootstrap";
import DividerDashed from "../../Divider/DividerDashed";
import Util from "../../../Util";
import FindSaleExchange from "./FindSaleExchange";

const SaleCreatePricesData = ({ saleData, onResponse }) => {
  const [sale, setSale] = useState(saleData);
  const [showAddSales, setShowAddSales] = useState(false);
  const [saleOriginal, setSaleOriginal] = useState(null);

  const listAccountCurrency =
    process.env.REACT_APP_ACCOUNTS_CURRENCY.split(",");
  const listAccountInvoiceStatus =
    process.env.REACT_APP_INVOICE_STATUS.split(",");

  const handleChange = (e) => {
    const data = sale;

    const { value, name } = e.target;

    data[name] = value;

    setSale((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    onResponse(value, name);
  };

  const handleChangeCheckbox = (event) => {
    const { name, checked } = event.target;

    setSale((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    onResponse(checked, name);
  };

  const handleSelectSale = (sale) => {
    setSaleOriginal(sale);

    const { id, totalSettingWogst, totalDiamondWogst, discountValue, balance } =
      sale;

    const valueSum = [totalSettingWogst, totalDiamondWogst].reduce((a, b) => {
      let valueA = a ? a.toString().replace(",", "") : 0;
      let valueB = b ? b.toString().replace(",", "") : 0;

      return parseFloat(Number(valueA)) + parseFloat(Number(valueB));
    });

    const valueRest = [discountValue, balance].reduce((a, b) => {
      let valueA = a ? a.toString().replace(",", "") : 0;
      let valueB = b ? b.toString().replace(",", "") : 0;

      return parseFloat(Number(valueA)) + parseFloat(Number(valueB));
    });

    const amountAlreadyPaid = Util.numberDecimal(valueSum - valueRest);

    setSale((prevState) => ({
      ...prevState,
      amountAlreadyPaid: amountAlreadyPaid,
      saleOriginal: id,
    }));

    onResponse(amountAlreadyPaid, "amountAlreadyPaid");
    onResponse(id, "originalSaleId");
  };

  return (
    <Card className="mt-2">
      <Card.Body>
        <Card.Title>Prices Information</Card.Title>
        <Row>
          <Col sm="12" lg="12">
            <Form.Check
              type="checkbox"
              name="pricesIncludeGst"
              label={"Prices Include " + process.env.REACT_APP_TAX_NAME}
              checked={sale.pricesIncludeGst ?? false}
              onChange={handleChangeCheckbox}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Total Diamond(s)</FormLabel>
              <Form.Control
                type="number"
                name="totalDiamondWogst"
                value={sale.totalDiamondWogst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Total Jewellery</FormLabel>
              <Form.Control
                type="number"
                name="totalSettingWogst"
                value={sale.totalSettingWogst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Express Manufacturing</FormLabel>
              <Form.Control
                type="number"
                name="expressManufacturingWogst"
                value={sale.expressManufacturingWogst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Engraving</FormLabel>
              <Form.Control
                type="number"
                name="engravingWogst"
                value={sale.engravingWogst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Total Invoice</FormLabel>
              <Form.Control
                type="number"
                name="totalIncGst"
                value={sale.totalIncGst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Balance</FormLabel>
              <Form.Control
                type="number"
                name="balance"
                value={sale.balance ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Order Currency</FormLabel>
              <Form.Select
                onChange={(e) => handleChange(e)}
                value={sale.orderCurrency ?? ""}
                name="orderCurrency"
              >
                <option value=""></option>
                {listAccountCurrency.map((listAccountCurrency) => {
                  return (
                    <option
                      key={listAccountCurrency}
                      value={listAccountCurrency}
                    >
                      {listAccountCurrency}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Invoice Status</FormLabel>
              <Form.Select
                onChange={(e) => handleChange(e)}
                value={sale.invoice ?? ""}
                name="invoice"
              >
                <option value=""></option>
                {listAccountInvoiceStatus.map((listInvoiceStatus) => {
                  return (
                    <option key={listInvoiceStatus} value={listInvoiceStatus}>
                      {listInvoiceStatus}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <DividerDashed />
        <Row>
          <Col sm="12" lg="12">
            <Form.Text muted>
              This order is created as an exchange from a return{" "}
              <b>{saleOriginal ? saleOriginal.onlineOrderNumber : ""}</b>
            </Form.Text>
            {showAddSales && (
              <FindSaleExchange
                closeComponent={() => {
                  setShowAddSales(false);
                }}
                setSelectedSale={handleSelectSale}
              />
            )}
          </Col>
          <Col sm="12" lg="12">
            <Button
              className="save-information-btn btn btn-primary"
              onClick={() => setShowAddSales(true)}
            >
              Find Original order
            </Button>
          </Col>
        </Row>
        {saleOriginal && (
          <Row className="mt-1">
            <Col sm="12" lg="4">
              <Form.Group className="form-group">
                <FormLabel>Already paid by customer</FormLabel>
                <Form.Control
                  type="number"
                  name="amountAlreadyPaid"
                  value={sale.amountAlreadyPaid ?? ""}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

SaleCreatePricesData.propTypes = {
  saleData: PropTypes.any.isRequired,
  onResponse: PropTypes.func.isRequired,
};

export default SaleCreatePricesData;
