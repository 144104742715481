import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS_PENDING } from "../../store/constants";

import LoadingLayer from "../LoadingLayer";
import CustomersHeader from "./header";
import CustomersList from "./list";

import "../../css/crm/crm.scss";
import "../../css/crm/customer_information.scss";
import CustomerInformation from "./information";
import axios from "axios";
import { fetchComments } from "../../store/slices/commentsSlice";
import { selectCustomer } from "../../store/slices";

export default function CRMView(props) {
  const { status } = useSelector((state) => state.customers);
  const [cancelToken, setCancelToken] = useState(null);

  const customer = useSelector((state) => state.customers.selectedCustomer);

  const dispatch = useDispatch();

  const handleSelection = (customer) => {
    if (cancelToken) {
      cancelToken.cancel();
      setCancelToken(axios.CancelToken.source());
    }
    dispatch(selectCustomer(customer));
    dispatch(fetchComments({ customerId: customer.id, cancelToken }));
  };

  return (
    <div id="crm_component">
      <div className="container_crm">
        {status === API_STATUS_PENDING && <LoadingLayer />}
        <div className="list_and_information">
          <div className="row">
            <div className="crm_list">
              <CustomersHeader />
              <CustomersList selected={customer} onSelected={handleSelection} />
            </div>
            <div id="CRMInformation_component">
              <CustomerInformation customer={customer} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
