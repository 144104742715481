import Util from "../../../../Util"

export const CalculateValueDiamondTotal = (
    diamonds
) => {
    let result = 0

    const results = diamonds.map(diamond => {
        const total = CalculateValueTotalPrice(diamond)
        return total
    })

    if (results.length <= 0) {
        return result
    }

    result = results.reduce((a, b) => {
        let valueA = a ? a.toString().replace(',', '') : 0
        let valueB = b ? b.toString().replace(',', '') : 0

        return parseFloat(valueA) + parseFloat(valueB);
    });

    return Util.numberDecimal(result)
}

export const CalculateValueDiscountPrice = (
    diamond
) => {
    const { soldPrice, discountPercentage } = diamond

    if (!soldPrice) {
        return null
    }

    if (!discountPercentage) {
        return null
    }

    const result = (soldPrice * discountPercentage) / 100

    return Util.numberDecimal(result)
}

export const CalculateValueDiscountPercentage = (
    diamond
) => {
    const { soldPrice, discountPrice } = diamond

    if (!soldPrice) {
        return null
    }

    if (!discountPrice) {
        return null
    }

    const result = (100 * discountPrice) / soldPrice

    return Util.numberDecimal(result)
}

export const CalculateValueTotalPrice = (
    diamond
) => {
    let { soldPrice = 0, discountPrice = 0 } = diamond

    discountPrice = discountPrice ? discountPrice : 0
    soldPrice = soldPrice ? soldPrice : 0
    
    return parseFloat(soldPrice) - parseFloat(discountPrice)
}