import React, { Component } from "react";
import { Modal, Button, Form, FormLabel } from "react-bootstrap";
import Auth from "../../../Auth";
import axios from "axios";
import FlashMessages from "../../FlashMessages";
import LoadingLayer from "../../LoadingLayer";
import DiamondRow from "../../sales/DiamondRow";
import InfiniteScroll from "react-infinite-scroll-component";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_create.scss";

const CancelToken = axios.CancelToken;
let cancel;

class SaleEditDiamondSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diamonds: [],
      total_results: 0,
      api_has_more: false,
      loading_list: false,
      show_diamond_search_modal: false,
      selected_diamond_index: null,
      disable_button: true,
    };
    this.closeModal = this.closeModal.bind(this);
    this.selectDiamond = this.selectDiamond.bind(this);
  }

  filter_params = {};

  closeModal(diamond = {}) {
    this.props.closeComponent(diamond);
  }

  selectDiamond() {
    try {
      const diamond = this.state.diamonds[this.state.selected_diamond_index];

      this.closeModal(diamond);
    } catch (e) {
      console.log(e);
    }
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    return (
      <div>
        <Modal show={true} onHide={this.closeModal} dialogClassName="modal-90w">
          <Modal.Header closeButton>SEARCH DIAMOND</Modal.Header>
          <Modal.Body>
            {this.state.loading_list === true ? <LoadingLayer /> : ""}
            {
              <FlashMessages
                error_messages={this.state.error_messages}
                warning_messages={this.state.warning_messages}
                success_messages={this.state.success_messages}
              />
            }

            {this.validPermmissionsForEditDiamonDetails() ? (
              <Form id="SaleEditDiamondSearch_component">
                <Form.Group>
                  <FormLabel>Diamond certificate:</FormLabel>
                  <input
                    className="box-bg"
                    type="text"
                    onChange={(e) =>
                      this.handleFilterChange(e, "generalSearch")
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                  />
                </Form.Group>
                <InfiniteScroll
                  dataLength={this.state.diamonds.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.api_has_more}
                  height={"calc(50vh - 200px)"}
                >
                  <table
                    className="table table-striped table-sm table-hover"
                    id="table_diamonds"
                  >
                    <thead className="thead-dark">
                      <tr className="titles">
                        <th className="certNo">Cert No.</th>
                        <th className="shape">Shape</th>
                        <th className="carat">Carat</th>
                        <th className="colour">Colour</th>
                        <th className="clarity">Clarity</th>
                        <th className="cut">Cut</th>
                        <th className="codeGD">Code GD</th>
                        <th className="location">Location</th>
                        <th className="location">Found in GD</th>
                        <th className="location">Found in ND</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.diamonds.map((diamond, index) => {
                        return (
                          <DiamondRow
                            ref={"diamondRow" + diamond.id}
                            key={diamond.id}
                            diamond={diamond}
                            index={index}
                            handleRowSelection={this.handleRowSelection}
                            selected_diamond={this.state.selected_diamond}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </InfiniteScroll>
                <Button
                  className="btn-default save-information-btn"
                  type="button"
                  onClick={this.selectDiamond}
                  disabled={
                    this.state.loading_list || this.state.disable_button
                  }
                >
                  Select
                </Button>
              </Form>
            ) : (
              <div>You do not have permissions to perform this task.</div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  validPermmissionsForEditDiamonDetails = () => {
    if (Auth.canEditField("rolesEditDiamonsSales")) {
      return true;
    }

    const user = Auth.getAuthenticatedUser();

    if (
      Auth.canEditField("rolesEditDiamonsOperations") &&
      user.employeeModel.isLeader
    ) {
      return true;
    }

    return false;
  };

  handleRowSelection = (index) => {
    this.setState({
      disable_button: false,
      selected_diamond: this.state.diamonds[index],
      selected_diamond_index: index,
    });
  };

  openDiamondSearchPopup = () => {
    this.setState({
      show_diamond_search_modal: true,
    });
  };

  closeDiamondSearchPopup = () => {
    this.setState({
      show_diamond_search_modal: false,
    });
  };

  handleFilterChange = (e, field_name) => {
    let filter_value = e.target.value;
    let execute_filter = false;

    if (field_name === "generalSearch") {
      this.filter_params[field_name] = filter_value;
      execute_filter = true;
    }
    this.filter_params["page"] = 1;
    this.filter_params["max_results"] = 100;
    this.filter_params["order_by"] = "created";
    this.filter_params["order_type"] = "DESC";
    if (execute_filter === true) {
      this.fetchDiamonds();
    }
  };

  ajaxRequest = null;
  fetchDiamonds() {
    this.setState({
      loading_list: true,
    });

    if (cancel !== undefined) {
      cancel();
    }

    const url =
      process.env.REACT_APP_NOVITAERP_API_DOMAIN +
      globalConst.REACT_APP_NOVITAERP_API_DIAMOND_PRODUCT_GD_SEARCH;

    const params = new URLSearchParams();
    params.append("certificate_number", this.filter_params.generalSearch);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };

    axios
      .post(url, params, config)
      .then((api_response) => {
        const result = api_response.data;
        const items = result.value;

        // add list to previous items
        let list = [];
        if (this.filter_params.page === 1) {
          list = items;
          // window.scrollTo(0,0);
          window.scrollTo({ top: 0 });
        } else {
          let current_list = [...this.state.diamonds];
          list = current_list.concat(items);
        }

        this.setState({
          diamonds: list,
          loading_list: false,
          total_results: result.count,
          api_has_more: result.amount_results > 0 ? true : false,
        });
      })
      .catch((err) => {
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            alert("User not authorised. Please reload the page");
          } else {
            alert(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
          this.setState({
            loading_list: false,
          });
        } else if (err.request) {
          alert(
            "There was a problem with your request. Please reload this page and try again."
          );
          this.setState({
            loading_list: false,
          });
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          this.setState({
            loading_list: true,
          });
        }
      });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleEditDiamondSearch;
