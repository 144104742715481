import { formatCustomerCreationDate } from "../../../utils/dateUtils";

export default function CustomerCard({ customer }) {
  const buildMiddleRow = (name, value) => {
    return (
      <div key={name} className="row border-bottom">
        <div className="p-1 col-sm table_title border-right ">
          <span className="table_title">{name}</span>
        </div>
        <div className="p-1 col-sm">{value}</div>
      </div>
    );
  };

  return (
    <div className="general_information customer-details-card">
      <h2>{customer.customerSales.length > 0 ? "Customer" : "Lead"}</h2>
      <div className="customer-details-container p-2">
        <div className="container">
          {buildMiddleRow("Customer Name", customer.name)}
          {buildMiddleRow("Email", customer.email)}
          {buildMiddleRow("Phone", customer.phone)}
          {buildMiddleRow("Ethnicity", customer.ethnicity)}
          {buildMiddleRow("How Heard About Us", customer.howHeardAboutUs)}
          {buildMiddleRow("Reasons for not buying", customer.reasonNotBuying)}
          {buildMiddleRow(
            "Created",
            formatCustomerCreationDate(customer.created)
          )}
        </div>
      </div>
    </div>
  );
}
