import React, {Component} from 'react';

// SCSS
import '../../../css/sales/sale_information.scss'

class PostSaleInformationBackOffice extends Component {

    constructor(props){
        super();
        this.state = {
            sale: props.sale
        }
    }

    render(){
        const sale = this.state.sale

        let statusClassName = ""
        if(sale.status !== null && sale.status !== ''){
            switch (sale.status) {
                case "Pending":
                    statusClassName = "pending"
                    break
                case "Ready":
                    statusClassName = "ready"
                    break
                case "Internal transit":
                    statusClassName = "internal-transit"
                    break
                case "Cancelled":
                    statusClassName = "cancelled"
                    break
                case "Collected/Shipped":
                    statusClassName = "collected-shipped"
                    break
                default:
                    break;
            }
        }

        let originalJeweller = ""
        if (sale.salesModel !== null && typeof sale.salesModel !== "undefined") {
            if ("jeweller" in sale.salesModel) {
                originalJeweller = sale.salesModel.jeweller;
            }
        }

        let jewellers = []
        if (typeof sale.jewellers !== "undefined" && sale.jewellers !== null) {
            if (sale.jewellers !== "") {
                try {
                    jewellers = JSON.parse(sale.jewellers)
                }
                catch (e)
                {
                    console.log("Error: " + e)
                }
            }
        }

        let datePickupRequested = null
        if(sale.datePickupRequested !== null && sale.datePickupRequested !== ''){
            datePickupRequested = new Date(sale.datePickupRequested).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            datePickupRequested = datePickupRequested.slice(0, -5) + ", " + datePickupRequested.slice(-4)
        }

        let shippingDate = null
        if(sale.shippingDate !== null && sale.shippingDate !== ''){
            shippingDate = new Date(sale.shippingDate).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            shippingDate = shippingDate.slice(0, -5) + ", " + shippingDate.slice(-4)
        }

        let dateReceivedInBackOffice = null
        if(sale.dateReceivedInBackOffice !== null && sale.dateReceivedInBackOffice !== ''){
            dateReceivedInBackOffice = new Date(sale.dateReceivedInBackOffice).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            dateReceivedInBackOffice = dateReceivedInBackOffice.slice(0, -5) + ", " + dateReceivedInBackOffice.slice(-4)
        }

        return(
            <div className='sale_information_subcontainer'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm  table_title">Status</div>
                        <div className={"col-sm " + statusClassName}>{sale.status}</div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-sm text-center"><b>Internal Transit</b></div>
                        </div>
                        <div className="row">
                            <div className="col-sm table_title">Job’s current location</div>
                            <div className="col-sm">{sale.currentLocation}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm table_title">In transit to</div>
                            <div className="col-sm">{sale.inTransitTo}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm table_title">Shipping date</div>
                            <div className="col-sm">{shippingDate}</div>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-sm text-center"><b>Startrack Pickup Information</b></div>
                        </div>
                        <div className="row">
                            <div className="col-sm table_title">Pick up tracking # and Booking Number</div>
                            <div className="col-sm">{sale.trackingBookingNumber}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm table_title">Destination</div>
                            <div className="col-sm">{sale.destination}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm table_title">Pick up date requested</div>
                            <div className="col-sm">{datePickupRequested}</div>
                        </div>
                    </div>
                    { jewellers.map((jeweller, index) => {
                        let datePickedUpFromJeweller = null
                        if(jeweller.datePickedUpFromJeweller !== null && jeweller.datePickedUpFromJeweller !== ''){
                            datePickedUpFromJeweller = new Date(jeweller.datePickedUpFromJeweller).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
                            datePickedUpFromJeweller = datePickedUpFromJeweller.slice(0, -5) + ", " + datePickedUpFromJeweller.slice(-4)
                        }
    
                        let dateGivenToJeweller = null
                        if(jeweller.dateGivenToJeweller !== null && jeweller.dateGivenToJeweller !== ''){
                            dateGivenToJeweller = new Date(jeweller.dateGivenToJeweller).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
                            dateGivenToJeweller = dateGivenToJeweller.slice(0, -5) + ", " + dateGivenToJeweller.slice(-4)
                        }

                        return(
                            <div key={index}>
                                <div className="row">
                                    <div className="col-sm text-center"><b>Jeweller #{(index + 1)}</b></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm table_title">Jeweller</div>
                                    <div className="col-sm">{jeweller.jeweller}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm table_title">Date Picked up from the Jeweller</div>
                                    <div className="col-sm">{datePickedUpFromJeweller}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm table_title">Date given to jeweller</div>
                                    <div className="col-sm">{dateGivenToJeweller}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm table_title">Job Package</div>
                                    <div className="col-sm">{jeweller.jobPackage}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm table_title">Notes</div>
                                    <div className="col-sm">{jeweller.notes}</div>
                                </div>
                            </div>
                        )})
                    }
                    <br></br>
                    <div className="row">
                        <div className="col-sm table_title">Date Received in the Back office</div>
                        <div className="col-sm">{dateReceivedInBackOffice}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm table_title">Original Setter or Jeweller</div>
                        <div className="col-sm">{originalJeweller}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm table_title">Return tracking to customer or showroom</div>
                        <div className="col-sm">{sale.returnTrackingToCustomer}</div>
                    </div>
                </div>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return{
            sale: props.sale
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

}

export default PostSaleInformationBackOffice;