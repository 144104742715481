import React, {Component} from 'react';

// SCSS
import '../../../css/sales/sale_information.scss'

class PostSaleInformationAccounting extends Component {

    constructor(props){
        super();
        this.state = {
            sale: props.sale
        }
    }

    render(){
        const sale = this.state.sale
        let customerNeedsToPay = "No"

        if ("customerNeedsToPay" in sale) {
            if (sale.customerNeedsToPay === true) {
                customerNeedsToPay = "Yes"
            }
        }

        let customerNeedsToPayHighlight = ""
        if (customerNeedsToPay === "Yes") {
            customerNeedsToPayHighlight = "redText"
        }

        let jewellers = []
        if (typeof sale.jewellers !== "undefined" && sale.jewellers !== null) {
            if (sale.jewellers !== "") {
                try {
                    jewellers = JSON.parse(sale.jewellers)
                }
                catch (e)
                {
                    console.log("Error: " + e)
                }
            }
        }

        return(
            <div className='sale_information_subcontainer'>
                <div className="container">
                    { jewellers.map((jeweller, index) => {
                        return(
                            <div key={index}>
                                <div className="row">
                                    <div className="col-sm text-center"><b>Jeweller #{(index + 1)}</b></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm table_title">Jeweller's Cost</div>
                                    <div className="col-sm">{jeweller.cost}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm table_title">Jeweller's Invoice</div>
                                    <div className="col-sm">{jeweller.jewellersInvoice}</div>
                                </div>
                            </div>
                        )})
                    }
                    <br></br>
                    <div className="row">
                        <div className="col-sm table_title">Customer needs to pay</div>
                        <div className={"col-sm " + customerNeedsToPayHighlight}>{customerNeedsToPay}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm table_title">Price to charge to customer</div>
                        <div className="col-sm">{sale.priceToCharge}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm table_title">Balance</div>
                        <div className="col-sm">{sale.balance}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm table_title">Xero Code</div>
                        <div className="col-sm">{sale.xeroCode}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm table_title">Payment Method</div>
                        <div className="col-sm">{sale.paymentMethod}</div>
                    </div>
                </div>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return{
            sale: props.sale
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

}

export default PostSaleInformationAccounting;