import { useState } from "react"
import { Button, Form, ListGroup, ListGroupItem } from "react-bootstrap";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function RoleSelect({ allRoles, roles, setRoles }) {
  const [ selectedRole, setSelectedRole ] = useState(allRoles[0]);

  const handleSelectionChanged = (event) => {
    setSelectedRole(event.target.value);
  }

  const handleAddRole = () => {
    if (!selectedRole) {
      return;
    }

    if (roles.some((r) => r == selectedRole)) {
      alert('Role already added');
      return;
    }

    setRoles([...roles, selectedRole]);
    setSelectedRole('');
  }

  const handleDelete = (role) => {
    setRoles(roles.filter((r) => r != role));
  }

  return (
    <Form.Group className="mb-3" controlId="formAddedRoles">
      <Form.Label>Add Roles</Form.Label>
      <div className="d-flex">
        <Form.Select
          style={{ fontSize: '12px' }}
          aria-label="Select a role"
          value={selectedRole}
          onChange={handleSelectionChanged}
        >
            {allRoles.map((role) => (
              <option key={role} value={role}>{role}</option>
          ))}
        </Form.Select>
        <Button variant="primary" className="ms-2" onClick={handleAddRole} disabled={!selectedRole}>
          Add
        </Button>
      </div>
      <div className="pt-1 pb-1" style={{ width: 'fit-content' }}>
        <ListGroup>
          {roles.map((role) => (<ListGroupItem style={{ fontSize: '12px' }} key={role}>
            <FontAwesomeIcon onClick={()=>handleDelete(role)} className="mt-1 me-2" role="button" icon={faTrash} style={{ float: 'left' }} /> {' '} {role}
          </ListGroupItem>))}
        </ListGroup>
      </div>
    </Form.Group>
  )
}
