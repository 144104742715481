import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Modal, Row } from 'react-bootstrap'

const ConfirmStockInShowroom = props => {
    const { message, onResponse } = props

    const [showModal, setShowModal] = useState(true)
    
    const closeModal = (value) => {
        setShowModal(false)

        onResponse(value)
    }

    return (
        <Modal
            show={showModal}
            size='sm'
            centered
        >
            <Modal.Header>
                {message}
            </Modal.Header>
            <Modal.Footer>
                <Row>
                    <Col lg='6'>
                        <Button
                            variant="primary"
                            onClick={() => closeModal(true)}>
                            Yes
                        </Button>
                    </Col>
                    <Col lg='6'>
                        <Button
                            variant="secondary"
                            onClick={() => closeModal(false)}>
                            No
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

ConfirmStockInShowroom.propTypes = {
    message: PropTypes.string.isRequired,
    onResponse: PropTypes.func.isRequired
}

export default ConfirmStockInShowroom