import { SaleCommissionEmployeeFind } from "../sales/edit/Data/SaleCommissionEmployeeFind"

export const isEmployeeRemoved = (
    saleRepNameOld,
    saleRepNameNew,
) => {
    try {
        let namesOlds = saleRepNameOld ? saleRepNameOld.split(',') : []
        let namesNews = saleRepNameNew ? saleRepNameNew.split(',') : []
        
        const removes = namesOlds.filter(item => {
            return !namesNews.includes(item)
        })

        return removes
    } catch (error) {
        return []
    }
}

export const employeeIsPaidDate = async(
    saleId,
    employeeName,
    type
) => {
    const saleCommission = await SaleCommissionEmployeeFind(
        saleId,
        employeeName,
        type
    )

    if (!saleCommission) {
        return false
    }

    const { paidDate } = saleCommission

    if (paidDate) {
        return true
    }

    return false
}