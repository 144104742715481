import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function CreatedFilter (props) {
    const [selectedDate, setSelectedDate] = useState(null)

    const handleDateChange = (date) => {
        setSelectedDate(date)
        props.handleCreatedFilter(date)
    }

    return(
        <div className='dateFilter'>
            <DatePicker
                selected={selectedDate}
                onChange={date => handleDateChange(date)}
                dateFormat='dd/MM/yyyy'
                maxDate={new Date()}
                isClearable
                closeOnScroll={true}
            />
        </div>
    )

}




export default CreatedFilter;