import Auth from "../../../../Auth"

export const isSalesNotLeader = () => {
    const isSales = Auth.canEditField('rolesIsSales')
    const isLeader = Auth.isLeader()

    return (isSales && !isLeader) ? true : false;
}

export const isRoleAdmin = () => {
    return Auth.canEditField('rolesAllAccess')
}

export const isRoleAccounting = () => {
    const isRole = Auth.canEditField('accountingStatus')
    
    return (isRole) ? true : false;
}

export const isRoleSalesLeader = () => {
    const isRole = Auth.canEditField('rolesIsSales')
    const isLeader = Auth.isLeader()
    
    return (isRole && isLeader) ? true : false;
}