import { Form, FormLabel, Button } from "react-bootstrap";
import React, { Component } from "react";
import LoadingLayer from "../../LoadingLayer";
import FlashMessages from "../../FlashMessages";
import Auth from "../../../Auth";
import axios from "axios";
import DateField from "../formComponents/DateField";
import Util from "../../../Util";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_edit.scss";
import {
  STATUS_COLLECTED_SHIPPED,
  STATUS_PENDING_APPROVAL,
  STATUS_RETURNED,
} from "../../../store/constants";

class SaleProductionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      settingStatus: props.sale.settingStatus,
      diamondLocation: props.sale.diamondLocation,
      inTransitTo: props.sale.inTransitTo,
      requestedToOverseasProvider: props.sale.requestedToOverseasProvider,
      confirmedFromOverseasProvider: props.sale.confirmedFromOverseasProvider,
      manufacturingLocation: props.sale.manufacturingLocation,
      customerNeedsOn: props.sale.customerNeedsOn,
      urgentDateRequestedByCustomer: props.sale.urgentDateRequestedByCustomer,
      dateWeNeedBy: props.sale.dateWeNeedBy,
      status: props.sale.status,
      notes: props.sale.notes,
      valuationStatus: props.sale.valuationStatus,
      ringSentWithResize: props.sale.ringSentWithResize,
      collectedShippedDate: props.sale.collectedShippedDate,
      error_messages: [],
      warning_messages: [],
      success_messages: [],
      updating_sale: false,
      fields_no_updated: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.editSale = this.editSale.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.removeJeweller = this.removeJeweller.bind(this);

    this.setListOrder();
  }

  setListOrder = () => {
    this.listOrderStatus = [];

    if (process.env.REACT_APP_SALES_ORDER_STATUS_LIST) {
      this.listOrderStatus =
        process.env.REACT_APP_SALES_ORDER_STATUS_LIST.split(",");
    }
  };

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("production", true);
    }
    this.setState(state);
  }

  handleChangeCheckbox(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.checked;
    if (event.target.checked !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("production", true);
    }
    this.setState(state);
  }

  handleChangeSelect(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("production", true);
    }
    this.setState(state);
  }

  handleChangeDateField = (date, field_name) => {
    const state = {};
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      state[field_name] = year + "-" + month + "-" + day;
    } else {
      state[field_name] = null;
    }
    if (date !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("production", true);
    }
    this.setState(state);
  };

  disabledStatusIsReturn = (status) => {
    if (
      status !== STATUS_PENDING_APPROVAL &&
      !Auth.canAccessRolesAndLeader("rolesEditStatusReturn", true)
    ) {
      return false;
    }

    if (Auth.canAccessRolesAndLeader("rolesEditStatusReturn", true)) {
      return false;
    }

    return true;
  };

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const sale = this.state.sale;

    let collectedShippedDate_date = "";
    if (sale.collectedShippedDate !== null) {
      const collectedShippedDate_string = sale.collectedShippedDate.substring(
        0,
        10
      );
      collectedShippedDate_date = new Date(collectedShippedDate_string);
      if (collectedShippedDate_date === "Invalid Date") {
        collectedShippedDate_date = "";
      }
    }

    let customerNeedsOnDate = "";
    if (sale.customerNeedsOn !== null) {
      const customerNeedsOn_string = sale.customerNeedsOn.substring(0, 10);
      customerNeedsOnDate = new Date(customerNeedsOn_string);
      if (customerNeedsOnDate === "Invalid Date") {
        customerNeedsOnDate = "";
      }
    }

    let dateWeNeedByDate = "";
    if (sale.dateWeNeedBy !== null) {
      const dateWeNeedBy_string = sale.dateWeNeedBy.substring(0, 10);
      dateWeNeedByDate = new Date(dateWeNeedBy_string);
      if (dateWeNeedByDate === "Invalid Date") {
        dateWeNeedByDate = "";
      }
    }

    let current_status = "";
    if (
      typeof this.state.status === "string" ||
      this.state.status instanceof String
    ) {
      current_status = this.state.status;
    }

    return (
      <div id="SaleProductionEdit_component">
        {this.state.updating_sale === true ? <LoadingLayer /> : ""}
        {
          <FlashMessages
            error_messages={this.state.error_messages}
            warning_messages={this.state.warning_messages}
            success_messages={this.state.success_messages}
          />
        }
        <Form>
          <Form.Group className="form-group">
            <div className="row">
              <div className="col-6">
                <Form.Group className="form-group">
                  <FormLabel>Order Status</FormLabel>
                  <Form.Select
                    onChange={this.handleChangeSelect}
                    value={current_status}
                    name="status"
                    disabled={this.disabledStatusIsReturn(current_status)}
                  >
                    <option value=""></option>
                    {this.listOrderStatus.map((orderStatus) => {
                      return (
                        <option
                          disabled={
                            orderStatus === STATUS_COLLECTED_SHIPPED ||
                            orderStatus === STATUS_RETURNED ||
                            orderStatus === STATUS_PENDING_APPROVAL
                          }
                          key={orderStatus}
                          value={orderStatus}
                        >
                          {orderStatus}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="form-group">
                  <FormLabel>Setting Status</FormLabel>
                  <Form.Control
                    type="text"
                    name="settingStatus"
                    value={
                      this.state.settingStatus === null
                        ? ""
                        : this.state.settingStatus
                    }
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </div>
              {Util.wordInArray(sale.saleType, [
                "engagement ring",
                "jewellery",
                "diamond",
              ]) === true ? (
                <div className="col-12">
                  <Form.Group className="form_group_diamond_location card">
                    <div className="row">
                      <div className="col-12">
                        <div className="sale_title alight-center bold_font">
                          Diamond Status
                        </div>
                      </div>
                      <div className="col-4">
                        <Form.Group className="form-group">
                          <FormLabel>Diamond Location</FormLabel>
                          <Form.Control
                            type="text"
                            name="diamondLocation"
                            value={
                              this.state.diamondLocation === null
                                ? ""
                                : this.state.diamondLocation
                            }
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-4">
                        <Form.Group className="form-group">
                          <FormLabel>In transit to</FormLabel>
                          <Form.Control
                            type="text"
                            name="inTransitTo"
                            value={
                              this.state.inTransitTo === null
                                ? ""
                                : this.state.inTransitTo
                            }
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-4">
                        <Form.Group className="form-group mt-3">
                          <Form.Check
                            type="checkbox"
                            name="requestedToOverseasProvider"
                            label="Requested to overseas provider"
                            checked={
                              this.state.requestedToOverseasProvider === true
                                ? true
                                : false
                            }
                            onChange={this.handleChangeCheckbox}
                          />
                          <Form.Check
                            type="checkbox"
                            name="confirmedFromOverseasProvider"
                            label="Confirmed from overseas provider"
                            checked={
                              this.state.confirmedFromOverseasProvider === true
                                ? true
                                : false
                            }
                            onChange={this.handleChangeCheckbox}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Form.Group>
                </div>
              ) : null}
              {Util.wordInArray(sale.saleType, [
                "engagement ring",
                "jewellery",
                "wedding",
              ]) === true ? (
                <div className="col-6">
                  <Form.Group className="form-group">
                    <FormLabel>Manufacturing Location</FormLabel>
                    <Form.Control
                      type="text"
                      name="manufacturingLocation"
                      value={
                        this.state.manufacturingLocation === null
                          ? ""
                          : this.state.manufacturingLocation
                      }
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </div>
              ) : null}
              <div className="col-6">
                <Form.Group className="form-group">
                  <FormLabel>Customer's Notes</FormLabel>
                  <Form.Control
                    type="text"
                    name="notes"
                    value={this.state.notes === null ? "" : this.state.notes}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="form-group">
                  <FormLabel>Date customer needs by</FormLabel>
                  <DateField
                    handleChangeDateField={this.handleChangeDateField}
                    field_name="customerNeedsOn"
                    currentDate={customerNeedsOnDate}
                  />
                </Form.Group>
              </div>
              {this.validShowUrgenDate() ? (
                <div className="col-12">
                  <Form.Group className="form-group">
                    <Form.Check
                      type="checkbox"
                      name="urgentDateRequestedByCustomer"
                      label="Urgent Date - requested by Customer"
                      checked={
                        this.state.urgentDateRequestedByCustomer === true
                          ? true
                          : false
                      }
                      onChange={this.handleChangeCheckbox}
                    />
                  </Form.Group>
                </div>
              ) : null}
              <div className="col-6">
                <Form.Group className="form-group">
                  <FormLabel>Date we need by</FormLabel>
                  <DateField
                    handleChangeDateField={this.handleChangeDateField}
                    field_name="dateWeNeedBy"
                    currentDate={dateWeNeedByDate}
                  />
                </Form.Group>
              </div>

              {Util.wordInArray(sale.saleType, [
                "engagement ring",
                "jewellery",
                "wedding",
              ]) === true ? (
                <div className="col-6">
                  <Form.Group className="form-group">
                    <FormLabel>Appraisal Status</FormLabel>
                    <Form.Control
                      type="text"
                      name="valuationStatus"
                      value={
                        this.state.valuationStatus === null
                          ? ""
                          : this.state.valuationStatus
                      }
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </div>
              ) : null}
              <div className="col-6">
                <Form.Group className="form-group">
                  <FormLabel>Ring Sent With Resize</FormLabel>
                  <Form.Control
                    type="text"
                    name="ringSentWithResize"
                    value={
                      this.state.ringSentWithResize === null
                        ? ""
                        : this.state.ringSentWithResize
                    }
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="form-group">
                  <FormLabel>Date collected or shipped</FormLabel>
                  <DateField
                    handleChangeDateField={this.handleChangeDateField}
                    field_name="collectedShippedDate"
                    currentDate={collectedShippedDate_date}
                  />
                </Form.Group>
              </div>
            </div>
          </Form.Group>
          <Button
            type="submit"
            onClick={this.editSale}
            disabled={this.state.updating_sale || this.state.fields_no_updated}
          >
            Save Production Information
          </Button>
        </Form>
      </div>
    );
  }

  removeJeweller() {
    this.setState({
      fields_no_updated: false,
      jeweller: null,
    });
    this.props.handleTabChange("production", true);
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
    //return values for the state
    return {
      sale: props.sale,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************

  axioRequest = null;
  editSale(event) {
    event.preventDefault();
    this.setState({
      updating_sale: true,
    });
    const user = Auth.getAuthenticatedUser();
    const sale = this.props.sale;
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (sale === null || sale === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        process.env.REACT_APP_NOVITAERP_API_DOMAIN +
        globalConst.REACT_APP_NOVITAERP_API_SALES_EDIT;
      url = url.replace("{id}", sale.id);
      const params = new URLSearchParams();

      params.append("settingStatus", this.state.settingStatus);
      params.append("diamondLocation", this.state.diamondLocation);
      params.append("inTransitTo", this.state.inTransitTo);
      params.append(
        "requestedToOverseasProvider",
        this.state.requestedToOverseasProvider
      );
      params.append(
        "confirmedFromOverseasProvider",
        this.state.confirmedFromOverseasProvider
      );
      params.append("manufacturingLocation", this.state.manufacturingLocation);
      params.append("customerNeedsOn", this.state.customerNeedsOn);
      params.append(
        "urgentDateRequestedByCustomer",
        this.state.urgentDateRequestedByCustomer
      );
      params.append("dateWeNeedBy", this.state.dateWeNeedBy);
      params.append("status", this.state.status);
      params.append("notes", this.state.notes);
      params.append("valuationStatus", this.state.valuationStatus);
      params.append("ringSentWithResize", this.state.ringSentWithResize);
      params.append("collectedShippedDate", this.state.collectedShippedDate);

      if (this.state.status === "Collected / Sent") {
        params.append("sheetType", "FINALISED");
      }

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Sale updated"],
              fields_no_updated: true,
            });
            this.props.handleTabChange("production", false);
            this.props.saleUpdated(data.result);
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            //error not detected. Request could have been cancelled and it's waiting for second
            //request to finish
            this.setState({
              updating_sale: true,
            });
          }
          //console.log((err);
          return null;
        });
    }
  }

  validShowUrgenDate = () => {
    return Auth.canAccessRolesAndLeader(
      "urgent_date_requested_by_customer",
      true
    );
  };
}

export default SaleProductionEdit;
