import React from "react";
import { Col, Row } from "react-bootstrap";
import TotalSalesReportDetails from "../components/total_sales_details";
import PieChartSaleReport from "../components/PieChartSaleReport";
import { useSelector } from "react-redux";
import TotalSalesReportDetailsPerEmployee from "../components/total_sales_details_per_employee";

const AppointmentsCharts = () => {
  const { resultAnalysisDetails, resultAnalysisDetailsPerEmployee } =
    useSelector((state) => state.reports);

  return (
    <Row className="card-sale-commissions">
      <Row className="mt-2">
        <Col xs={12} lg={8}>
          <TotalSalesReportDetails jsonData={resultAnalysisDetails ?? {}} />
        </Col>
        <Col xs={12} lg={4}>
          <PieChartSaleReport data={resultAnalysisDetails ?? {}} />
        </Col>
      </Row>
      <Row className="mt-2 mb-5">
        <Col xs={12} lg={12}>
          <TotalSalesReportDetailsPerEmployee
            jsonData={resultAnalysisDetailsPerEmployee ?? {}}
          />
        </Col>
      </Row>
    </Row>
  );
};

export default AppointmentsCharts;
