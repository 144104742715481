import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import ResultAnalysisDateFilter from "../components/result_analysis_date_filter";
import SalesRepSelect from "../components/sales_rep_select";
import { dateFormatCustom } from "../../../utils/dateUtils";
import Auth from "../../../Auth";
import {
  fetchResultAnalysis,
  fetchResultAnalysisDetails,
  fetchResultAnalysisDetailsPerEmployee,
  fetchResultCommissionsAnalysis,
  fetchResultSalesEfficincys,
  loadingRepots,
  setDownloadExcel,
  setDownloadExcelReport,
} from "../../../store/slices";
import { useDispatch, useSelector } from "react-redux";
import { DownloadField } from "../../actions/ExportReportSales";
import { DOWNLOAD_CHOICE_REPORTS } from "../../../store/constants";
import {
  fetchAppointmentsReportExcel,
  fetchCommissionsReportExcel,
  fetchResultAppointmentsEfficiency,
} from "./FecthReports";

const ALL = "";
const REPORT_SALES = "resultSales";
const REPORT_APPOINTMETS = "resultAppointments";
const REPORT_COMMISSIONS_SALES = "resultCommissionsSales";
const REPORT_COMMISSIONS_REVIEWS = "resultCommissionsReviews";

const InputsFormSearch = () => {
  const { downloadExcel, downloadExcelNameReport } = useSelector(
    (state) => state.reports
  );

  const date = new Date();
  const user = Auth.getAuthenticatedUser();
  const { name } = user.employeeModel;
  const leader = Auth.canAccessRolesAndLeader("rolesEditReports", true);
  const roleAccounting = Auth.canAccessRolesAndLeader("accountingStatus");

  const dispatch = useDispatch();

  const { selectedReport } = useSelector((state) => state.reports);
  const [employee, setEmployee] = useState(
    leader || roleAccounting ? ALL : name
  );
  const [startDate, setStartDate] = useState(
    `${dateFormatCustom(date, "YYYY")}-01-01`
  );
  const [endDate, setEndDate] = useState(dateFormatCustom(date));

  const onResponseDate = async (response) => {
    const { startDate, endDate } = response;

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onResponseEmployee = async (employee) => {
    setEmployee(employee);
  };

  const onGenerateReport = useCallback(async () => {
    dispatch(loadingRepots(true));
    if (REPORT_SALES === selectedReport) {
      await dispatch(
        fetchResultAnalysis({
          startDate: startDate,
          endDate: endDate,
          employee: employee,
        })
      );
    }

    if (REPORT_APPOINTMETS === selectedReport) {
      await dispatch(
        fetchResultAnalysisDetails({
          startDate: startDate,
          endDate: endDate,
          employee: employee,
        })
      );

      await dispatch(
        fetchResultAnalysisDetailsPerEmployee({
          startDate: startDate,
          endDate: endDate,
          employee: employee,
        })
      );
    }

    if (
      [REPORT_COMMISSIONS_SALES, REPORT_COMMISSIONS_REVIEWS].includes(
        selectedReport
      )
    ) {
      await dispatch(
        fetchResultCommissionsAnalysis({
          startDate: startDate,
          endDate: endDate,
          employee: employee,
          typeOfReport:
            selectedReport === REPORT_COMMISSIONS_SALES ? "sales" : "reviews",
        })
      );
    }
    dispatch(loadingRepots(false));
  }, [dispatch, employee, endDate, selectedReport, startDate]);

  useEffect(() => {
    onGenerateReport();
  }, [onGenerateReport, dispatch]);

  useEffect(() => {
    if (downloadExcel && REPORT_APPOINTMETS === selectedReport) {
      fetchAppointmentsReportExcel({
        startDate: startDate,
        endDate: endDate,
        employee: employee,
      }).then((data) => {
        DownloadField(data, "appointments");
        dispatch(setDownloadExcel(false));
      });
    }
    if (
      downloadExcel &&
      [REPORT_COMMISSIONS_SALES, REPORT_COMMISSIONS_REVIEWS].includes(
        selectedReport
      )
    ) {
      fetchCommissionsReportExcel({
        startDate: startDate,
        endDate: endDate,
        employee: employee,
        typeOfReport:
          selectedReport === REPORT_COMMISSIONS_SALES ? "sales" : "reviews",
      }).then((data) => {
        let reportFilename =
          selectedReport === REPORT_COMMISSIONS_SALES
            ? "commissions-sales"
            : "commissions-reviews";
        DownloadField(data, reportFilename);
        dispatch(setDownloadExcel(false));
      });
    }

    if (downloadExcelNameReport) {
      switch (downloadExcelNameReport) {
        case DOWNLOAD_CHOICE_REPORTS[0]:
          fetchResultAppointmentsEfficiency({
            startDate: startDate,
            endDate: endDate,
            employee: employee,
          }).then((data) => {
            DownloadField(data, "appointments-efficiency");
            dispatch(setDownloadExcel(""));
          });
          break;
        default:
          break;
      }
      dispatch(setDownloadExcelReport(""));
    }
  }, [
    dispatch,
    employee,
    endDate,
    selectedReport,
    startDate,
    downloadExcel,
    downloadExcelNameReport,
  ]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={12} lg={8}>
            <Row>
              <Col xs={12} lg={8}>
                <ResultAnalysisDateFilter onResponse={onResponseDate} />
              </Col>
              <Col xs={12} lg={4}>
                <SalesRepSelect onResponse={onResponseEmployee} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InputsFormSearch;
