import React from "react";
import { useSelector } from "react-redux";
import Auth from "../../Auth";
import SalesCharts from "./charts/SalesCharts";
import AppointmentsCharts from "./charts/AppointmentsCharts";
import InputsFormSearch from "./Inputs/InputsFormSearch";
import LoadingSpinnerLayer from "../LoadingSpinnerLayer";
import { Row } from "react-bootstrap";
import CommissionsCharts from "./charts/CommissionsCharts";

const REPORT_SALES = "resultSales";
const REPORT_APPOINTMETS = "resultAppointments";
const REPORT_COMMISSIONS_SALES = "resultCommissionsSales";
const REPORT_COMMISSIONS_REVIEWS = "resultCommissionsReviews";

const MainReports = () => {
  const { selectedReport, loadingRepots } = useSelector(
    (state) => state.reports
  );

  const leaders = Auth.canAccessRolesAndLeader("rolesEditReports", true);

  const roleSales = Auth.canAccessRolesAndLeader("rolesIsSales");
  const roleAccounting = Auth.canAccessRolesAndLeader("accountingStatus");

  if (!leaders && !roleSales && !roleAccounting) {
    return (
      <div className="main-reports-header">
        <h1>Unauthorized</h1>
      </div>
    );
  }

  return (
    <main className="main-flex">
      <InputsFormSearch />

      {loadingRepots && (
        <Row className="center-element mt-2">
          <LoadingSpinnerLayer />
        </Row>
      )}

      {selectedReport === REPORT_SALES && !loadingRepots && <SalesCharts />}

      {selectedReport === REPORT_APPOINTMETS && !loadingRepots && (
        <AppointmentsCharts />
      )}
      {[REPORT_COMMISSIONS_SALES, REPORT_COMMISSIONS_REVIEWS].includes(selectedReport) && !loadingRepots && (
        <CommissionsCharts />
      )}
    </main>
  );
};

export default MainReports;
