import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import Util from '../../Util';
import Auth from '../../Auth';
import { sprintf } from "sprintf-js";

class SaleRow extends Component {

    constructor(props) {
        super();
        this.state = {
            sale: props.sale,
            selected_sale: props.selected_sale,
            addToMyList: props.addToMyList,
            currentTab: props.currentTab,
            selectedTabToAdd: props.selectedTabToAdd,
        }
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        //this.addSaleToCustomisedList = this.addSaleToCustomisedList.bind(this)
    }

    handleChangeCheckbox(event) {
        if (this.state.selectedTabToAdd === null || this.state.selectedTabToAdd === "") {
            alert("You need to select a personalised list from the dropdown to add elements to it.")
        }
        else {
            const field_name = event.target.name
            let state = {}
            state[field_name] = event.target.checked
            this.setState(state)
            this.props.handleChangeCheckbox(this.props.index, event.target.checked)
            //this.addSaleToCustomisedList()
        }
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        var access_to_accounting_columns = Auth.isAuthorised("sales_accounting_columns");

        const sale = this.state.sale;
        let saleDate = null
        if (sale.saleDate !== null && sale.saleDate !== '') {
            saleDate = new Date(sale.saleDate).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            saleDate = saleDate.slice(0, -5) + ", " + saleDate.slice(-4)
        }

        let dateCustomersNeedBy = null
        if (sale.customerNeedsOn !== null && sale.customerNeedsOn !== '') {
            dateCustomersNeedBy = new Date(sale.customerNeedsOn).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            dateCustomersNeedBy = dateCustomersNeedBy.slice(0, -5) + ", " + dateCustomersNeedBy.slice(-4)
        }

        let row_class = "sale_row";
        if (this.state.highlight_row === true) {
            row_class = "sale_row highlighted_row";
        }

        if (this.state.selected_sale !== null && this.state.selected_sale !== undefined && sale !== null && sale.id === this.state.selected_sale.id) {
            row_class = row_class + " highlighted_row"
        }

        let orderTypeClass = ""
        if (sale.orderType === "Express") {
            orderTypeClass = "express"
        }
        else if (sale.orderType === "Ready to Ship") {
            orderTypeClass = "readyToShip"
        }

        let dateCustomersNeedByHighlight = ""
        if (sale.urgentDateRequestedByCustomer) {
            dateCustomersNeedByHighlight = " dateCustomerNeedsByHighlighted"
        }

        let workflowStatus = ""
        let saleWorkflow = null;
        let workflowActivity = {
            created: ''
        };

        let jeweller = ''

        if ("workflowSaleModels" in sale) {
            if (sale.workflowSaleModels.length > 0) {
                let allCencelled = true

                sale.workflowSaleModels.map(workflowSale => {
                    if (workflowSale.status !== 'Cancelled') {
                        allCencelled = false
                    }
                })

                sale.workflowSaleModels.forEach(function (workflowSale, index) {
                    if ("workflowActivityModels" in workflowSale) {
                        if (workflowSale.workflowActivityModels.length > 0) {
                            workflowSale.workflowActivityModels.forEach(function (activity, index) {
                                if (allCencelled) {
                                    if (
                                        activity.created > workflowActivity.created
                                    ) {
                                        workflowActivity = activity;
                                        saleWorkflow = workflowSale;
                                    }
                                } else {
                                    if (
                                        activity.created > workflowActivity.created && 
                                        workflowSale.status != 'Cancelled'
                                    ) {
                                        workflowActivity = activity;
                                        saleWorkflow = workflowSale;
                                    }
                                }
                            })
                        }
                    }

                    if (workflowSale?.hasOwnProperty('jewellerModels')) {
                        if (workflowSale?.jewellerModels.length > 0) {
                            const jewellerData = workflowSale.jewellerModels[0]
                            jeweller = jewellerData.contactName
                        }
                    }
                })
            }

            workflowStatus = saleWorkflow ? workflowActivity.type + ' - ' + saleWorkflow.status : '';
        }

        jeweller = sale?.workflowLatestProviderContactName
        
        workflowStatus = sprintf(
            '%s - %s',
            sale?.workflowLatestActivityType ?? '',
            sale?.workflowLatestStatus ?? ''
        )

        if (workflowStatus === ' - ') {
            workflowStatus = ''
        }

        return (
            (access_to_accounting_columns === true) ? (
                <tr className={row_class} onClick={() => this.props.handleRowSelection(this.props.index)}>
                    <td className="saleDate">
                        {saleDate !== null ?
                            <div className="saleDate">
                                {saleDate}
                            </div>
                            : ('')
                        }
                    </td>
                    <td className="addToMyList" onClick={e => e.stopPropagation()}>
                        <Form.Check
                            type="checkbox"
                            name="addToMyList"
                            id={"addToMyList" + this.props.index}
                            checked={this.state.addToMyList === true ? true : false}
                            onChange={this.handleChangeCheckbox}
                        //disabled={(this.state.selectedTabToAdd === "results" || this.state.selectedTabToAdd === "" || this.state.selectedTabToAdd === null) ? true : false}
                        />
                    </td>
                    <td className="name">
                        {sale.name}
                    </td>
                    <td className="saleType">
                        {sale.saleType}
                    </td>
                    <td className="diamondDetails">
                        {sale.diamondDetails}
                    </td>
                    <td className="ringPreference">
                        {sale.ringPreference}
                    </td>
                    <td className="orderCurrency">
                        {sale.orderCurrency}
                    </td>
                    <td className="invoice">
                        {sale.invoice}
                    </td>
                    <td className="totalIncGst">
                        {this.formatCurrency(sale.totalIncGst)}
                    </td>
                    <td className="balance">
                        {this.formatCurrency(sale.balance)}
                    </td>
                    <td className="xeroCode">
                        {sale.xeroCode}
                    </td>
                    <td className="onlineOrderNumber">
                        {sale.onlineOrderNumber}
                    </td>
                    <td className="paymentMethod">
                        {sale.paymentMethod}
                    </td>
                    <td className="accountingStatus">
                        {sale.accountingStatus}
                    </td>
                </tr>
            ) : (
                <tr className={row_class} onClick={() => this.props.handleRowSelection(this.props.index)}>
                    <td className="saleDate">
                        {saleDate !== null ?
                            <div className="saleDate">
                                {saleDate}
                            </div>
                            : ('')
                        }
                    </td>
                    <td className="addToMyList" onClick={e => e.stopPropagation()}>
                        <Form.Check
                            type="checkbox"
                            name="addToMyList"
                            id={"addToMyList" + this.props.index}
                            checked={this.state.addToMyList === true ? true : false}
                            onChange={this.handleChangeCheckbox}
                        //disabled={(this.state.selectedTabToAdd === "results" || this.state.selectedTabToAdd === "" || this.state.selectedTabToAdd === null) ? true : false}
                        />
                    </td>
                    <td className="onlineOrderNumber">
                        {sale.onlineOrderNumber}
                    </td>
                    <td className="name">
                        {sale.name}
                    </td>
                    <td className="status">
                        <div className={Util.statusAsSlug(sale.status)}></div>
                        {sale.status}
                    </td>
                    <td className={"orderType " + orderTypeClass}>
                        {sale.orderType}
                    </td>
                    <td className="dateCustomersNeedBy">
                        {dateCustomersNeedBy !== null ?
                            <div className={"dateCustomersNeedBy" + dateCustomersNeedByHighlight}>
                                {dateCustomersNeedBy}
                            </div>
                            : ('')
                        }
                    </td>
                    <td className="workflowStatus" dangerouslySetInnerHTML={{ __html: workflowStatus }}>
                    </td>
                    <td className="saleType">
                        {sale.saleType}
                    </td>
                    <td className="ringPreference">
                        {sale.ringPreference}
                    </td>
                    <td className="jeweller">
                        {jeweller}
                    </td>
                    <td className="diamondLocation">
                        {sale.diamondLocation}
                    </td>
                    <td className="manufacturingLocation">
                        {sale.manufacturingLocation}
                    </td>
                </tr>
            )
        )
    }

    formatCurrency(value) {
        if (typeof value === "undefined") {
            return "";
        }
        else if (value === null || value === "") {
            return "";
        }
        else if (isNaN(Number(value))) {
            return value;
        }

        value = value.toString();
        value = value.replace(",", "");
        value = (Math.round(value * 100) / 100).toFixed(2);
        const currencyFormatter = Intl.NumberFormat(process.env.REACT_APP_NUMBER_FROMAT, { style: 'currency', currency: process.env.REACT_APP_CURRENCY });
        let formattedValue = currencyFormatter.format(value).replace("$", "");
        if (typeof formattedValue === "undefined") {
            return Number(0).toFixed(2);
        }
        if (formattedValue === "NaN") {
            return Number(0).toFixed(2);
        }
        return formattedValue;
    }


    highlightRow() {
        this.setState({
            highlight_row: !this.state.highlight_row
        });
    }

    removeSaleFromCustomisedList() {
        let saleId = this.sale.id;
        this.props.removeSaleFromCustomisedList(saleId);
    }

    /*addSaleToCustomisedList() {
        let saleId = this.state.sale.id;
        this.props.addSaleToCustomisedList(saleId);
    }*/

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return {
            sale: props.sale,
            selected_sale: props.selected_sale,
            addToMyList: props.addToMyList,
            currentTab: props.currentTab,
            selectedTabToAdd: props.selectedTabToAdd,
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

}

export default SaleRow;