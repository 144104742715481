import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, FormLabel, Row } from "react-bootstrap";

const FormInputText = (props) => {
  const {
    value,
    label,
    nameInput,
    onResponse,
    disabled,
    onlyNumber,
    columnsSize,
    required,
    placeholder,
  } = props;
  const [valueNew, setValueNew] = useState(value);

  if (value !== valueNew) {
    setValueNew(value);
  }

  const changeValue = (event) => {
    let { value } = event.target;

    if (onlyNumber) {
      value = value.toString();
      value = value.replace(/,/g, ".").replace(/[^0-9.]/g, "");
    }

    setValueNew(value);

    onResponse(value, nameInput);
  };

  return (
    <Form.Group className="form-group">
      <Row>
        <Col sm="12" lg={columnsSize}>
          <FormLabel>{label}</FormLabel>
        </Col>
        <Col sm="12" lg={columnsSize}>
          <Form.Control
            required={required}
            type="text"
            name={nameInput}
            value={valueNew ?? ""}
            onChange={changeValue}
            disabled={disabled}
            placeholder={placeholder}
          />
          <Form.Control.Feedback type="invalid">
            {label.replace("*", "")} is required
          </Form.Control.Feedback>
        </Col>
      </Row>
    </Form.Group>
  );
};

FormInputText.propTypes = {
  label: PropTypes.string.isRequired,
  nameInput: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onResponse: PropTypes.func,
  disabled: PropTypes.bool,
  onlyNumber: PropTypes.bool,
  columnsSize: PropTypes.any,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

FormInputText.defaultProps = {
  disabled: false,
  onlyNumber: false,
  columnsSize: 6,
  required: false,
  placeholder: "",
};

export default FormInputText;
