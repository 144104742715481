import React, { Component } from 'react';
import axios from 'axios';
import Auth from '../../../Auth';
import Util from '../../../Util';
import * as globalConst from '../../../global/const';
import { findTypeOfClawStandard, renderIsStandarMetalClaw, renderIsStandarTypeOfClaw } from '../edit/SaleFindTypeClaw';
import { Col, Row } from 'react-bootstrap';
import { getEngagementRingPicture } from '../Information/EngagementRingPicture';
import { getDiamonProductPicture } from '../Information/LooseDiamondPicture';
import LoadingSpinnerLayer from '../../LoadingSpinnerLayer';
import { sprintf } from "sprintf-js";

class SaleDetailProduct extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sale: props.sale,
            product: null,
            product_image_url: null,
            typeAndClaw: {
                metalClaw: '',
                metalClawStandard: '',
                typeOfClaw: ''
            },
            loadDataStandard: true
        }
        this.fetchJewelleryByReference = this.fetchJewelleryByReference.bind(this)
        this.getProductPictureUrl = this.getProductPictureUrl.bind(this)
    }

    setDataStandard = async () => {
        this.setState({
            loadDataStandard: true
        })

        const { saleType, ringPreference } = this.state.sale

        const response = await findTypeOfClawStandard(
            ringPreference, saleType
        )

        this.setState({
            typeAndClaw: {
                metalClaw: response.metalClaw,
                metalClawStandard: response.metalClawStandard,
                typeOfClaw: response.typeOfClaw
            },
            loadDataStandard: response.loadDataStandard
        })
    }

    getEngagementRing = async () => {
        const { sale } = this.state;

        const product = await getEngagementRingPicture(sale)

        if (!product) {
            this.setState({
                product: null,
                loadImage: false
            })

            return
        }

        this.setState({
            product: product,
            loadImage: false
        })

        this.getProductImageUrl()
    }

    getDiamonProduct = async () => {
        this.setProductDataImage()

        const { sale } = this.state;

        const product = await getDiamonProductPicture(sale)

        if (!product) {
            this.setState({
                product: null,
                loadImage: false
            })
        }

        this.setState({
            product: product,
            loadImage: false
        })

        this.getDiamonProductUrlAndName(product)
    }

    getDiamonProductUrlAndName = (diamondProduct) => {
        if (!diamondProduct) {
            this.setProductDataImage()
            return
        }

        this.setProductDataImage(diamondProduct.imageExternalUrl)
    }

    ajaxRequest = null;
    async fetchJewelleryByReference() {
        const { ringPreference, saleType } = this.state.sale

        if (Util.wordInArray(saleType, ['loose diamond']) === true) {
            return await this.getDiamonProduct()
        }

        if (
            Util.wordInArray(saleType, ['engagement ring']) === true
        ) {
            return await this.getEngagementRing()
        }

        if (
            Util.wordInArray(saleType, ['wedding band', 'jewellery']) === true &&
            ringPreference !== '' &&
            ringPreference !== null &&
            ringPreference !== undefined
        ) {
            //AJAX CONTROLS
            //cancel previous ajax request if exists
            if (this.ajaxRequest !== null) {
                this.ajaxRequest.cancel();
            }
            // creates a new token for upcomming ajax (overwrite the previous one)
            this.ajaxRequest = axios.CancelToken.source();
            //FINISH AJAX CONTROLS
            const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_PRODUCT_LIST

            const parameters = {
                reference: ringPreference
            }
            axios.get(
                url,
                {
                    params: parameters,
                    headers: { Authorization: `Bearer ${Auth.getToken()}` }
                },
                {
                    cancelToken: this.ajaxRequest.token
                }
            )
                .then(api_response => {
                    const result = api_response.data.result;
                    const items = result.rows;
                    if (Array.isArray(items) === true && items.length > 0) {
                        const product = items[0]

                        this.setState({
                            product: product
                        })

                        this.getProductImageUrl()
                    }
                })
                .catch(err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            console.log('User not authorised. Please reload the page');
                        }
                        else {
                            console.log('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        console.log('There was a problem with your request. Please reload this page and try again.');
                    }
                    console.log(err);
                })
        }
    }

    getProductImageUrl() {
        this.setProductDataImage()

        const { product } = this.state

        if (!product) {
            return null
        }

        const { productPictureModels } = product

        if (!Array.isArray(productPictureModels)) {
            return null
        }

        productPictureModels.map(productPicture => {
            if (
                productPicture.mainPicture === true &&
                productPicture.realLivePicture !== true
            ) {
                this.setProductDataImage(
                    this.getProductPictureUrl(
                        productPicture,
                        product.productCategoryId
                    )
                )

                return;
            }
        })
    }

    setProductDataImage = (
        image = null
    ) => {
        this.setState({
            product_image_url: image ?? null
        })
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        const sale = this.state.sale

        let ownReferenceModified = ""
        let ownReferenceModifiedClass = ""
        if (sale.ownReferenceModified) {
            ownReferenceModified = "Yes"
            ownReferenceModifiedClass = "modifiedDesign"
        } else if (sale.ownReferenceModified === false) {
            ownReferenceModified = "No"
        } else {
            ownReferenceModified = "Not specified"
        }

        let settingHeightClass = 'col-sm'
        let clawTypeClass = 'col-sm'

        if (Util.wordInArray(this.state.sale.saleType, ['engagement ring']) === true) {
            if (sale.settingHeight !== null && sale.settingHeight.toLowerCase().includes('standard') === false) {
                settingHeightClass = 'col-sm red_colour'
            }
            if (sale.clawType !== null && sale.clawType.toLowerCase().includes('round') === false) {
                clawTypeClass = 'col-sm red_colour'
            }
        }


        //
        const product = this.state.product
        let product_name = null
        if (product !== null && product !== undefined) {
            if (product.name !== undefined) {
                product_name = product.name
            }
        }

        let orderTypeClass = ""
        if (sale.orderType === "Express") {
            orderTypeClass = "express"
        }
        else if (sale.orderType === "Ready to Ship") {
            orderTypeClass = "readyToShip"
        }

        return (
            <div id="SaleDetailProduct_component">
                <div className='sale_data'>
                    <h2 className='heading'>PRODUCT</h2>
                    <div className="container mb-4">
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'jewellery', 'diamond']) === true) ?
                                <div className="row">
                                    <div className="col-sm">
                                        Diamond cert #, Shape ct Colour Clarity
                                        <br />
                                        {
                                            (sale.diamondDetails === '' || sale.diamondDetails === null) ? (
                                                'Diamond not included'
                                            ) :
                                                (sale.diamondDetails)
                                        }
                                    </div>
                                </div>
                                : (null)
                        }

                        {
                            this.state.loadImage ?
                                <div className="center-element mt-2">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                null
                        }

                        {
                            (
                                this.state.product_image_url !== null
                            ) ?
                                <div className="row">
                                    <div className="col-sm align-center">
                                        <img src={this.state.product_image_url} alt={sale.diamondDetails} />
                                    </div>
                                </div>
                                : (null)
                        }
                        {
                            (
                                product_name !== null
                            ) ?
                                <div className="row">
                                    <div className="col-sm align-center">
                                        <div dangerouslySetInnerHTML={{ __html: product_name }}></div>
                                    </div>
                                </div>
                                : (null)
                        }
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band']) === true
                                && sale.expressJobRequired) ?
                                <div className="row">
                                    <div className="col-sm align-center red_colour">This order includes EXPRESS JOB</div>
                                </div>
                                : (null)
                        }
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring']) === true
                                && sale.includesReadyToShip) ?
                                <div className="row">
                                    <div className="col-sm align-center red_colour">This order includes a product READY TO SHIP</div>
                                </div>
                                : (null)
                        }
                        <div className="row">
                            <div className="col-sm border-right bold">Type of Product</div>
                            <div className="col-sm">{sale.saleType}</div>
                        </div>
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band', 'jewellery']) === true) ?
                                <div className="row">
                                    <div className="col-sm border-right bold">Product's reference</div>
                                    <div className="col-sm">{sale.ringPreference}</div>
                                </div>
                                : (null)
                        }
                        <div className="row">
                            <div className="col-sm border-right bold">Customisation</div>
                            <div className={"col-sm " + ownReferenceModifiedClass}>{ownReferenceModified}</div>
                        </div>
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring']) === true) ?
                                (
                                    <div className="row">
                                        <div className="col-sm border-right bold">
                                            <Row>
                                                <Col lg="8">
                                                    Type of Claw
                                                </Col>
                                                <Col lg="4">
                                                    {
                                                        this.state.loadDataStandard === true ?
                                                            <LoadingSpinnerLayer />
                                                            :
                                                            (null)
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="col-sm"
                                            dangerouslySetInnerHTML={
                                                {
                                                    __html: renderIsStandarTypeOfClaw(
                                                        sale.clawType ?? '', this.state.typeAndClaw
                                                    )
                                                }
                                            }
                                        />
                                    </div>
                                ) : (null)
                        }
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring']) === true) ?
                                <div className="row">
                                    <div className="col-sm border-right bold">Setting Height</div>
                                    <div className={settingHeightClass}>
                                        {sale.settingHeight}
                                    </div>
                                </div>
                                : (null)
                        }
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band']) === true) ?
                                <div className="row">
                                    <div className="col-sm border-right bold">Engraving</div>
                                    <div className="col-sm">{sale.engraving}</div>
                                </div>
                                : (null)
                        }
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band', 'jewellery']) === true) ?
                                <div className="row">
                                    <div className="col-sm border-right bold">Band Metal</div>
                                    <div className="col-sm">{sale.metal}</div>
                                </div>
                                : (null)
                        }
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring']) === true) ?
                                <div className="row">
                                    <div className="col-sm border-right bold">
                                        <Row className='table_title'>
                                            <Col lg="8">
                                                Claw metal
                                            </Col>
                                            <Col lg="4">
                                                {
                                                    this.state.loadDataStandard === true ?
                                                        <LoadingSpinnerLayer />
                                                        :
                                                        (null)
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="col-sm"
                                        dangerouslySetInnerHTML={
                                            {
                                                __html: renderIsStandarMetalClaw(
                                                    sale.clawMetal ?? '', this.state.typeAndClaw
                                                )
                                            }
                                        }
                                    />
                                </div>
                                : (null)
                        }
                        {
                            (Util.wordInArray(sale.saleType, ['engagement ring', 'wedding band']) === true) ?
                                (
                                    <div className="row">
                                        <div className="col-sm border-right bold">Ring size</div>
                                        <div className="col-sm">{sale.ringSize}</div>
                                    </div>
                                ) : (null)
                        }
                        {
                            (Util.wordInArray(sale.saleType, ['jewellery']) === true) ?
                                (
                                    <div className="row">
                                        <div className="col-sm border-right bold">Type of Jewellery</div>
                                        <div className="col-sm">{sale.jewelleryType}</div>
                                    </div>
                                ) : (null)
                        }
                        <div className="row">
                            <div className="col-sm border-right bold">Type of Order</div>
                            <div className={"col-sm orderType " + orderTypeClass}>{sale.orderType}</div>
                        </div>
                        {
                            (
                                Util.wordInArray(sale.saleType, ['wedding']) === true &&
                                sale.ringPreference === globalConst.REACT_APP_REFERENCE_AG_WBJ
                            ) ?
                                <div className="row">
                                    <div className="col-sm border-right bold">Product details</div>
                                    <div className={"col-sm"}>{sale.productDetails}</div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }

    getProductPictureUrl(productPicture, procuct_category_id) {
        let product_picture_url = globalConst.REACT_APP_NOVITA_DOCS_DOMAIN

        if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_RING) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_RINGS}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_EARRING) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_EARRINGS}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_BRACELET) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_BRACELETS}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_NECKLACE) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_NECKLACES}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_ER) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_ENG_RINGS}`
        } else if (procuct_category_id === globalConst.REACT_APP_PRODUCT_CATEGORY_ID_CABLE_CHAIN) {
            product_picture_url += `${globalConst.REACT_APP_NOVITA_DOCS_CABLE_CHAINS}`
        }

        return product_picture_url += `${productPicture.filename}`
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    async componentDidMount() {
        this.fetchJewelleryByReference()

        this.setDataStandard()
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************


}

export default SaleDetailProduct;