import React, {Component} from 'react';

class FlashMessages extends Component {
    //this component receives the following parameters as array of strings:
    // error_messages
    // warning_messages
    // success_messages
    render(){
        const error_messages = this.props.error_messages
        const warning_messages = this.props.warning_messages
        const success_messages = this.props.success_messages
        return(
            <div className='FlashMessages_component'>
                {/* <FlashMessage duration={5000} persistOnHover={true}> */}
                    {
                        (error_messages !== undefined && error_messages.length > 0) ? 
                        (
                            <div className='alert alert-error'> 
                                <ul className='mb-0'>
                                {
                                    error_messages.map(function(message, index){
                                        return <li key={index}>{message}</li>
                                    })
                                }
                                </ul>
                            </div>
                        ):
                        ('')
                    }
                    {
                        (warning_messages !== undefined && warning_messages.length > 0) ? 
                        (
                            <div className='alert alert-warning'> 
                                <ul className='mb-0'>
                                    {
                                        warning_messages.map(function(message, index){
                                            return <li key={index}>{message}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        ):
                        ('')
                    }
                    {
                        (success_messages !== undefined && success_messages.length > 0) ? 
                        (
                            <div className='alert alert-success'> 
                                <ul className='mb-0'>
                                    {
                                        success_messages.map(function(message, index){
                                            return <li key={index}>{message}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        ):
                        ('')
                    }
                {/* </FlashMessage> */}
            </div>
        )
    }
}

export default FlashMessages;