import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { findCustomers } from "../../../store/slices";

const SaleFindCustomer = ({ onResponseInput, onResponseCustomer }) => {
  const dispatch = useDispatch();

  const { customerCreateFind = [] } = useSelector((state) => state.customers);

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [customer, setCustomer] = useState({
    customerSearch: true,
    customerCreate: false,
  });

  useEffect(() => {
    if (customerCreateFind.length > 0) {
      setOptions(customerCreateFind);
    }
  }, [customerCreateFind]);

  const handleChangeCheckbox = (event, disabledField = "") => {
    const { checked, name } = event.target;

    setCustomer((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    if (disabledField) {
      setCustomer((prevState) => ({
        ...prevState,
        [disabledField]: !checked,
      }));
    }

    onResponseInput(checked, name);

    if (name === "customerCreate") {
      setSelected([]);
    }
  };

  const renderLabelAtucomplete = (option) => {
    return option.name + " (" + option.phone + ")";
  };

  const searchElementsAutocomplete = async (value) => {
    setIsLoading(true);
    const args = {
      columns: [],
      filterText: value,
      cancelToken: null,
      returnEmptyValues: null,
      minDate: null,
      maxDate: null,
      createdSort: "DESC",
      exportCSV: null,
      page: 1,
      maxResults: 10,
    };
    await dispatch(findCustomers(args));
    setIsLoading(false);
  };

  return (
    <>
      <Row>
        <Col xs="6" lg="6">
          <Form.Check type={"switch"}>
            <Form.Check.Input
              className="check-all-link"
              name="customerSearch"
              type="checkbox"
              id="customerSearch"
              checked={customer.customerSearch ?? true}
              onChange={(e) => handleChangeCheckbox(e, "customerCreate")}
            />
            <Form.Check.Label
              className="check-all-link"
              htmlFor="customerSearch"
            >
              Find exist customer
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="6" lg="6">
          <Form.Check type={"switch"}>
            <Form.Check.Input
              className="check-all-link"
              name="customerCreate"
              type="checkbox"
              id="customerCreate"
              checked={customer.customerCreate ?? true}
              onChange={(e) => handleChangeCheckbox(e, "customerSearch")}
            />
            <Form.Check.Label
              className="check-all-link"
              htmlFor="customerCreate"
            >
              Create new Customer
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="4">
          <FormLabel>
            Find customer {customer.customerSearch ? "*" : ""}
          </FormLabel>
          <AsyncTypeahead
            id="async-autocomplete"
            isLoading={isLoading}
            labelKey={"name"}
            minLength={0}
            selected={selected}
            onSearch={async (e) => searchElementsAutocomplete(e)}
            options={options}
            onChange={(selected) => {
              setSelected(selected);
              onResponseCustomer(selected[0]);
            }}
            disabled={!customer.customerSearch}
            placeholder={"Search cutomer..."}
            renderMenuItemChildren={(option) => (
              <div>{renderLabelAtucomplete(option)}</div>
            )}
            isRequired
            inputProps={{ required: true }}
          />
        </Col>
      </Row>
    </>
  );
};

SaleFindCustomer.propTypes = {
  onResponseCustomer: PropTypes.func.isRequired,
  onResponseInput: PropTypes.func.isRequired,
};

export default SaleFindCustomer;
