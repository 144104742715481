import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Modal, Row } from "react-bootstrap";
import {
  calculateCommissionValue,
  getPriceExtGst,
  getSaleCommissionPaidDate,
  getSaleCommissionRateValue,
} from "./Data/ActionSaleCommission";
import { findEmployeeCommissionRate } from "./Data/FindEmployeeCommissionRate";
import { updateSaleCommission } from "./Data/UpdateSaleCommission";
import LoadingLayer from "../../LoadingLayer";
import Util from "../../../Util";
import FormDatePicker from "../../Form/FormDatePicker";
import FormSelectCustomer from "../../Form/FormSelectCustomer";
import FormInputText from "../../Form/FormInputText";
import {
  SALE_COMMISSION_TYPE_REVIEWS,
  SALE_COMMISSION_TYPE_SALES,
} from "../../../global/const";

const INPUT_COMMISSION_PERCENTAGE = "commissionPercentage";
const INPUT_COMMISSION_BASE = "commissionBase";

const CommissionsEdit = ({
  sale,
  saleCommission,
  showSaleComissionEdit,
  onActionModel,
  total,
  type,
}) => {
  const [saleCommissionData, setSaleCommissionData] = useState(saleCommission);
  const [commissionPercentage, setCommissionPercentage] = useState(
    saleCommissionData.commissionPercentage
  );
  const [commissionValue, setCommissionValue] = useState(
    saleCommissionData.commissionValue
  );
  const [employeeCommissionRates, setEmployeeCommissionRates] = useState([]);
  const [commissionBase, setCommissionBase] = useState(
    saleCommissionData.commissionBase
  );
  const [consultEmployeeCommissionRate, setConsultEmployeeCommissionRate] =
    useState(false);
  const [saveComission, setSaveComission] = useState(false);
  const [errorSaveComission, setErrorSaveComission] = useState(false);
  const [loadSaveComission, setLoadSaveComission] = useState(false);

  const handleClose = () => {
    if (showSaleComissionEdit) {
      onActionModel(false, saleCommissionData);
    }
  };

  const calculateValue = () => {
    const totalIncGst = getPriceExtGst(sale);

    const value = calculateCommissionValue(
      saleCommissionData.commissionPercentage,
      totalIncGst
    );

    const totalCommission = Util.numberDecimal(value / total);

    saleCommissionData.commissionValue = totalCommission;

    setSaleCommissionData(saleCommissionData);
    setCommissionValue(totalCommission);
  };

  const calculateValueReviews = () => {
    const totalCommission = Util.numberDecimal(
      saleCommissionData.commissionBase / total
    );

    saleCommissionData.commissionValue = totalCommission;

    setSaleCommissionData(saleCommissionData);
    setCommissionValue(totalCommission);
  };

  const onChangeInput = (value, name) => {
    if (value.length <= 0) {
      value = "";
    }

    saleCommissionData[name] = value;
    setSaleCommissionData(saleCommissionData);

    if (name === INPUT_COMMISSION_PERCENTAGE) {
      setCommissionPercentage(value);

      calculateValue();
    }

    if (name === INPUT_COMMISSION_BASE) {
      setCommissionBase(value);

      calculateValueReviews();
    }
  };

  const onChangeSelect = (value) => {
    const data = getSaleCommissionRateValue(value);
    saleCommissionData.commissionPercentage = data?.rate ?? "";
    saleCommissionData.employeeCommissionRateId = data?.id ?? "";

    setSaleCommissionData(saleCommissionData);
    setCommissionPercentage(data?.rate ?? "");

    calculateValue();
  };

  const onChangeDate = (value, name) => {
    const date = getSaleCommissionPaidDate(value);
    saleCommissionData[name] = date;

    setSaleCommissionData(saleCommissionData);
  };

  const save = async () => {
    setLoadSaveComission(true);

    const response = await updateSaleCommission(saleCommissionData);

    if (response.id) {
      setSaleCommissionData(response);
      setSaveComission(true);
    }

    if (response.code === 400) {
      setErrorSaveComission(true);
    }

    setLoadSaveComission(false);
  };

  useEffect(() => {
    if (!consultEmployeeCommissionRate) {
      async function fetchData() {
        setConsultEmployeeCommissionRate(true);

        setEmployeeCommissionRates(
          await findEmployeeCommissionRate(
            sale.id,
            saleCommission.employeeModel.id
          )
        );
      }

      fetchData();
    }
  }, [
    consultEmployeeCommissionRate,
    employeeCommissionRates,
    sale,
    saleCommission,
    showSaleComissionEdit,
    onActionModel,
  ]);

  const { employeeCommissionRateModel = {} } = saleCommission;

  const commission = employeeCommissionRateModel;

  return (
    <Modal show={showSaleComissionEdit} onHide={handleClose}>
      {loadSaveComission ? <LoadingLayer /> : null}

      <Modal.Header closeButton>
        <Modal.Title>Edit employee commission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {saveComission ? (
          <Alert
            variant="success"
            onClose={() => setSaveComission(false)}
            dismissible
          >
            <p>Sale commission updated</p>
          </Alert>
        ) : null}

        {errorSaveComission ? (
          <Alert
            variant="danger"
            onClose={() => setErrorSaveComission(false)}
            dismissible
          >
            <p>The commission has already been paid</p>
          </Alert>
        ) : null}

        <Row>
          {type === SALE_COMMISSION_TYPE_SALES && (
            <FormSelectCustomer
              label="Commission type"
              nameInput="name"
              items={employeeCommissionRates}
              keyValue={"id"}
              value={commission?.id ?? ""}
              onResponse={onChangeSelect}
            />
          )}
        </Row>
        <Row className="mt-2">
          {type === SALE_COMMISSION_TYPE_SALES && (
            <FormInputText
              onlyNumber={true}
              label="Commission percentage"
              nameInput="commissionPercentage"
              value={commissionPercentage}
              onResponse={onChangeInput}
            />
          )}
          {type === SALE_COMMISSION_TYPE_REVIEWS && (
            <FormInputText
              onlyNumber={true}
              label="Commission base"
              nameInput="commissionBase"
              value={commissionBase}
              onResponse={onChangeInput}
            />
          )}
        </Row>
        <Row className="mt-2">
          <FormInputText
            label="Commission value"
            nameInput="commissionValue"
            value={commissionValue}
            disabled={true}
          />
        </Row>
        <Row className="mt-2">
          <FormDatePicker
            label="Paid date"
            nameInput="paidDate"
            value={saleCommission.paidDate ?? ""}
            onResponse={onChangeDate}
            required={false}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-novita-pink" variant="primary" onClick={save}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CommissionsEdit.propTypes = {
  sale: PropTypes.any.isRequired,
  showSaleComissionEdit: PropTypes.bool.isRequired,
  saleCommission: PropTypes.any.isRequired,
  onActionModel: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

CommissionsEdit.defaultProps = {
  type: SALE_COMMISSION_TYPE_SALES,
};

export default CommissionsEdit;
