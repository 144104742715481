import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployees } from "../../../store/slices/employeeSlice";
import { Button, Form, ListGroup, ListGroupItem } from "react-bootstrap";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function EmployeeSelectOption({ employee }) {
  return <option value={employee.id}>{employee.name}</option>;
}

function EmployeeSelectAddedEmployee({ employee, onRemove }) {
  return (
    <ListGroupItem style={{ fontSize: '12px' }}>
      <FontAwesomeIcon
        icon={faTrash}
        className="mt-1 me-2"
        style={{ float: 'left' }}
        onClick={() => onRemove(employee)}
        role="button"
      />
      {employee.name}
    </ListGroupItem>
  );
}

export function EmployeeSelect({ addedEmployees, setAddedEmployees }) {
  const dispatch = useDispatch();
  const { employees, isLoading } = useSelector((state) => state.employees);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  useEffect(()=>{
    if (employees.length > 0) {
      setSelectedEmployeeId(employees[0].id);
    }
  }, [employees]);

  const handleSelectionChanged = (event) => {
    setSelectedEmployeeId(event.target.value);
  };

  const handleAddEmployee = () => {
    const selectedEmployee = employees.find((e) => e.id == selectedEmployeeId);

    if (!selectedEmployee) {
      return;
    }

    if (addedEmployees.some((e) => e.id == selectedEmployee.id)) {
      alert('Employee already added');
      return;
    }

    setAddedEmployees([...addedEmployees, selectedEmployee]);
    setSelectedEmployeeId('');
  };

  const handleRemoveEmployee = (employeeToRemove) => {
    const filteredEmployees = addedEmployees.filter((e) => e.id !== employeeToRemove.id);
    setAddedEmployees(filteredEmployees);
  };

  return (
    <Form.Group className="mb-3" controlId="formEmployees">
      <Form.Label>Add Employees</Form.Label>
      <div className="d-flex">
        <Form.Select
          style={{ fontSize: '12px' }}
          aria-label="Select an employee"
          value={selectedEmployeeId}
          onChange={handleSelectionChanged}
        >
          {isLoading ? (
            <option>Loading employees...</option>
          ) : (
            employees.map((employee) => (
              <EmployeeSelectOption key={employee.id} employee={employee} />
            ))
          )}
        </Form.Select>
        <Button variant="primary" className="ms-2" onClick={handleAddEmployee} disabled={!selectedEmployeeId}>
          Add
        </Button>
      </div>
      <div className="pt-1 pb-1" style={{ width: 'fit-content' }}>
        <ListGroup>
          {addedEmployees.map((employee) => (
            <EmployeeSelectAddedEmployee
              key={employee.id}
              employee={employee}
              onRemove={handleRemoveEmployee}
            />
          ))}
        </ListGroup>
      </div>
    </Form.Group>
  );
}
