import { REACT_APP_SALE_TYPE_LOOSE_DIAMOND } from "../../../../../../global/const";

export const validFormSendGD = (saleDiamonds, sale) => {
  const { saleType } = sale;

  if (saleType === REACT_APP_SALE_TYPE_LOOSE_DIAMOND) {
    const diamonds = true;
    const showDiamonds = true;

    const jewellery = false;
    const showJewellery = false;

    return {
      showDiamonds: showDiamonds,
      showJewellery: showJewellery,
      diamonds: diamonds,
      jewellery: jewellery,
      disabledDiamonds: true,
      disabledJewellery: true,
    };
  }

  if (saleDiamonds.length >= 1) {
    const diamonds = true;
    const showDiamonds = true;

    let jewellery = false;
    let showJewellery = true;
    let disabledJewellery = false;

    if (saleDiamonds.length >= 2) {
      jewellery = true;
      showJewellery = true;
      disabledJewellery = true;
    }

    return {
      showDiamonds: showDiamonds,
      showJewellery: showJewellery,
      diamonds: diamonds,
      jewellery: jewellery,
      disabledDiamonds: true,
      disabledJewellery: disabledJewellery,
    };
  }

  if (saleDiamonds.length >= 0) {
    const jewellery = true;
    const showJewellery = true;

    const showDiamonds = false;
    const diamonds = false;

    return {
      showDiamonds: showDiamonds,
      showJewellery: showJewellery,
      diamonds: diamonds,
      jewellery: jewellery,
      disabledDiamonds: false,
      disabledJewellery: true,
    };
  }

  return {
    showDiamonds: true,
    showJewellery: true,
    diamonds: false,
    jewellery: false,
    disabledDiamonds: false,
    disabledJewellery: false,
  };
};
