import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Auth from "../../../Auth";
import CommissionFormSelect from "./CommissionFormSelect";
import {
  SALE_COMMISSION_TYPE_REVIEWS,
  SALE_COMMISSION_TYPE_SALES,
} from "../../../global/const";
import Util from "../../../Util";

const CommissionsRow = ({
  saleCommissions,
  onActionModel,
  showAction,
  sale,
  onResponse,
  type,
}) => {
  const [saleCommissionRows, setSaleCommissionRows] = useState([]);

  const onResponseUpdate = async () => {
    onResponse(false);
  };

  const renderEditCommission = (saleCommission) => {
    return (
      <CommissionFormSelect
        sale={sale}
        saleCommission={saleCommission}
        onResponseUpdateCommission={onResponseUpdate}
        onResponseUpdate={onResponseUpdate}
        total={saleCommissions.length}
      />
    );
  };

  const renderSaleCommission = (saleCommission) => {
    const { employeeModel, employeeCommissionRateModel } = saleCommission;

    const employeeLogin = Auth.getEmployee();
    const superUSer = Auth.canAccessRolesAndLeader(
      "rolesAccessMyFollowUps",
      true
    );

    if (superUSer && !showAction && type === SALE_COMMISSION_TYPE_SALES) {
      return renderEditCommission(saleCommission);
    }

    const isSales = Auth.canAccessRolesAndLeader("rolesAccessMyFollowUps");
    const isLeader = Auth.isLeader();

    if (
      isSales &&
      isLeader &&
      employeeModel.name === employeeLogin?.name &&
      !showAction &&
      type === SALE_COMMISSION_TYPE_SALES
    ) {
      return renderEditCommission(saleCommission);
    }

    if (
      employeeLogin.id === saleCommission.employeeId &&
      employeeLogin.allowCommissionEditing
    ) {
      return renderEditCommission(saleCommission);
    }

    const commission = employeeCommissionRateModel;

    if (!commission) {
      return "Other";
    }

    return commission?.name;
  };

  useEffect(() => {
    if (saleCommissionRows.length <= 0) {
      setSaleCommissionRows(saleCommissions);
    }
  }, [saleCommissionRows.length, saleCommissions]);

  return saleCommissionRows.map((saleCommission) => {
    const {
      employeeModel,
      commissionPercentage,
      commissionValue,
      paidDate,
      type,
      commissionBase,
    } = saleCommission;

    return (
      <tr key={saleCommission.id}>
        <td>{employeeModel?.name}</td>
        <td>{type}</td>
        {type === SALE_COMMISSION_TYPE_SALES && (
          <>
            <td>{renderSaleCommission(saleCommission)}</td>
            <td>{commissionPercentage}</td>
          </>
        )}
        {type === SALE_COMMISSION_TYPE_REVIEWS && (
          <>
            <td>{commissionBase}</td>
          </>
        )}
        <td>{Util.numberDecimal(commissionValue)}</td>
        <td>{paidDate}</td>

        {showAction ? (
          <td>
            <Button
              className="btn-novita-pink-outline"
              variant="outline-primary"
              onClick={() => onActionModel(true, saleCommission)}
            >
              Edit
            </Button>
          </td>
        ) : null}
      </tr>
    );
  });
};

CommissionsRow.propTypes = {
  sale: PropTypes.any.isRequired,
  saleCommissions: PropTypes.array.isRequired,
  showAction: PropTypes.bool.isRequired,
  onResponse: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

CommissionsRow.defaultProps = {
  type: SALE_COMMISSION_TYPE_SALES,
};

export default CommissionsRow;
