import React from "react";
import PropTypes from "prop-types";
import { ListGroup } from "react-bootstrap";

const CustomerSalesRepresentatives = ({ customerAppointment }) => {
  const buildSalesReps = () => {
    return customerAppointment.customerEmployees.map((rep) => {
      return <ListGroup.Item key={rep.id}>{rep.employee.name}</ListGroup.Item>;
    });
  };

  return <ListGroup>{buildSalesReps()}</ListGroup>;
};

CustomerSalesRepresentatives.propTypes = {
  customerAppointment: PropTypes.object.isRequired,
};

export default CustomerSalesRepresentatives;
