//Accounts
export const rolesAllAccess = ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"];
export const billingInformation = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const paymentIdVerification = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const orderCurrency = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const cadPayment = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
  "ROLE_SALES",
];
export const balance = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
  "ROLE_SALES",
];
export const paysByDeposit = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const paymentMethod = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const invoice = ["ROLE_ADMIN", "ROLE_OPERATIONS", "ROLE_ACCOUNTING"];
export const xeroCode = ["ROLE_ADMIN", "ROLE_OPERATIONS", "ROLE_ACCOUNTING"];
export const jewellerInvoiceNumber = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const settingCostPrice = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const sellingPriceNova = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const xeroCodeMmdia = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const xeroCodeNova = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const invoiceValueLocalCurrency = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
];
export const accountingStatus = ["ROLE_ACCOUNTING"];
//Production
export const urgent_date_requested_by_customer = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_SALES",
  "ROLE_ACCOUNTING",
];
export const rolesToCostWorkflow = [
  "ROLE_COSTS",
  "ROLE_ACCOUNTING",
  "ROLE_ADMIN",
];
export const rolesExportWorkflowCost = [
  "ROLE_COSTS",
  "ROLE_ACCOUNTING",
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
];
export const rolesSearchStockNumber = [
  "ROLE_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_COSTS",
  "ROLE_ACCOUNTING",
];
export const rolesInteractiveWorkflow = [
  "ROLE_ADMIN",
  "ROLE_ACCOUNTING",
  "ROLE_COSTS",
  "ROLE_OPERATIONS",
  "ROLE_SALES",
];
export const rolesEditDiamonsSales = ["ROLE_ADMIN", "ROLE_SALES"];
export const rolesEditDiamonsOperations = ["ROLE_ADMIN", "ROLE_OPERATIONS"];
export const rolesSaleCommissionsView = [
  "ROLE_ADMIN",
  "ROLE_SALES",
  "ROLE_ACCOUNTING",
];
export const rolesIsSales = ["ROLE_SALES"];
export const rolesEditReports = ["ROLE_ADMIN", "ROLE_SALES", "ROLE_OPERATIONS"];
//Accounts
export const rolesAccessCRMList = [
  "ROLE_ADMIN",
  "ROLE_SUPER_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_ACCOUNTING",
  "ROLE_SALES",
  "ROLE_MARKETING",
  "ROLE_COSTS",
];

export const rolesAccessCRMSale = [
  "ROLE_ADMIN",
  "ROLE_SUPER_ADMIN",
  "ROLE_OPERATIONS",
  "ROLE_SALES",
];

export const rolesAccessCRMCreate = [
  "ROLE_ADMIN",
  "ROLE_SUPER_ADMIN",
  "ROLE_SALES",
];

export const rolesAccessCRMOperation = ["ROLE_OPERATIONS"];

export const rolesEditStatusReturn = [
  "ROLE_ADMIN",
  "ROLE_SUPER_ADMIN",
  "ROLE_COSTS",
  "ROLE_SALES",
];

//Advanced Filter access for default filter as (My follow ups)
export const rolesAccessMyFollowUps = [
  "ROLE_ADMIN",
  "ROLE_SUPER_ADMIN",
  "ROLE_SALES",
];

export const rolesAccessEditMultiple = [
  "ROLE_ADMIN",
  "ROLE_SUPER_ADMIN",
  "ROLE_OPERATIONS",
];
