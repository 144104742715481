import { Form, FormLabel } from "react-bootstrap"
import DateField from '../formComponents/DateField';
import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

class PostSaleJewellerRowEdit extends Component {

    constructor(props) {
        super();
        this.state = {
            index: props.index,
            jeweller: (props.jeweller !== undefined) ? props.jeweller.jeweller : '',
            providers: (props.providers !== undefined) ? props.providers : [],
            datePickedUpFromJeweller: (props.jeweller !== undefined) ? props.jeweller.datePickedUpFromJeweller : null,
            dateGivenToJeweller: (props.jeweller !== undefined) ? props.jeweller.dateGivenToJeweller : null,
            jobPackage: (props.jeweller !== undefined) ? props.jeweller.jobPackage : '',
            notes: (props.jeweller !== undefined) ? props.jeweller.notes : '',
            provider: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.deleteJeweller = this.deleteJeweller.bind(this)

        this.setProvider()
    }

    handleChange(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        if (event.target.value !== this.state[field_name]) {
            state["fields_no_updated"] = false
        }
        this.setState(state)
    }

    handleChangeSelect(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        if (event.target.value !== this.state[field_name]) {
            state["fields_no_updated"] = false
        }
        this.setState(state)
        this.props.updateJeweller(this.state.index, state)
    }

    handleChangeDateField = (date, field_name) => {
        const state = {}
        if (date !== null && date !== '') {
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const year = date.getFullYear()
            state[field_name] = year + '-' + month + '-' + day
        }
        else {
            state[field_name] = null
        }
        if (date !== this.state[field_name]) {
            state["fields_no_updated"] = false
        }
        this.setState(state)
        this.props.updateJeweller(this.state.index, state)
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        let datePickedUpFromJeweller
        if (typeof this.state.datePickedUpFromJeweller !== "undefined" && this.state.datePickedUpFromJeweller !== null) {
            const datePickedUpFromJeweller_string = this.state.datePickedUpFromJeweller.substring(0, 10)
            datePickedUpFromJeweller = new Date(datePickedUpFromJeweller_string)
            if (datePickedUpFromJeweller === 'Invalid Date'
                || isNaN(datePickedUpFromJeweller)) {
                datePickedUpFromJeweller = ''
            }
        }

        let dateGivenToJeweller
        if (typeof this.state.dateGivenToJeweller !== "undefined" && this.state.dateGivenToJeweller !== null) {
            const dateGivenToJeweller_string = this.state.dateGivenToJeweller.substring(0, 10)
            dateGivenToJeweller = new Date(dateGivenToJeweller_string)
            if (dateGivenToJeweller === 'Invalid Date'
                || isNaN(dateGivenToJeweller)) {
                dateGivenToJeweller = ''
            }
        }

        return (
            <Form.Group className="form_group_create card mx-4">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-10">
                                <div className="sale_title alight-center bold_font">Jeweller #{(this.state.index + 1)}</div>
                            </div>
                            <div className="col-2 float-right">
                                <div className="sale_title bold_font delete_jeweller" onClick={this.deleteJeweller}>X</div>
                            </div>
                        </div>
                        <Form.Group>
                            <FormLabel className="d-block">Jeweller</FormLabel>
                            <Typeahead
                                id="basic-typeahead-single"
                                labelKey="nameComplete"
                                options={this.state.providers}
                                placeholder="Choose a provider..."
                                defaultSelected={this.state.provider}
                                onChange={(e) => this.handleChangeProvider(e)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <FormLabel>Date given to jeweller</FormLabel>
                            <DateField
                                handleChangeDateField={this.handleChangeDateField}
                                field_name='dateGivenToJeweller'
                                currentDate={dateGivenToJeweller}
                            />
                        </Form.Group>
                        <Form.Group>
                            <FormLabel>Date Picked up from the Jeweller</FormLabel>
                            <DateField
                                handleChangeDateField={this.handleChangeDateField}
                                field_name='datePickedUpFromJeweller'
                                currentDate={datePickedUpFromJeweller}
                            />
                        </Form.Group>
                        <Form.Group>
                            <FormLabel>Job Package</FormLabel>
                            <Form.Control
                                type="text"
                                name="jobPackage"
                                value={this.state.jobPackage === null ? '' : this.state.jobPackage}
                                onChange={this.handleChange}
                                onBlur={(e) => {
                                    this.props.updateJeweller(this.state.index, { "jobPackage": this.state.jobPackage })
                                }}
                            />
                        </Form.Group>
                        <div className='text_field'>
                            <label className='form-label'>Notes</label>
                            <textarea
                                className="form-control w-100"
                                name="notes"
                                value={this.state.notes}
                                onChange={this.handleChange}
                                onBlur={(e) => {
                                    this.props.updateJeweller(this.state.index, { "notes": this.state.notes })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Form.Group>
        )
    }

    setProvider = () => {
        let provider = [];

        provider = this.state.providers.filter(provider => {
            if (provider.contactName === this.state.jeweller) {
                return provider
            }
        })

        this.state.provider = provider
    }

    handleChangeProvider(event) {
        if (event.length <= 0) {
            this.setState({
                jeweller: ''
            })

            return
        }

        this.setState({
            providerId: event[0].contactName
        })

        this.state.jeweller = event[0].contactName

        this.props.updateJeweller(this.state.index, this.state)
    }

    deleteJeweller() {
        this.props.deleteJeweller(this.state.index);
    }
}

export default PostSaleJewellerRowEdit;