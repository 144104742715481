import React, { Component } from 'react';
import { Dropdown, Button, Row, Col, Tab, Tabs, Alert } from 'react-bootstrap';
import { faFilter, faClose, faSearch, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddFieldSearch from './AddFieldSearch';
import RenderFieldSearch from './RenderFieldSearch';
import ConfirmRemoveFilters from './ConfirmRemoveFilters';
import CreateFavouriteFilter from './favouriteFilter/CreateFavouriteFilter';
import ListFavouriteFilter from './favouriteFilter/ListFavouriteFilter';
import axios from 'axios';
import * as globalConst from '../../global/const';
import Auth from '../../Auth';
import { sprintf } from "sprintf-js";

// css
import '../../css/advanced-filter.scss'

// Data Json
import filterSaleData from './filtros/filter-sale-workflow.json';

const CancelToken = axios.CancelToken;
let cancel;

const CustomToggleFilter = React.forwardRef(({ children, onClick }, ref) => (
    <Button
        className='btn-active-filter'
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
        ref={ref}
    >
        <FontAwesomeIcon icon={faFilter} className="btn-fa-filter" />
    </Button>
));

class AdvancedFilter extends Component {

    constructor(props) {
        super();

        const filterSaleDataString = JSON.stringify(filterSaleData);
        const filterSaleDataArray = JSON.parse(filterSaleDataString);

        this.state = {
            dataForAdvancedFilter: props.dataForAdvancedFilter,
            dataForAdvancedFilterOrder: props.dataForAdvancedFilterOrder,
            pageAdvancedFilter: props.pageAdvancedFilter,
            sheetType: props.sheetType,
            fields: filterSaleDataArray,
            fieldsFavourite: Object.assign([], filterSaleData),
            disabledSearch: props.disabledSearch ?? true,
            activeFilter: false,
            showFilters: true,
            showRemoveFilter: false,
            searchActive: false,
            dropdownIsOpen: true,
            searchShowResults: false,
            autoSearch: false,
            showFavouriteFilter: false,
            showFavouriteFilterList: true,
            favouriteFilterCreate: false,
            tabKey: 'filter',
            clearDataForAdvancedFilter: false
        }

        this.favouriteFilters = []

        this.responseFilters = Object.assign([], this.state.fields)
        this.dataForAdvancedFilter = Object.assign([], this.state.dataForAdvancedFilter)
        this.responseFiltersFavourite = []

        this.createFavouriteFilter = false
    }

    componentWillUnmount = () => {
        this.clearAdvancedFilter()
    }

    clearAdvancedFilter = () => {
        const fields = this.state.fields.map(field => {
            field = this.clearDataFilter(field)
            return field
        })

        this.setState({fields: fields})
    }

    componentDidUpdate(nextProps, nextState) {
        let dataForAdvancedFilter = nextProps.dataForAdvancedFilter;

        if (nextState.clearDataForAdvancedFilter) {
            this.dataForAdvancedFilter = []
            dataForAdvancedFilter = []
        }

        this.state.dataForAdvancedFilter = nextProps.dataForAdvancedFilter

        let fields = this.state.fields

        this.state.disabledSearch = nextProps.disabledSearch

        if (!nextProps.disabledSearch) {
            this.state.searchShowResults = true
        }

        this.state.dataForAdvancedFilterOrder = nextProps.dataForAdvancedFilterOrder

        dataForAdvancedFilter.map(itemAdvancedFilter => {
            fields.map(field => {
                let fieldData = {
                    name: field.name,
                    model: field.model,
                    value: field.value,
                    isSelected: field.isSelected,
                    isEmpty: field.isEmpty
                }

                let fieldCompare = {
                    name: itemAdvancedFilter.name,
                    model: itemAdvancedFilter.model,
                    value: itemAdvancedFilter.value,
                    isSelected: itemAdvancedFilter.isSelected,
                    isEmpty: itemAdvancedFilter.isEmpty
                }

                if (
                    field.name === itemAdvancedFilter.name &&
                    field.model === itemAdvancedFilter.model &&
                    JSON.stringify(fieldData) !== JSON.stringify(fieldCompare)
                ) {
                    field.isSelected = itemAdvancedFilter.isSelected
                    field.isEmpty = itemAdvancedFilter.isEmpty
                    field.isEmptyReload = true
                    field.value = itemAdvancedFilter.value

                    this.responseFilters.map(field => {
                        if (itemAdvancedFilter.name === field.name && itemAdvancedFilter.model === field.model) {
                            field.value = itemAdvancedFilter.value
                            field.valueNew = itemAdvancedFilter.value
                        }
                    })
                }
            })
        })

        if (nextProps.sheetType) {
            if (nextProps.sheetType != this.state.sheetType) {
                this.state.sheetType = nextProps.sheetType
            }
        }

        if (nextProps.pageAdvancedFilter) {
            if (nextProps.pageAdvancedFilter != this.state.pageAdvancedFilter) {
                this.state.pageAdvancedFilter = nextProps.pageAdvancedFilter
            }
        }
    }

    render() {
        return (
            <Dropdown
                className={'d-inline mx-2' + (this.state.searchActive ? ' advanced-search-active' : ' ')}
                autoClose={false}
                open={this.state.dropdownIsOpen}>

                <Dropdown.Toggle
                    as={CustomToggleFilter}
                    id={"dropdown-autoclose-false" + this.state.searchActive ? ' advanced-search-active' : ''}>
                </Dropdown.Toggle>

                <Dropdown.Menu className='menu-advanced-filter'>
                    <Dropdown.Header id="item" href="#">
                        <div className='row'>
                            {
                                this.state.favouriteFilterCreate > 0 ?
                                    (
                                        <Alert variant="success">
                                            <p>
                                                The favorite filter was created.
                                            </p>
                                        </Alert>
                                    ) :
                                    (null)
                            }

                            <Tabs
                                activeKey={this.state.tabKey}
                                onSelect={this.handleSelect}
                                defaultActiveKey="filter"
                                id="tab-advanced-filter"
                            >
                                <Tab
                                    eventKey="filter"
                                    title="Advanced Filter"
                                    className='title-tab-advanced-filter'>

                                    <div className='col-12 mt-2 overflow-hidden'>
                                        {
                                            this.state.showFilters && this.state.tabKey === 'filter' ?
                                                this.renderFields()
                                                :
                                                ''
                                        }

                                        <Dropdown.Divider></Dropdown.Divider>

                                        {this.renderAddField()}
                                    </div>
                                </Tab>
                                <Tab
                                    eventKey="personalFilter"
                                    title="Personal filter"
                                    className='title-tab-advanced-filter'>

                                    {
                                        (
                                            this.state.showFavouriteFilterList &&
                                            this.state.tabKey === 'personalFilter'
                                        ) ?
                                            (
                                                <ListFavouriteFilter
                                                    parentApplyFavouriteFilter={this.applyFavouriteFilter}
                                                />
                                            ) :
                                            (null)
                                    }

                                    {
                                        (
                                            this.state.showFavouriteFilter
                                        ) ?
                                            (
                                                <CreateFavouriteFilter
                                                    createFavouriteFilter={this.createFavouriteFilter}
                                                    fields={this.favouriteFilters}
                                                    showModal={this.state.showFavouriteFilter}
                                                    responseCancelCreateFavourite={this.cancelCreateFavourite}
                                                    parentIsLoading={this.parentIsLoading}
                                                    parentUpdateList={this.parentUpdateList}
                                                />
                                            ) :
                                            (null)
                                    }

                                </Tab>
                            </Tabs>

                            <Dropdown.Divider></Dropdown.Divider>

                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <Row>
                                            <Col lg="12">
                                                <Row>
                                                    <Col lg="6">
                                                        <button
                                                            type='button'
                                                            className='btn btn-primary btn-novitadimonds btn-advanced-search m-1'
                                                            onClick={(e) => this.searchAdvancedFilter()}>
                                                            <FontAwesomeIcon icon={faSearch} /> {' '}
                                                            Search
                                                        </button>
                                                    </Col>
                                                    <Col lg="6">
                                                        <button
                                                            type='button'
                                                            className='btn btn-primary btn-novitadimonds btn-advanced-search m-1'
                                                            onClick={(e) => this.clearFilters()}>
                                                            <FontAwesomeIcon icon={faClose} /> {' '}
                                                            Clear filters
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg="12">
                                                <button
                                                    type='button'
                                                    className='btn btn-primary btn-novitadimonds btn-advanced-search m-1'
                                                    onClick={(e) => this.activeFavouriteFilter()}
                                                >
                                                    <FontAwesomeIcon icon={faSquarePlus} /> {' '}
                                                    Create personal filter
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>

                            <Dropdown.Divider></Dropdown.Divider>

                            {
                                this.state.showRemoveFilter === true ?
                                    <ConfirmRemoveFilters
                                        showModal={this.state.showRemoveFilter}
                                        removeMultiple={true}
                                        parentRemoveFilters={this.parentRemoveFilters} />
                                    :
                                    ('')
                            }

                            <div className='col-12'></div>
                        </div>
                    </Dropdown.Header>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    parentRemoveFilters = (value) => {
        if (value === true) {
            window.location = window.location.pathname;
        }

        this.setState({
            showRemoveFilter: false,
        })
    }

    removeField = (response) => {
        let fields = Object.assign([], this.state.fields)

        fields.map(field => {
            if (field.label === response.label) {
                field = this.clearDataFilter(field)
            }

            return field
        })

        this.dataForAdvancedFilter = Object.assign([], this.state.dataForAdvancedFilter)

        this.updateDataFilters(true)

        this.dataForAdvancedFilter.map((field, index) => {
            if (field.name === response.name && field.model === response.model) {
                this.dataForAdvancedFilter.splice(index, 1)
            }
        })

        this.props.parentFiltersAdvancedFilter(this.responseFilters)

        this.searchAdvancedFilter()

        this.responseFieldData({})
    }

    clearDataFilter = (item) => {
        item = Object.assign({}, item)

        item.value = ''
        item.valueNew = ''
        item.isSelected = false
        item.showInAddFilter = true
        item.search = false
        item.isSelectedImportant = false
        item.isEmpty = false

        return item;
    }

    clearFilters = () => {
        this.setState({
            showRemoveFilter: true
        })
    }

    openModal = () => {
        this.setState({
            showModal: true
        })
    }

    renderAddField = () => {
        return (
            <div>
                {
                    <AddFieldSearch
                        showModalAddField={this.state.showModal}
                        fields={this.state.fields}
                        responseField={this.responseFieldData}
                    />
                }
            </div>
        )
    }

    renderFields = () => {
        return (
            <div className='col-12 scroll-auto'>
                {
                    (this.state.fields.length > 0) ?
                        this.state.fields.map(field => {
                            return field.isSelected ?
                                <div key={field.label}>
                                    <RenderFieldSearch
                                        field={field}
                                        parentRemoveField={this.removeField}
                                        parentResponseDataField={this.responseFieldData}
                                        parentResponseDataForAdvancedFilter={this.responsedataForAdvancedFilter}
                                    />
                                    <Dropdown.Divider></Dropdown.Divider>
                                </div>
                                :
                                ''
                        })
                        :
                        ''
                }
            </div>
        )
    }

    responsedataForAdvancedFilter = (response) => {
        let data = this.state.dataForAdvancedFilter

        data.map(item => {
            if (response.name === item.name && response.model === item.model) {
                item.value = response.value
                item.isEmpty = response.isEmpty
            }
        })

        this.setState({
            dataForAdvancedFilter: data
        })
    }

    responseFieldData = (response) => {
        let fields = this.state.fields.map(field => {
            if (field.name === response.name && field.model === response.model) {
                field = response
            }

            return field
        })

        this.responseFilters = Object.assign([], fields)

        this.setState({
            fields: fields
        })

        this.state.fields = fields
    }

    searchAdvancedFilter = (activeToggleDropdown = true, responseDataForAdvancedFilter = true, sheetType = '') => {
        let dataFilter = [
            {
                name: 'sheetType',
                model: 'salesModel',
                value: sheetType.length > 0 ? sheetType : this.state.sheetType,
                isEmpty: false,
                typeField: "autocomplete",
                typeQuery: "iLike",
                fields: []
            }
        ]

        this.state.fields.filter(item => {
            let filter = {}

            if ((item.value + '').length > 0 || item.isEmpty === true) {
                filter.name = item.name
                filter.model = item.model
                filter.value = item.valueNew ?? item.value
                filter.isEmpty = item.isEmpty
                filter.typeQuery = item.typeQuery
                filter.typeField = item.typeField
                filter.fields = item.fields

                dataFilter.push(filter)
            }
        })

        if (activeToggleDropdown) {
            this.setState({
                searchActive: true
            })
        }

        this.searchData(dataFilter, activeToggleDropdown, responseDataForAdvancedFilter)
    }

    searchData = (dataFilter, activeToggleDropdown, responseDataForAdvancedFilter) => {
        this.props.parentIsLoading(true)
        if (cancel !== undefined) {
            cancel();
        }

        const url = sprintf(
            '%s%s',
            process.env.REACT_APP_NOVITAERP_API_DOMAIN,
            globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_ADVANCED_FILTER
        )

        let advancedFilter = {
            page: activeToggleDropdown ? 1 : this.state.pageAdvancedFilter,
            maxResults: 100,
            fields: dataFilter,
            orderBy: this.state.dataForAdvancedFilterOrder.orderBy,
            orderType: this.state.dataForAdvancedFilterOrder.orderType,
            modelOrder: this.state.dataForAdvancedFilterOrder.modelOrder,
        }

        const config = {
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }

        axios.post(
            url,
            advancedFilter,
            config
        ).then(response => {
            this.toggleDropdown(activeToggleDropdown)

            let data = {
                response: response
            }

            this.updateDataFilters()

            if (responseDataForAdvancedFilter) {
                this.props.parentFiltersAdvancedFilter(this.responseFilters)
                this.props.parentDataForAdvancedFilter(this.dataForAdvancedFilter)

                if (this.responseFiltersFavourite.length > 0) {
                    let responseFiltersFavourite = this.responseFiltersFavourite;
                    this.props.parentFiltersAdvancedFilter(responseFiltersFavourite)
                    this.responseFiltersFavourite = []
                }
            }

            this.props.parentFiltersExportAdvancedFilter(dataFilter)
            this.props.parentResultsAdvancedFilter(data)
            this.props.parentIsLoading(false)

            this.setState({
                searchShowResults: true,
                isLoading: false
            })
        }).catch((error) => {
            if (axios.isCancel(error)) {
                return;
            }

            this.props.parentIsLoading(false)
        })
    }

    updateDataFilters = (clear) => {
        try {
            if (!this.responseFilters) {
                this.responseFilters = []
            }

            if (this.dataForAdvancedFilter.length <= 0) {
                this.responseFilters.map(item => {
                    if (item.value.length > 0 || item.isEmpty === true) {
                        this.dataForAdvancedFilter.push({
                            name: item.name,
                            model: item.model,
                            value: item.valueNew ?? item.value,
                            isSelected: clear ? false : item.isSelected,
                            isEmpty: clear ? false : item.isEmpty
                        })
                    }
                })
            }

            this.dataForAdvancedFilter.map(itemDataForAdvancedFilter => {
                this.responseFilters.map(item => {
                    if (itemDataForAdvancedFilter.name === item.name && itemDataForAdvancedFilter.model === item.model) {
                        itemDataForAdvancedFilter.name = item.name
                        itemDataForAdvancedFilter.model = item.model
                        itemDataForAdvancedFilter.value = item.valueNew ?? item.value
                        itemDataForAdvancedFilter.isSelected = clear ? false : item.isSelected
                        itemDataForAdvancedFilter.isEmpty = clear ? false : item.isEmpty
                    }
                })
            })
        } catch (error) { }
    }

    toggleDropdown = (activeToggleDropdown = true) => {
        if (activeToggleDropdown) {
            let buttonTogge = document.getElementsByClassName('btn-active-filter')

            buttonTogge[0].click();
        }
    }

    activeFavouriteFilter = () => {
        let favouriteFilters = []

        this.state.fields.map(field => {
            if (
                field.isSelected
            ) {
                this.createFavouriteFilter = true

                favouriteFilters.push(field)
            }
        })

        this.favouriteFilters = favouriteFilters

        this.setState({
            showFavouriteFilter: true
        })
    }

    cancelCreateFavourite = () => {
        this.createFavouriteFilter = false

        this.setState({
            showFavouriteFilter: false,
        })
    }

    parentIsLoading = (value) => {
        this.props.parentIsLoading(value)
    }

    parentUpdateList = () => {
        this.setState({
            showFavouriteFilterList: false
        })

        setTimeout(() => {
            this.setState({
                showFavouriteFilterList: true,
                showFavouriteFilter: false
            })
        }, 2)

        this.showMessageAlert(true)

        setTimeout(() => {
            this.showMessageAlert(false)
        }, 5000);
    }

    showMessageAlert = (value) => {
        this.setState({
            favouriteFilterCreate: value
        })
    }

    applyFavouriteFilter = (filters) => {
        this.setState({
            clearDataForAdvancedFilter: true,
            dataForAdvancedFilter: []
        })

        this.dataForAdvancedFilter = Object.assign([], [])
        this.responseFiltersFavourite = Object.assign([], [])
        let dataFilters = Object.assign([], filters)

        let fields = Object.assign([], this.state.fieldsFavourite)

        fields = fields.map(item => {
            let data = Object.assign([], item)
            return this.clearDataFilter(data)
        })

        dataFilters.map(favouriteFilter => {
            fields = fields.map(filter => {
                if (
                    favouriteFilter.name === filter.name &&
                    favouriteFilter.model === filter.model
                ) {
                    return favouriteFilter
                }

                return filter
            })
        })

        this.responseFiltersFavourite = Object.assign([], fields)

        this.setState({
            fields: fields
        })

        setTimeout(() => {
            this.updateDataFilters()

            this.setState({
                tabKey: 'filter'
            })
        }, 50)
    }

    handleSelect = (key) => {
        this.setState({
            tabKey: key
        })
    }
}

export default AdvancedFilter;