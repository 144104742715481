import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const FormInputCheckbox = (props) => {
  const {
    checked,
    label,
    nameInput,
    onResponse,
    disabled,
    required,
    type,
    typeView,
  } = props;
  const [checkedNew, setCheckedNew] = useState(checked);

  if (checked !== checkedNew) {
    setCheckedNew(checked);
  }

  const changeInput = (event) => {
    let { checked } = event.target;

    setCheckedNew(checked);

    onResponse(checked, nameInput);
  };

  return (
    <Form.Group>
      <Form.Check type={typeView}>
        <Form.Check.Input
          className="check-all-link"
          name={nameInput}
          type={type}
          id={nameInput}
          checked={checkedNew ?? false}
          disabled={disabled}
          onChange={(e) => changeInput(e)}
          required={required}
        />
        <Form.Check.Label className="check-all-link" htmlFor={nameInput}>
          {label}
        </Form.Check.Label>
        <Form.Control.Feedback type="invalid">
          {label.replace("*", "")} is required
        </Form.Control.Feedback>
      </Form.Check>
    </Form.Group>
  );
};

FormInputCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  nameInput: PropTypes.string.isRequired,
  checked: PropTypes.any.isRequired,
  onResponse: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  typeView: PropTypes.string,
};

FormInputCheckbox.defaultProps = {
  disabled: false,
  required: false,
  type: "checkbox",
  typeView: "checkbox",
};

export default FormInputCheckbox;
