import {
  API_STATUS_PENDING,
} from "../../../../store/constants";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import LoadingLayer from "../../../LoadingLayer";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import RowSaleExchange from "./RowSaleExchange";
import {
  clearSalesExchanges,
  fetchSaleExcahnges,
} from "../../../../store/slices/workflowReturnSlice";
import { debounce } from "lodash";
import AlertMessage from "../../../Utils/AlertMessage";

const FindSaleExchange = ({ closeComponent, setSelectedSale }) => {
  const dispatch = useDispatch();
  const { status, salesExchanges = [] } = useSelector(
    (state) => state.workflowReturn
  );

  const [sale, setSale] = useState(null);
  const [isFind, setIsFind] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterChange = useCallback(
    debounce(async (event) => {
      const { value } = event.target;

      setIsFind(false);

      if (!value) {
        dispatch(clearSalesExchanges());

        return;
      }

      await dispatch(fetchSaleExcahnges(value));

      setIsFind(true);
    }, 300),
    [dispatch]
  );

  const closeModal = () => {
    closeComponent(true);
  };

  const activeSelect = (sale) => {
    setSale(sale);
  };

  const handleSelectSale = (sale) => {
    closeComponent(true);
    setSelectedSale(sale);
  };

  useEffect(() => {
    return () => {
      dispatch(clearSalesExchanges());
    };
  }, [dispatch]);

  return (
    <Modal show={true} onHide={closeModal} size="lg">
      <Modal.Header closeButton>SELECT SALE</Modal.Header>
      <Modal.Body>
        {status === API_STATUS_PENDING && <LoadingLayer />}

        <Row>
          <Col xs={12} lg={12}>
            <Form.Group>
              <Form.Control
                placeholder="Search sale Order Number..."
                onChange={(event) => {
                  handleFilterChange(event);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        {salesExchanges.length > 0 && status !== API_STATUS_PENDING && (
          <>
            <Table striped bordered hover className="mt-1">
              <thead className="thead-dark">
                <tr className="titles">
                  <th className="onlineOrderNumber">Order Number</th>
                  <th className="name">Name</th>
                  <th className="orderStatus">Order Status</th>
                  <th className="saleType">Type of Product</th>
                  <th className="ringPreference">Product's Ref.</th>
                </tr>
              </thead>

              <tbody>
                {salesExchanges.map((sale) => {
                  return (
                    <RowSaleExchange
                      key={sale.id}
                      sale={sale}
                      activeSelect={activeSelect}
                    />
                  );
                })}
              </tbody>
            </Table>
            <Button
              disabled={!sale ? true : false}
              onClick={() => handleSelectSale(sale)}
            >
              Select
            </Button>
          </>
        )}

        {salesExchanges.length <= 0 && isFind && (
          <Col className="mt-1">
            <AlertMessage
              type="warning"
              message={"Sale not found"}
              infoHeading=""
              autoClose={false}
            />
          </Col>
        )}
      </Modal.Body>
    </Modal>
  );
};

FindSaleExchange.propTypes = {
  closeComponent: PropTypes.func.isRequired,
  setSelectedSale: PropTypes.func.isRequired,
};

export default FindSaleExchange;
