import React, {Component} from 'react';

class LoadingLayer extends Component {
    render(){
        return(
            <div id="LoadingLayer_component">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}

export default LoadingLayer;