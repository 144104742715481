import React, { Component } from "react";
import { Form, FormLabel, Card } from "react-bootstrap";
import DateField from "../../formComponents/DateField";

// SCSS
import "../../../../css/sales/sale_edit.scss";

class SaleWorkflowEditDeliveringToCustomer extends Component {
  constructor(props) {
    super();

    this.state = {
      activity: props.activity,
      readyToPayToProvider: props.activity.readyToPayToProvider,
      inTransitToShowroom:
        props.activity.workflowDeliveringToCustomerModel !== null
          ? props.activity.workflowDeliveringToCustomerModel.inTransitToShowroom
          : null,
      customerWasUpdated:
        props.activity.workflowDeliveringToCustomerModel !== null
          ? props.activity.workflowDeliveringToCustomerModel
              .inTransitCustomerUpdated
          : null,
      customerContactedToOrganiseDelivery:
        props.activity.workflowDeliveringToCustomerModel !== null
          ? props.activity.workflowDeliveringToCustomerModel
              .deliveringToCustomer
          : null,
      deliveringCommentsAboutCall:
        props.activity.workflowDeliveringToCustomerModel !== null
          ? props.activity.workflowDeliveringToCustomerModel
              .deliveringCommentsAboutCall
          : null,
      pickedUpInShowroom:
        props.activity.workflowDeliveringToCustomerModel !== null
          ? props.activity.workflowDeliveringToCustomerModel
              .deliveringPickedUpInShowroom
          : null,
      deliveredToCustomer:
        props.activity.workflowDeliveringToCustomerModel !== null
          ? props.activity.workflowDeliveringToCustomerModel.deliveredToCustomer
          : null,
      deliveredToCustomerTrackingNumber: "",
      inTransitDestinationShowroom: "",
    };

    if (props.activity.workflowDeliveringToCustomerModel !== null) {
      this.state.inTransitDestinationShowroom =
        props.activity.workflowDeliveringToCustomerModel
          .inTransitDestinationShowroom ?? "";
      this.state.deliveredToCustomerTrackingNumber =
        props.activity.workflowDeliveringToCustomerModel
          .deliveredToCustomerTrackingNumber ?? "";

      this.state.inTransitDateSent = props.activity
        .workflowDeliveringToCustomerModel.inTransitDateSent
        ? new Date(
            props.activity.workflowDeliveringToCustomerModel.inTransitDateSent
          )
        : null;

      this.state.inTransitDateReceived = props.activity
        .workflowDeliveringToCustomerModel.inTransitDateReceived
        ? new Date(
            props.activity.workflowDeliveringToCustomerModel.inTransitDateReceived
          )
        : null;

      this.state.deliveringDateOfContact = props.activity
        .workflowDeliveringToCustomerModel.deliveringDateOfContact
        ? new Date(
            props.activity.workflowDeliveringToCustomerModel.deliveringDateOfContact
          )
        : null;

      this.state.deliveringPickedUpInShowroomDate = props.activity
        .workflowDeliveringToCustomerModel.deliveringPickedUpInShowroomDate
        ? new Date(
            props.activity.workflowDeliveringToCustomerModel.deliveringPickedUpInShowroomDate
          )
        : null;

      this.state.deliveredToCustomerDate = props.activity
        .workflowDeliveringToCustomerModel.deliveredToCustomerDate
        ? new Date(
            props.activity.workflowDeliveringToCustomerModel.deliveredToCustomerDate
          )
        : null;
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeDateField = this.handleChangeDateField.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);

    this.setListShowroomCity();
  }

  setListShowroomCity = () => {
    this.listOrderStatus = [];

    if (process.env.REACT_APP_SHOWROOM_CITY_OPTIONS) {
      this.listShowroomCity =
        process.env.REACT_APP_SHOWROOM_CITY_OPTIONS.split(",");
    }

    this.listShowroomCity = this.listShowroomCity.sort();
  };

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    this.setState(state);
  }

  handleChangeCheckbox(event) {
    const field_name = event.target.name;
    let state = {};
    if (field_name === "pickedUpInShowroom" && event.target.checked) {
      state["deliveredToCustomer"] = false;
    }
    if (field_name === "deliveredToCustomer" && event.target.checked) {
      state["pickedUpInShowroom"] = false;
    }
    state[field_name] = event.target.checked;
    this.setState(state);
  }

  handleChangeDateField = (date, field_name) => {
    const state = {};

    if (isNaN(Date.parse(date))) {
      state[field_name] = null;
    } else {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      date = new Date(year, month - 1, day, hours, minutes);
      if (isNaN(Date.parse(date))) {
        state[field_name] = null;
      } else {
        state[field_name] =
          year + "-" + month + "-" + day + " " + hours + ":" + minutes;
      }
    }
    this.setState(state);
  };

  handleChangeSelect(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    this.setState(state);
  }

  render() {
    return (
      <div className="sale_information_subcontainer">
        <div className="container">
          <Card>
            <Card.Body>
              <Card.Title>
                <Form.Check
                  type="checkbox"
                  name="inTransitToShowroom"
                  label="In transit to showroom"
                  checked={
                    this.state.inTransitToShowroom === true ? true : false
                  }
                  onChange={this.handleChangeCheckbox}
                />
              </Card.Title>
              {this.state.inTransitToShowroom ? (
                <div className="workflow-activity-subelement">
                  <FormLabel className="d-block">
                    Destination showroom
                  </FormLabel>
                  <Form.Select
                    onChange={(e) => this.handleChangeSelect(e)}
                    value={this.state.inTransitDestinationShowroom ?? ""}
                    name="inTransitDestinationShowroom"
                  >
                    <option value=""></option>
                    {this.listShowroomCity.map((orderStatus) => {
                      return (
                        <option key={orderStatus} value={orderStatus}>
                          {orderStatus}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Group className="form-group">
                    <FormLabel>Date sent</FormLabel>
                    <DateField
                      handleChangeDateField={this.handleChangeDateField}
                      field_name="inTransitDateSent"
                      currentDate={this.state.inTransitDateSent}
                    />
                  </Form.Group>
                  <Form.Check
                    type="checkbox"
                    name="customerWasUpdated"
                    label="Customer was updated"
                    checked={
                      this.state.customerWasUpdated === true ? true : false
                    }
                    onChange={this.handleChangeCheckbox}
                  />
                  <Form.Group className="form-group">
                    <FormLabel>Date received</FormLabel>
                    <DateField
                      handleChangeDateField={this.handleChangeDateField}
                      field_name="inTransitDateReceived"
                      currentDate={this.state.inTransitDateReceived}
                    />
                  </Form.Group>
                </div>
              ) : null}
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <Form.Check
                  type="checkbox"
                  name="customerContactedToOrganiseDelivery"
                  label="Customer contacted to organise delivery or pick up"
                  checked={
                    this.state.customerContactedToOrganiseDelivery === true
                      ? true
                      : false
                  }
                  onChange={this.handleChangeCheckbox}
                />
              </Card.Title>
              {this.state.customerContactedToOrganiseDelivery ? (
                <div className="workflow-activity-subelement">
                  <Form.Group className="form-group">
                    <FormLabel>Date of contact</FormLabel>
                    <DateField
                      handleChangeDateField={this.handleChangeDateField}
                      field_name="deliveringDateOfContact"
                      currentDate={this.state.deliveringDateOfContact}
                    />
                  </Form.Group>
                  <Form.Group>
                    <FormLabel>Comments about call with customer</FormLabel>
                    <Form.Control
                      type="text"
                      name="deliveringCommentsAboutCall"
                      value={this.state.deliveringCommentsAboutCall ?? ""}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </div>
              ) : null}
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <Form.Check
                  type="checkbox"
                  name="pickedUpInShowroom"
                  label="Picked up in a showroom"
                  checked={
                    this.state.pickedUpInShowroom === true ? true : false
                  }
                  onChange={this.handleChangeCheckbox}
                />
              </Card.Title>
              {this.state.pickedUpInShowroom ? (
                <Form.Group className="form-group">
                  <FormLabel>Date picked up</FormLabel>
                  <DateField
                    handleChangeDateField={this.handleChangeDateField}
                    field_name="deliveringPickedUpInShowroomDate"
                    currentDate={this.state.deliveringPickedUpInShowroomDate}
                    showTimeSelect={false}
                    dateFormat="dd/MM/yyyy"
                    isClearable={false}
                  />
                </Form.Group>
              ) : null}
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Body>
              <Card.Title>
                <Form.Check
                  type="checkbox"
                  name="deliveredToCustomer"
                  label="Delivered to customer"
                  checked={
                    this.state.deliveredToCustomer === true ? true : false
                  }
                  onChange={this.handleChangeCheckbox}
                />
              </Card.Title>
              {this.state.deliveredToCustomer ? (
                <div>
                  <Form.Group className="form-group">
                    <FormLabel>Date sent</FormLabel>
                    <DateField
                      handleChangeDateField={this.handleChangeDateField}
                      field_name="deliveredToCustomerDate"
                      currentDate={this.state.deliveredToCustomerDate}
                      showTimeSelect={false}
                      dateFormat="dd/MM/yyyy"
                      isClearable={false}
                    />
                  </Form.Group>
                  <Form.Group>
                    <FormLabel>Tracking number</FormLabel>
                    <Form.Control
                      type="text"
                      name="deliveredToCustomerTrackingNumber"
                      value={this.state.deliveredToCustomerTrackingNumber ?? ""}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

export default SaleWorkflowEditDeliveringToCustomer;
