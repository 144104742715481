import React, { Component } from 'react';
import Auth from '../../../Auth';
import FlashMessages from '../../FlashMessages';
import axios from 'axios';
import * as globalConst from '../../../global/const';
import { Col, Form, Row } from 'react-bootstrap';

class PostSaleCommentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value_topic: 'select',
            value_text: '',
            value_sale_id: props.sale.id,
            error_messages: [],
            warning_messages: [],
            success_messages: [],
            creating_comment: false,
            isAccounting: (props.isAccounting ? true : false),
            isCosts: (props.isCosts ? true : false),
        };
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.loadListInformation()
    }

    loadListInformation = () => {
        this.listShowTopic = process.env.REACT_APP_POST_SALE_SHOW_TOPIC.split(',')
    }

    handleSelectChange(event) {
        event.preventDefault();
        this.setState(
            {
                value_topic: event.target.value
            }
        )
    }
    handleTextChange(event) {
        event.preventDefault();
        this.setState(
            {
                value_text: event.target.value
            }
        )
    }
    handleSubmit(event) {
        event.preventDefault();
        const error_messages = []
        if (!this.state.isAccounting && !this.state.isCosts) {
            if (this.state.value_topic === 'select') {
                error_messages.push("Choose a topic")
            }
        }
        if (this.state.value_text === '') {
            error_messages.push('Add a message')
        }
        if (error_messages.length > 0) {
            this.setState(
                {
                    error_messages: error_messages,
                    success_messages: []
                }
            )
        }
        else {
            this.createNewComment()
        }
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        return (
            <div id="SaleCommentForm_component">
                {
                    <FlashMessages
                        error_messages={this.state.error_messages}
                        warning_messages={this.state.warning_messages}
                        success_messages={this.state.success_messages}
                    />
                }
                <form onSubmit={this.handleSubmit}>
                    <div className='col-12  mb-3 mt-3 pink-border-top'>
                        <div className='topic_field'>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <label className='form-label  comment-label'>ADD A COMMENT:</label>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Row>
                                        <Col xs={12} lg={6}></Col>
                                        <Col xs={12} lg={6}>
                                            {(!this.state.isAccounting && !this.state.isCosts) ? (
                                                <Form.Select
                                                    onChange={(e) => this.handleSelectChange(e)}
                                                    value={this.state.value_topic ?? ''}
                                                >
                                                    <option value=""></option>
                                                    {
                                                        this.listShowTopic.map(listShowTopic => {
                                                            return (
                                                                <option
                                                                    key={listShowTopic}
                                                                    value={listShowTopic}>
                                                                    {listShowTopic}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                            ) : (null)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='col-12  mb-2'>
                        <div className='text_field'>
                            <textarea className="form-control w-100" value={this.state.value_text} onChange={this.handleTextChange} />
                        </div>
                    </div>
                    <div className='col-12'>
                        {
                            this.state.creating_comment === true ?
                                ('Sending...') :
                                (
                                    <div className='submit_field'>
                                        <input className='submit-btn btn btn-default' type="submit" value="Submit" />
                                    </div>
                                )
                        }
                    </div>
                </form>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    shouldComponentUpdate(nextProps, nextState) {
        return true
    }

    static getDerivedStateFromProps(props, state) {
        //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
        //return values for the state
        if (props.sale.id !== state.value_sale_id) {
            return {
                value_topic: 'select',
                value_text: '',
                value_sale_id: props.sale.id,
                error_messages: [],
                warning_messages: [],
                success_messages: [],
                creating_comment: false
            }
        }
        else {
            return state;
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

    axioRequest = null;
    createNewComment() {
        this.setState({
            creating_comment: true
        })
        const user = Auth.getAuthenticatedUser()
        const sale = this.props.sale
        if (user === null || user === undefined || user.employeeModel === null || user.employeeModel === undefined) {
            this.setState(
                {
                    error_messages: ['Authentication error. Reload the page and try again.']
                }
            )
        }
        else if (sale === null || sale === undefined) {
            this.setState(
                {
                    error_messages: ['Sale identification error. Reload the page and try again.']
                }
            )
        }
        else {
            const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_POST_SALE_COMMENT_NEW
            const params = new URLSearchParams()
            if (this.state.isAccounting) {
                params.append('topic', 'Accounting')
            }
            else if (this.state.isCosts) {
                params.append('topic', 'Costs')
            }
            else {
                params.append('topic', this.state.value_topic)
            }
            params.append('text', this.state.value_text)
            params.append('postSaleServiceId', sale.id)
            params.append('employeeId', user.employeeModel.id)
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }

            axios.post(
                url,
                params,
                config
            )
                .then(
                    (response) => {
                        const data = response.data
                        if (data !== undefined && data.code === 200) {
                            this.setState(
                                {
                                    value_topic: 'select',
                                    value_text: '',
                                    creating_comment: false,
                                    error_messages: [],
                                    success_messages: ['Message sent']
                                }
                            )
                            this.props.reloadSaleComments()
                        }
                        else {
                            this.setState(
                                {
                                    creating_comment: false,
                                    error_messages: ['There was a problem processing the information. Please reload this page and try again.']
                                }
                            )
                        }
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            let error_messages = []
                            if ('status' in err.response && err.response.status === 401) {
                                error_messages = ['Not authorised to add comments.']
                            }
                            else {
                                error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                            }
                            this.setState(
                                {
                                    creating_comment: false,
                                    error_messages: error_messages
                                }
                            )
                        } else if (err.request) {
                            const error_messages = ['There was a problem processing the information. Please reload this page and try again.']
                            this.setState(
                                {
                                    creating_comment: false,
                                    error_messages: error_messages
                                }
                            )
                        } else {
                            //error not detected. Request could have been cancelled and it's waiting for second
                            //request to finish
                            this.setState(
                                {
                                    creating_comment: true
                                }
                            )
                        }
                        //console.log((err);
                        return null;
                    }
                )
        }
    }
}

export default PostSaleCommentForm;