import React, {Component} from 'react';
import LeftbarDashboard from'./leftbarDashboard';
import Centerdashboard from'./centerDashboard';
import Rightbardashboard from'./rightbarDashboard';
import SaleEditionRequirementModal from'./SaleEditionRequirementModal';
import SaleEditionAssignTaskModal from'./SaleEditionAssignTaskModal';
import SaleEditionUnarchiveCompletedModal from'./SaleEditionUnarchiveCompletedModal';
import '../../css/dashboard.scss';

// import { Switch, Route } from 'react-router-dom';

class Dashboard extends Component {
    constructor(props){
        super(props)
        this.state = {
            show_sale_edition_requirement_modal: false,
            show_sale_edition_assign_task_modal: false,
            show_sale_edition_unarchive_completed_modal: false,
            sale_edition_requirement_selected_id: null,
            sale_edition_unarchive_completed_selected_id: null,
            sale_edition_unarchive_completed_selected_item_tasks: []
        }
        this.closeSaleEditionRequirementModal = this.closeSaleEditionRequirementModal.bind(this)
    }

    render(){

        return(
            <div className="dashboard-view">
                {
                    this.state.show_sale_edition_requirement_modal === true ?
                        <SaleEditionRequirementModal
                            closeSaleEditionRequirementModal = {this.closeSaleEditionRequirementModal}
                            updateSaleEditRequirementsList = {this.updateSaleEditRequirementsList}
                            sale_edition_requirement_selected_id = {this.state.sale_edition_requirement_selected_id}
                        />
                    :(null)
                }
                {
                    this.state.show_sale_edition_assign_task_modal === true ?
                        <SaleEditionAssignTaskModal
                            closeSaleEditionAssignTaskModal = {this.closeSaleEditionAssignTaskModal}
                            updateSaleEditRequirementsList = {this.updateSaleEditRequirementsList}
                            updateEmployeeTaskList = {this.updateEmployeeTaskList}
                            sale_edition_assign_task_selected_id = {this.state.sale_edition_assign_task_selected_id}
                            resetFiltersMyTasks = {this.resetFiltersMyTasks}
                        />
                    :(null)
                }
                {
                    this.state.show_sale_edition_unarchive_completed_modal === true ?
                        <SaleEditionUnarchiveCompletedModal
                            closeSaleEditionUnarchiveCompletedModal = {this.closeSaleEditionUnarchiveCompletedModal}
                            updateSaleEditRequirementsList = {this.updateSaleEditRequirementsList}
                            sale_edition_unarchive_completed_selected_id = {this.state.sale_edition_unarchive_completed_selected_id}
                            sale_edition_unarchive_completed_selected_item_tasks = {this.state.sale_edition_unarchive_completed_selected_item_tasks}
                        />
                    :(null)
                }
                <LeftbarDashboard
                    pushHistory={ this.pushHistory }
                />
                <Centerdashboard/>
                <Rightbardashboard
                    removeFromList= { this.removeFromList }
                    assignTask= { this.assignTask }
                    unarchiveCompletedTask= { this.unarchiveCompletedTask }
                    getSaleEditionRequirementListUnassigned= {click => this.getSaleEditionRequirementListUnassigned = click}
                    getSaleEditionRequirementListAssigned= {click => this.getSaleEditionRequirementListAssigned = click}
                    getSaleEditionRequirementListHistory= {click => this.getSaleEditionRequirementListHistory = click}
                    getEmployeeTaskList= {click => this.getEmployeeTaskList = click}
                    resetFiltersMyTasks= {click => this.resetFiltersMyTasks = click}
                    resetFiltersActivityUnassigned= {click => this.resetFiltersActivityUnassigned = click}
                    resetFiltersActivityAssigned= {click => this.resetFiltersActivityAssigned = click}
                    resetFiltersActivityHistory= {click => this.resetFiltersActivityHistory = click}
                />
            </div>
        )
    }

    removeFromList = (id) => {
        this.setState(
            {
                show_sale_edition_requirement_modal: true,
                sale_edition_requirement_selected_id: id
            }
        )
    }

    assignTask = () => {
        this.setState(
            {
                show_sale_edition_assign_task_modal: true,
                //sale_edition_assign_task_selected_id: id
            }
        )
    }

    unarchiveCompletedTask = (id, saleTasks) => {
        this.setState(
            {
                show_sale_edition_unarchive_completed_modal: true,
                sale_edition_unarchive_completed_selected_id: id,
                sale_edition_unarchive_completed_selected_item_tasks: saleTasks
            }
        )
    }

    pushHistory = (uri_to_push) => {
        this.props.history.push(uri_to_push)
    }

    closeSaleEditionRequirementModal = () => {
        this.setState(
            {
                show_sale_edition_requirement_modal: false
            }
        )
    }

    openSaleEditionRequirementModal = () => {
        this.setState(
            {
                show_sale_edition_requirement_modal: true
            }
        )
    }

    closeSaleEditionAssignTaskModal = () => {
        this.setState(
            {
                show_sale_edition_assign_task_modal: false
            }
        )
    }

    openSaleEditionAssignTaskModal = () => {
        this.setState(
            {
                show_sale_edition_assign_task_modal: true
            }
        )
    }

    closeSaleEditionUnarchiveCompletedModal = () => {
        this.setState(
            {
                show_sale_edition_unarchive_completed_modal: false
            }
        )
    }

    openSaleEditionUnarchiveCompletedModal = () => {
        this.setState(
            {
                show_sale_edition_unarchive_completed_modal: true
            }
        )
    }

    updateSaleEditRequirementsList = () => {
        this.resetFiltersActivityUnassigned();
        this.resetFiltersActivityAssigned();
        this.resetFiltersActivityHistory();
        this.getSaleEditionRequirementListUnassigned();
        this.getSaleEditionRequirementListAssigned();
        this.getSaleEditionRequirementListHistory();
    }

    updateEmployeeTaskList = () => {
        this.resetFiltersMyTasks();
        this.getEmployeeTaskList();
    }
}

export default Dashboard;