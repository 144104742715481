import React, { Component } from "react";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointLeft, faCopy } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import SaleComments from "../saleComment/SaleComments";
import SaleInformationAccounts from "./SaleInformationAccounts";
import SaleInformationGeneral from "./SaleInformationGeneral";
import SaleInformationProduct from "./SaleInformationProduct";
import SaleInformationProduction from "./SaleInformationProduction";
import SaleInformationSalesMarketing from "./SaleInformationSalesMarketing";
import SaleWorkflowActivities from "../saleWorkflow/SaleWorkflowActivities";
import Auth from "../../../Auth";
import axios from "axios";
import * as globalConst from "../../../global/const";
import EditIcon from "../../../image/Edit.png";
import detailsIcon from "../../../image/see_details.png";

// SCSS
import "../../../css/sales/sale_information.scss";
import {
  getOnlineOrderNumberparent,
  saleIsReturn,
} from "../edit/Acctions/SaleRetutn";

class SaleInformation extends Component {
  last_rendered_sale = null;

  constructor(props) {
    super();
    this.state = {
      sale: props.sale,
      sale_comments: [],
      loading_comments: false,
      openEditionComponent: props.openEditionComponent,
      openWorkflowModal: props.openWorkflowModal,
      openWorkflowPanel: props.openWorkflowPanel,
      openQualityCheckPanel: props.openQualityCheckPanel,
      openWorkflowEditionComponent: props.openWorkflowEditionComponent,
      currentTab: "information",
      workflowSales: [],
      hasWorkflowSales: false,
    };
    this.saleWorkflowActivities = React.createRef();

    this.editSale = this.editSale.bind(this);
    this.getWorkflowSales = this.getWorkflowSales.bind(this);
    this.openWorkflow = this.openWorkflow.bind(this);
    this.openWorkflowPanel = this.openWorkflowPanel.bind(this);
    this.openQualityCheckPanel = this.openQualityCheckPanel.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.changeToWorkflowTab = this.changeToWorkflowTab.bind(this);
    this.reset = this.reset.bind(this);
  }

  handleSelect(key) {
    if (key === "workflow") {
      this.saleWorkflowActivities.current.resetWorkflowList();
    }

    this.setState({
      currentTab: key,
    });
  }

  changeToWorkflowTab() {
    this.saleWorkflowActivities.current.resetWorkflowList();
    this.setState({
      currentTab: "workflow",
    });
  }

  reset() {
    this.setState({
      currentTab: "information",
    });
  }

  ajaxRequest = null;
  getWorkflowSales() {
    if (!Auth.canEditField("rolesInteractiveWorkflow")) {
      return;
    }

    const user = Auth.getAuthenticatedUser();

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else {
      //AJAX CONTROLS
      //cancel previous ajax request if exists
      if (this.ajaxRequest !== null) {
        this.ajaxRequest.cancel();
      }
      // creates a new token for upcomming ajax (overwrite the previous one)
      this.ajaxRequest = axios.CancelToken.source();
      //FINISH AJAX CONTROLS

      let url =
        process.env.REACT_APP_NOVITAERP_API_DOMAIN +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_SALE_LIST;
      const params = new URLSearchParams();
      params.append("saleId", this.state.sale.id);

      axios
        .get(
          url,
          {
            params: params,
            headers: { Authorization: `Bearer ${Auth.getToken()}` },
          },
          {
            cancelToken: this.ajaxRequest.token,
          }
        )
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            var workflowSales = data.result.rows;
            this.setState({
              workflowSales: workflowSales,
            });
          } else {
            alert(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            alert(JSON.stringify(error_messages));
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            alert(JSON.stringify(error_messages));
          } else {
            //error not detected. Request could have been cancelled and it's waiting for second
            //request to finish
          }
          //console.log((err);
          return null;
        });
    }
  }

  openWorkflow() {
    this.state.openWorkflowModal();
  }

  openWorkflowPanel() {
    this.state.openWorkflowPanel(this.state.sale);
  }

  openQualityCheckPanel() {
    this.state.openQualityCheckPanel(this.state.sale);
  }

  editSale() {
    this.state.openEditionComponent(this.state.sale);
  }

  createDuplicate = () => {
    this.props.openCreateSaleClone(this.state.sale);
  };

  render() {
    const sale = this.state.sale;

    var access_to_edit_general = Auth.isAuthorised("sales_edit_general");
    var access_to_edit_product = Auth.isAuthorised("sales_edit_product");
    var access_to_edit_production = Auth.isAuthorised("sales_edit_production");
    var access_to_edit_accounts = Auth.isAuthorised("sales_edit_accounts");
    var access_to_edit_sales_and_marketing = Auth.isAuthorised(
      "sales_edit_sales_and_marketing"
    );
    var access_to_edit = true;
    if (
      !access_to_edit_general &&
      !access_to_edit_product &&
      !access_to_edit_production &&
      !access_to_edit_accounts &&
      !access_to_edit_sales_and_marketing
    ) {
      access_to_edit = false;
    }

    if (sale === null) {
      return (
        <div id="SaleInformation_component">
          <div className="choose_message">
            Choose an item from the list to find more
            <br />
            <span>
              <FontAwesomeIcon icon={faHandPointLeft} />
            </span>
          </div>
        </div>
      );
    }

    return (
      <div id="SaleInformation_component">
        <div className="information">
          <div className="details-top-heading">
            <div className="order-no-section">
              <h1>
                ORDER #{sale.onlineOrderNumber}
                {saleIsReturn(this.state.sale) && (
                  <>
                    - This order was created from a return{" "}
                    {getOnlineOrderNumberparent(this.state.sale)}
                  </>
                )}
              </h1>
            </div>
            <Row className="row order-action-section">
              {access_to_edit === true ? (
                <Col lg={4} className="link_to_edit">
                  <button className="btn" onClick={this.editSale}>
                    Edit Sales <img src={EditIcon} alt="" />
                  </button>
                </Col>
              ) : null}
              {access_to_edit === true ? (
                <Col lg={4} className="SalesList_component link_to_edit">
                  <button
                    className="btn btn-default"
                    onClick={this.createDuplicate}
                  >
                    <Row>
                      <Col
                        lg="8"
                        className="center-element line-height-initial"
                      >
                        Create new sale from customer
                      </Col>
                      <Col lg="4" className="grid-padding-0">
                        <FontAwesomeIcon
                          className="icon-btn-novitadiamond"
                          icon={faCopy}
                        />
                      </Col>
                    </Row>
                  </button>
                </Col>
              ) : null}
              <Col lg={4} className="link_to_detail">
                <Link to={`/sale/${sale.id}`} target="_blank">
                  See All Details <img src={detailsIcon} alt="" />
                </Link>
              </Col>
            </Row>
          </div>
          <SaleComments sale={this.state.sale} />
          <Tabs
            defaultActiveKey={this.state.currentTab}
            activeKey={this.state.currentTab}
            id="SalesInformationTabs"
            className="border-none"
            onSelect={this.handleSelect}
          >
            <Tab eventKey="information" title={"Information"}>
              <div className="general_information sales-details-card">
                <h2>General Information</h2>
                <SaleInformationGeneral sale={this.state.sale} />
              </div>
              <div className="product_information sales-details-card">
                <h2>Product</h2>
                <SaleInformationProduct sale={this.state.sale} />
              </div>

              <div className="production_information sales-details-card">
                <h2>Production</h2>
                <SaleInformationProduction
                  sale={this.state.sale}
                  workflowSales={this.state.workflowSales}
                />
              </div>
              <div className="accounts_information sales-details-card">
                <h2>Accounts</h2>
                <SaleInformationAccounts sale={this.state.sale} />
              </div>
              <div className="sales_and_marketing_information sales-details-card">
                <h2>Sales and Marketing</h2>
                <SaleInformationSalesMarketing sale={this.state.sale} />
              </div>
            </Tab>

            {Auth.canEditField("rolesInteractiveWorkflow") ? (
              <Tab eventKey="workflow" title={"Workflow"}>
                <SaleWorkflowActivities
                  sale={this.state.sale}
                  workflowSales={this.state.workflowSales}
                  openWorkflowEditionComponent={
                    this.state.openWorkflowEditionComponent
                  }
                  openWorkflowModal={this.openWorkflow}
                  ref={this.saleWorkflowActivities}
                />
              </Tab>
            ) : null}
          </Tabs>
        </div>
      </div>
    );
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    //execute once the first time
  }

  componentDidUpdate() {
    if (this.state.sale !== null) {
      this.getWorkflowSales();
      this.last_rendered_sale = this.state.sale;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    //only updates the render if the sale to show in the information is different than the one that is already shown

    const nextcurrentTab = nextState.currentTab;
    if (nextcurrentTab !== this.state.currentTab) {
      return true;
    }

    const next_sale = nextState.sale;
    if (nextState.sale === null) {
      return false;
    } else if (this.last_rendered_sale === next_sale) {
      return false;
    } else {
      return true;
    }
  }

  static getDerivedStateFromProps(props, state) {
    //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
    //return values for the state
    return {
      sale: props.sale,
    };
  }
  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleInformation;
