import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, Col, Form, FormLabel, Row } from "react-bootstrap";
import FormDatePicker from "../../../Form/FormDatePicker";
import FormSelectCustomer from "../../../Form/FormSelectCustomer";
import { useDispatch, useSelector } from "react-redux";
import { fetchJewellery } from "../../../../store/slices/jewellerySlice";
import { Typeahead } from "react-bootstrap-typeahead";
import { sprintf } from "sprintf-js";
import { dateFormatCustom } from "../../../../utils/dateUtils";
import {
  REACT_APP_WORKFLOW_SALE_STATUS_COLLECTED_COMPLETED,
  REACT_APP_WORKFLOW_SALE_STATUS_PROGRESS,
  REACT_APP_WORKFLOW_SALE_STATUS_TRANSIT,
  REACT_APP_WORKFLOW_TYPE_MANUFACTURING,
} from "../../../../global/const";

const NAME_STATUS = "status";

const WorkflowSaleForm = ({ onResponse }) => {
  const dispatch = useDispatch();
  const { jewelleries } = useSelector((state) => state.jewellery);

  let jewelleriesResponse = JSON.parse(JSON.stringify(jewelleries));

  const [showProvider, setShowProvider] = useState(false);
  const [showDates, setShowDates] = useState(false);

  const [data, setData] = useState({
    activity: REACT_APP_WORKFLOW_TYPE_MANUFACTURING,
    provider: null,
    givenDate: null,
    expectedDate: null,
    status: "",
  });

  jewelleriesResponse.map((item) => {
    return (item.nameComplete = sprintf(
      "%s (%s)",
      item.name,
      item.contactName
    ));
  });

  let listStatus = process.env.REACT_APP_WORKFLOW_STATUS.replaceAll(
    ",Cancelled",
    ""
  ).split(",");
  let listActivities = process.env.REACT_APP_LIST_ACTIVITIES.split(",");

  listStatus = listStatus.map((item) => {
    return {
      status: item,
      value: item,
    };
  });

  listActivities = listActivities.map((item) => {
    return {
      activity: item,
      value: item,
    };
  });

  const onChangeDate = (value, name) => {
    if (value) {
      value = dateFormatCustom(value);
    }

    data[name] = value;

    setData({
      ...data,
      [name]: value,
    });

    onResponse(data);
  };

  const handleChangeSelect = (response, name) => {
    let value = "";

    const item = response ? JSON.parse(response) : {};

    value = item.value;

    data[name] = value;

    setData({
      ...data,
      [name]: value,
    });

    onResponse(data);

    if (name === NAME_STATUS) {
      activeInputs(value);
    }
  };

  const activeInputs = (value) => {
    setShowDates(false);
    setShowProvider(false);
    if (REACT_APP_WORKFLOW_SALE_STATUS_PROGRESS === value) {
      setShowDates(true);
      setShowProvider(true);
    }
  };

  const handleChangeSelectProvider = (response) => {
    let value = "";

    if (response.length > 0) {
      value = response[0].id;
    }

    data["provider"] = value;

    setData({
      ...data,
      provider: value,
    });

    onResponse(data);
  };

  useEffect(() => {
    if (jewelleries.length <= 0) {
      dispatch(fetchJewellery());
    }
  }, [dispatch, jewelleries]);

  return (
    <>
      <Row className="mt-1">
        <FormSelectCustomer
          label="Activity"
          nameInput="activity"
          items={listActivities}
          keyValue={"value"}
          value={REACT_APP_WORKFLOW_TYPE_MANUFACTURING}
          onResponse={handleChangeSelect}
          showOptionEmpty={false}
        />
      </Row>

      <Row className="mt-1">
        <FormSelectCustomer
          label="New status for workflow activity"
          nameInput={NAME_STATUS}
          items={listStatus}
          keyValue={"value"}
          value={""}
          onResponse={handleChangeSelect}
        />
      </Row>

      {showProvider && (
        <Row className="mt-1">
          {jewelleriesResponse.length > 0 && (
            <Form.Group className="form-group">
              <Row>
                <Col xs={12} lg={6}>
                  <FormLabel className="d-block">Provider</FormLabel>
                </Col>
                <Col xs={12} lg={6}>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="nameComplete"
                    options={jewelleriesResponse}
                    placeholder="Choose a provider..."
                    onChange={(e) => handleChangeSelectProvider(e)}
                  />
                </Col>
              </Row>
            </Form.Group>
          )}
        </Row>
      )}

      {showDates && (
        <>
          <Row className="mt-1">
            <FormDatePicker
              label="Date given to provider *"
              nameInput="givenDate"
              value={data.givenDate ?? ""}
              onResponse={onChangeDate}
              required={true}
            />
            <Row>
              <Col></Col>
              <Col>
                <Form.Control
                  name="expectedDate"
                  hidden={true}
                  value={data.expectedDate ?? ""}
                  required={
                    data.status === REACT_APP_WORKFLOW_SALE_STATUS_PROGRESS
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setData({
                      ...data,
                      expectedDate: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  <ul>
                    <li>The Date given to provider is required</li>
                  </ul>
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Row>

          <Row className="mt-1">
            <FormDatePicker
              label="Expected date from provider *"
              nameInput="expectedDate"
              value={data.expectedDate ?? ""}
              onResponse={onChangeDate}
              required={true}
            />

            <Row>
              <Col></Col>
              <Col>
                <Form.Control
                  name="expectedDate"
                  hidden={true}
                  value={data.expectedDate ?? ""}
                  required={
                    data.status === REACT_APP_WORKFLOW_SALE_STATUS_PROGRESS
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    setData({
                      ...data,
                      expectedDate: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  <ul>
                    <li>The Expected date from provider is required</li>
                  </ul>
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Row>
        </>
      )}

      <Row>
        <Col>
          {!data.status && (
            <Alert variant="warning">
              If you save without choosing any workflow status, then, the status
              of all the sales in your tab will be changed to "
              <b>Order in Process</b>"
            </Alert>
          )}
          {data.status === REACT_APP_WORKFLOW_SALE_STATUS_PROGRESS && (
            <Alert variant="warning">
              If you save this changes, the status of the workflow manufacturing
              will be changed to "<b>In progress</b>". Information related to
              Provider, date given to provider and expected date will be updated
              to your selection above.
            </Alert>
          )}
          {data.status === REACT_APP_WORKFLOW_SALE_STATUS_TRANSIT && (
            <Alert variant="warning">
              If you save this changes, the status of the workflow manufacturing
              will be changed to "<b>In transit</b>". Provider, date given to
              provider and expected date will NOT be changed.
            </Alert>
          )}
          {data.status ===
            REACT_APP_WORKFLOW_SALE_STATUS_COLLECTED_COMPLETED && (
            <Alert variant="warning">
              If you save this changes, the status of the workflow manufacturing
              will be changed to "<b>Collected/Completed</b>". Provider, date
              given to provider and expected date will NOT be changed.
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};

WorkflowSaleForm.propTypes = {
  onResponse: PropTypes.func.isRequired,
};

export default WorkflowSaleForm;
