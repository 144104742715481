import React, { Component } from 'react';
import * as globalConst from '../../../global/const';
import axios from 'axios';
import Auth from '../../../Auth';
import Util from '../../../Util';
import { sprintf } from "sprintf-js";
import { Col, Row } from 'react-bootstrap';
import { GetSaleDiamonds } from '../detail/Actions/GetSaleDiamonds';
import SaleDiamondInformation from './SaleDiamondInformation';

// SCSS
import '../../../css/sales/sale_information.scss'

class SaleInformationCosts extends Component {

    constructor(props) {
        super();

        this.state = {
            sale: props.sale,
            workflowStatus: '',
            workflowSales: []
        }

        this.findWorkflowStatus()
    }

    componentDidUpdate = (nextProp) => {
        if (nextProp.sale.id !== this.state.sale.id) {
            this.state.workflowSales = []
            this.findWorkflowStatus()
        }
    }

    ajaxRequest = null;
    findWorkflowStatus() {
        //AJAX CONTROLS
        //cancel previous ajax request if exists
        if (this.ajaxRequest !== null) {
            this.ajaxRequest.cancel();
        }

        this.ajaxRequest = axios.CancelToken.source();
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_SALE_WORKFLOW_ACTIVITY
        url = url.replace("{saleID}", this.state.sale.id)

        axios.get(
            url,
            {
                params: this.filter_params,//this params are sent as query. In post requests they should go as body params in the following block
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            },
            {
                cancelToken: this.ajaxRequest.token
            }
        ).then(api_response => {
            this.setState({
                workflowStatus: ''
            });

            if (api_response.data.result.rows.length > 0) {
                let workFlows = api_response.data.result.rows;
                let workflowStatus = ""
                let saleWorkflow = null;
                let workflowActivity = {
                    created: ''
                };

                let allCencelled = true

                workFlows.map(workflowSale => {
                    if (workflowSale.status !== 'Cancelled') {
                        allCencelled = false
                    }

                    return null
                })

                workFlows.forEach(workflowSale => {
                    if ("workflowActivityModels" in workflowSale) {
                        if (workflowSale.workflowActivityModels.length > 0) {
                            workflowSale.workflowActivityModels.forEach(function (activity) {
                                if (allCencelled) {
                                    if (activity.created > workflowActivity.created) {
                                        workflowActivity = activity;
                                        saleWorkflow = workflowSale;
                                    }
                                } else {
                                    if (
                                        activity.created > workflowActivity.created &&
                                        workflowSale.status !== 'Cancelled'
                                    ) {
                                        workflowActivity = activity;
                                        saleWorkflow = workflowSale;
                                    }
                                }
                            })
                        }
                    }
                });

                workflowStatus = saleWorkflow ? workflowActivity.type + ' - ' + saleWorkflow.status : '';

                this.setState({
                    sale: this.state.sale,
                    workflowStatus: workflowStatus,
                    workflowSales: api_response.data.result.rows
                });
            }
        })
    }

    render() {
        const sale = this.state.sale

        const saleDiamond = GetSaleDiamonds(sale)

        return (
            <div className='sale_information_subcontainer'>
                <div className="container">

                    <SaleDiamondInformation
                        diamonds={saleDiamond.saleDiamondModels}
                        sale={sale}
                        searchDetailDiamond={saleDiamond.searchDetailDiamond}
                    />

                    <Row>
                        <Col lg="6" className="table_title">Workflow costs finalised</Col>
                        <Col lg="6">{sale.costReady ? 'Yes' : 'No'}</Col>
                    </Row>
                    <Row className="row">
                        <Col lg="6" className="table_title">Workflow status</Col>
                        <Col lg="6">{this.state.workflowStatus}</Col>
                    </Row>

                    {
                        this.state.workflowSales.length > 0 ?
                            this.renderCostWorkflow(this.state.workflowSales)
                            : ('')
                    }
                </div>
            </div>
        )
    }

    renderCostWorkflow = (workflowSales) => {
        return (
            <div className="mt-2 workflow-cost-summary-container">
                <h2 className="center-element">WORKFLOW SALES COST</h2>
                <div className='workflow-cost-activities'>
                    {
                        workflowSales.map((workflowSale) => {
                            return workflowSale.workflowActivityModels.map((workflowActivity) => {
                                return (
                                    <div
                                        key={workflowActivity.id.toString() + '_' + Math.random()}
                                        className='workflow-cost-activity'
                                    >
                                        {
                                            (
                                                workflowActivity.type !== 'Delivering to customer' &&
                                                workflowActivity.type !== 'Photos'
                                            ) ?
                                                <div className={!this.isCostready(workflowActivity) ? 'cost-not-ready' : ''}>
                                                    <h2 className="center-element">
                                                        {workflowActivity.type}
                                                    </h2>

                                                    {
                                                        !this.isCostready(workflowActivity) ?
                                                            (
                                                                <span className="center-element cost-not-ready-color">
                                                                    Cost not ready yet
                                                                </span>
                                                            ) :
                                                            ('')
                                                    }

                                                    <div className="ps-3 px-3">
                                                        <div className="row">
                                                            <div className="col-sm">Details</div>
                                                            <div className="col-sm">
                                                                {workflowActivity.stockNumber ?? ''}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm">Cost</div>
                                                            <div className="col-sm">
                                                                {
                                                                    Util.formatCurrency(workflowActivity.cost)
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm">Invoice To</div>
                                                            <div className="col-sm">
                                                                {workflowActivity.invoiceTo ?? ''}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm">Supplier invoice</div>
                                                            <div className="col-sm">
                                                                {workflowActivity.invoiceNumber ?? ''}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm">MMDIA Invoice</div>
                                                            <div className="col-sm">
                                                                {workflowActivity.mmdiaInvoice ?? ''}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm">Nova Invoince</div>
                                                            <div className="col-sm">
                                                                {workflowActivity.novaInvoice ?? ''}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm">Status</div>
                                                            <div className="col-sm">
                                                                {workflowActivity.invoiceStatus ?? ''}
                                                            </div>
                                                        </div>

                                                        {this.renderCostMelees(workflowActivity)}

                                                        {this.renderCostMetals(workflowActivity)}
                                                    </div>

                                                </div>
                                                :
                                                (null)
                                        }

                                    </div>
                                )
                            })
                        })
                    }
                </div>
            </div>
        )
    }

    isCostready = (workflowActivity) => {
        let costReady = true

        if (!workflowActivity.readyToPayToProvider) {
            costReady = false
            return costReady
        }

        workflowActivity.workflowMelesModels.map(workflowMelee => {
            if (!workflowMelee.costReady) {
                costReady = false
                return costReady
            }

            return null
        })

        return costReady
    }

    renderCostMelees = (workflowActivity) => {
        if (!workflowActivity.workflowMelesModels) {
            return ''
        }

        return (
            <div className="mt-2">
                <h2 className="center-element workflow-acivity-melees">Melees</h2>
                {
                    workflowActivity.workflowMelesModels.map((workflowMelee) => {
                        return (
                            <div
                                className="mt-3"
                                key={workflowMelee.id + Math.random()}
                            >
                                <div className="ps-3 px-3">
                                    <div className="row">
                                        <div className="col-sm">Details</div>
                                        <div className="col-sm">
                                            {
                                                this.descriptionMelees(workflowMelee)
                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm">Cost</div>
                                        <div className="col-sm">
                                            {
                                                Util.formatCurrency(workflowMelee.melesCost)
                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm">NOVA invoice</div>
                                        <div className="col-sm">
                                            {
                                                workflowMelee.novaInvoice
                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm">MMDIA Invoice</div>
                                        <div className="col-sm">
                                            {
                                                workflowMelee.mmdiaInvoice
                                            }
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm">Status</div>
                                        <div className="col-sm">
                                            {
                                                workflowMelee.invoiceStatus
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {
                    (workflowActivity.workflowMelesModels.length <= 0) ?
                        (<div>Ne melees recored</div>) :
                        (null)
                }
            </div >
        )
    }

    descriptionMelees = (melee) => {
        return sprintf(
            '%s %s mm %s pcs %sct',
            melee.melesDetails ?? '',
            melee.melesSize ?? '',
            melee.melesQuantity ?? '',
            melee.melesWeight ?? ''
        )
    }

    renderCostMetals = (workflowActivity) => {
        if (!workflowActivity) {
            return ''
        }

        if (!workflowActivity.workflowMetalModel) {
            return ''
        }

        return (
            <div className="mt-2">
                <h2 className="center-element workflow-acivity-metal">Metal</h2>

                <div className="ps-3 px-3">
                    <div className="row">
                        <div className="col-sm">Details</div>
                        <div className="col-sm">
                            {
                                this.descriptionMetal(workflowActivity?.workflowMetalModel)
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">Cost</div>
                        <div className="col-sm">
                            {
                                Util.formatCurrency(workflowActivity?.workflowMetalModel?.metalCost ?? 0)
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">Supplier invoice</div>
                        <div className="col-sm">
                            {workflowActivity?.workflowMetalModel?.metalInvoiceNumber ?? ''}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    descriptionMetal = (metal) => {
        let text = ''

        if (metal.metalYellowWeight) {
            text += 'Yellow %sgms'

            text = sprintf(
                text,
                metal.metalYellowWeight
            )
        }

        if (metal.metalWhiteWeight) {
            text += text.length > 0 ? ', White %sgms' : 'White %sgms'

            text = sprintf(
                text,
                metal.metalWhiteWeight
            )
        }

        if (metal.metalRoseWeight) {
            text += text.length > 0 ? ', Rose %sgms' : 'Rose %sgms'

            text = sprintf(
                text,
                metal.metalRoseWeight
            )
        }

        if (metal.metalPlatinumWeight) {
            text += text.length > 0 ? ', Platinum %sgms' : 'Platinum %sgms'

            text = sprintf(
                text,
                metal.metalPlatinumWeight
            )
        }

        return text;
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return {
            sale: props.sale
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

}

export default SaleInformationCosts;