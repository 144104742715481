import React, { Component } from 'react';

class LoadingSpinnerLayer extends Component {
    render() {
        return (
            <div className="spinner-border" role="status"/>
        )
    }
}

export default LoadingSpinnerLayer;