import React, { Component } from "react";
import logo from '../../image/novita_logo.svg';
import '../../css/login.scss'
import Auth from '../../Auth';
import axios from 'axios';
import LoginLoadingLayer from '../LoginLoadingLayer';
import * as globalConst from '../../global/const';

class Login extends Component {

    constructor(props){
        super(props);
        const isAuth = Auth.getIsAuth()
        if(isAuth === true){
            props.history.push('/dashboard')
        }
        this.state = {
            error_401: false,
            error_500: false,
            loading_page: false
        }
    }

    render() {
        return (
            <div className="login_container">
                <div className="main-div">
                    <div className="child-div">
                        <div className="logo-section">
                            <img src={logo} alt="" className="site-logo" />
                            <hr />
                            <h4 className="text-center crm-text border-none">ERP</h4>
                        </div>
                        <div className="form-section mt-5">
                            { 
                                this.state.error_401 === true ? (
                                    <div className="error">
                                        Username or password incorrect
                                    </div>
                                ):('')
                            }
                            { 
                                this.state.error_500 === true ? (
                                    <div className="error">
                                        There was an error. Please reload the screen and try again. If the problem persists, contact the system administrator.
                                    </div>
                                ):('')
                            }
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group ">
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        placeholder="Username/Email"
                                    />
                                </div>
                                <div className="input-group">
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control password_input"
                                        placeholder="Password"
                                        aria-label="Recipient's username"
                                        aria-describedby="password_view"
                                    />
                                </div>
                                <div className="form-group text-center">
                                    <button type="submit" className="btn btn-dark login-btn btn-block">Login</button>
                                </div>
                                
                                { this.state.loading_page === true ? <LoginLoadingLayer/> : ('') }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    handleSubmit = (event) => {
        this.setState({
            loading_page : true
        })
        event.preventDefault();
        const email = event.target.elements.email.value
        const password = event.target.elements.password.value
        const url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_USER_AUTHENTICATE
        const params = new URLSearchParams()
        params.append('email', email)
        params.append('plainPassword', password)
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        axios.post(url, params, config)
        .then((response) => {
            this.setState({
                loading_page: false
            })
            if(
                'data' in response && 
                'code' in response.data && 
                response.data.code === 200 && 
                'result' in response.data && 
                'token' in response.data.result &&
                (typeof response.data.result.token === 'string' || response.data.result.token instanceof String)
            ){
                Auth.authenticate(response.data.result);
                this.props.history.push('/dashboard')
            }
            else{
                Auth.signout();
            }
        })
        .catch((err) => {
            this.setState({
                loading_page: false
            })
            Auth.signout();
            if (err.response) {
                if('status' in err.response && err.response.status === 401){
                    this.setState({
                        error_401 : true
                    })
                }
                else{
                    this.setState({
                        error_500 : true
                    })
                }
            } else if (err.request) {
                this.setState({
                    error_500 : true
                })
            } else {
                this.setState({
                    error_500 : true
                })
            }
        })


    }
}

export default Login;
