import React, { Component } from 'react';

class SaleDetailSalesMarketing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sale: props.sale
        }
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        const sale = this.state.sale

        let reviewRequestDateSent_date = sale.reviewRequestDateSent
        if (sale.reviewRequestDateSent !== null && sale.reviewRequestDateSent !== '') {
            reviewRequestDateSent_date = new Date(sale.reviewRequestDateSent).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE });
            reviewRequestDateSent_date = reviewRequestDateSent_date.slice(0, -5) + ", " + reviewRequestDateSent_date.slice(-4)
        }

        return (
            <div id="SaleDetailSalesMarketing_component">
                <div className='sale_data'>

                    <h2 className='heading'>SALES AND MARKETING</h2>
                    <div className="container mb-4">
                        <div className="row">
                            <div className="col-sm">Sold from</div>
                            <div className="col-sm">{sale.soldFrom}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Selling City</div>
                            <div className="col-sm">{sale.city}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Sales Rep Name</div>
                            <div className="col-sm">{sale.salesRepName}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Review request sent on</div>
                            <div className="col-sm">{reviewRequestDateSent_date}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm">Review request status?</div>
                            <div className="col-sm">{sale.customerLeaveTrustpilotReview}</div>
                        </div>
                        <div className="row">
                            <div className="col-sm">How did you find about us?</div>
                            <div className="col-sm">{sale.howYouFindAboutUs}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************


}

export default SaleDetailSalesMarketing;