import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import TotalCommissionsReportDetails from "../components/total_commissions_report_details";
import { useSelector } from "react-redux";
import TotalCommissionsReportDetailsReviews from "../components/total_commissions_report_details_reviews";
const REPORT_COMMISSIONS_SALES = "resultCommissionsSales";
const CommissionsCharts = () => {
    const { resultCommissionsEfficincys, selectedReport } = useSelector(
        (state) => state.reports
    );
    return (
        <Row className="card-sale-commissions">
            <Row className="mt-2 mb-5">
                <Col xs={12} lg={12}>
                    {selectedReport === REPORT_COMMISSIONS_SALES ?
                        <TotalCommissionsReportDetails jsonData={resultCommissionsEfficincys ?? {}} />
                        : <TotalCommissionsReportDetailsReviews jsonData={resultCommissionsEfficincys ?? {}} />
                    }
                </Col>
            </Row>
        </Row>
    );
};

export default CommissionsCharts;
