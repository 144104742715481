import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Auth from './Auth';

import SalesList from './components/sales/SalesList'
import OrdersToList from './components/sales/orderToList'
import SaleDetail from './components/sales/detail/SaleDetail'
import CRMView from './components/customers'
import singleOrderDetails from './components/sales/SingleOrderDetails'
import Dashboard from './components/dashboard/Dashboard'
import Login from './components/login/Login'
import LoadInvoices from './components/costs/LoadInvoices'
import CostsList from './components/costs/CostsList'
import PostSaleServices from './components/post_sale_services/PostSaleServices'
import Accounting from './components/accounting/Accounting'
import PostSaleServicesDetail from './components/post_sale_services/detail/PostSaleServicesDetail'
import Documentation from './components/documentation/index'
import LoadCommissions from './components/accounting/LoadCommissions/LoadCommissions';
import { Reports } from './components/reports';

const Router = (props) => (
    <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/sales" component={SalesList} />
        <PrivateRoute exact path="/ordersto" component={OrdersToList} />
        <PrivateRoute exact path="/sale/:id" component={SaleDetail} />
        <PrivateRoute exact path="/customers" component={CRMView} />
        <PrivateRoute exact path="/orderDetails" component={singleOrderDetails} />
        <PrivateRoute exact path="/costs/load_invoices" component={LoadInvoices} />
        <PrivateRoute exact path="/costs/show_costs" component={CostsList} />
        <PrivateRoute exact path="/post_sale_services" component={PostSaleServices} />
        <PrivateRoute exact path="/accounting" component={Accounting} />
        <PrivateRoute exact path="/accounting/load-commissions" component={LoadCommissions} />
        <PrivateRoute exact path="/post_sale_services/:id" component={PostSaleServicesDetail} />
        <PrivateRoute exact path="/documentation" component={Documentation} />
        <PrivateRoute exact path="/reports" component={Reports} />
    </Switch>
)

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render=
        {
            props => Auth.getIsAuth() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={
                        { pathname: "/" }
                    }
                />
            )
        }
    />
);

export default Router;
