import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Commissions from "../../accounting/Commissions/Commissions";
import {
  isRoleAccounting,
  isRoleAdmin,
  isRoleSalesLeader,
  isSalesNotLeader,
} from "./Actions/RolesSoleCommissions";
import {
  SALE_COMMISSION_TYPE_REVIEWS,
  SALE_COMMISSION_TYPE_SALES,
} from "../../../global/const";

const SaleDetailSaleCommissions = ({ sale, type }) => {
  const [showCommissions, setShowCommissions] = useState(false);

  const rolSalesNotLeader = isSalesNotLeader();
  const rolIsAdmin = isRoleAdmin();
  const rolAccounting = isRoleAccounting();
  const rolSalesLeader = isRoleSalesLeader();

  useEffect(() => {
    if (rolIsAdmin) {
      setShowCommissions(true);
    }

    if (rolSalesNotLeader) {
      setShowCommissions(true);
    }

    if (rolAccounting) {
      setShowCommissions(true);
    }

    if (rolSalesLeader) {
      setShowCommissions(true);
    }
  }, [
    sale,
    showCommissions,
    rolSalesNotLeader,
    rolIsAdmin,
    rolAccounting,
    rolSalesLeader,
  ]);

  return (
    <Col className="col-sm">
      {showCommissions ? (
        <Row className="sale_data">
          <h2 className="heading">
            {type === SALE_COMMISSION_TYPE_SALES && <>Commissions for Sales</>}
            {type === SALE_COMMISSION_TYPE_REVIEWS && (
              <>Commissions for Reviews</>
            )}
          </h2>

          <Col className="container mb-6">
            <Commissions
              sale={sale}
              showAction={false}
              showAll={rolSalesNotLeader ? false : true}
              type={type}
            ></Commissions>
          </Col>
        </Row>
      ) : null}
    </Col>
  );
};

SaleDetailSaleCommissions.propTypes = {
  sale: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
};

SaleDetailSaleCommissions.defaultProps = {
  type: SALE_COMMISSION_TYPE_SALES,
};

export default SaleDetailSaleCommissions;
