import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import SaleWorkflowEditReturnStepOne from "./SaleWorkflowEditReturnStepOne";
import SaleWorkflowEditReturnStepTwo from "./SaleWorkflowEditReturnStepTwo";
import SaleWorkflowEditReturnStepThree from "./SaleWorkflowEditReturnStepThree";
import {
  clearWorkflowReturn,
  fetchWorkflowReturn,
  fetchWorkflowReturnSendGD,
} from "../../../../../store/slices/workflowReturnSlice";
import { useDispatch, useSelector } from "react-redux";
import Util from "../../../../../Util";
import { workflowReturnPrepareData } from "../../../edit/Acctions/WorkflowReturn";
import { clear } from "../../../../../store/slices";

const SaleWorkflowEditReturn = ({
  activity,
  onResponseReturn,
  sale,
  workflowSale,
  onResponseLoading,
}) => {
  let showStepTwo = false;
  let showStepThree = false;
  let disabledInputsStepOne = false;
  let disabledInputsStepTwo = false;

  const dispatch = useDispatch();
  const { workflowReturn } = useSelector((state) => state.workflowReturn);

  const [workflowReturnModel, setWorkflowReturnModel] = useState(
    activity.workflowReturnModel ?? null
  );
  const [chageWorflowReturn, setChageWorflowReturn] = useState(false);

  if (
    workflowReturnModel?.stepOneQuestions?.complete &&
    (workflowReturnModel?.stepOneQuestions?.type || workflowReturnModel?.type)
  ) {
    showStepTwo = true;
    disabledInputsStepOne = true;
  }

  if (workflowReturnModel?.stepTwoQuestions?.complete) {
    showStepThree = true;
    disabledInputsStepTwo = true;
  }

  useEffect(() => {
    if (JSON.stringify(workflowReturn).length > 10) {
      const fetchData = async () => {
        setChageWorflowReturn(true);
        setWorkflowReturnModel(workflowReturnPrepareData(workflowReturn));

        await Util.timeAwait(10);

        setChageWorflowReturn(false);

        dispatch(clearWorkflowReturn());
      };

      return fetchData();
    }
  }, [dispatch, workflowReturn]);

  useEffect(() => {
    dispatch(clear());
  }, [dispatch]);

  const onResponse = (workflowReturn, name) => {
    workflowReturnModel[name] = workflowReturn;

    activity.workflowReturnModel = workflowReturnModel;

    onResponseReturn(activity);
  };

  const workflowReturnSave = async (type) => {
    if (onResponseLoading) {
      onResponseLoading(true);
    }

    await dispatch(
      await fetchWorkflowReturn({
        id: workflowReturnModel.id,
        type: type,
        saleId: sale.id,
      })
    );

    if (onResponseLoading) {
      onResponseLoading(false);
    }
  };

  const workflowReturnSendToGd = async () => {
    if (onResponseLoading) {
      onResponseLoading(true);
    }

    await dispatch(
      await fetchWorkflowReturnSendGD({
        sale: sale,
        workflowReturn: workflowReturnModel,
        id: workflowReturnModel.id,
      })
    );

    if (onResponseLoading) {
      onResponseLoading(false);
    }
  };

  return (
    <Row>
      {!chageWorflowReturn && (
        <SaleWorkflowEditReturnStepOne
          sale={sale}
          stepOne={workflowReturnModel.stepOneQuestions}
          onResponse={onResponse}
          type={workflowReturnModel?.type ?? ""}
          onResponseSave={workflowReturnSave}
          workflowStatus={workflowSale.status}
          disabledInputs={disabledInputsStepOne}
        />
      )}

      {showStepTwo && !chageWorflowReturn && (
        <SaleWorkflowEditReturnStepTwo
          stepTwo={workflowReturnModel.stepTwoQuestions}
          type={workflowReturnModel?.stepOneQuestions?.type}
          onResponse={onResponse}
          date={workflowReturnModel.created}
          disabledInputs={disabledInputsStepTwo}
        />
      )}

      {showStepThree && !chageWorflowReturn && (
        <SaleWorkflowEditReturnStepThree
          sale={sale}
          stepThree={workflowReturnModel.stepThreeQuestions}
          onResponse={onResponse}
          onResponseGD={workflowReturnSendToGd}
        />
      )}
    </Row>
  );
};

SaleWorkflowEditReturn.propTypes = {
  activity: PropTypes.object.isRequired,
  sale: PropTypes.object.isRequired,
  workflowSale: PropTypes.any,
  onResponseLoading: PropTypes.func,
  onResponseReturnCreate: PropTypes.func,
};

SaleWorkflowEditReturn.defaultProps = {
  workflowSale: {
    status: "",
  },
};

export default SaleWorkflowEditReturn;
