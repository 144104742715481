import { Accordion } from 'react-bootstrap';
import CustomerComment from './comment';

const CustomerComments = ({ comments }) => {
    return (
        <div className="mt-3 pb-3">
            <Accordion defaultActiveKey="0">
                <Accordion.Item>
                    <Accordion.Header>VIEW COMMENTS ({comments?.length ?? 0})</Accordion.Header>
                    <Accordion.Body>
                        <div id="SaleCommentsList_component">
                            {!comments ? (
                                <div className="form-group">
                                    <span className="mt-2 no-comments-section">No comments yet</span>
                                </div>
                            ) : (
                                comments.map((comment) => (
                                    <CustomerComment key={comment.id} comment={comment} />
                                ))
                            )}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
};

export default CustomerComments;