import axios from "axios";
import * as globalConst from "../../../global/const";
import { sprintf } from "sprintf-js";
import Auth from "../../../Auth";
import moment from "moment";

const EXPORT_SALES_REPORT = "Sales report";
const EXPORT_WORKFLOW_COST = "Workflow Costs";

export const generateSalesExcelAdvancedFilterResults = async (
  dataAdvancedFilter
) => {
  let url = sprintf(
    "%s%s",
    process.env.REACT_APP_NOVITAERP_API_DOMAIN,
    globalConst.REACT_APP_NOVITAERP_API_SALES_EXPORT_SALE_ADVANCED
  );

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
    responseType: "arraybuffer",
  };

  await axios.post(url, dataAdvancedFilter, config).then((response) => {
    downloadNameCustom(response, EXPORT_SALES_REPORT);
  });
};

export const generateSalesExcelAdvancedFilterPersonalTab = async (
  currentTabs
) => {
  if (currentTabs.lenght <= 0) {
    return;
  }

  const currentTab = currentTabs[0];
  const { salesIds = "[]" } = currentTab;

  if (!salesIds) {
    alert("There are no salts stored, to create the report");
    return;
  }

  const ids = salesIds.toString().replace("[", "").replace("]", "");

  const filter = [
    {
      name: "id",
      model: "salesModel",
      value: ids,
      isEmpty: false,
      typeField: "in",
      typeQuery: "in",
      fields: [],
    },
  ];

  let url = sprintf(
    "%s%s",
    process.env.REACT_APP_NOVITAERP_API_DOMAIN,
    globalConst.REACT_APP_NOVITAERP_API_SALES_EXPORT_SALE_ADVANCED
  );

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
    responseType: "arraybuffer",
  };

  await axios.post(url, filter, config).then((response) => {
    downloadNameCustom(response, EXPORT_SALES_REPORT);
  });
};

export const generateWorkflowCostsReportAdvancedFilterResults = async (
  dataAdvancedFilter
) => {
  let url = sprintf(
    "%s%s",
    process.env.REACT_APP_NOVITAERP_API_DOMAIN,
    globalConst.REACT_APP_NOVITAERP_API_SALES_EXPORT_WORKFLOW_COST_ADVANCED
  );

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
    responseType: "arraybuffer",
  };

  await axios.post(url, dataAdvancedFilter, config).then((response) => {
    downloadNameCustom(response, EXPORT_WORKFLOW_COST);
  });
};

export const generateWorkflowCostsReportAdvancedFilterPersonalTab = async (
  currentTabs
) => {
  if (currentTabs.lenght <= 0) {
    return;
  }

  const currentTab = currentTabs[0];
  const { salesIds = "[]" } = currentTab;

  if (!salesIds) {
    alert("There are no salts stored, to create the report");
    return;
  }

  const ids = salesIds.toString().replace("[", "").replace("]", "");

  const filter = [
    {
      name: "id",
      model: "salesModel",
      value: ids,
      isEmpty: false,
      typeField: "in",
      typeQuery: "in",
      fields: [],
    },
  ];

  let url = sprintf(
    "%s%s",
    process.env.REACT_APP_NOVITAERP_API_DOMAIN,
    globalConst.REACT_APP_NOVITAERP_API_SALES_EXPORT_WORKFLOW_COST_ADVANCED
  );

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
    responseType: "arraybuffer",
  };

  await axios.post(url, filter, config).then((response) => {
    downloadNameCustom(response, EXPORT_WORKFLOW_COST);
  });
};

export const downloadNameCustom = (response, name) => {
  const data = response.data;
  let blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const nameFile = sprintf(
    "%s %s.xlsx",
    name,
    moment(new Date()).format("YYYY-DD-MM")
  );

  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", nameFile);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
