import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, FormLabel, Row } from "react-bootstrap";

const FormInputTextarea = (props) => {
  const {
    value,
    label,
    nameInput,
    onResponse,
    disabled,
    onlyNumber,
    columnsSize,
    required,
  } = props;

  const [valueNew, setValueNew] = useState(value);

  const changeValue = (event) => {
    let { value } = event.target;

    if (onlyNumber) {
      value = value.toString();
      value = value.replace(/,/g, ".").replace(/[^0-9.]/g, "");
    }

    setValueNew(value);

    onResponse(value, nameInput);
  };

  return (
    <Form.Group className="form-group">
      <Row>
        <Col sm="12" lg={columnsSize}>
          <FormLabel>{label}</FormLabel>
        </Col>
        <Col sm="12" lg={columnsSize}>
          <Form.Control
            required={required}
            as="textarea"
            name={nameInput}
            value={valueNew ?? ""}
            onChange={changeValue}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">
            {label.replace("*", "")} is required
          </Form.Control.Feedback>
        </Col>
      </Row>
    </Form.Group>
  );
};

FormInputTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  nameInput: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onResponse: PropTypes.func,
  disabled: PropTypes.bool,
  onlyNumber: PropTypes.bool,
  columnsSize: PropTypes.any,
  required: PropTypes.bool,
};

FormInputTextarea.defaultProps = {
  disabled: false,
  onlyNumber: false,
  columnsSize: 12,
  required: false,
};

export default FormInputTextarea;
