import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_FAILED,
  API_STATUS_IDLE,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
} from "../constants";
import Auth from "../../Auth";

const initialState = {
  error: null,
  employees: [],
  status: API_STATUS_IDLE,
};

export const fetchEmployees = createAsyncThunk(
  "/employee",
  async (args, thunkAPI) => {
    try {
      const user = Auth.getAuthenticatedUser()
      const leader = Auth.canAccessRolesAndLeader('rolesEditDiamonsSales', true)
      const saleNotLeader = Auth.canEditField('rolesEditDiamonsSales')
      let flag = args?.flag;

      const { id = null } = user

      let url = `/api/v1/employee?roles=ROLE_SALES`
      
      if (!leader && saleNotLeader && !flag) {
        url += `&userId=${id}`
      }

      const response = await axios.get(url);
      const { data } = response

      if (!data) {
        return []
      }

      const { result } = data
      const { rows } = result

      if (Array.isArray(rows)) {
        return rows
      }

      return []
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    clear(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.employees = initialState.customers;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchEmployees.fulfilled, (state, action) => ({
      employees: action.payload,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(fetchEmployees.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchEmployees.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const {
  clear,
} = employeesSlice.actions;

export default employeesSlice.reducer;
