import React, { Component } from "react";

// SCSS
import "../../../css/sales/sale_information.scss";

class SaleInformationSalesMarketing extends Component {
  constructor(props) {
    super();
    this.state = {
      sale: props.sale,
    };
  }

  render() {
    const sale = this.state.sale;
    return (
      <div className="sale_information_subcontainer">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <span className="table_title">Sales rep name</span>
            </div>
            <div className="col-sm">{sale.salesRepName}</div>
          </div>
          <div className="row">
            <div className="col-sm">
              <span className="table_title">
                Sales rep as reported in website
              </span>
            </div>
            <div className="col-sm">{sale.websiteSalesRep}</div>
          </div>
          <div className="row">
            <div className="col-sm">
              <span className="table_title">Sold from</span>
            </div>
            <div className="col-sm">
              {sale.soldFrom ? sale.soldFrom.replace(",", ", ") : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    return {
      sale: props.sale,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleInformationSalesMarketing;
