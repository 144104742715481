import React, { Component } from 'react';
import { Modal, Button, Form, FormLabel, Tabs, Tab, Card, Dropdown } from 'react-bootstrap';
import Auth from "../../Auth";
import axios from 'axios';
import DateField from '../sales/formComponents/DateField';
import FlashMessages from "../FlashMessages";
import * as globalConst from '../../global/const';
import SaleSearch from './SaleSearch';
import LoadingLayer from '../LoadingLayer'

// SCSS
import '../../css/sales/sale_create.scss'

class SaleEditionAssignTaskModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            employeeTaskList: [],
            employees: [],
            employeeId: 1,
            salesId: null,
            selected_sale: null,
            show_sale_search_modal: false,
            showSaleSeachForm: false,
            disable_button: true,
            description: '',
            dueDate: '',
            currentTab: "assign",
            showLoading: false
        }
        this.access_to_activity_assign_list = Auth.isAuthorised("dashboard_activity_assign_task");
        this.access_to_activity_assign_own_task = Auth.isAuthorised("dashboard_activity_assign_own_task");
        this.closeModal = this.closeModal.bind(this)
        this.handleChangeDateField = this.handleChangeDateField.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.selectSale = this.selectSale.bind(this)
    }

    closeModal() {
        this.props.closeSaleEditionAssignTaskModal()
    }

    handleSelect = (key) => {
        if (key === "assign") {
            this.setState({
                currentTab: "assign"
            })
        }
        else if (key === "list") {
            this.setState({
                currentTab: "list"
            })
        }
    }

    handleChangeSelect(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    handleChangeDateField = (date, field_name) => {
        const state = {}

        if (isNaN(Date.parse(date))) {
            state[field_name] = null
        }
        else {
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const year = date.getFullYear()
            const hours = date.getHours()
            const minutes = date.getMinutes()

            date = new Date(year, month - 1, day, hours, minutes)
            if (isNaN(Date.parse(date))) {
                state[field_name] = null
            }
            else {
                state[field_name] = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
            }
        }
        this.setState(state)
    }

    handleChange(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        return (
            <div id="SaleEditionAssignTaskModal_component">
                {
                    this.state.showLoading === true ?
                        (
                            <LoadingLayer />
                        )
                        :
                        ('')
                }

                <Modal
                    show={true}
                    onHide={this.closeModal}
                    dialogClassName="modal-90w"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        Event's tasks
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs activeKey={this.state.currentTab} id="SaleEditionAssignTaskTabs" className="mb-3" onSelect={this.handleSelect}>
                            <Tab eventKey="assign" title="New task">
                                {
                                    <FlashMessages
                                        error_messages={this.state.error_messages}
                                    />
                                }
                                {
                                    this.state.show_sale_search_modal === true ?
                                        <SaleSearch
                                            closeComponent={this.closeSaleSearchPopup}
                                            setSelectedSale={this.selectSale}
                                        />
                                        : (null)
                                }
                                <Form>
                                    <div className="row">
                                        <Card className="mb-2">
                                            <Card.Body>
                                                <div className='col-12'>
                                                    <a
                                                        className={"add_link add-task-button"}
                                                        onClick={this.openSaleSearchForm}>
                                                        Link Sale
                                                    </a>
                                                </div>
                                                {
                                                    (this.state.showSaleSeachForm) ? (
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <Form.Group>
                                                                    <FormLabel>Sale</FormLabel>
                                                                    <Form.Control
                                                                        disabled
                                                                        type="text"
                                                                        name="saleId"
                                                                        value={this.state.selected_sale === null ? '' : this.state.selected_sale.name}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                            <div className='row col-6 select-sale-button'>
                                                                <div className="col-6">
                                                                    <Button
                                                                        className="btn-default save-information-btn"
                                                                        type="button"
                                                                        onClick={this.openSaleSearchPopup}
                                                                    >
                                                                        Select
                                                                    </Button>
                                                                </div>
                                                                <div className="col-6">
                                                                    {
                                                                        (this.state.selected_sale) ? (
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle
                                                                                    variant="danger"
                                                                                    id="dropdown-basic">
                                                                                    Remove Select
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item href="#/action-2">No</Dropdown.Item>
                                                                                    <Dropdown.Item onClick={this.removeSaleSelect}>
                                                                                        Yes
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        ) : (null)
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </Card.Body>
                                        </Card>

                                    </div>
                                    <div className='row'>
                                        <Card className="mb-2">
                                            <Card.Body>
                                                <div className='col'>
                                                    <Form.Group className="form-group">
                                                        <FormLabel className='d-block'>Employee: </FormLabel>
                                                        <select name="employeeId" className='select-box w-100 ml-0' value={this.state.employeeId} onChange={(e) => this.handleChangeSelect(e)}>
                                                            {
                                                                this.state.employees.map((row, index) => {
                                                                    return (
                                                                        <option key={index} value={!!row.id ? row.id : ""}>{!!row.name ? row.name : ""}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </Form.Group>
                                                </div>
                                                <div className='col'>
                                                    <Form.Group className="form-group">
                                                        <FormLabel>Task:</FormLabel>
                                                        <Form.Control
                                                            required={true}
                                                            type="text"
                                                            name="description"
                                                            value={this.state.description === null ? '' : this.state.description}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className='col'>
                                                    <Form.Group className="form-group">
                                                        <FormLabel>Due Date:</FormLabel>
                                                        <DateField
                                                            handleChangeDateField={this.handleChangeDateField}
                                                            field_name='dueDate'
                                                            currentDate={this.state.dueDate}
                                                            showTimeSelect={true}
                                                            dateFormat='dd/MM/yyyy HH:mm'
                                                            isClearable={false}
                                                        />
                                                    </Form.Group>
                                                </div>

                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <Button className='save-information-btn'
                                        type="button"
                                        onClick={this.assignTask}
                                    >
                                        Save
                                    </Button>
                                </Form>
                            </Tab>
                            {/*
                            <Tab eventKey="list" title="All tasks">    
                                <Form>
                                    <div className='row'>

                                    
                                    {
                                        this.state.employeeTaskList.length === 0 ?
                                            <p>
                                                There are no tasks assigned to this event
                                            </p>
                                        :(null)
                                    }
                                    {
                                        this.state.employeeTaskList.map((row, index) => {
                                            let created_date = null
                                            if(row.created !== null && row.created !== '') {
                                                created_date = new Date(row.created);
                                            }
                                            let due_date = null
                                            if(row.dueDate !== null && row.dueDate !== '') {
                                                due_date = new Date(row.dueDate);
                                            }
                                            return(
                                                <div className='col-md-4' key={index} >
                                                    <div className='card'>
                                                        <div className="card-header">
                                                            <b>- Task #{ row.id }: { row.description }</b>
                                                        </div>
                                                        <div className="card-body">
                                                            <p className='mb-2'>
                                                                <b>Assigned to: </b>
                                                                { row.employeeModel.name }
                                                            </p>
                                                            <p className='mb-2'>
                                                                <b>Status: </b>
                                                                <span className={ row.employeeTaskStatusModel.name }>{ row.employeeTaskStatusModel.name }</span>
                                                            </p>
                                                            <p className='mb-2'>
                                                                <b>Created:  </b>
                                                                {created_date.toLocaleString('default', { day: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE })} {created_date.toLocaleString('default', { month: 'short', timeZone: process.env.REACT_APP_TIMEZONE })}, { created_date.toLocaleString('default', { year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE }) } - { created_date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: process.env.REACT_APP_TIMEZONE }) }
                                                            </p>
                                                            <p className='mb-0'>
                                                                <b>Due date:  </b>
                                                                {due_date.toLocaleString('default', { day: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE })} {due_date.toLocaleString('default', { month: 'short', timeZone: process.env.REACT_APP_TIMEZONE })}, { due_date.toLocaleString('default', { year: 'numeric', timeZone: process.env.REACT_APP_TIMEZONE }) } - { due_date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: process.env.REACT_APP_TIMEZONE }) }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </Form>

                            </Tab>*/
                            }
                        </Tabs>

                    </Modal.Body>
                </Modal>
            </div>
        )
    }


    removeSaleSelect = () => {
        this.setState({
            selected_sale: null,
            showSaleSeachForm: false
        })
    }

    getEmployeeTaskList = () => {
        let url;
        if (this.access_to_activity_assign_list) {
            url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_LIST
        }
        else if (this.access_to_activity_assign_own_task) {
            url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_LIST_OWN
        }

        if (url) {
            let sale_edition_id = this.props.sale_edition_assign_task_selected_id;

            const params = new URLSearchParams()
            params.append('saleEditionRequirementId', sale_edition_id)
            axios.get(
                url,
                {
                    params: params,
                    headers: { Authorization: `Bearer ${Auth.getToken()}` }
                }
            )
                .then(
                    api_response => {
                        const data = api_response.data
                        this.setState({
                            employeeTaskList: data.result.rows,
                        })
                        if (data !== undefined && data.code === 200) {

                        }
                        else {
                            alert('error')
                        }
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            if ('status' in err.response && err.response.status === 401) {
                                alert('User not authorised. Please reload the page');
                            }
                            else {
                                alert('There was a problem processing the information. Please reload this page and try again.');
                            }
                        } else if (err.request) {
                            alert('There was a problem with your request. Please reload this page and try again.');
                        } else {
                            //error not detected. Request could have been cancelled and it's waiting for second
                            //request to finish
                        }
                        //console.log(err);
                        return null;
                    }
                )
        }
    }

    openSaleSearchForm = () => {
        this.setState({
            showSaleSeachForm: !this.state.showSaleSeachForm
        })
    }

    getEmployees = () => {
        const user = Auth.getAuthenticatedUser()
        let is_leader = false;
        if ("employeeModel" in user) {
            if ("isLeader" in user.employeeModel) {
                is_leader = user.employeeModel.isLeader
            }
        }

        let url;
        if (is_leader) {
            url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_LIST
        }
        else {
            url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_GET_OWN_USER
        }

        const params = new URLSearchParams()
        params.append('roles', user.roles)

        axios.get(
            url,
            {
                params: params,
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        )
            .then(
                api_response => {
                    const data = api_response.data
                    this.setState({
                        employees: data.result.rows,
                        employeeId: data.result.rows[0].id
                    })
                    if (data !== undefined && data.code === 200) {

                    }
                    else {
                        alert('error')
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            alert('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    //console.log(err);
                    return null;
                }
            )
    }

    createEmployeeTask() {
        this.setState({
            showLoading: true
        });

        let assignedById
        const user = Auth.getAuthenticatedUser()
        if ("employeeModel" in user) {
            if (user.employeeModel !== null) {
                assignedById = user.employeeModel.id
            }
        }

        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_CREATE

        let params = new URLSearchParams()
        params.append('employeeId', this.state.employeeId)
        params.append('description', this.state.description)
        params.append('employeeTaskStatusId', 2) //Assigned
        params.append('dueDate', this.state.dueDate)
        params.append('assignedById', assignedById)

        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }

        axios.post(
            url,
            params,
            config
        )
            .then(
                api_response => {
                    const data = api_response.data
                    if (data !== undefined && data.code === 200) {
                        // this.createSaleTask(data.result.id);
                        if (this.state.selected_sale) {
                            this.createSalesEmployeeTask(data.result.id);
                        } else {
                            window.location.reload()
                        }
                    }
                    else {
                        this.setState({
                            showLoading: false
                        });

                        alert('error')
                        return null;
                    }
                }
            )
            .catch(
                err => {
                    this.setState({
                        showLoading: false
                    });

                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            alert('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    console.log(err);
                    return null;
                }
            )
    }

    createSaleTask(employee_task_id) {
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALE_TASK_CREATE

        if (url) {

            let sale_edition_id = this.props.sale_edition_assign_task_selected_id;

            let params = new URLSearchParams()
            params.append('salesaleEditionRequirementId', sale_edition_id)
            params.append('employeeTaskId', employee_task_id)

            let config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }

            axios.put(
                url,
                params,
                config
            )
                .then(
                    api_response => {
                        const data = api_response.data
                        if (data !== undefined && data.code === 200) {
                            this.updateSaleEditionRequirement();
                        }
                        else {
                            alert('error')
                        }
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            if ('status' in err.response && err.response.status === 401) {
                                alert('User not authorised. Please reload the page');
                            }
                            else {
                                alert('There was a problem processing the information. Please reload this page and try again.');
                            }
                        } else if (err.request) {
                            alert('There was a problem with your request. Please reload this page and try again.');
                        } else {
                            //error not detected. Request could have been cancelled and it's waiting for second
                            //request to finish
                        }
                        //console.log(err);
                        return null;
                    }
                )
        }
    }

    createSalesEmployeeTask(employee_task_id) {
        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALES_EMPLOYEE_TASK_CREATE

        if (url) {

            //let sale_edition_id = this.props.sale_edition_assign_task_selected_id;

            let params = new URLSearchParams()
            params.append('salesId', this.state.selected_sale.id)
            params.append('employeeTaskId', employee_task_id)

            let config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }

            axios.post(
                url,
                params,
                config
            )
                .then(
                    api_response => {
                        const data = api_response.data
                        this.setState({
                            showLoading: false
                        });

                        if (data !== undefined && data.code === 200) {
                            //this.updateSaleEditionRequirement();
                            window.location.reload()
                        }
                        else {
                            alert('error')
                        }
                    }
                )
                .catch(
                    err => {
                        if (err.response) {
                            if ('status' in err.response && err.response.status === 401) {
                                alert('User not authorised. Please reload the page');
                            }
                            else {
                                alert('There was a problem processing the information. Please reload this page and try again.');
                            }
                        } else if (err.request) {
                            alert('There was a problem with your request. Please reload this page and try again.');
                        } else {
                            //error not detected. Request could have been cancelled and it's waiting for second
                            //request to finish
                        }
                        //console.log(err);
                        return null;
                    }
                )
        }
    }

    updateSaleEditionRequirement() {
        let sale_edition_id = this.props.sale_edition_assign_task_selected_id;

        let url = process.env.REACT_APP_NOVITAERP_API_DOMAIN + globalConst.REACT_APP_NOVITAERP_API_SALE_EDITION_REQUIREMENT_EDIT
        url = url.replace("{id}", sale_edition_id)
        let params = new URLSearchParams()
        params.append('requiresAction', 'true')

        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }

        axios.put(
            url,
            params,
            config
        )
            .then(
                api_response => {
                    const data = api_response.data
                    if (data !== undefined && data.code === 200) {
                        this.props.updateSaleEditRequirementsList()
                        this.props.updateEmployeeTaskList()
                        this.getEmployeeTaskList()
                        this.setState({
                            currentTab: "list"
                        })
                        this.handleSelect("list")
                    }
                    else {
                        alert('error')
                        return null;
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            alert('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    //console.log(err);
                    return null;
                }
            )

    }

    assignTask = () => {
        let error_messages = []

        if (
            this.state.selected_sale === null
            && this.state.showSaleSeachForm === true
        ) {
            error_messages.push("Select a valid sale")
        }

        if (this.state.description.length < 1) {
            error_messages.push("Description should have at least 1 character")
        }
        if (typeof this.state.dueDate !== "undefined") {
            if (this.state.dueDate === null) {
                error_messages.push("Select valid due date")
            }
            else if (this.state.dueDate.length < 1) {
                error_messages.push("Select valid due date")
            }
        } else {
            error_messages.push("Select valid due date")
        }

        if (error_messages.length > 0) {
            this.setState({
                error_messages: error_messages
            })
        }
        else {
            //this.props.resetFiltersMyTasks();
            this.createEmployeeTask();
        }
    }

    openSaleSearchPopup = () => {
        this.setState(
            {
                show_sale_search_modal: true
            }
        )
    }

    closeSaleSearchPopup = () => {
        this.setState(
            {
                show_sale_search_modal: false
            }
        )
    }

    selectSale(sale) {
        let name
        let phone
        let email
        let pickupAddress
        let dropoffAddress

        if ('name' in sale) {
            name = sale.name;
        }
        if ('phone' in sale) {
            phone = sale.phone;
        }
        if ('email' in sale) {
            email = sale.email;
        }
        if ('pickupAddress' in sale) {
            pickupAddress = sale.pickupAddress;
        }
        if ('dropoffAddress' in sale) {
            dropoffAddress = sale.dropoffAddress;
        }

        this.setState(
            {
                disable_button: false,
                selected_sale: sale,
                name: name,
                phone: phone,
                email: email,
                pickupAddress: pickupAddress,
                dropoffAddress: dropoffAddress,
            }
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    componentDidMount() {
        //this.getEmployeeTaskList();
        this.getEmployees();
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************


}

export default SaleEditionAssignTaskModal;