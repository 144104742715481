import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, Table } from "react-bootstrap";
import moment from "moment";
import Util from "../../../Util";

const TableTotalSalesReport = ({ jsonData }) => {
  const { results = [] } = jsonData;
  const totalAmount = results.reduce((acc, curr) => acc + curr.monthlyTotal, 0);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Row>
            <Col xs={12} lg={12}>
              <h2>Sales per month</h2>
            </Col>
          </Row>
        </Card.Title>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Date of Sale</th>
              <th>Total Sales</th>
              <th>Percentage of Total Sales</th>
            </tr>
          </thead>
          <tbody>
            {results.map((data, index) => {
              const { year, month } = data;
              const monthDate = moment(
                new Date(`${year}-${month}-28`),
                "YYYY-MM-DD"
              ).format("MMM");

              const percentageOfTotal = (data.monthlyTotal / totalAmount) * 100;
              return (
                <tr key={index}>
                  <td>{`${year}-${monthDate}`}</td>
                  <td>{Util.formatCurrency(data.monthlyTotal)}</td>
                  <td>
                    <div className="progressBar">
                      <div
                        className="progressBarFill"
                        style={{ width: `${percentageOfTotal}%` }}
                      ></div>
                      <span className="percentageText">
                        {Util.numberDecimal(percentageOfTotal)}%
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

TableTotalSalesReport.propTypes = {
  jsonData: PropTypes.object.isRequired,
};

export default TableTotalSalesReport;
