import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row, Table } from 'react-bootstrap'
import { SearchSales } from './Acctions/SearchSales';
import LoadingSpinnerLayer from '../../../LoadingSpinnerLayer';
import RowSearchSaleDiamond from './RowSearchSaleDiamond';
import AlertMessage from '../../../Utils/AlertMessage';
import {
    ValidOnlyOneDiamond,
    ValidSaleDiamondNotNull,
    ValidSaleIsClear,
    ValidSaleType
} from './Acctions/ValidSaleMerge';

const TableSearchSaleDiamond = props => {
    const { sale, onResponse } = props

    const [sales, setSales] = useState([])
    const [saleSelected, setSaleSelected] = useState(null)
    const [searchSale, setSearchSale] = useState('')
    const [loading, setLoading] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [messageWarning, setMessageWarning] = useState('')

    const fetchData = async (searchSale) => {
        setLoading(true)

        const sales = await SearchSales(sale, searchSale)

        setSales(sales)
        setLoading(false)
    }

    const onChangeSearch = (event) => {
        const { value } = event.target

        setSearchSale(value)
        setSaleSelected(null)
        onResponse(null)

        fetchData(value)
    }

    const onSaleResponse = (sale) => {
        setShowWarning(false)
        setMessageWarning('')

        if (validSaleIsClear(sale)) {
            return
        }

        if (validSaleType(sale)) {
            return
        }

        if (validSaleDiamondNotNull(sale)) {
            return
        }

        if (validOnlyOneDiamond(sale)) {
            return
        }

        setSaleSelected(sale)
        onResponse(sale)
    }

    const validSaleDiamondNotNull = (sale) => {
        const response = ValidSaleDiamondNotNull(sale)

        if (response.show) {
            clearData(response)

            return true
        }

        return false
    }

    const validSaleIsClear = (sale) => {
        const response = ValidSaleIsClear(sale)

        if (response.show) {
            clearData(response)

            return true
        }

        return false
    }

    const validSaleType = (sale) => {
        const response = ValidSaleType(sale)

        if (response.show) {
            clearData(response)

            return true
        }

        return false
    }

    const validOnlyOneDiamond = (sale) => {
        const response = ValidOnlyOneDiamond(sale)

        if (response.show) {
            clearData(response)

            return true
        }

        return false
    }

    const clearData = (response) => {
        setSaleSelected(null)
        setShowWarning(response.show)
        setMessageWarning(response.message)
        onResponse(null)
    }

    const closeAlert = (value) => {
        setShowWarning(value)
    }

    return (
        <Row>
            <Col lg="12">
                <Form.Group>
                    <Row>
                        <Col sm="12" lg="3">
                            <Form.Label>
                                Search sale:
                            </Form.Label>
                        </Col>
                        <Col sm="12" lg="9">
                            <Form.Control
                                type='text'
                                onChange={onChangeSearch}
                                value={searchSale}
                            />
                        </Col>
                    </Row>

                </Form.Group>
            </Col>
            <Col lg="12 mt-2">
                {
                    showWarning ?
                        <AlertMessage
                            type='warning'
                            message={messageWarning}
                            infoHeading=''
                            autoClose={true}
                            timeClose={10000}
                            onResponse={closeAlert}
                        />
                        :
                        null
                }
                {
                    loading ?
                        <Row className='center-element'>
                            <LoadingSpinnerLayer />
                        </Row>
                        :
                        null
                }
                {
                    sales.length > 0 ?
                        <Table striped bordered hover className='table table-striped table-sm table-hover'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Order Number</th>
                                    <th>Diamond cert #, Shape ct Colour Clarity</th>
                                    <th>Sold price</th>
                                    <th>Type of Product</th>
                                    <th>Total Jewellery</th>
                                    <th>Express Manufacturing</th>
                                    <th>Engraving</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    <RowSearchSaleDiamond key={sale.id}
                                        sales={sales}
                                        onResponse={onSaleResponse}
                                    />
                                }
                            </tbody>
                        </Table>
                        :
                        null
                }
                {
                    saleSelected ?
                        <Form.Text muted>
                            selected sale: <b>({saleSelected.name} - {saleSelected.onlineOrderNumber})</b>
                        </Form.Text>
                        :
                        null
                }
            </Col>
        </Row>
    )
}

TableSearchSaleDiamond.propTypes = {
    sale: PropTypes.any.isRequired,
    onResponse: PropTypes.func.isRequired
}

export default TableSearchSaleDiamond