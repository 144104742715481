import { REACT_APP_WORKFLOW_TYPE_MANUFACTURING } from "../../../../../../global/const";
import { STATUS_CANCELLED } from "../../../../../../store/constants";

export const findWorkflowActivity = (workflowSale) => {
  const { workflowSaleModels = [] } = workflowSale;

  let workflowActivityResponse = null;

  workflowSaleModels.forEach((workflowSale) => {
    const { workflowActivityModels = {}, status = "" } = workflowSale;

    workflowActivityModels.forEach((workflowActivity) => {
      const { type = "", stockNumber = "" } = workflowActivity;

      if (
        type === REACT_APP_WORKFLOW_TYPE_MANUFACTURING &&
        stockNumber &&
        status !== STATUS_CANCELLED
      ) {
        workflowActivityResponse = workflowActivity;
      }
    });
  });

  return workflowActivityResponse;
};
